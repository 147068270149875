import React from "react";
import { Card } from "react-bootstrap";
import VehicleStateGraph from "./components/VehicleStateGraph";
import DriverStateGraph from "./components/DriverStateGraph";

const OverviewGraphPage = () => {
  return (
    <div className="overviewGraph">
      <h2 className="title">State Graph</h2>
      <Card style={{ border: "0px" }}>
        <VehicleStateGraph />
      </Card><br />
      <Card style={{ border: "0px" }}>
        <DriverStateGraph />
      </Card>
    </div>
  )
}

export default OverviewGraphPage;