import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  fetchResultsByCustomer,
  fetchSearchResults,
  fetchTachoDownloads,
} from "../../actions/tachoDownloadActions";
import Spinner from "../common/Spinner";
import { Card } from "react-bootstrap";
import PropTypes from "prop-types";
import TachoDownloadList from "./components/TachoMassDownloadList";
import { handleError } from "../../api/apiUtils";
import { fetchCustomers } from "../../actions/customerActions";
import * as tachoDownloadApi from "../../api/tachoDownloadApi";
import { apiCallError } from "../../actions/apiStatusActions";
import LazySearchBar from "../common/LazySearchBar";

const TachoDownloadPage = ({
  auth,
  customers,
  tachoDownloads,
  apiCallsInProgress,
}) => {
  const dwnltype = 1;
  const dispatch = useDispatch();
  const tachoDownloadList = [...tachoDownloads];
  let filters = ["CardNumber", "IMEI", "LicensePlate", "Name", "VIN"];
  if (auth.user.IsReseller == false) {
    filters = ["CardNumber", "IMEI", "LicensePlate", "Name", "VIN"];
  }

  const [resultCount, setResultCount] = useState(0);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    if (apiCallsInProgress === 0) {
      getCustomers(auth);

      tachoDownloadApi
        .GetTotalRowsCount(auth, dwnltype)
        .then((resultCount) => {
          setResultCount(resultCount);
        })
        .catch((error) => {
          apiCallError(error);
        });

      dispatch(fetchTachoDownloads(auth, dwnltype));
    }
  };

  const getCustomers = (auth) => {
    dispatch(fetchCustomers(auth));
  };

  const getSearchResults = (searchTerm, filter, customerId = null) => {
    dispatch(
      fetchSearchResults(auth, searchTerm, filter, dwnltype, customerId)
    );
  };

  const setSearchActive = () => {};

  const getTachoDownloadsByCustomer = (customer) => {
    dispatch(fetchResultsByCustomer(auth, customer, dwnltype));
  };

  const downloadFile = (e) => {
    let fileName = e.target.getAttribute("filename");
    const baseUrl = process.env.API_URL + "/api/rtddata/";
    let uri = baseUrl + e.target.id;

    fetch(uri, {
      headers: { Authorization: auth.accessToken },
    })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const href = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => handleError(error));
  };

  return (
    <div className="tachoMassDownload">
      <h2 className="title">Tacho MassMemory Download</h2>
      <LazySearchBar
        filters={filters}
        refresh={refresh}
        customers={customers}
        resultCount={resultCount}
        getSearchResult={getSearchResults}
        setSearchActive={setSearchActive}
        apiCallsInProgress={apiCallsInProgress}
        getObjectByCustomer={getTachoDownloadsByCustomer}
      />
      <br />
      <Card>
        {apiCallsInProgress === 0 ? (
          <TachoDownloadList
            tachoDownloads={tachoDownloadList}
            downloadFile={downloadFile}
          />
        ) : (
          <Spinner />
        )}
      </Card>
    </div>
  );
};

TachoDownloadPage.propTypes = {
  auth: PropTypes.object.isRequired,
  customers: PropTypes.array.isRequired,
  tachoDownloads: PropTypes.array.isRequired,
  apiCallsInProgress: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    customers: state.customers,
    tachoDownloads: state.tachoDownloads,
    apiCallsInProgress: state.apiCallsInProgress,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TachoDownloadPage);
