import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

// Redux
import {connect, useDispatch} from "react-redux";
import {
    fetchEndpoints,
    fetchEndpointsByCustomer,
    fetchMessageTypes,
    fetchProtocols,
    loadSearchedEndpoints,
    saveEndpoint, saveIntegration,
} from "../../actions/endpointActions";

// Components
import EndpointList from "./components/EndpointList";
import Spinner from "../common/Spinner";

// Styling
import CreateEndpoint from "./components/CreateEndpoint";
import {fetchCustomers} from "../../actions/customerActions";
import LazySearchBar from "../common/LazySearchBar";
import {getEndpointsCount} from "../../api/endpointApi";
import {apiCallError} from "../../actions/apiStatusActions";
import CreateIntegration from "./components/CreateIntegration";

const EndpointPage = ({auth, endpoints, customers, apiCallsInProgress}) => {
    const dispatch = useDispatch();
    //const endpointList = [...endpoints];
    const [showEndpoint, setShowEndoint] = useState(false);
    const [showIntegration, setShowIntegration] = useState(false);
    const [resultCount, setResultCount] = useState(0);
    const filters = ["Name", "IP", "Port"];

    useEffect(() => {
        refresh();
    }, []);

    useEffect(() => {
        Updateresultcount(auth);
    }, [endpoints]);

    const Updateresultcount = (auth) => {
        getEndpointsCount(auth)
            .then((resultCount) => {
                setResultCount(resultCount);
            })
            .catch((error) => {
                dispatch(apiCallError(error));
            });
    };

    const refresh = () => {
        if (apiCallsInProgress === 0) {
            dispatch(fetchEndpoints(auth));
            dispatch(fetchCustomers(auth));
            dispatch(fetchProtocols(auth));
            dispatch(fetchMessageTypes(auth));
            Updateresultcount(auth);
        }
    };

    const getSearchResult = (searchTerm, filter, customer = null) => {
        dispatch(loadSearchedEndpoints(auth, searchTerm, filter, customer));
    };

    const setSearchActive = () => {
    };

    const getEndpointsByCustomer = (customer) => {
        dispatch(fetchEndpointsByCustomer(auth, customer));
    };

    const saveEndpointHandler = (endpoint) => {
        dispatch(saveEndpoint(auth, endpoint));
    };

    const saveIntegrationHandler = (integration) => {
        dispatch(saveIntegration(auth, integration));
    };

    // console.log("Render: auth", auth);
    // console.log("Render: endpoints", endpoints);
    // console.log("Render: customers", customers);
    // console.log("Render: apiCallsInProgress", apiCallsInProgress);

    return (
        <div className="endpoints">
            <h2 className="title">Endpoints </h2>
            <LazySearchBar
                filters={filters}
                refresh={refresh}
                customers={customers}
                resultCount={resultCount}
                getSearchResult={getSearchResult}
                setSearchActive={setSearchActive}
                getObjectByCustomer={getEndpointsByCustomer}
                apiCallsInProgress={apiCallsInProgress}
            />
            <div className="create-object-div">
                <CreateEndpoint
                    show={showEndpoint}
                    setShow={setShowEndoint}
                    saveEndpoint={saveEndpointHandler}
                />
                <CreateIntegration
                    show={showIntegration}
                    setShow={setShowIntegration}
                    saveIntegration={saveIntegrationHandler}
                />
            </div>
            {endpoints.length === 0 && apiCallsInProgress > 0 ? (
                <Spinner/>
            ) : (
                <div>
                    {apiCallsInProgress === 0 ? (
                        <>
                            <EndpointList endpoints={endpoints}/>
                        </>
                    ) : (
                        <Spinner/>
                    )}
                </div>
            )}
        </div>
    );
};

EndpointPage.propTypes = {
    auth: PropTypes.object.isRequired,
    endpoints: PropTypes.array.isRequired,
    customers: PropTypes.array.isRequired,
    apiCallsInProgress: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        endpoints: state.endpoints,
        customers: state.customers,
        apiCallsInProgress: state.apiCallsInProgress,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EndpointPage);
