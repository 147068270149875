import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";

// Redux
import { useDispatch, connect } from "react-redux";
import { saveDeviceSettings } from "../../../actions/deviceSettingActions";
import { getDeviceById } from "../../../actions/deviceActions";

// Styling
import { Form, Row, Col, Button, Alert } from "react-bootstrap";

// Components
import Spinner from "../../common/Spinner";
import { getSettingsProfiles } from "../../../actions/settingsProfileActions";

const DeviceSettingsPaneV2 = ({
    auth,
    device,
    currentConfig,
    newConfig,
    isLoading,
    settingsProfiles,
    apiCallsInProgress,
}) => {
    const dispatch = useDispatch();
    let history = useNavigate();
    const [currentConfigSettings, setCurrentConfigSettings] = useState({}); // CURRENT
    const [currentSetConfigSettings, setCurrentSetConfigSettings] = useState({}); // NEW
    const [newConfigSettings, setNewConfigSettings] = useState({}); // NEWFORUX
    const [changedSettings, setChangedSettings] = useState({});

    const refresh = () => {
        if (apiCallsInProgress === 0) {
            setChangedSettings({});
            dispatch(getSettingsProfiles(auth))
            dispatch(getDeviceById(auth, device.Id));
            setConfigValues();
        }
    };

    useEffect(() => {
        refresh();
    }, []);

    useEffect(() => {
        setChangedSettings({});
        //document.getElementById("settings-form-id").reset();
        setConfigValues();
    }, [device]);

    const setConfigValues = () => {
        setCurrentConfigSettings({
            iButton: currentConfig.iButton,
            PrivateSwitchEnabled: currentConfig.PrivateSwitchEnabled,
            TachoLiveEnabled: currentConfig.TachoLiveEnabled,
            TachoRDLEnabled: currentConfig.TachoRDLEnabled,
            external5VEnabled: currentConfig.external5VEnabled,
            drivingStyleEventsEnabled: currentConfig.drivingStyleEventsEnabled,
            outputTogglingEnabled: currentConfig.outputTogglingEnabled,
            PTOEnabled: currentConfig.PTOEnabled,
            timedIntervalRelativeToSpeed: currentConfig.timedIntervalRelativeToSpeed,
            canWakeupEnabled: currentConfig.canWakeupEnabled,
            standbyMessageInterval: currentConfig.standbyMessageInterval,
            timedMessageInterval: currentConfig.timedMessageInterval,
            iButtonClearInterval: currentConfig.iButtonClearInterval,
            VIN: currentConfig.VIN,
            highResolutionRecordingEnabled: currentConfig.highResolutionRecordingEnabled,
            chargingMessageInterval: currentConfig.chargingMessageInterval,
            minuteRuleEnabled: currentConfig.minuteRuleEnabled,
            settingsProfileID: currentConfig.settingsProfileID,
        });
        setNewConfigSettings({
            iButton: newConfig.iButton,
            PrivateSwitchEnabled: newConfig.PrivateSwitchEnabled,
            TachoLiveEnabled: newConfig.TachoLiveEnabled,
            TachoRDLEnabled: newConfig.TachoRDLEnabled,
            external5VEnabled: newConfig.external5VEnabled,
            drivingStyleEventsEnabled: newConfig.drivingStyleEventsEnabled,
            outputTogglingEnabled: newConfig.outputTogglingEnabled,
            PTOEnabled: newConfig.PTOEnabled,
            timedIntervalRelativeToSpeed: newConfig.timedIntervalRelativeToSpeed,
            canWakeupEnabled: newConfig.canWakeupEnabled,
            standbyMessageInterval: newConfig.standbyMessageInterval,
            timedMessageInterval: newConfig.timedMessageInterval,
            iButtonClearInterval: newConfig.iButtonClearInterval,
            VIN: newConfig.VIN,
            highResolutionRecordingEnabled: newConfig.highResolutionRecordingEnabled,
            chargingMessageInterval: newConfig.chargingMessageInterval,
            minuteRuleEnabled: newConfig.minuteRuleEnabled,
            settingsProfileID: newConfig.settingsProfileID,
        });
        setCurrentSetConfigSettings({
            iButton: newConfig.iButton,
            PrivateSwitchEnabled: newConfig.PrivateSwitchEnabled,
            TachoLiveEnabled: newConfig.TachoLiveEnabled,
            TachoRDLEnabled: newConfig.TachoRDLEnabled,
            external5VEnabled: newConfig.external5VEnabled,
            drivingStyleEventsEnabled: newConfig.drivingStyleEventsEnabled,
            outputTogglingEnabled: newConfig.outputTogglingEnabled,
            PTOEnabled: newConfig.PTOEnabled,
            timedIntervalRelativeToSpeed: newConfig.timedIntervalRelativeToSpeed,
            canWakeupEnabled: newConfig.canWakeupEnabled,
            standbyMessageInterval: newConfig.standbyMessageInterval,
            timedMessageInterval: newConfig.timedMessageInterval,
            iButtonClearInterval: newConfig.iButtonClearInterval,
            VIN: newConfig.VIN,
            highResolutionRecordingEnabled: newConfig.highResolutionRecordingEnabled,
            chargingMessageInterval: newConfig.chargingMessageInterval,
            minuteRuleEnabled: newConfig.minuteRuleEnabled,
            settingsProfileID: newConfig.settingsProfileID,
        });
    };

    const inputHandler = (e) => {
        if (e.target.type == "checkbox") {
            const value = e.target.checked;
            setNewConfigSettings({
                ...newConfigSettings,
                [e.target.name]: value ? "1" : "0",
            });
            setChangedSettings({
                ...changedSettings,
                [e.target.name]: value ? "1" : "0",
            });
        } else if (e.target.name == "settingsProfileID") {
            let value = e.target.value
            if (e.target.value == "null") {
                value = ""
            }
            setNewConfigSettings({
                ...newConfigSettings,
                [e.target.name]: value,
            });
            setChangedSettings({
                ...changedSettings,
                [e.target.name]: value,
            });

        } else {
            let value = e.target.value;
            if (e.target.name === "VIN") {
                if (e.target.value == 0) {
                    value = ""
                }
            }
            setNewConfigSettings({
                ...newConfigSettings,
                [e.target.name]: value,
            });
            setChangedSettings({
                ...changedSettings,
                [e.target.name]: value,
            });
        }
    };

    const saveSettingsHandler = async (e) => {
        e.preventDefault();
        var changeList = [];
        for (const property in changedSettings) {
            if (currentSetConfigSettings[property] == changedSettings[property])
                continue;
            var obj = {
                SettingName: property,
                Value: changedSettings[property],
            };
            changeList.push(obj);
        }
        dispatch(
            saveDeviceSettings(auth, device.Id, device.IMEI, {
                SettingsList: changeList,
            })
        );
    };

    const isViewer = auth.user.Role === "Viewer";
    const disableOptions = () => {
        return (isViewer || newConfigSettings.settingsProfileID != "00000000-0000-0000-0000-000000000000")
    }


    const settingsProfile = settingsProfiles.find((e) => e.Id == currentConfigSettings.settingsProfileID)
    const settingsProfileName = settingsProfile ? settingsProfile.Name : "none"
    return (
        <>
            {!isLoading ? (
                <>
                    <Form id="settings-form-id">
                        {device.InSync ? (
                            ""
                        ) : (
                            <Alert variant="warning">Device is not in sync</Alert>
                        )}
                        <Form.Group as={Row}>
                            <Form.Label column sm="3">
                                <h6>Settings profile: </h6>
                                <i>
                                    {currentConfigSettings.settingsProfileID ==
                                        newConfigSettings.settingsProfileID
                                        ? ""
                                        : " (Currently " + settingsProfileName + ")"
                                    }
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    name="settingsProfileID"
                                    disabled={isViewer}
                                    as="select"
                                    value={newConfigSettings.settingsProfileID}
                                    onChange={(e) => inputHandler(e)}
                                >
                                    <option key="null" value="null">
                                        --
                                    </option>

                                    {settingsProfiles && settingsProfiles.map((profile) => {
                                        return (
                                            <option key={profile.Id} value={profile.Id}>
                                                {profile.Name}
                                            </option>
                                        )
                                    })}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                iButton
                                <i>
                                    {currentConfigSettings.iButton == newConfigSettings.iButton
                                        ? ""
                                        : currentConfigSettings.iButton == 1
                                            ? " (Currently ON)"
                                            : " (Currently OFF)"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Check
                                    name="iButton"
                                    checked={newConfigSettings.iButton == 1 ? true : false}
                                    onChange={inputHandler}
                                    disabled={disableOptions()}

                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                PrivateSwitchEnabled
                                <i>
                                    {currentConfigSettings.PrivateSwitchEnabled ==
                                        newConfigSettings.PrivateSwitchEnabled
                                        ? ""
                                        : currentConfigSettings.PrivateSwitchEnabled == 1
                                            ? " (Currently ON)"
                                            : " (Currently OFF)"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Check
                                    name="PrivateSwitchEnabled"
                                    value={newConfigSettings.PrivateSwitchEnabled}
                                    checked={
                                        newConfigSettings.PrivateSwitchEnabled == 1 ? true : false
                                    }
                                    onChange={inputHandler}
                                    disabled={disableOptions()}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                TachoLiveEnabled
                                <i>
                                    {currentConfigSettings.TachoLiveEnabled ==
                                        newConfigSettings.TachoLiveEnabled
                                        ? ""
                                        : currentConfigSettings.TachoLiveEnabled == 1
                                            ? " (Currently ON)"
                                            : " (Currently OFF)"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Check
                                    name="TachoLiveEnabled"
                                    value={newConfigSettings.TachoLiveEnabled}
                                    checked={
                                        newConfigSettings.TachoLiveEnabled == 1 ? true : false
                                    }
                                    onChange={inputHandler}
                                    disabled={disableOptions()}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                Tacho RDL
                                <i>
                                    {currentConfigSettings.TachoRDLEnabled ==
                                        newConfigSettings.TachoRDLEnabled
                                        ? ""
                                        : currentConfigSettings.TachoRDLEnabled == 1
                                            ? " (Currently ON)"
                                            : " (Currently OFF)"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Check
                                    name="TachoRDLEnabled"
                                    value={newConfigSettings.TachoRDLEnabled}
                                    defaultChecked={
                                        newConfigSettings.TachoRDLEnabled == 1 ? true : false
                                    }
                                    onChange={inputHandler}
                                    disabled={disableOptions()}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                External5VEnabled
                                <i>
                                    {currentConfigSettings.external5VEnabled ==
                                        newConfigSettings.external5VEnabled
                                        ? ""
                                        : currentConfigSettings.external5VEnabled == 1
                                            ? " (Currently ON)"
                                            : " (Currently OFF)"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Check
                                    name="external5VEnabled"
                                    value={newConfigSettings.external5VEnabled}
                                    defaultChecked={
                                        newConfigSettings.external5VEnabled == 1 ? true : false
                                    }
                                    onChange={inputHandler}
                                    disabled={disableOptions()}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                DrivingStyleEventsEnabled
                                <i>
                                    {currentConfigSettings.drivingStyleEventsEnabled ==
                                        newConfigSettings.drivingStyleEventsEnabled
                                        ? ""
                                        : " (Currently " +
                                        currentConfigSettings.drivingStyleEventsEnabled +
                                        ")"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Check
                                    name="drivingStyleEventsEnabled"
                                    value={newConfigSettings.drivingStyleEventsEnabled}
                                    defaultChecked={
                                        newConfigSettings.drivingStyleEventsEnabled == 1 ? true : false
                                    }
                                    onChange={inputHandler}
                                    disabled={disableOptions()}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                OutputTogglingEnabled
                                <i>
                                    {currentConfigSettings.outputTogglingEnabled ==
                                        newConfigSettings.outputTogglingEnabled
                                        ? ""
                                        : " (Currently " +
                                        currentConfigSettings.outputTogglingEnabled +
                                        ")"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Check
                                    name="outputTogglingEnabled"
                                    value={newConfigSettings.outputTogglingEnabled}
                                    defaultChecked={
                                        newConfigSettings.outputTogglingEnabled == 1 ? true : false
                                    }
                                    onChange={inputHandler}
                                    disabled={disableOptions()}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                TimedIntervalRelativeToSpeed
                                <i>
                                    {currentConfigSettings.timedIntervalRelativeToSpeed ==
                                        newConfigSettings.timedIntervalRelativeToSpeed
                                        ? ""
                                        : " (Currently " +
                                        currentConfigSettings.timedIntervalRelativeToSpeed +
                                        ")"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Check
                                    name="timedIntervalRelativeToSpeed"
                                    value={newConfigSettings.timedIntervalRelativeToSpeed}
                                    defaultChecked={
                                        newConfigSettings.timedIntervalRelativeToSpeed == 1 ? true : false
                                    }
                                    onChange={inputHandler}
                                    disabled={disableOptions()}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                HighResolutionRecordingEnabled
                                <i>
                                    {currentConfigSettings.highResolutionRecordingEnabled ==
                                        newConfigSettings.highResolutionRecordingEnabled
                                        ? ""
                                        : " (Currently " +
                                        currentConfigSettings.highResolutionRecordingEnabled +
                                        ")"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Check
                                    name="highResolutionRecordingEnabled"
                                    value={newConfigSettings.highResolutionRecordingEnabled}
                                    defaultChecked={
                                        newConfigSettings.highResolutionRecordingEnabled == 1 ? true : false
                                    }
                                    onChange={inputHandler}
                                    disabled={disableOptions()}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                CANWakeUpEnabled
                                <i>
                                    {currentConfigSettings.canWakeupEnabled ==
                                        newConfigSettings.canWakeupEnabled
                                        ? ""
                                        : " (Currently " +
                                        currentConfigSettings.canWakeupEnabled +
                                        ")"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Check
                                    name="canWakeupEnabled"
                                    value={newConfigSettings.canWakeupEnabled}
                                    defaultChecked={
                                        newConfigSettings.canWakeupEnabled == 1 ? true : false
                                    }
                                    onChange={inputHandler}
                                    disabled={disableOptions()}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                MinuteRuleEnabled
                                <i>
                                    {currentConfigSettings.minuteRuleEnabled ==
                                        newConfigSettings.minuteRuleEnabled
                                        ? ""
                                        : " (Currently " +
                                        currentConfigSettings.minuteRuleEnabled +
                                        ")"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Check
                                    name="minuteRuleEnabled"
                                    value={newConfigSettings.minuteRuleEnabled}
                                    defaultChecked={
                                        newConfigSettings.minuteRuleEnabled == 1 ? true : false
                                    }
                                    onChange={inputHandler}
                                    disabled={disableOptions()}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                StandbyMessageInterval (s)
                                <i>
                                    {currentConfigSettings.standbyMessageInterval ==
                                        newConfigSettings.standbyMessageInterval
                                        ? ""
                                        : " (Currently " +
                                        currentConfigSettings.standbyMessageInterval +
                                        ")"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    type="number"
                                    name="standbyMessageInterval"
                                    defaultValue={newConfigSettings.standbyMessageInterval}
                                    onChange={inputHandler}
                                    disabled={disableOptions()}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                TimedMessageInterval (s)
                                <i>
                                    {currentConfigSettings.timedMessageInterval ==
                                        newConfigSettings.timedMessageInterval
                                        ? ""
                                        : " (Currently " +
                                        currentConfigSettings.timedMessageInterval +
                                        ")"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    type="number"
                                    name="timedMessageInterval"
                                    defaultValue={newConfigSettings.timedMessageInterval}
                                    onChange={inputHandler}
                                    disabled={disableOptions()}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                iButtonClearInterval (s)
                                <i>
                                    {currentConfigSettings.iButtonClearInterval ==
                                        newConfigSettings.iButtonClearInterval
                                        ? ""
                                        : " (Currently " +
                                        currentConfigSettings.iButtonClearInterval +
                                        ")"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    type="number"
                                    name="iButtonClearInterval"
                                    defaultValue={newConfigSettings.iButtonClearInterval}
                                    onChange={inputHandler}
                                    disabled={disableOptions()}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                ChargingMessageInterval (s)
                                <i>
                                    {currentConfigSettings.chargingMessageInterval ==
                                        newConfigSettings.chargingMessageInterval
                                        ? ""
                                        : " (Currently " +
                                        currentConfigSettings.chargingMessageInterval +
                                        ")"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    type="number"
                                    name="chargingMessageInterval"
                                    defaultValue={newConfigSettings.chargingMessageInterval}
                                    onChange={inputHandler}
                                    disabled={disableOptions()}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                VIN
                                <i>
                                    {currentConfigSettings.VIN ==
                                        newConfigSettings.VIN
                                        ? ""
                                        : " (Currently " +
                                        currentConfigSettings.VIN +
                                        ")"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    type="string"
                                    name="VIN"
                                    defaultValue={newConfigSettings.VIN == 0 ? "" : newConfigSettings.VIN}
                                    onChange={inputHandler}
                                    disabled={disableOptions()}
                                />
                            </Col>
                        </Form.Group>

                        <hr></hr>
                        <Form.Group>
                            <Row>
                                <Col sm="11">
                                    <Button
                                        variant="outline-warning"
                                        onClick={() => history.push("/devices")}
                                    >
                                        Back
                                    </Button>
                                </Col>
                                <Col sm="1">
                                    {!isViewer && (
                                        <Button
                                            variant="success"
                                            onClick={(e) => saveSettingsHandler(e)}
                                        >
                                            Save
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                </>
            ) : (
                <Spinner />
            )}
        </>
    );
};

DeviceSettingsPaneV2.propTypes = {
    auth: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired,
    currentConfig: PropTypes.object.isRequired,
    newConfig: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    settingsProfiles: PropTypes.array.isRequired,
    apiCallsInProgress: PropTypes.number.isRequired
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        device: state.deviceDetails.device,
        currentConfig: state.deviceDetails.currentConfig,
        newConfig: state.deviceDetails.newConfig,
        isLoading: state.deviceDetails.isLoading,
        apiCallsInProgress: state.apiCallsInProgress,
        settingsProfiles: state.settingsProfiles,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSettingsPaneV2);
