import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const customerDetailsReducer = (
  state = initialState.customerDetails,
  action
) => {
  switch (action.type) {
    case "LOADING_CUSTOMER_DETAILS":
      return {
        ...state,
        isLoading: true,
      };
    case types.LOAD_CUSTOMER_DETAILS_SUCCESS:
      return {
        ...state,
        customer: action.payload.customer.Customer,
        users: action.payload.customer.Users,
        devices: action.payload.customer.Devices,
        companyCards: action.payload.customer.CompanyCards,
        authServers: action.payload.customer.AuthServers,
        isLoading: false,
      };
    case types.LOAD_CUSTOMER_DEVICES_SUCCESS:
      return {
        ...state,
        devices: action.payload.devices,
        isLoading: false,
      };
    case types.LOAD_CONFIG_TEMPLATES_SUCCESS:
      return {
        ...state,
        configTemplates: action.payload.configTemplates,
        isLoading: false,
      };

    case types.SAVE_CUSTOMER_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload.user],
      };

    case types.UPDATE_CUSTOMER_USER_SUCCESS:
      return {
        ...state,
        users: [
          ...state.users.filter((user) => user.Id != action.payload.user.Id),
          action.payload.user,
        ],
      };
    case types.UPDATE_COMPANY_CARD_SUCCESS:
      return {
        ...state,
        companyCards: [
          ...state.companyCards.filter(
            (card) => card.Id != action.payload.companyCard.Id
          ),
          action.payload.companyCard,
        ],
      };

    case types.REMOVE_CUSTOMER_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter((user) => user.Id != action.payload.user),
      };

    case types.SAVE_CUSTOMER_DEVICES_SUCCESS:
      return {
        ...state,
        devices: [...state.devices, action.payload.devices],
      };

    case types.REMOVE_CUSTOMER_DEVICES_SUCCESS:
      return {
        ...state,
        devices: state.devices.filter(
          (device) => device.Id != action.payload.devices
        ),
      };
    default:
      return { ...state };
  }
};

export default customerDetailsReducer;
