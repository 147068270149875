import initialState from "./initialState";
import * as types from "../actions/actionsTypes";

export default function protocolsReducer(
    state = initialState.protocols,
    action
) {
    switch (action.type) {
        case types.LOAD_PROTOCOLS_SUCCESS:
            return action.payload.protocols;
        case types.LOGOUT:
            return initialState.protocols;
        default:
            return state;
    }
}