import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const firmwareReducer = (state = initialState.firmwares, action) => {
  switch (action.type) {
    case types.LOAD_FIRMWARES_SUCCESS:
      return action.payload.firmwares;
    case types.LOAD_ACTIVEFIRMWARES_SUCCESS:
      return action.payload.firmwares;
    case types.LOAD_FIRMWARE_SUCCESS:
      return [
        ...state.filter(
          (firmware) => firmware.Id != action.payload.firmwares.Id
        ),
        action.payload.firmwares,
      ];
    case types.UPDATE_FIRMWARE_SUCCESS:
      return [
        ...state.filter(
          (firmware) => firmware.Id != action.payload.firmwares.Id
        ),
        action.payload.firmwares,
      ];
    case types.SAVE_FIRMWARE_SUCCESS:
      console.log(action.payload.firmwares)
      return [
        ...state.filter(
          (firmware) => firmware.Id != action.payload.firmwares.Id
        ),
        action.payload.firmwares,
      ];
      case types.DELETE_FIRMWARE_SUCCESS:
        return state.filter((firmware) => firmware.Id !== action.payload.firmwares.Id);
    default:
      return state;
  }
};

export default firmwareReducer;
