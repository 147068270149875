import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const roleReducer = (state = initialState.roles, action) => {
  switch (action.type) {
    case types.LOAD_ROLES_SUCCESS:
      return action.payload.roles;
    case types.SAVE_ROLE_SUCCESS:
      return [...state, action.payload.role];
    case types.UPDATE_ROLE_SUCCESS:
      return [
        ...state.filter((role) => role.Id != action.payload.role.Id),
        action.payload.role,
      ];
    case types.REMOVE_ROLE_SUCCESS:
      return state.filter((role) => role.Id != action.payload.role);
    default:
      return state;
  }
};

export default roleReducer;
