import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Redux
import { useDispatch, connect } from "react-redux";
import { saveUser } from "../../actions/userActions";
import { fetchRoles } from "../../actions/roleActions";

// Components
import CreateUserForCustomerModal from "./components/CreateUserForCustomerModal";
import UserTable from "../account/components/UserTable";

const CustomerUsersTab = ({ auth, users, roles, customer }) => {
  const dispatch = useDispatch();
  const [showUser, setShowUser] = useState(false);
  const [newUser, setNewUser] = useState({
    customerId: "",
    email: "",
    password: "",
    passwordConf: "",
    roleId: "",
  });

  useEffect(() => {
    dispatch(fetchRoles(auth));
  }, []);

  const saveCustomerUser = () => {
    newUser.customerId = customer.Id;
    delete newUser.passwordConf;

    dispatch(saveUser(auth, newUser));
    setShowUser(false);
    setNewUser({
      customerId: "",
      email: "",
      password: "",
      passwordConf: "",
      roleId: "",
    });
  };


  //dispatch(updateAccountUser(auth, auth.user.Id, user));

  return (
    <div>
      <div className="create-object-div">
        <CreateUserForCustomerModal
          auth={auth}
          show={showUser}
          setShow={setShowUser}
          newUser={newUser}
          setNewUser={setNewUser}
          saveCustomerUser={saveCustomerUser}
          roles={roles}
        />
      </div>
      <br />
      <UserTable account={false} auth={auth} users={users} roles={roles} />
    </div>
  );
};

CustomerUsersTab.propTypes = {
  auth: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    customer: state.customerDetails.customer,
    users: state.customerDetails.users,
    roles: state.roles,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerUsersTab);
