import React from 'react';
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import moment from "moment";
import {InputGroup, Form, FormControl, Button, Card, Row, Col} from "react-bootstrap";

const DeviceSearch = ({
                          devices,
                          deviceTestSettings,
                          deviceList,
                          setDeviceList,
                          startDeviceTesting,
                          openConfigurationSettingsModal
                      }) => {

    const handleDeviceInput = (e) => {
        setDeviceList([]);
        let input = e.target.value;
        if (input.length > 2) {
            let list = devices.filter((i) => {
                return i.IMEI.includes(input) || i.SN.includes(input);
            });
            if (list[0] != undefined) {
                setDeviceList(list);
            }
        }
    };

    return (
        <>
            <h2 className="title">Installation Test</h2>
            <InputGroup className="mb-3">
                <FormControl
                    placeholder="IMEI or S/N"
                    aria-describedby="basic-addon2"
                    onChange={handleDeviceInput}
                />
            </InputGroup>
            <Form.Text muted>
                Enter the IMEI of Serialnumber to start the installation test. (minimal
                3 char)
            </Form.Text>
            {deviceList.length == 0 || deviceList.length > 5 ? (
                <></>
            ) : (
                deviceList.map((device) => {
                    return (
                        <div key={device.Id}>
                            <Card>
                                <Card.Header as="h5">
                                    {device.IMEI} - {device.SN}
                                </Card.Header>
                                <Card.Text>
                                    Customer : <b>{device.Customer}</b>
                                    <br/>
                                    Hardware : <b>{device.Hardware}</b>
                                    <br/>
                                    Last Message :{" "}
                                    <b>{moment(device.LastMsg).format("DD-MM-YYYY HH:mm")}</b>
                                    <br/>
                                    {device.ConfigurationGroup != null && (
                                        <>
                                            ConfigurationGroup : <b>{device.ConfigurationGroup}</b>
                                            <br/>{" "}
                                        </>
                                    )}
                                    InSync : <b>{device.InSync === true ? <>True</> : <>False</>}</b>
                                    <br/>
                                </Card.Text>
                                <Card.Footer>
                                    {deviceTestSettings.filter(devTestSetting => devTestSetting.Imei === device.IMEI).length > 0 ? (
                                        <Row key={device.Id}>
                                            <Col>
                                                <Button className="w-100 btn-secondary"
                                                        onClick={() => openConfigurationSettingsModal(device)}>
                                                    Change Configuration
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button className="w-100"
                                                        onClick={() =>
                                                            startDeviceTesting(deviceTestSettings.find(devTestSetting => devTestSetting.Imei === device.IMEI))
                                                        }>
                                                    Start Testing
                                                </Button>
                                            </Col>
                                        </Row>
                                    ) : (
                                        <Row key={device.Id}>
                                            <Col>
                                                <Button className="w-100"
                                                        onClick={() => openConfigurationSettingsModal(device)}>
                                                    Set Configuration
                                                </Button>
                                            </Col>
                                        </Row>
                                    )}
                                </Card.Footer>
                            </Card>
                            <br/>
                        </div>
                    );
                })
            )}
        </>
    );
}

DeviceSearch.propTypes = {
    devices: PropTypes.array.isRequired,
    deviceTestSettings: PropTypes.array.isRequired,
    deviceList: PropTypes.array.isRequired,
    setDeviceList: PropTypes.func.isRequired,
    startDeviceTesting: PropTypes.func.isRequired,
    openConfigurationSettingsModal: PropTypes.func.isRequired,
};

function mapStateToProps() {
    return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSearch);