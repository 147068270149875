import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const authServerReducer = (state = initialState.authServers, action) => {
    switch (action.type) {
        case types.LOAD_AUTHENTICATION_SERVERS_SUCCESS:
            return action.payload.authServers;
        case types.SAVE_AUTHENTICATION_SERVER_SUCCESS:
            return [...state, action.payload.authServer];
        case types.UPDATE_AUTHENTICATION_SERVER_SUCCESS:
            return [
                ...state.filter((server) => server.Id !== action.payload.authServer.Id),
                action.payload.authServer,
            ];
        case types.REMOVE_AUTHENTICATION_SERVER_SUCCESS:
            return state.filter((server) => server.Id !== action.payload.authServer);
        case types.LOAD_SEARCH_RESULT_AUTHENTICATION_SERVER_SUCCESS:
            return action.payload.authServers;
        default:
            return state;
    }
};

export default authServerReducer;
