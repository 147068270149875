import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Table } from "react-bootstrap";
import MessageURLControlRow from "../../deviceDetails/messages/components/MessageURLControlRow";
import MessageOverViewRow from "../../deviceDetails/messages/components/MessageOverviewRow";
import ChangeMessageModal from "../../deviceDetails/messages/components/ChangeMessageModal";

const SettingsProfileMessagesModal = ({
    settingsProfile,
    setSettingsProfile,
    nextPage,
    previousPage,
}) => {
    const [message, setMessage] = useState({});
    const [show, setShow] = useState(false)
    const showModalHandler = (message) => {
        setMessage(message)
        setShow(true);
    }

    const saveMessageHandler = () => {
        switch (message.index) {
            case 0:
                setSettingsProfile({
                    ...settingsProfile,
                    message0: { ...message }
                })
                break
            case 1:
                setSettingsProfile({
                    ...settingsProfile,
                    message1: { ...message }
                })
                break
            case 2:
                setSettingsProfile({
                    ...settingsProfile,
                    message2: { ...message }
                })
                break

        }
    }

    const disableOptions = () => { return false }

    return (
        <>
            <Modal.Body>
                <MessageURLControlRow
                    name="Message 0 URL"
                    message={settingsProfile.message0}
                    setMessage={setMessage}
                    isViewer={false}
                    disableOptions={disableOptions}
                    saveMessageHandler={saveMessageHandler}
                />
                <br />
                <MessageURLControlRow
                    name="Message 1 URL"
                    message={settingsProfile.message1}
                    setMessage={setMessage}
                    isViewer={false}
                    disableOptions={disableOptions}
                    saveMessageHandler={saveMessageHandler}
                />
                <br />
                <MessageURLControlRow
                    name="Message 2 URL"
                    message={settingsProfile.message2}
                    setMessage={setMessage}
                    isViewer={false}
                    disableOptions={disableOptions}
                    saveMessageHandler={saveMessageHandler}
                />
                <hr></hr>
                <Table striped hover responsive>
                    <thead>
                        <tr>
                            <th>Message</th>
                            <th>Time</th>
                            <th>TripStart</th>
                            <th>TripEnd</th>
                            <th>Charge</th>
                            <th>Power</th>
                            <th>Wake</th>
                            <th>Standby</th>
                            <th>Move</th>
                            <th>LowBV</th>
                            <th>IO</th>
                            <th>Unauth</th>
                            <th>Login</th>
                            <th>DriveStyle</th>
                            <th>Diag</th>
                            <th>TachoLive</th>
                            <th>Request</th>
                        </tr>
                    </thead>
                    <tbody>
                        <MessageOverViewRow
                            message={settingsProfile.message0}
                            showModalHandler={showModalHandler}
                        />
                        <MessageOverViewRow
                            message={settingsProfile.message1}
                            showModalHandler={showModalHandler}
                        />
                        <MessageOverViewRow
                            message={settingsProfile.message2}
                            showModalHandler={showModalHandler}
                        />
                    </tbody>
                </Table>
                {message && (
                    <ChangeMessageModal
                        message={message}
                        saveMessageHandler={saveMessageHandler}
                        setMessage={setMessage}
                        name={"Message configuration"}
                        show={show}
                        setShow={setShow}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={previousPage}>Back</Button>
                <Button onClick={nextPage}>Next</Button>
            </Modal.Footer>
        </>
    )
}

SettingsProfileMessagesModal.propTypes = {
    settingsProfile: PropTypes.object.isRequired,
    setSettingsProfile: PropTypes.func.isRequired,
    nextPage: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
}

export default SettingsProfileMessagesModal