import React, { useState } from "react";
import PropTypes from "prop-types";

// Bootstrap
import { Button, Modal } from "react-bootstrap";

const Confirmation = ({ buttonText, onConfirm, description }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const ConfirmAction = () => {
    onConfirm();
    handleClose();
  };

  return (
    <>
      <div onClick={handleShow}>{buttonText}</div>
      <Modal
        className="confirmation"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>{description}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={ConfirmAction}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

Confirmation.propTypes = {
  buttonText: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
};

export default Confirmation;
