import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";
import { Table, Button } from "react-bootstrap";


import ChangeCustomerModal from "./ChangeCustomerModal";

//Compoments
import { SortedTable } from "../../common/SortedTable";
import SortButton from "../../common/SortButton";

//TODO: Add the edit button down where the X selected device is
//TODO: Add the delete button down where the X selected device is

const DeviceList = ({
    features,
    disableOptions,
    devices,
    selected,
    setSelected,
    show,
    setShow,
    customer,
    setCustomer,
    configurationGroup,
    setConfigurationGroup,
    saveDevices,
    showModalHandler,
    removeSingleSelect,
    auth,

}) => {

    const { list, requestSort, sortConfig } = SortedTable(devices);

    const tableRowSelected = (e) => {
        if (e.target.checked === true) {
            let selectedList = [...selected, e.target.value];
            setSelected(selectedList);
        } else {
            let unSelectedList = [...selected];
            let index = unSelectedList.indexOf(e.target.value);
            if (index !== -1) {
                unSelectedList.splice(index, 1);
                setSelected(unSelectedList);
            }
        }
    };

    const setCheckboxes = (e) => {
        if (e.target.checked === true) {
            let deviceList = [];
            devices.forEach((device) => {
                deviceList.push(device.IMEI);
            });
            setSelected(deviceList);
        } else {
            setSelected([]);
        }
    };

    // const clearAllSelected = () => {
    //     setSelected([]);
    //     setSingleSelect("");
    // };

    // const openNewTab = () => {
    //     const device = devices.find((device) => device.IMEI == singleSelect);
    //     window.open("/device/" + device.Id);
    // };

    function dateValidator(lastDownloaded, interval) {
        if (lastDownloaded >= "01-01-1900") {
            if (moment(lastDownloaded).add(interval, "days") <= moment()) {
                return (
                    <div className="font-weight-bolder text-danger">
                        {moment(lastDownloaded).format("DD-MM-YYYY HH:mm")}
                    </div>
                );
            } else {
                return (
                    <div className="font-weight-bolder text-success">
                        {moment(lastDownloaded).format("DD-MM-YYYY HH:mm")}
                    </div>
                );
            }
        } else {
            return <div className="font-weight-bolder text-warning">No Download</div>;
        }
    }

    const isViewer = auth.user.Role === "Viewer";

    return (
        <>
            <Table hover striped responsive className="devices-table">
                <thead className="thead-inverse">
                    <tr>
                        {!isViewer && (
                            <th className="checkbox-table-cell">
                                <input
                                    disabled={disableOptions}
                                    type="checkbox"
                                    className="checkbox-table-style"
                                    checked={devices.length === selected.length}
                                    onChange={(e) => setCheckboxes(e)}
                                />
                            </th>
                        )}
                        <th className="device-table-customer">
                            Customer
                            <SortButton
                                name="Customer"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            IMEI
                            <SortButton
                                name="IMEI"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            SN
                            <SortButton
                                name="SN"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Hardware
                            <SortButton
                                name="Hardware"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Type
                            <SortButton
                                name="DeviceName"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            FirmWare
                            <SortButton
                                name="Firmware"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Protocol
                            <SortButton
                                name="Protocol"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Name
                            <SortButton
                                name="ProtocolName"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Conf
                            <SortButton
                                name="ConfigurationGroup"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            ICCID
                            <SortButton
                                name="ICCID"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th className="device-table-inSync">
                            InSync
                            <SortButton
                                name="InSync"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            LastMsg
                            <SortButton
                                name="LastMsg"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        {
                            features.filter((e) => e === "Tacho Download").length === 1 && (
                                <th>Last Download</th>
                            )
                        }
                        {
                            (!disableOptions && selected.length <= 1) && (
                                <>
                                    <th></th>
                                    {/*<th></th>*/}
                                </>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {list.length === 0 ? (
                        <tr>
                            <td colSpan="14" style={{ textAlign: "center" }}>
                                No devices found
                            </td>
                        </tr>
                    ) : (
                        list.map((device) => {

                            return (
                                <tr
                                    className={"table-row " + (selected.includes(device.IMEI) ? "row-selected" : "")}
                                    key={device.IMEI}
                                >
                                    {!isViewer && (
                                        <td className="options-table-cell">
                                            <input
                                                disabled={disableOptions}
                                                type="checkbox"
                                                className="options-table-cell"
                                                name="device"
                                                value={device.IMEI}
                                                onChange={tableRowSelected}
                                                checked={selected.includes(device.IMEI)}
                                            />
                                        </td>
                                    )}
                                    <td className="device-table-customer">{device.Customer}</td>
                                    <td>
                                        <Link to={"/device/" + device.Id}>{device.IMEI}</Link>
                                    </td>
                                    <td>{device.SN}</td>
                                    <td>{device.Hardware}</td>
                                    <td>{device.DeviceName}</td>
                                    <td>{device.Firmware}</td>
                                    <td>{device.Protocol}</td>
                                    <td>
                                        {device.ProtocolName} <i>({device.ProtocolBrand})</i>
                                    </td>
                                    <td>{device.ConfigurationGroup}</td>
                                    <td>{device.ICCID}</td>
                                    <td>{device.InSync ? "Yes" : "No"}</td>
                                    <td>{moment(device.LastMsg).format("DD-MM-YYYY HH:mm")}</td>
                                    {
                                        features.filter((e) => e === "Tacho Download").length ===
                                        1 && (
                                            <td>
                                                {dateValidator(device.LastDownload, device.RTDInterval)}
                                            </td>
                                        )
                                    }
                                    {
                                        (!disableOptions && selected.length <= 1 && !isViewer) && (
                                            <>
                                                <td>
                                                    <Button
                                                        className="shadow-sm border-secondary"
                                                        variant="light"
                                                        size="sm"
                                                        onClick={() => {
                                                            showModalHandler(device.IMEI)
                                                        }}
                                                    >
                                                        <span>
                                                            {/*<img*/}
                                                            {/*    src={EditIcon}*/}
                                                            {/*    alt="Edit"*/}
                                                            {/*/>*/}
                                                            Change Customer
                                                        </span>
                                                    </Button>
                                                </td>
                                                {/*<td>*/}
                                                {/*    <Button className="shadow-sm border-secondary" variant="light"*/}
                                                {/*            onClick={() => {*/}
                                                {/*                openNewTab();*/}
                                                {/*            }}*/}
                                                {/*    >*/}
                                                {/*        <span>*/}
                                                {/*            <img*/}
                                                {/*                src={EditIcon}*/}
                                                {/*                alt="Edit"*/}
                                                {/*            />*/}
                                                {/*            Open in new tab*/}
                                                {/*        </span>*/}
                                                {/*    </Button>*/}
                                                {/*</td>*/}
                                            </>
                                        )
                                    }
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </Table>
            {!disableOptions && (
                <>
                    {selected.length > 0 &&
                        (
                            <ChangeCustomerModal
                                show={show}
                                setShow={setShow}
                                selected={selected}
                                customer={customer}
                                setCustomer={setCustomer}
                                configurationGroup={configurationGroup}
                                setConfigurationGroup={setConfigurationGroup}
                                saveDevices={saveDevices}
                                removeSingleSelect={removeSingleSelect}
                            />
                        )
                    }
                </>
            )}
        </>
    );
};

DeviceList.propTypes = {
    features: PropTypes.array.isRequired,
    devices: PropTypes.array.isRequired,
    selected: PropTypes.array.isRequired,
    setSelected: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    customer: PropTypes.string.isRequired,
    setCustomer: PropTypes.func.isRequired,
    configurationGroup: PropTypes.string.isRequired,
    setConfigurationGroup: PropTypes.func.isRequired,
    saveDevices: PropTypes.func.isRequired,
    disableOptions: PropTypes.bool.isRequired,
    showModalHandler: PropTypes.func.isRequired,
    removeSingleSelect: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

export default DeviceList;
