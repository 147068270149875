const baseUrl = process.env.API_URL + "/api/devices/flags";
import {handleResponse, handleError} from "./apiUtils";

export function getDeviceTypes(auth) {
    return fetch(baseUrl, {
        headers: {
            Authorization: auth.accessToken,
        },
    })
        .then(handleResponse)
        .catch(handleError);
}