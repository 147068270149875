import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, connect } from "react-redux";
import { fetchEndpoints } from "../../../actions/endpointActions";
import {
    getEndpointsByDevice,
    removeEndpointDevice, saveEndpointDevice, updateEndpointDevice,
} from "../../../actions/deviceEndpointActions";
import Confirmation from "../../common/Confirmation";
import ChangeEndpointModal from "./ChangeEndpointModal";
import Spinner from "../../common/Spinner";
import { Button, Table } from "react-bootstrap";
import "./EndpointsPane.css";
import EditIcon from "../../common/icons/pen-to-square-solid.svg";
import DeleteIcon from "../../common/icons/trash-can-solid.svg";

const DeviceEndpointsPane = ({
    auth,
    device,
    endpoints,
    deviceEndpoints,
    isLoading,
}) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [selectedEndpoint, setSelectedEndpoint] = useState({});

    useEffect(() => {
        getEndpointsDevice();
    }, []);

    const getEndpointsDevice = () => {
        dispatch(fetchEndpoints(auth));
        dispatch(getEndpointsByDevice(auth, device.IMEI));
    };

    const selectEndpoint = (e) => {
        const endpoint = deviceEndpoints.find((item) => item.MessageType === e);
        setSelectedEndpoint({ ...endpoint });
    };

    const handleShow = () => {
        setShow(true);
    };

    const removeEndpointHandler = (e) => {
        const endpointToRemove = deviceEndpoints.find((item) => item.Id === e);
        (() => dispatch(removeEndpointDevice(auth, e, endpointToRemove.MessageType)))();
        setShow(false);
    };

    const showModalHandler = (e) => {
        handleShow();
        selectEndpoint(e);
    }

    const saveEndpointHandler = (e) => {
        e.preventDefault();

        const endpointToSave = { ...selectedEndpoint, IMEI: device.IMEI };

        if (endpointToSave.Protocol !== "non") {
            (() => dispatch(updateEndpointDevice(auth, endpointToSave)))();
        } else {
            (() => dispatch(saveEndpointDevice(auth, endpointToSave)))();
        }
        setShow(false);
    };
    const devEps = [...deviceEndpoints].sort((a, b) => (a.MessageType > b.MessageType ? 1 : -1))
    return (
        <>
            {!isLoading ? (
                <>
                    <Table striped hover responsive>
                        <thead>
                        <tr>
                            <th>Message</th>
                            <th>Protocol</th>
                            <th>Name</th>
                            <th>Server</th>
                            <th>Port</th>
                            <th>Username</th>
                            <th>Password</th>
                            <th>API_KEY</th>
                            <th>Authorization</th>
                            <th>Status</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        { devEps.map((endpoint) => {
                            
                            const isViewer = auth.user.Role === "Viewer";
                                return (
                                    <tr key={endpoint.MessageType} className="table-row">
                                        <td>{endpoint.MessageType}</td>
                                        <td>{endpoint.Protocol}</td>
                                        <td>{endpoint.Name}</td>
                                        <td>{endpoint.IP}</td>
                                        <td>{endpoint.Port}</td>
                                        <td>{endpoint.Username}</td>
                                        <td>{endpoint.Password}</td>
                                        <td>{endpoint.API_KEY}</td>
                                        <td>{endpoint.Authorization}</td>
                                        <td>{endpoint.Status}</td>
                                        <td>
                                        {!isViewer && (
                                            <Button className="shadow-sm border-secondary" variant="light"
                                                    onClick={() => {
                                                        showModalHandler(endpoint.MessageType)
                                                    }}>
                                                <img src={EditIcon} alt="Edit"/>
                                            </Button>
                                        )}
                                        </td>
                                        <td>
                                        {!isViewer && (
                                            <Button className="shadow-sm border-secondary" variant="light">
                                                <Confirmation
                                                    buttonText={<img src={DeleteIcon} alt="Delete"/>}
                                                    description={`Are you sure you want to remove this modem request?`}
                                                    onConfirm={() => removeEndpointHandler(endpoint.Id)}
                                                />
                                            </Button>
                                        )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    {selectedEndpoint && (
                        <ChangeEndpointModal
                            device={device}
                            show={show}
                            setShow={setShow}
                            selectedEndpoint={selectedEndpoint}
                            setSelectedEndpoint={setSelectedEndpoint}
                            saveEndpointHandler={saveEndpointHandler}
                            removeEndpointHandler={removeEndpointHandler}
                            endpoints={endpoints}
                        />
                    )}
                </>
            ) : (
                <Spinner/>
            )}
        </>
    );
};

DeviceEndpointsPane.propTypes = {
    auth: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired,
    endpoints: PropTypes.array.isRequired,
    deviceEndpoints: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        device: state.deviceDetails.device,
        endpoints: state.endpoints,
        deviceEndpoints: state.deviceDetails.endpoints,
        isLoading: state.deviceDetails.isLoading,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceEndpointsPane);
