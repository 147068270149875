import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Styling
import { Modal, Button, Form, Row, Col } from "react-bootstrap";

const UpdateSettingModal = ({
  show,
  setShow,
  updatedSetting,
  setUpdatedSetting,
  saveSettingsHandler,
}) => {
  const [disabled, setDisabled] = useState(false);
  const handleClose = () => setShow(false);

  const inputHandler = (e) => {
    setUpdatedSetting({
      ...updatedSetting,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    let boolNames = ["iButton", "PrivateSwitchEnabled", "TachoLiveEnabled"];
    let stringNames = ["TachoType"];
    let validValue = true;
    let validLength = true;

    if (stringNames.includes(updatedSetting.SettingName)) {
      if (
        updatedSetting.Value == "vdo" ||
        updatedSetting.Value == "stoneridge" ||
        updatedSetting.Value == "0"
      ) {
        validValue = true;
      } else {
        validValue = false;
      }
    }

    if (boolNames.includes(updatedSetting.SettingName)) {
      if (updatedSetting.Value == "0" || updatedSetting.Value == "1") {
        validValue = true;
      } else {
        validValue = false;
      }
    }

    if (updatedSetting.Value == "") {
      validLength == false;
    } else {
      validLength == true;
    }

    if (validValue && validLength) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [updatedSetting]);

  return (
    <div>
      <Modal show={show} onHide={handleClose} centered className="text-center">
        <Form>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {updatedSetting.SettingName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="2">
                <h6>Value:</h6>
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type={
                    updatedSetting.SettingName == "TachoType"
                      ? "text"
                      : "number"
                  }
                  name="Value"
                  defaultValue={updatedSetting.Value}
                  onChange={inputHandler}
                />
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={disabled}
              onClick={saveSettingsHandler}
              variant="success"
              type="submit"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

UpdateSettingModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  updatedSetting: PropTypes.object.isRequired,
  setUpdatedSetting: PropTypes.func.isRequired,
  saveSettingsHandler: PropTypes.func.isRequired,
};

export default UpdateSettingModal;
