import React, { useEffect, useState } from "react";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Styling
import { Modal, Button, Form, Container, Row, Col } from "react-bootstrap";

const ChangeEndpointModal = ({
  device,
  show,
  setShow,
  selectedEndpoint,
  setSelectedEndpoint,
  saveEndpointHandler,
  endpoints,
}) => {
  // const [protocolList, setProtocolList] = useState([]);
  // const [updatedEndpoint, setUpdatedEndpoint] = useState({});
  const [listEndpoint, setListEndpoint] = useState();
  const [disabled, setDisabled] = useState(true);
  const handleClose = () => setShow(false);

  useEffect(() => {
    setListEndpoint(
      endpoints.find((item) => item.Id === selectedEndpoint.EndpointId)
    );
  }, [selectedEndpoint, endpoints]);

  const inputHandler = (e) => {
    if (e.target.name === "EndpointId") {
      setListEndpoint(endpoints.find((item) => item.Id == e.target.value));
    }

    setSelectedEndpoint({
      ...selectedEndpoint,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (selectedEndpoint.EndpointId === undefined) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [selectedEndpoint]);

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Form>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {device.IMEI}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col xs={12} md={4}>
                  <Form.Label>
                    <h6>Message Type: </h6>
                  </Form.Label>
                </Col>
                <Col xs={6} md={4}>
                  <Form.Control
                    plaintext
                    readOnly
                    defaultValue={selectedEndpoint.MessageType}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4}>
                  <Form.Label>
                    <h6>Endpoint : </h6>
                  </Form.Label>
                </Col>
                <Col xs={6} md={4}>
                  <Form.Control
                    as="select"
                    name="EndpointId"
                    defaultValue={selectedEndpoint.EndpointId}
                    onChange={inputHandler}
                  >
                    <option value={"null"} key={"null"}>
                      ---
                    </option>
                    {endpoints.map((endpoint) => {
                      return (
                        endpoint.MessageTypes && 
                        endpoint.MessageTypes.length > 0 && 
                        endpoint.MessageTypes[0].MessageType === selectedEndpoint.MessageType ? (
                          <option key={endpoint.Id} value={endpoint.Id}>
                            {endpoint.Name}
                          </option>
                        ) : null
                      );
                    })}
                  </Form.Control>
                </Col>
              </Row>
              {listEndpoint && (
                <>
                  <br />
                  <Row>
                    <Col xs={12} md={4}>
                      <h6>IP Address :</h6>{" "}
                    </Col>
                    <Col xs={6} md={4}>
                      {listEndpoint.IP}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={4}>
                      <h6>Port :</h6>{" "}
                    </Col>
                    <Col xs={6} md={4}>
                      {listEndpoint.Port}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={4}>
                      <h6>Status :</h6>{" "}
                    </Col>
                    <Col xs={6} md={4}>
                      {listEndpoint.Status}
                    </Col>
                  </Row>
                </>
              )}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={saveEndpointHandler}
              disabled={disabled}
              variant="success"
              type="submit"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

ChangeEndpointModal.propTypes = {
  device: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  selectedEndpoint: PropTypes.object.isRequired,
  setSelectedEndpoint: PropTypes.func.isRequired,
  saveEndpointHandler: PropTypes.func.isRequired,
  endpoints: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEndpointModal);
