import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";

// Redux
import { useDispatch, connect } from "react-redux";
import { saveDeviceSettings } from "../../../actions/deviceSettingActions";
import { getDeviceById } from "../../../actions/deviceActions";

// Styling
import { Form, Row, Col, Button, Alert } from "react-bootstrap";

// Components
import Spinner from "../../common/Spinner";

const DeviceSettingsPane = ({
    auth,
    device,
    currentConfig,
    newConfig,
    isLoading,
}) => {
    const dispatch = useDispatch();
    let history = useNavigate();
    const [currentConfigSettings, setCurrentConfigSettings] = useState({}); // CURRENT
    const [currentSetConfigSettings, setCurrentSetConfigSettings] = useState({}); // NEW
    const [newConfigSettings, setNewConfigSettings] = useState({}); // NEWFORUX
    const [changedSettings, setChangedSettings] = useState({});

    const refresh = () => {
        setChangedSettings({});
        dispatch(getDeviceById(auth, device.Id));
        setConfigValues();
    };

    useEffect(() => {
        refresh();
    }, []);

    useEffect(() => {
        setChangedSettings({});
        //document.getElementById("settings-form-id").reset();
        setConfigValues();
    }, [device]);

    const setConfigValues = () => {
        setCurrentConfigSettings({
            iButton: currentConfig.iButton,
            PrivateSwitchEnabled: currentConfig.PrivateSwitchEnabled,
            TachoLiveEnabled: currentConfig.TachoLiveEnabled,
            TachoType: currentConfig.TachoType,
            TachoRDLEnabled: currentConfig.TachoRDLEnabled,
            TachoRDLAdress: currentConfig.TachoRDLAdress,
            RTStandbyInterval: currentConfig.RTStandbyInterval,
            RTDatatimeInterval: currentConfig.RTDatatimeInterval,
            RTDatadistanceInterval: currentConfig.RTDatadistanceInterval,
            RCDateTimeInterval: currentConfig.RCDateTimeInterval,
            RCDataDistanceInterval: currentConfig.RCDataDistanceInterval,
            RCDataTriggerMask: currentConfig.RCDataTriggerMask,
            TDDataTimeInterval: currentConfig.TDDataTimeInterval,
            TDDataDistanceInterval: currentConfig.TDDataDistanceInterval,
            TDDataTriggerMask: currentConfig.TDDataTriggerMask,
            iButtonClearInterval: currentConfig.iButtonClearInterval,
            FutureInt1: currentConfig.FutureInt1,
            FutureInt2: currentConfig.FutureInt2,
        });
        setNewConfigSettings({
            iButton: newConfig.iButton,
            PrivateSwitchEnabled: newConfig.PrivateSwitchEnabled,
            TachoLiveEnabled: newConfig.TachoLiveEnabled,
            TachoType: newConfig.TachoType,
            TachoRDLEnabled: newConfig.TachoRDLEnabled,
            TachoRDLAdress: newConfig.TachoRDLAdress,
            RTStandbyInterval: newConfig.RTStandbyInterval,
            RTDatatimeInterval: newConfig.RTDatatimeInterval,
            RTDatadistanceInterval: newConfig.RTDatadistanceInterval,
            RCDateTimeInterval: newConfig.RCDateTimeInterval,
            RCDataDistanceInterval: newConfig.RCDataDistanceInterval,
            RCDataTriggerMask: newConfig.RCDataTriggerMask,
            TDDataTimeInterval: newConfig.TDDataTimeInterval,
            TDDataDistanceInterval: newConfig.TDDataDistanceInterval,
            TDDataTriggerMask: newConfig.TDDataTriggerMask,
            iButtonClearInterval: newConfig.iButtonClearInterval,
            FutureInt1: newConfig.FutureInt1,
            FutureInt2: newConfig.FutureInt2,
        });
        setCurrentSetConfigSettings({
            iButton: newConfig.iButton,
            PrivateSwitchEnabled: newConfig.PrivateSwitchEnabled,
            TachoLiveEnabled: newConfig.TachoLiveEnabled,
            TachoType: newConfig.TachoType,
            TachoRDLEnabled: newConfig.TachoRDLEnabled,
            TachoRDLAdress: newConfig.TachoRDLAdress,
            RTStandbyInterval: newConfig.RTStandbyInterval,
            RTDatatimeInterval: newConfig.RTDatatimeInterval,
            RTDatadistanceInterval: newConfig.RTDatadistanceInterval,
            RCDateTimeInterval: newConfig.RCDateTimeInterval,
            RCDataDistanceInterval: newConfig.RCDataDistanceInterval,
            RCDataTriggerMask: newConfig.RCDataTriggerMask,
            TDDataTimeInterval: newConfig.TDDataTimeInterval,
            TDDataDistanceInterval: newConfig.TDDataDistanceInterval,
            TDDataTriggerMask: newConfig.TDDataTriggerMask,
            iButtonClearInterval: newConfig.iButtonClearInterval,
            FutureInt1: newConfig.FutureInt1,
            FutureInt2: newConfig.FutureInt2,
        });
    };

    const inputHandler = (e) => {
        if (e.target.type == "checkbox") {
            const value = e.target.checked;
            setNewConfigSettings({
                ...newConfigSettings,
                [e.target.name]: value ? "1" : "0",
            });
            setChangedSettings({
                ...changedSettings,
                [e.target.name]: value ? "1" : "0",
            });
        } else {
            let value = e.target.value;

            if (e.target.name == "TachoRDLAdress") {
                if (value < 251) {
                    value = 251;
                }
                if (value > 254) {
                    value = 254;
                }
            }

            setNewConfigSettings({
                ...newConfigSettings,
                [e.target.name]: value,
            });
            setChangedSettings({
                ...changedSettings,
                [e.target.name]: value,
            });
        }
    };

    const saveSettingsHandler = async (e) => {
        e.preventDefault();
        var changeList = [];
        for (const property in changedSettings) {
            if (currentSetConfigSettings[property] == changedSettings[property])
                continue;
            var obj = {
                SettingName: property,
                Value: changedSettings[property],
            };
            changeList.push(obj);
        }
        dispatch(
            saveDeviceSettings(auth, device.Id, device.IMEI, {
                SettingsList: changeList,
            })
        );
    };
    const isViewer = auth.user.Role === "Viewer";

    return (
        <>
            {!isLoading ? (
                <>
                    <Form id="settings-form-id">
                        {device.InSync ? (
                            ""
                        ) : (
                            <Alert variant="warning">Device is not in sync</Alert>
                        )}
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                iButton
                                <i>
                                    {currentConfigSettings.iButton == newConfigSettings.iButton
                                        ? ""
                                        : currentConfigSettings.iButton == 1
                                            ? " (Currently ON)"
                                            : " (Currently OFF)"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Check
                                    name="iButton"
                                    checked={newConfigSettings.iButton == 1 ? true : false}
                                    onChange={inputHandler}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                PrivateSwitchEnabled
                                <i>
                                    {currentConfigSettings.PrivateSwitchEnabled ==
                                        newConfigSettings.PrivateSwitchEnabled
                                        ? ""
                                        : currentConfigSettings.PrivateSwitchEnabled == 1
                                            ? " (Currently ON)"
                                            : " (Currently OFF)"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Check
                                    name="PrivateSwitchEnabled"
                                    value={newConfigSettings.PrivateSwitchEnabled}
                                    checked={
                                        newConfigSettings.PrivateSwitchEnabled == 1 ? true : false
                                    }
                                    onChange={inputHandler}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                TachoLiveEnabled
                                <i>
                                    {currentConfigSettings.TachoLiveEnabled ==
                                        newConfigSettings.TachoLiveEnabled
                                        ? ""
                                        : currentConfigSettings.TachoLiveEnabled == 1
                                            ? " (Currently ON)"
                                            : " (Currently OFF)"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Check
                                    name="TachoLiveEnabled"
                                    value={newConfigSettings.TachoLiveEnabled}
                                    checked={
                                        newConfigSettings.TachoLiveEnabled == 1 ? true : false
                                    }
                                    onChange={inputHandler}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                TachoType
                                <i>
                                    {currentConfigSettings.TachoType ==
                                        newConfigSettings.TachoType
                                        ? ""
                                        : currentConfigSettings.TachoType == "0"
                                            ? " (Currently None)"
                                            : " (Currently " + currentConfigSettings.TachoType + ")"}
                                </i>
                            </Form.Label>
                            <Col sm="9">
                                <Form.Control
                                    as="select"
                                    name="TachoType"
                                    value={newConfigSettings.TachoType}
                                    onChange={inputHandler}
                                >
                                    <option value="0">None</option>
                                    <option value="1">VDO</option>
                                    <option value="2">Stoneridge</option>
                                    <option value="3">CAN 3</option>
                                </Form.Control>
                                {/*<Form.Check*/}
                                {/*  name="TachoType"*/}
                                {/*  type="radio"*/}
                                {/*  label="VDO"*/}
                                {/*  className="radio"*/}
                                {/*  defaultChecked={*/}
                                {/*    newConfigSettings.TachoType == "vdo" ? true : false*/}
                                {/*  }*/}
                                {/*  value="vdo"*/}
                                {/*  onChange={inputHandler}*/}
                                {/*/>*/}
                                {/*<Form.Check*/}
                                {/*  name="TachoType"*/}
                                {/*  type="radio"*/}
                                {/*  label="Stoneridge"*/}
                                {/*  className="radio"*/}
                                {/*  defaultChecked={*/}
                                {/*    newConfigSettings.TachoType == "stoneridge" ? true : false*/}
                                {/*  }*/}
                                {/*  value="stoneridge"*/}
                                {/*  onChange={inputHandler}*/}
                                {/*/>*/}
                                {/*<Form.Check*/}
                                {/*  name="TachoType"*/}
                                {/*  type="radio"*/}
                                {/*  label="None"*/}
                                {/*  className="radio"*/}
                                {/*  defaultChecked={*/}
                                {/*    newConfigSettings.TachoType == "0" ? true : false*/}
                                {/*  }*/}
                                {/*  value="0"*/}
                                {/*  onChange={inputHandler}*/}
                                {/*/>*/}
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                Tacho RDL
                                <i>
                                    {currentConfigSettings.TachoRDLEnabled ==
                                        newConfigSettings.TachoRDLEnabled
                                        ? ""
                                        : currentConfigSettings.TachoRDLEnabled == 1
                                            ? " (Currently ON)"
                                            : " (Currently OFF)"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Check
                                    name="TachoRDLEnabled"
                                    value={newConfigSettings.TachoRDLEnabled}
                                    defaultChecked={
                                        newConfigSettings.TachoRDLEnabled == 1 ? true : false
                                    }
                                    onChange={inputHandler}
                                />
                            </Col>
                        </Form.Group>


                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                TachoRDLAdress
                                <i>
                                    {currentConfigSettings.TachoRDLAdress ==
                                        newConfigSettings.TachoRDLAdress
                                        ? ""
                                        : " (Currently " +
                                        currentConfigSettings.TachoRDLAdress +
                                        ")"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    type="number"
                                    name="TachoRDLAdress"
                                    value={currentConfigSettings.TachoRDLAdress === 9999 ? "N/A" : newConfigSettings.TachoRDLAdress}
                                    onChange={inputHandler}
                                    disabled={currentConfigSettings.TachoRDLAdress === 9999 ? true : false}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                RTStandbyInterval
                                <i>
                                    {currentConfigSettings.RTStandbyInterval ==
                                        newConfigSettings.RTStandbyInterval
                                        ? ""
                                        : " (Currently " +
                                        currentConfigSettings.RTStandbyInterval +
                                        ")"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    type="number"
                                    name="RTStandbyInterval"
                                    defaultValue={newConfigSettings.RTStandbyInterval}
                                    onChange={inputHandler}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                RTDatatimeInterval
                                <i>
                                    {currentConfigSettings.RTDatatimeInterval ==
                                        newConfigSettings.RTDatatimeInterval
                                        ? ""
                                        : " (Currently " +
                                        currentConfigSettings.RTDatatimeInterval +
                                        ")"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    type="number"
                                    name="RTDatatimeInterval"
                                    defaultValue={newConfigSettings.RTDatatimeInterval}
                                    onChange={inputHandler}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                RTDatadistanceInterval
                                <i>
                                    {currentConfigSettings.RTDatadistanceInterval ==
                                        newConfigSettings.RTDatadistanceInterval
                                        ? ""
                                        : " (Currently " +
                                        currentConfigSettings.RTDatadistanceInterval +
                                        ")"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    type="number"
                                    name="RTDatadistanceInterval"
                                    defaultValue={newConfigSettings.RTDatadistanceInterval}
                                    onChange={inputHandler}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                RCDateTimeInterval
                                <i>
                                    {currentConfigSettings.RCDateTimeInterval ==
                                        newConfigSettings.RCDateTimeInterval
                                        ? ""
                                        : " (Currently " +
                                        currentConfigSettings.RCDateTimeInterval +
                                        ")"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    type="number"
                                    name="RCDateTimeInterval"
                                    defaultValue={newConfigSettings.RCDateTimeInterval}
                                    onChange={inputHandler}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                RCDataDistanceInterval
                                <i>
                                    {currentConfigSettings.RCDataDistanceInterval ==
                                        newConfigSettings.RCDataDistanceInterval
                                        ? ""
                                        : " (Currently " +
                                        currentConfigSettings.RCDataDistanceInterval +
                                        ")"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    type="number"
                                    name="RCDataDistanceInterval"
                                    defaultValue={newConfigSettings.RCDataDistanceInterval}
                                    onChange={inputHandler}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                RCDataTriggerMask
                                <i>
                                    {currentConfigSettings.RCDataTriggerMask ==
                                        newConfigSettings.RCDataTriggerMask
                                        ? ""
                                        : " (Currently " +
                                        currentConfigSettings.RCDataTriggerMask +
                                        ")"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    type="number"
                                    name="RCDataTriggerMask"
                                    defaultValue={newConfigSettings.RCDataTriggerMask}
                                    onChange={inputHandler}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                TDDataTimeInterval
                                <i>
                                    {currentConfigSettings.TDDataTimeInterval ==
                                        newConfigSettings.TDDataTimeInterval
                                        ? ""
                                        : " (Currently " +
                                        currentConfigSettings.TDDataTimeInterval +
                                        ")"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    type="number"
                                    name="TDDataTimeInterval"
                                    defaultValue={newConfigSettings.TDDataTimeInterval}
                                    onChange={inputHandler}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                TDDataDistanceInterval
                                <i>
                                    {currentConfigSettings.TDDataDistanceInterval ==
                                        newConfigSettings.TDDataDistanceInterval
                                        ? ""
                                        : " (Currently " +
                                        currentConfigSettings.TDDataDistanceInterval +
                                        ")"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    type="number"
                                    name="TDDataDistanceInterval"
                                    defaultValue={newConfigSettings.TDDataDistanceInterval}
                                    onChange={inputHandler}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                TDDataTriggerMask
                                <i>
                                    {currentConfigSettings.TDDataTriggerMask ==
                                        newConfigSettings.TDDataTriggerMask
                                        ? ""
                                        : " (Currently " +
                                        currentConfigSettings.TDDataTriggerMask +
                                        ")"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    type="number"
                                    name="TDDataTriggerMask"
                                    defaultValue={newConfigSettings.TDDataTriggerMask}
                                    onChange={inputHandler}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                iButtonClearInterval
                                <i>
                                    {currentConfigSettings.iButtonClearInterval ==
                                        newConfigSettings.iButtonClearInterval
                                        ? ""
                                        : " (Currently " +
                                        currentConfigSettings.iButtonClearInterval +
                                        ")"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    type="number"
                                    name="iButtonClearInterval"
                                    defaultValue={newConfigSettings.iButtonClearInterval}
                                    onChange={inputHandler}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                FutureInt1
                                <i>
                                    {currentConfigSettings.FutureInt1 ==
                                        newConfigSettings.FutureInt1
                                        ? ""
                                        : " (Currently " + currentConfigSettings.FutureInt1 + ")"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    type="number"
                                    name="FutureInt1"
                                    defaultValue={newConfigSettings.FutureInt1}
                                    onChange={inputHandler}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column lg={3}>
                                FutureInt2
                                <i>
                                    {currentConfigSettings.FutureInt2 ==
                                        newConfigSettings.FutureInt2
                                        ? ""
                                        : " (Currently " + currentConfigSettings.FutureInt2 + ")"}
                                </i>
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    type="number"
                                    name="FutureInt2"
                                    defaultValue={newConfigSettings.FutureInt2}
                                    onChange={inputHandler}
                                />
                            </Col>
                        </Form.Group>

                        <hr></hr>
                        <Form.Group>
                            <Row>
                                <Col sm="11">
                                    <Button
                                        variant="outline-warning"
                                        onClick={() => history.push("/devices")}
                                    >
                                        Back
                                    </Button>
                                </Col>

                                <Col sm="1">
                                    {!isViewer && (
                                        <Button
                                            variant="success"
                                            onClick={(e) => saveSettingsHandler(e)}
                                        >
                                            Save
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                </>
            ) : (
                <Spinner />
            )}
        </>
    );
};

DeviceSettingsPane.propTypes = {
    auth: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired,
    currentConfig: PropTypes.object.isRequired,
    newConfig: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        device: state.deviceDetails.device,
        currentConfig: state.deviceDetails.currentConfig,
        newConfig: state.deviceDetails.newConfig,
        isLoading: state.deviceDetails.isLoading,
        apiCallsInProgress: state.apiCallsInProgress,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSettingsPane);
