import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const canConfigsReducer = (state = initialState.canConfigs, action) => {
  switch (action.type) {
    case types.LOAD_CANCONFIGS_SUCCESS:
      return action.payload.canConfigs;
    case types.LOAD_ACTIVECANCONFIGS_SUCCESS:
      return action.payload.canConfigs;
    case types.LOAD_CANCONFIG_SUCCESS:
      return [
        ...state.filter(
          (canConfig) => canConfig.Id != action.payload.canConfigs.Id
        ),
        action.payload.canConfigs,
      ];
    case types.UPDATE_CANCONFIG_SUCCESS:
      return [
        ...state.filter(
          (canConfig) => canConfig.Id != action.payload.canConfigs.Id
        ),
        action.payload.canConfigs,
      ];
    case types.SAVE_CANCONFIG_SUCCESS:
      return [
        ...state.filter(
          (canConfig) => canConfig.Id != action.payload.canConfigs.Id
        ),
        action.payload.canConfigs,
      ];
    default:
      return state;
  }
};

export default canConfigsReducer;
