import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, connect } from "react-redux";
import { loadDeviceTests } from "../../../actions/deviceTestActions";

import Spinner from "../../common/Spinner";
import SearchBar from "../../common/SearchBar";
import DeviceTestList from "./components/DeviceTestList";
import { Card } from "react-bootstrap";

const DeviceTestOveriewPage = ({ auth, devicetests, apiCallsInProgress }) => {
  const dispatch = useDispatch();
  const deviceTestList = [...devicetests];
  const [searchResult, setSearchResult] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const filters = ["Customer", "IMEI", "User", "TimeStamp", "TestResult"];

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    dispatch(loadDeviceTests(auth));
  };

  return (
    <div className="DeviceTestOverview" id="devicetestoverview-list-page">
      <h2 className="title">Device Tests Overview</h2>
      <SearchBar
        filters={filters}
        setSearchResult={setSearchResult}
        searchResult={searchResult}
        all={deviceTestList}
        refresh={refresh}
        setSearchActive={setSearchActive}
      />
      <br />
      {deviceTestList.length === 0 && apiCallsInProgress > 0 ? (
        <Spinner />
      ) : (
        <Card>
          {searchResult.length === 0 && searchActive == false ? (
            <DeviceTestList deviceTests={deviceTestList} />
          ) : (
            <DeviceTestList deviceTests={searchResult} />
          )}
        </Card>
      )}
    </div>
  );
};

DeviceTestOveriewPage.propTypes = {
  auth: PropTypes.object.isRequired,
  devicetests: PropTypes.array.isRequired,
  apiCallsInProgress: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    devicetests: state.deviceTests,
    apiCallsInProgress: state.apiCallsInProgress,
  };
}
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceTestOveriewPage);
