import React from "react";
import { NavLink, Link } from "react-router-dom";
import { Navbar, Nav, NavItem, Form, NavDropdown } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const accountButton = (auth) => {
  if (auth.isLoggedIn === true) {
    return (
      <NavDropdown
        title=""
        className="invisible-dropdown-text"
        id="dropdown-menu-align-responsive-1"
      >
        <NavDropdown.Item as={Link} to="/account">
          Account
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item as={Link} to="/logout">
          Logout
        </NavDropdown.Item>
      </NavDropdown>
    );
  } else {
    return (
      <NavLink id="dropdown-menu-align-right" to="/login" className="nav-link">
        Login
      </NavLink>
    );
  }
};

const adminButton = (auth) => {
  if (auth.isLoggedIn === true && auth.user.Role === "Admin") {
    return (
      <NavDropdown id="nav-dropdown-dark-example" title="Admin">
        <NavDropdown.Item as={Link} to="/rawmessages">
          Raw Messages
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/features">
          Features
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/roles">
          Roles
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/log">
          Log
        </NavDropdown.Item>
      </NavDropdown>
    );
  }
};

const Header = ({ auth }) => {
  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Navbar.Brand href="/">TelematiQs MiddleWare</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {auth.isLoggedIn === true && (
              <>
                <NavItem>
                  <NavLink to="/" className="nav-link" exact="true">
                    Home
                  </NavLink>
                </NavItem>
                {auth.user.IsReseller || auth.user.Role === "Admin" ? (
                  <>
                    <NavItem>
                      <NavLink to="/customers" className="nav-link">
                        Customers
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/devices" className="nav-link">
                        Devices
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/endpoints" className="nav-link">
                        Endpoints
                      </NavLink>
                    </NavItem>
                    {auth.user.Role === "Admin" && (
                      <>
                        <NavItem>
                          <NavLink to="/settingsProfiles" className="nav-link">
                            SettingsProfiles
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink to="/canprotocol" className="nav-link">
                            QTLibrary
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink to="/firmware" className="nav-link">
                            Firmware
                          </NavLink>
                        </NavItem>
                      </>
                    )}
                    <NavDropdown id="nav-dropdown-dark-example" title="Installation">
                      <NavDropdown.Item to="/devicetest" as={Link}>
                        Run Test
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item to="/devicetests" as={Link}>
                        Overview
                      </NavDropdown.Item>
                    </NavDropdown>
                  </>
                ) : auth.user.Role !== "Viewer" ? (
                  <>
                    <NavItem>
                      <NavLink to="/customers" className="nav-link">
                        Customers
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/devices" className="nav-link">
                        Devices
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/endpoints" className="nav-link">
                        Endpoints
                      </NavLink>
                    </NavItem>
                  </>
                ) : (
                  <>
                    <NavItem>
                      <NavLink to="/customers" className="nav-link">
                        Customers
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/devices" className="nav-link">
                        Devices
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/endpoints" className="nav-link">
                        Endpoints
                      </NavLink>
                    </NavItem>
                  </>
                )}
                {auth.user.Features.includes("Tacho Download") && (
                  <NavDropdown id="nav-dropdown-dark-example" title="Tacho">
                    {auth.user.Role === "Admin" && (
                      <>
                        <NavDropdown.Item to="/tacho" as={Link}>
                          Auth Server
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                      </>
                    )}
                    <NavDropdown.Item to="/tachoobject" as={Link}>
                      Mass Overview
                    </NavDropdown.Item>
                    <NavDropdown.Item to="/tachodriver" as={Link}>
                      Driver Overview
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item to="/tachomassdownload" as={Link}>
                      Tacho MassMemory Download
                    </NavDropdown.Item>
                    <NavDropdown.Item to="/tachodriverdownload" as={Link}>
                      DriverCard Download
                    </NavDropdown.Item>
                  </NavDropdown>
                )}
                <NavItem> {adminButton(auth)}</NavItem>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
        <Form inline="true">
          <Nav className="mr-auto">
            <NavItem> {accountButton(auth)}</NavItem>
          </Nav>
        </Form>
      </Navbar>
    </>
  );
};

Header.propTypes = {
  auth: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(Header);
