import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/api/authservers/";

export const getAuthServerById = (auth, id) => {
  return fetch(baseUrl + id, {
    headers: { Authorization: auth.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
};

export function getAuthServers(auth) {
  return fetch(baseUrl, {
    headers: { Authorization: auth.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function CountTotalRows(auth) {
  return fetch(baseUrl + "all/", { headers: { Authorization: auth.accessToken } })
      .then(handleResponse)
      .catch(handleError);
}

export function getAuthServersByCustomer(auth, customer) {
  return fetch(baseUrl + "customer/" + customer, {headers: {Authorization: auth.accessToken}})
      .then(handleResponse)
      .catch(handleError)
}

export function getAuthServersFromSearch(auth, searchTerm, filter, customer = null) {
  return customer === "null"
      ? fetch(`${baseUrl}search/${searchTerm}/${filter}`, {headers: {Authorization: auth.accessToken}})
          .then(handleResponse)
          .catch(handleError)
      : fetch(`${baseUrl}search/${searchTerm}/${filter}/${customer}`, {headers: {Authorization: auth.accessToken}})
          .then(handleResponse)
          .catch(handleError)
}

export const updateAuthServer = (auth, id, authServer) => {
  return fetch(baseUrl + id, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      Authorization: auth.accessToken,
    },
    body: JSON.stringify(authServer),
  })
    .then(handleResponse)
    .catch(handleError);
};

export const saveAuthServer = (auth, authServer) => {
  return fetch(baseUrl, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: auth.accessToken,
    },
    body: JSON.stringify(authServer),
  })
    .then(handleResponse)
    .catch(handleError);
};

export const removeAuthServer = (auth, id) => {
  return fetch(baseUrl + id, {
    method: "DELETE",
    headers: {
      Authorization: auth.accessToken,
    },
  })
    .then(handleResponse)
    .catch(handleError);
};
