import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Redux
import { useDispatch, connect } from "react-redux";
import { fetchRoles } from "../../actions/roleActions";

// Components
import RoleList from "./RoleList";
import CreateRoleModal from "./CreateRoleModal";
import SearchBar from "../common/SearchBar";
import Spinner from "../common/Spinner";

// Styling
import { Card } from "react-bootstrap";

const RolePage = ({ auth, apiCallsInProgress, roles }) => {
  const dispatch = useDispatch();
  const roleList = [...roles];
  const [searchResult, setSearchResult] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [show, setShow] = useState(false);
  const filters = ["Name"];

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    dispatch(fetchRoles(auth));
  };

  return (
    <div className="roles">
      <h2 className="title">Roles</h2>
      <SearchBar
        filters={filters}
        setSearchResult={setSearchResult}
        searchResult={searchResult}
        all={roleList}
        refresh={refresh}
        setSearchActive={setSearchActive}
      />
      <div className="create-object-div">
        <CreateRoleModal show={show} setShow={setShow} />
      </div>
      {roles.length === 0 && apiCallsInProgress > 0 ? (
        <Spinner />
      ) : (
        <Card>
          {searchResult.length === 0 && searchActive == false ? (
            <RoleList roles={roleList} />
          ) : (
            <RoleList roles={searchResult} />
          )}
        </Card>
      )}
    </div>
  );
};

RolePage.propTypes = {
  auth: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  apiCallsInProgress: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    roles: state.roles,
    apiCallsInProgress: state.apiCallsInProgress,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RolePage);
