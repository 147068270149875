import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const endpointReducer = (state = initialState.endpoints, action) => {
  switch (action.type) {
    case types.LOAD_ENDPOINTS_SUCCESS:
      return action.payload.endpoints;
    case types.SAVE_ENDPOINT_SUCCESS:
      return [...state, action.payload.endpoint];
    case types.UPDATE_ENDPOINT_SUCCESS:
      return [
        ...state.filter(
          (endpoint) => endpoint.Id != action.payload.endpoint.Id
        ),
        action.payload.endpoint,
      ];
    case types.LOAD_SEARCH_RESULT_ENDPOINT_SUCCESS:
      return action.payload.endpoints;
    case types.LOGOUT:
      return initialState.endpoints;
    default:
      return state;
  }
};

export default endpointReducer;
