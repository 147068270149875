import React from "react";
import PropTypes from "prop-types";
import { useDispatch, connect } from "react-redux";
import { saveModemRequest } from "../../../../actions/deviceModemRequestActions";

import { Row, Col, Button } from "react-bootstrap";


export const DeviceControlRow = ({ auth, device }) => {
  const dispatch = useDispatch();

  const OnClickReset = () => {
    let request = {
      Command: `RESET ${device.IMEI} 0 FF`,
      CommandType: 20,
    };
    dispatch(saveModemRequest(auth, device.IMEI, request));
  };

  const OnClickConfig = () => {
    let request = {
      Command: `$REQUEST,DD FF`,
      CommandType: 20,
    };
    dispatch(saveModemRequest(auth, device.IMEI, request));
  };

  const OnClickreqRT = () => {
    let request = {
      Command: `$REQUEST,RT FF`,
      CommandType: 20,
    };
    dispatch(saveModemRequest(auth, device.IMEI, request));
  };

  const OnClickreqTD = () => {
    let request = {
      Command: `$REQUEST,TD FF`,
      CommandType: 20,
    };
    dispatch(saveModemRequest(auth, device.IMEI, request));
  };

  const OnClickreqRC = () => {
    let request = {
      Command: `$REQUEST,RC FF`,
      CommandType: 20,
    };
    dispatch(saveModemRequest(auth, device.IMEI, request));
  };

  const isViewer = auth.user.Role === "Viewer";

  return (

    <Row>
      <Col>
        {!isViewer && (
          <Button variant="info" onClick={OnClickConfig}>
            Request Configuration
          </Button>
        )}
        {"  "}
        {!isViewer && (
          <Button variant="secondary" onClick={OnClickreqRT}>
            Request RT
          </Button>
        )}
        {"  "}
        {!isViewer && (
          <Button variant="secondary" onClick={OnClickreqTD}>
            Request TD
          </Button>
        )}
          {"  "}
        {!isViewer &&(
        <Button variant="secondary" onClick={OnClickreqRC}>
          Request RC
        </Button>
        )}
        {"  "}

      {!isViewer &&(
        <Button variant="warning" onClick={OnClickReset}>
          Request Reset
        </Button>
      )}
      </Col>
      <Col xs lg={12}>
        {" "}
      </Col>
    </Row>

  )
}

DeviceControlRow.propTypes = {
  device: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    device: state.deviceDetails.device,
  };
}
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceControlRow);