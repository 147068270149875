import InitialState from "./initialState";
import * as types from "../actions/actionsTypes";

const deviceTypesReducer = (state = InitialState.deviceTypes, action) => {
    switch (action.type) {
        case types.LOAD_DEVICE_TYPES_SUCCESS:
            return action.payload.deviceTypes;
        case types.LOGOUT:
            return InitialState.deviceTypes;
        default:
            return state;
    }
}

export default deviceTypesReducer;