import React from "react"
import EditIcon from "../../../common/icons/pen-to-square-solid.svg";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
const MessageOverViewRow = ({
    message,
    showModalHandler,
}) => {
    return (
        <>
            <tr key={message.index} className="table-row">
                <td>Message {message.index} </td>
                <td>{message.TIME}</td>
                <td>{message.SOT}</td>
                <td>{message.EOT}</td>
                <td>{message.CHG}</td>
                <td>{message.POR}</td>
                <td>{message.WAKE}</td>
                <td>{message.STB}</td>
                <td>{message.MOV}</td>
                <td>{message.LOWBV}</td>
                <td>{message.PZ}</td>
                <td>{message.DWL}</td>
                <td>{message.LOG}</td>
                <td>{message.DS}</td>
                <td>{message.DIAG}</td>
                <td>{message.TL}</td>
                <td>{message.REQ}</td>
                <td>
                    <Button className="shadow-sm border-secondary" variant="light"
                        onClick={() => {
                            showModalHandler(message)
                        }}>
                        <img src={EditIcon} alt="Edit" />
                    </Button>
                </td>
            </tr>
        </>
    )
}

MessageOverViewRow.propTypes = {
    message: PropTypes.object.isRequired,
    showModalHandler: PropTypes.func.isRequired
}

export default (MessageOverViewRow)