import React from "react";
import { Navigate, Outlet  } from "react-router-dom";
import PropTypes from "prop-types";
//import jwt from "jsonwebtoken";
import { jwtDecode } from "jwt-decode";
import { logout } from "../../actions/authActions";
// Time
import moment from "moment";
// Redux
import { connect, useDispatch } from "react-redux";

const AdminRoute = ({ auth, component: Component, ...rest }) => {
  const dispatch = useDispatch();

  // Check if token is still valid

  if (auth.isLoggedIn === true) {
    //var decodedToken = jwt.decode(auth.accessToken, { complete: true });
    const decodedToken = jwtDecode(auth.accessToken);
    const current = moment(Math.floor(Date.now() / 1000));

    if (decodedToken.exp - 10 > current) {
      if (auth.user.Role == "Admin") {
        return <Outlet {...rest} render={(props) => <Component {...props} />} />;
      } else {
        alert("Hey! You're not allowed here!");
        return <Navigate to="/" />;
      }
    } else {
      alert("Your session has expired. Please log in.");
      dispatch(logout());
      return <Navigate to="/Login" />;
    }
  }
  //redirect if there is no user
  return <Navigate to="/login" />;
};

AdminRoute.propTypes = {
  auth: PropTypes.object.isRequired,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };}

export default connect(mapStateToProps)(AdminRoute);
