import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

// Redux
import {connect, useDispatch} from "react-redux";
import {
    loadAllRawMessages,
    loadAllRawMessagesFromId,
    loadSearchedRawMessages
} from "../../actions/rawMessageActions";

// Components
import RawMessageList from "./RawMessageList";
import Spinner from "../common/Spinner";
import LazyLoader from "../common/LazyLoader";
import LazySearchBar from "../common/LazySearchBar";

// Styling
import "./RawMessagePage.css";
import {Card} from "react-bootstrap";
import {apiCallError} from "../../actions/apiStatusActions";
import * as rawMessagesApi from "../../api/rawMessageApi";

const RawMessagePage = ({auth, rawMessages, apiCallsInProgress, endOfList}) => {
    const dispatch = useDispatch();
    const rawMessagesList = [...rawMessages];
    const [resultCount, setResultCount] = useState(0);
    const [searchActive, setSearchActive] = useState(false);

    const filters = ["Message"];

    useEffect(() => {
        refresh();
    }, []);

    const refresh = (id = null) => {
        if (apiCallsInProgress === 0) {
            rawMessagesApi
                .getRawMessagesCount(auth)
                .then((resultCount) => {
                    setResultCount(resultCount);
                })
                .catch((error) => {
                    dispatch(apiCallError(error))
                });

            if (id === null) {
                dispatch(loadAllRawMessages(auth));
            } else {
                dispatch(loadAllRawMessagesFromId(auth, id));
            }
        }
    };

    const getSearchResult = (searchTerm, filter) => {
        dispatch(loadSearchedRawMessages(auth, searchTerm, filter));
    }

    return (
        <div className="raw-messages">
            <h2 className="title">Raw Messages</h2>
            <LazySearchBar
                filters={filters}
                refresh={refresh}
                resultCount={resultCount}
                setSearchActive={setSearchActive}
                getSearchResult={getSearchResult}
                apiCallsInProgress={apiCallsInProgress}
            />
            <br/>
            <Card>
                {searchActive === false ? (
                    <div>
                        <RawMessageList
                            rawMessages={rawMessagesList}
                        />
                        <LazyLoader
                            refresh={refresh}
                            collection={rawMessagesList}
                            apiCallsInProgress={apiCallsInProgress}
                            endOfList={endOfList}
                        />
                    </div>
                ) : (
                    <>
                        {apiCallsInProgress === 0 ? (
                            <RawMessageList rawMessages={rawMessagesList}/>
                        ) : (
                            <Spinner/>
                        )
                        }
                    </>
                )}
            </Card>
        </div>
    );
};

RawMessagePage.propTypes = {
    auth: PropTypes.object.isRequired,
    rawMessages: PropTypes.array.isRequired,
    apiCallsInProgress: PropTypes.number.isRequired,
    endOfList: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        rawMessages: state.rawMessages,
        apiCallsInProgress: state.apiCallsInProgress,
        endOfList: state.endOfList,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RawMessagePage);
