import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/api/devices/settings/";

export const getSettingsByDevice = (auth, IMEI) => {
  return fetch(baseUrl + IMEI, {
    headers: { Authorization: auth.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
};

export function saveSettingsForDevice(auth, IMEI, request) {
  return fetch(baseUrl + IMEI, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: auth.accessToken,
    },
    body: JSON.stringify(request),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function updateSettingForDevice(auth, id, request) {
  return fetch(baseUrl + id, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      Authorization: auth.accessToken,
    },
    body: JSON.stringify(request),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function removeSettingForDevice(auth, id) {
  return fetch(baseUrl + id, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: auth.accessToken,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function removeAllUnprocessedSettingsForDevice(auth, imei) {
  return fetch(baseUrl + "all/" + imei, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: auth.accessToken,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}
