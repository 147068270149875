import {Button, Card, Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

const LazySearchBar = ({
                           filters,
                           refresh,
                           customers,
                           resultCount,
                           setSearchActive,
                           getSearchResult,
                           getObjectByCustomer,
                           apiCallsInProgress,
                       }) => {
    const [filter, setFilter] = useState(filters[0]);
    const [customer, setCustomer] = useState("null");
    const [searchTerm, setSearchTerm] = useState("");
    const [currentfilter, setcurrentfilter] = useState("");

    useEffect(() => {
        const searchDelay = setTimeout(() => {
            if (searchTerm.length >= 3 && apiCallsInProgress === 0) {
                setSearchActive(true);
                getSearchResult(searchTerm.toLocaleLowerCase(), filter, customer);
            } else if (customer !== "null") {
                getObjectByCustomer(customer);
            } else {
                setSearchActive(false);
                if (searchTerm.length < 2 && filter === currentfilter) {
                    refresh();
                }
            }
        }, 800);

        if (filter != currentfilter) {
            setcurrentfilter(filter);
        }

        return () => clearTimeout(searchDelay);
    }, [filter, searchTerm, customer]);

    const handleSearchTerm = (e) => {
        if (apiCallsInProgress === 0) {
            setSearchTerm(e.target.value);
        }
    };

    const handleRefresh = () => {
        setSearchTerm("");
        setCustomer("null");
        refresh();
    };

    const handleFilter = (e) => {
        setFilter(e.target.value);
    };

    const handleCustomerFilter = (e) => {
        setCustomer(e.target.value);
    };

    return (
        <>
            <Card>
                <div className="search">
                    <Form.Control
                        className="search-select border-start"
                        as="select"
                        name="filter"
                        value={filter}
                        onChange={handleFilter}
                    >
                        {filters.map((filter) => {
                            return (
                                <option key={filter} value={filter}>
                                    {filter}
                                </option>
                            );
                        })}
                    </Form.Control>
                    {customers != null ? (
                        <Form.Control
                            className="search-select rounded-0"
                            as="select"
                            name="customer"
                            value={customer}
                            onChange={handleCustomerFilter}
                        >
                            <option value="null">
                                --
                            </option>
                            {customers.map((customer) => {
                                return (
                                    <option key={customer.Id} value={customer.Id}>
                                        {customer.Name}
                                    </option>
                                );
                            })}
                            abc
                        </Form.Control>
                    ) : (
                        <></>
                    )}
                    <input
                        className="search-input"
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearchTerm}
                    />
                    <div className="result">
                        <b>Results : </b>
                        {resultCount > 0 ? " " + resultCount : " " + 0}
                    </div>
                    <Button
                        className="refresh-border border-end"
                        variant="light"
                        onClick={handleRefresh}
                    >
                        ⟳
                    </Button>
                </div>
            </Card>
        </>
    );
};

LazySearchBar.propTypes = {
    filters: PropTypes.array.isRequired,
    refresh: PropTypes.func.isRequired,
    customers: PropTypes.array,
    resultCount: PropTypes.number.isRequired,
    setSearchActive: PropTypes.func.isRequired,
    getSearchResult: PropTypes.func.isRequired,
    getObjectByCustomer: PropTypes.func,
    apiCallsInProgress: PropTypes.number.isRequired,
};

export default LazySearchBar;
