import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Redux
import { useDispatch, connect } from "react-redux";
import { saveRole } from "../../actions/roleActions";

// Styling
import { Form, Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";

const CreateRoleModal = ({ auth, show, setShow, roles }) => {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [validForm, setValidForm] = useState(false);
  const [role, setRole] = useState({ name: "" });

  const dispatch = useDispatch();

  const saveRoleHandler = () => {
    dispatch(saveRole(auth, role));
    setShow(false);
    setRole({ name: "" });
  };

  const inputHandler = (e) => {
    let value = e.target.value.replace(/[^\w\s]/gi, "");
    setRole({ ...role, [e.target.name]: value });
  };

  useEffect(() => {
    const validName = role.name.length >= 3;
    const checkName = (obj) => obj.name === role.name;

    setValidForm(validName && !roles.some(checkName));
  }, [role, roles]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Make sure the name consists of more than 3 characters.
    </Tooltip>
  );

  return (
    <div className="create-role">
      <Button className="create-object-button" onClick={handleShow}>
        New Role
      </Button>
      <Modal show={show} onHide={handleClose} centered className="text-center">
        <Modal.Header closeButton>
          <Modal.Title>Add Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="text-left">
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                placeholder="Role Name"
                type="text"
                name="name"
                value={role.name}
                onChange={inputHandler}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <span className="d-inline-block">
              <Button
                variant="primary"
                style={!validForm ? { pointerEvents: "none" } : null}
                disabled={!validForm}
                onClick={saveRoleHandler}
              >
                Save Role
              </Button>
            </span>
          </OverlayTrigger>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

CreateRoleModal.propTypes = {
  auth: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  roles: state.roles,
});

export default connect(mapStateToProps)(CreateRoleModal);
