import React from "react";
import { Navigate, Outlet } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import PropTypes from "prop-types";
//import jwt from "jsonwebtoken";
import { logout } from "../../actions/authActions";
// Time
import moment from "moment";
// Redux
import { connect, useDispatch } from "react-redux";


const PrivateRoute = ({ auth, ...props }) => {
  const dispatch = useDispatch();
  const { component: Component, ...rest } = props;

  // Check if token is still valid
  if (auth.isLoggedIn === true) {
    //var decodedToken = jwt.decode(auth.accessToken, { complete: true });
    var decodedToken = jwtDecode(auth.accessToken);
    var current = moment(Math.floor(Date.now() / 1000));
    if (decodedToken.exp - 10 > current) {
      return <Outlet {...rest} render={(props) => <Component {...props} />} />;
    } else {
      alert("Your session has expired. Please log in.");
      dispatch(logout());
      return <Navigate to="/Login" />;
    }
  }
  //redirect if there is no user
  return <Navigate to="/login" />;
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(PrivateRoute);
