import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Redux
import { connect, useDispatch } from "react-redux";
import {
  loadAllLogsFromId,
  loadLogs,
  loadSearchedLogs,
} from "../../actions/logAction";

// Components
import LogList from "./LogList";
import Spinner from "../common/Spinner";
import LazySearchBar from "../common/LazySearchBar";
import LazyLoader from "../common/LazyLoader";

// Styling
import { Card } from "react-bootstrap";
import * as LogApi from "../../api/logApi";
import { apiCallError } from "../../actions/apiStatusActions";

const LogPage = ({ auth, logs, apiCallsInProgress, endOfList }) => {
  const dispatch = useDispatch();
  const logList = [...logs];
  const [resultCount, setResultCount] = useState(0);
  const [searchActive, setSearchActive] = useState(false);
  const filters = ["Message", "Level", "TimeStamp"];

  useEffect(() => {
    refresh();
  }, []);

  const refresh = (id = null) => {
    if (apiCallsInProgress === 0) {
      LogApi.getLogsCount(auth)
        .then((resultCount) => {
          setResultCount(resultCount);
        })
        .catch((error) => {
          dispatch(apiCallError(error));
        });
      if (id === null) {
        dispatch(loadLogs(auth));
      } else {
        dispatch(loadAllLogsFromId(auth, id));
      }
    }
  };

  const getSearchResult = (searchTerm, filter) => {
    dispatch(loadSearchedLogs(auth, searchTerm, filter));
  };

  return (
    <div className="log">
      <h2 className="title">Log</h2>
      <LazySearchBar
        filters={filters}
        refresh={refresh}
        apiCallsInProgress={apiCallsInProgress}
        resultCount={resultCount}
        getSearchResult={getSearchResult}
        setSearchActive={setSearchActive}
      />
      <br />
      <Card>
        {searchActive === false ? (
          <div>
            <LogList logs={logList} />
            <LazyLoader
              refresh={refresh}
              collection={logList}
              apiCallsInProgress={apiCallsInProgress}
              endOfList={endOfList}
            />
          </div>
        ) : (
          <>
            {apiCallsInProgress === 0 ? (
              <LogList logs={logList} />
            ) : (
              <Spinner />
            )}
          </>
        )}
      </Card>
    </div>
  );
};

LogPage.propTypes = {
  auth: PropTypes.object.isRequired,
  logs: PropTypes.array.isRequired,
  apiCallsInProgress: PropTypes.number.isRequired,
  endOfList: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    logs: state.logs,
    apiCallsInProgress: state.apiCallsInProgress,
    endOfList: state.endOfList,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LogPage);
