import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function endOfListReducer(state = initialState.endOfList, action) {
    switch (action.type) {
        case types.CHECK_END_OF_LIST:
            return action.payload.messages.length < 50;
        default:
            return state;
    }
}