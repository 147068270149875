import * as types from "./actionsTypes";
import * as dashboardApi from "../api/dashboardApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import moment from "moment";

export const loadDashboard = (auth) => async (dispatch) => {
  dispatch(beginApiCall());

  dashboardApi
    .getDashboard(auth)
    .then((dashboard) => {
      dashboard.Messages.forEach((x) => {
        x.Date = moment(x.Date).format("Do MMMM YYYY");
      });
      dispatch({
        type: types.LOAD_DASHBOARD_SUCCESS,
        payload: {
          dashboard: dashboard,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};
