import React from "react";
import PropTypes from "prop-types";

const SortButton = ({ name, requestSort, sortConfig }) => {
  return (
    <button
      className="sort-button"
      type="button"
      onClick={() => requestSort(name)}
    >
      {sortConfig !== null ? (sortConfig.key === name && sortConfig.direction === "ascending" ? "▼" : (sortConfig.key === name && sortConfig.direction === "descending" ? "▲" : "▲")) : "▲"}
    </button>
  );
};

SortButton.propTypes = {
  name: PropTypes.string.isRequired,
  requestSort: PropTypes.func.isRequired,
  sortConfig: PropTypes.object,
};

export default SortButton;
