import * as types from "./actionsTypes";
import * as customerDevicesApi from "../api/deviceCustomerApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import { toast } from "react-toastify";

export const saveSingleDeviceToCustomer =
  (auth, id, devices) => async (dispatch) => {
    dispatch(beginApiCall());

    customerDevicesApi
      .saveDevicesToCustomer(auth, id, devices)
      .then((device) => {
        // Response returns list of device;
        dispatch({
          type: types.SAVE_DEVICE_CUSTOMER_CONNECTION_SUCCESS,
          payload: {
            device: device[0],
          },
        });
        toast.success(
          device[0].IMEI + " succesfully added to " + device[0].Customer
        );
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };

export const saveDevicesToCustomer =
  (auth, id, devices) => async (dispatch) => {
    dispatch(beginApiCall());

    customerDevicesApi
      .saveDevicesToCustomer(auth, id, devices)
      .then((devices) => {
        // Response returns list of devices;
        dispatch({
          type: types.SAVE_DEVICE_CUSTOMER_CONNECTIONS_SUCCESS,
          payload: {
            devices: devices,
          },
        });
        toast.success(
          devices.length + " device(s) succesfully added to customer"
        );
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };

export const removeDevicesFromCustomer =
  (auth, devices) => async (dispatch) => {
    dispatch(beginApiCall());

    customerDevicesApi
      .removeDevicesFromCustomer(auth, devices)
      .then((devices) => {
        // Response returns list of devices;
        dispatch({
          type: types.SAVE_DEVICE_CUSTOMER_CONNECTIONS_SUCCESS,
          payload: {
            devices: devices,
          },
        });
        toast.warn(
          devices.length + " device(s) succesfully removed from customer"
        );
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };

export const getCustomerHistoryByDevice = (auth, id) => async (dispatch) => {
  dispatch(beginLoadingDevice());
  

  customerDevicesApi
    .getCustomerHistoryByDevice(auth, id)
    .then((customerHistory) => {
      dispatch({
        type: types.LOAD_DEVICE_CUSTOMER_HISTORY_SUCCESS,
        payload: {
          customerHistory: customerHistory,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

const beginLoadingDevice = () => async (dispatch) => {
  dispatch(beginApiCall());
  dispatch({
    type: "LOADING_DEVICE",
    payload: {
      isLoading: true,
    },
  });
};
