import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/api/devices/customerconnection/";

export const saveDevicesToCustomer = (auth, id, devices) => {
  return fetch(baseUrl + id, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: auth.accessToken,
    },
    body: JSON.stringify(devices),
  })
    .then(handleResponse)
    .catch(handleError);
};

export const removeDevicesFromCustomer = (auth, devices) => {
  return fetch(baseUrl, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      Authorization: auth.accessToken,
    },
    body: JSON.stringify(devices),
  })
    .then(handleResponse)
    .catch(handleError);
};

export const getCustomerHistoryByDevice = (auth, id) => {
  return fetch(baseUrl + "history/" + id, {
    headers: { Authorization: auth.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
};
