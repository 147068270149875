import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

// Redux
import { connect } from "react-redux";

// Components
import DeviceInfoPane from "./deviceInformation/DeviceInfoPane";
//import DeviceTestPane from "./installationTest/DeviceTestPane";
import DeviceSettingsPaneV2 from "./settings/DeviceSettingsPaneV2";
import DeviceSettingsPane from "./settings/DeviceSettingsPane";
import DeviceEndpointsPane from "./endpoints/DeviceEndpointsPane";
import DeviceMessagesPane from "./messages/DeviceMessagesPane";
import DeviceCanConfigPane from "./canConfig/DeviceCanConfigPane";
import DeviceThresholdsPane from "./thresholds/DeviceThresholdsPane";
import DeviceThresholdsPaneV2 from "./thresholds/DeviceThresholdsPaneV2";
import DeviceRequestsPane from "./requests/DeviceRequestsPane";
import DeviceRawMessagesPane from "./rawMessage/DeviceRawMessagesPane";
import DeviceSettingsHistoryPane from "./settingsHistory/DeviceSettingsHistoryPane";
import DeviceCustomerHistoryPane from "./customerHistory/DeviceCustomerHistoryPane";

// Styling
import { Tab, Nav, Row, Col, Card } from "react-bootstrap";
import DeviceInfoPaneV2 from "./deviceInformation/DeviceInfoPaneV2";

const DeviceNavigation = ({ auth, device, ...props }) => {
  const { id } = useParams();
  useEffect(() => {
    disabledOptions();
  }, []);

  const [currentTab, setCurrentTab] = useState("Information");
  const HandleTab = (key) => {
    setCurrentTab(key);
  };

  const [disableOptions, setDisableOptions] = useState(true);
  const disabledOptions = () => {
    if (auth.user.Role == "Admin") {
      setDisableOptions(false);
    } else {
      setDisableOptions(true);
    }
  };
  
  return (
    <>
      <Card className="card-style device">
        <Card.Header>
          <Card.Title>
            {id === device.Id ? (
              <h1 className="device-info-title">Device: {device.IMEI}</h1>
            ) : (
              <h1 className="device-info-title">Device</h1>
            )}
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Tab.Container
            id="left-tabs"
            defaultActiveKey="Information"
            onSelect={HandleTab}
          >
            <Row>
              <Col sm={2}>
                <Nav variant="pills" className="flex-column device-nav">
                  <Nav.Item>
                    <Nav.Link eventKey="Information">Information</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Settings">Settings</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Endpoints">Endpoints</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    {props.currentConfig.applicationVersion != 0 ? <Nav.Link eventKey="Messages">Messages</Nav.Link> : null}
                  </Nav.Item>
                  <Nav.Item>
                    {props.currentConfig.applicationVersion == 0 ? <Nav.Link eventKey="CANConfig">CAN Config</Nav.Link> : null}
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="ThresholdConfig">Thresholds</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    {/* <Nav.Link href={"/devicetest/" + device.IMEI}>Installation Test</Nav.Link> */}
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Requests">Requests</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="RawMessage">RawMessage</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="History">Settings History</Nav.Link>
                  </Nav.Item>
                  {!disableOptions && (
                    <Nav.Item>
                      <Nav.Link eventKey="Customer">Customer History</Nav.Link>
                    </Nav.Item>
                  )}
                </Nav>
              </Col>
              <Col sm={10}>
                <Tab.Content>
                  <Tab.Pane eventKey="Information">
                    {currentTab == "Information" && id === device.Id ? (props.currentConfig.applicationVersion != 0 ? <DeviceInfoPaneV2 /> : <DeviceInfoPane />) : null}
                  </Tab.Pane>
                  <Tab.Pane eventKey="Settings">
                    {currentTab == "Settings" ? (props.currentConfig.applicationVersion != 0 ? <DeviceSettingsPaneV2 /> : <DeviceSettingsPane />) : null}
                  </Tab.Pane>
                  <Tab.Pane eventKey="Endpoints">
                    {currentTab == "Endpoints" ? <DeviceEndpointsPane /> : null}
                  </Tab.Pane>
                  <Tab.Pane eventKey="Messages">
                    {currentTab == "Messages" ? (props.currentConfig.applicationVersion != 0 ? <DeviceMessagesPane /> : null) : null}
                  </Tab.Pane>
                  <Tab.Pane eventKey="CANConfig">
                    {currentTab == "CANConfig" ? <DeviceCanConfigPane /> : null}
                  </Tab.Pane>
                  <Tab.Pane eventKey="ThresholdConfig">
                    {currentTab == "ThresholdConfig" ? (props.currentConfig.applicationVersion != 0 ? <DeviceThresholdsPaneV2 /> : <DeviceThresholdsPane />) : null}
                  </Tab.Pane>
                  <Tab.Pane eventKey="Test">
                    devicetest
                  </Tab.Pane>
                  <Tab.Pane eventKey="Requests">
                    {currentTab == "Requests" ? <DeviceRequestsPane /> : null}
                  </Tab.Pane>
                  <Tab.Pane eventKey="RawMessage">
                    {currentTab == "RawMessage" ? (
                      <DeviceRawMessagesPane />
                    ) : null}
                  </Tab.Pane>
                  <Tab.Pane eventKey="History">
                    {currentTab == "History" ? (
                      <DeviceSettingsHistoryPane />
                    ) : null}
                  </Tab.Pane>
                  {!disableOptions && (
                    <Tab.Pane eventKey="Customer">
                      {currentTab == "Customer" ? (
                        <DeviceCustomerHistoryPane />
                      ) : null}
                    </Tab.Pane>
                  )}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Card.Body>
      </Card>
    </>
  );
};

DeviceNavigation.propTypes = {
  auth: PropTypes.object.isRequired,
  device: PropTypes.object.isRequired,
  currentConfig: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    device: state.deviceDetails.device,
    currentConfig: state.deviceDetails.currentConfig,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps,)(DeviceNavigation);
