import React from "react";
import PropTypes from "prop-types";

// Redux
import {connect, useDispatch} from "react-redux";
import {removeFeature} from "../../actions/featureActions";

// Components
import Confirmation from "../common/Confirmation";
import {SortedTable} from "../common/SortedTable";
import SortButton from "../common/SortButton";
import {Button, Table} from "react-bootstrap";
import DeleteIcon from "../common/icons/trash-can-solid.svg";

const FeatureList = ({auth, features}) => {
    const dispatch = useDispatch();
    const {list, requestSort, sortConfig} = SortedTable(features);

    const removeFeatureHandler = (feature) => {
        dispatch(removeFeature(auth, feature.Id));
    };

    return (
        <div className="feature">
            <Table hover responsive striped>
                <thead>
                <tr>
                    <th>
                        Name
                        <SortButton
                            name="Name"
                            requestSort={requestSort}
                            sortConfig={sortConfig}
                        />
                    </th>
                    <th>
                        Id
                        <SortButton
                            name="Id"
                            requestSort={requestSort}
                            sortConfig={sortConfig}
                        />
                    </th>
                    <th>
                        UsedBy
                        <SortButton
                            name="UsedBy"
                            requestSort={requestSort}
                            sortConfig={sortConfig}
                        />
                    </th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {list.length === 0 ? (
                    <tr>
                        <td colSpan="15" style={{textAlign: "center"}}>
                            No features found
                        </td>
                    </tr>
                ) : (
                    list.map((feature) => {
                        return (
                            <tr
                                className="table-row"
                                key={feature.Id}
                            >
                                <td>{feature.Name}</td>
                                <td>{feature.Id}</td>
                                <td>{feature.UsedBy}</td>
                                <td>
                                    <Button className="shadow-sm border-secondary" variant="light">
                                        <Confirmation
                                            buttonText={
                                                <img
                                                    src={DeleteIcon}
                                                    alt="Delete"
                                                />
                                            }
                                            description={`Are you sure you want to remove this modem request?`}
                                            onConfirm={() => removeFeatureHandler(feature)}
                                        />
                                    </Button>
                                </td>
                            </tr>
                        );
                    })
                )}
                </tbody>
            </Table>
        </div>
    );
};

FeatureList.propTypes = {
    auth: PropTypes.object.isRequired,
    features: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FeatureList);
