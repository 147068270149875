const auth = JSON.parse(localStorage.getItem("telematiqs-auth"));
const token = JSON.parse(localStorage.getItem("telematiqs-token"));

const initialAccountState = {
  users: [],
  customer: null,
};

const initialDashboardState = {
  deviceCount: 0,
  endpoints: 0,
  rawMessages: 0,
  lastMsg: 0,
  weekly: [],
  invalidmsg: [],
  insync: [],
};

const initialCustomerDetailsState = {
  customer: null,
  devices: [],
  users: [],
  companyCards: [],
  authServers: [],
  isLoading: false,
};

const initialUserState =
  auth && token
    ? { isLoggedIn: true, user: auth, accessToken: token }
    : { isLoggedIn: false, user: null, accessToken: "" };

const initialDeviceDetailState = {
  isLoading: false,
  device: null,
  currentConfig: null,
  newConfig: null,
  state: null,
  configurationGroups: [],
  endpoints: [],
  modemRequests: [],
  rawMessages: [],
  settings: [],
  customerHistory: [],
};

export default {
  auth: initialUserState,
  account: initialAccountState,
  dashboard: initialDashboardState,
  configurationGroups: [],
  devices: [],
  deviceTestSockets: [],
  customers: [],
  rtdIntegrations: [],
  customerDetails: initialCustomerDetailsState,
  companyCards: [],
  authServers: [],
  tachoDownloads: [],
  customTestData: [],
  users: [],
  roles: [],
  features: [],
  endpoints: [],
  deviceTypes: [],
  protocols: [],
  messageTypes: [],
  rawMessages: [],
  apiCallsInProgress: 0,
  message: {},
  deviceDetails: initialDeviceDetailState,
  logs: [],
  configTemplates: [],
  deviceTests: [],
  deviceTest: [],
  deviceTestSettings: [],
  canConfigs: [],
  firmwares: [],
  firmwareFlags: [],
  tachoObjects: [],
  tachoDrivers: [],
  endOfList: false,
  settingsProfiles: [],
};
