import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/api/log/";

export function getLogsCount(auth) {
  return fetch(baseUrl + "all/", { headers: { Authorization: auth.accessToken } })
      .then(handleResponse)
      .catch(handleError);
}

export function getLogs(auth) {
  return fetch(baseUrl, {
    headers: { Authorization: auth.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getLogsFromId(auth, id) {
  return fetch(baseUrl + id, { headers: { Authorization: auth.accessToken}})
      .then(handleResponse)
      .catch(handleError);
}

export function getLogsFromSearch(auth, searchTerm, filter) {
  return fetch(baseUrl + "search/" + searchTerm + "/" + filter, { headers: {Authorization: auth.accessToken}})
      .then(handleResponse)
      .catch(handleError);
}