import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/api/features/";

export function getFeatures(auth) {
  return fetch(baseUrl, {
    headers: { Authorization: auth.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
}

export const getFeatureById = (auth, id) => {
  return fetch(baseUrl + id, {
    headers: { Authorization: auth.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
};

export const updateFeature = (auth, id, feature) => {
  return fetch(baseUrl + id, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      Authorization: auth.accessToken,
    },
    body: JSON.stringify(feature),
  })
    .then(handleResponse)
    .catch(handleError);
};

export const saveFeature = (auth, feature) => {
  return fetch(baseUrl, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: auth.accessToken,
    },
    body: JSON.stringify(feature),
  })
    .then(handleResponse)
    .catch(handleError);
};

export const removeFeature = (auth, id) => {
  return fetch(baseUrl + id, {
    method: "DELETE",
    headers: {
      Authorization: auth.accessToken,
    },
  })
    .then(handleResponse)
    .catch(handleError);
};
