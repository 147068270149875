import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Time
import moment from "moment";

// Redux
import { useDispatch, connect } from "react-redux";
import {
    getSettingsByDevice,
    updateDeviceSetting,
    removeDeviceSetting,
    removeAllUnprocessed,
} from "../../../actions/deviceSettingActions";

// Components
import Confirmation from "../../common/Confirmation";
import { Table, Button } from "react-bootstrap";
import Spinner from "../../common/Spinner";
import UpdateSettingModal from "./UpdateSettingModal";

import EditIcon from "../../common/icons/pen-to-square-solid.svg";
import DeleteIcon from "../../common/icons/trash-can-solid.svg";

const DeviceSettingsHistoryPane = ({ auth, device, settings, isLoading,apiCallsInProgress }) => {
    const dispatch = useDispatch();
    const settingList = [...settings];
    const [show, setShow] = useState(false);
    const [selectedSetting, setSelectedSetting] = useState({});
    const [updatedSetting, setUpdatedSetting] = useState({
        SettingName: "",
        Value: "",
    });

    useEffect(() => {
        refreshSettingsHistory();
    }, []);

    const refreshSettingsHistory = () => {
        if (apiCallsInProgress === 0) {
            dispatch(getSettingsByDevice(auth, device.IMEI));
        }
    };

    const selectSetting = (e) => {
        const setting = settings.find((item) => item.Id === e);
        setSelectedSetting(setting);
        setUpdatedSetting({
            SettingName: setting.SettingName,
            Value: setting.Value,
        });
    };

    const saveSettingsHandler = (e) => {
        e.preventDefault();
        dispatch(updateDeviceSetting(auth, selectedSetting.Id, updatedSetting));
        setShow(false);
    };

    const RemoveAllUnprocessedSettings = () => {
        var unprocessedSettings = settingList.some((setting) => !setting.Processed);
        if (unprocessedSettings) dispatch(removeAllUnprocessed(auth, device.IMEI));
    };

    const removeSettingHandler = (e) => {
        dispatch(removeDeviceSetting(auth, e));
        setShow(false);
    };

    const showModalHandler = (e) => {
        selectSetting(e);
        setShow(true);
    };

    const isViewer = auth.user.Role === "Viewer";

    return (
        <>
            {!isLoading ? (
                <>
                    <Table hover striped responsive>
                        <thead className="thead-inverse">
                            <tr>
                                <th>Time</th>
                                <th>Name</th>
                                <th>Value</th>
                                <th>Processed</th>
                                <th colSpan="2">
                                    <Button
                                        style={{ float: "right" }}
                                        variant="light"
                                        onClick={refreshSettingsHistory}
                                    >
                                        ⟳
                                    </Button>
                                    {!isViewer && (
                                        <Button
                                            style={{ float: "right", marginRight: "10px" }}
                                            variant="light"
                                            onClick={RemoveAllUnprocessedSettings}
                                        >
                                            Remove Unprocessed
                                        </Button>
                                    )}
                                </th>
                            </tr>
                        </thead>
                        <tbody style={{ wordBreak: "break-all" }}>
                            {settingList
                                .sort(function (a, b) {
                                    return new Date(b.CreatedDate) - new Date(a.CreatedDate);
                                })
                                .map((setting) => {

                                    const isViewer = auth.user.Role === "Viewer";
                                    return (
                                        <tr
                                            className="table-row"
                                            key={setting.Id}
                                        >
                                            <td>
                                                {moment(setting.CreatedDate).format(
                                                    "DD-MM-YYYY HH:mm:ss"
                                                )}
                                            </td>
                                            <td>{setting.SettingName}</td>
                                            <td>{setting.Value}</td>
                                            <td>{setting.Processed ? "Yes" : "No"}</td>
                                            <td>
                                                {!isViewer && (
                                                    <Button className="shadow-sm border-secondary" variant="light"
                                                        onClick={() => {
                                                            showModalHandler(setting.Id)
                                                        }}>
                                                        <img
                                                            src={EditIcon}
                                                            alt="Edit"
                                                        />
                                                    </Button>
                                                )}
                                            </td>
                                            <td>
                                                {!isViewer && (
                                                    <Button className="shadow-sm border-secondary" variant="light">
                                                        <Confirmation
                                                            buttonText={
                                                                <img
                                                                    src={DeleteIcon}
                                                                    alt="Delete"
                                                                />
                                                            }
                                                            description={`Are you sure you want to remove this setting?`}
                                                            onConfirm={() => removeSettingHandler(setting.Id)}
                                                        />
                                                    </Button>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </Table>
                    {
                        selectedSetting && (
                            <UpdateSettingModal
                                show={show}
                                setShow={setShow}
                                updatedSetting={updatedSetting}
                                setUpdatedSetting={setUpdatedSetting}
                                saveSettingsHandler={saveSettingsHandler}
                            />
                        )
                    }
                </>
            ) :
                (
                    <Spinner />
                )
            }
        </>
    );
};

DeviceSettingsHistoryPane.propTypes = {
    auth: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired,
    settings: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    apiCallsInProgress: PropTypes.number.isRequired
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        device: state.deviceDetails.device,
        settings: state.deviceDetails.settings,
        isLoading: state.deviceDetails.isLoading,
        apiCallsInProgress : state.apiCallsInProgress,
    };
}

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceSettingsHistoryPane);
