import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form, Col, Row, Button } from "react-bootstrap";

const MessageURLControlRow = ({
    name,
    message,
    setMessage,
    saveMessageHandler,
    disableOptions,
    isViewer,
    currentMessage,
}) => {
    var newMessage = message;

    const inputHandler = (e) => {
        newMessage = {...newMessage, URL: e.target.value}
        setMessage(newMessage)
    }

    return (
        <>
            <Form.Group as={Row}>
                <Form.Label column sm="3">
                    <h6>{name}{ + !currentMessage || message.URL == currentMessage.URL ? "" : "*"}:</h6>
                </Form.Label>
                <Col sm="8">
                    <Form.Control
                        name={name}
                        disabled={disableOptions() || isViewer}
                        type="string"
                        defaultValue={newMessage.URL}
                        onChange={(e) => inputHandler(e)}
                    />
                </Col>
                <Col sm="1">
                    {!isViewer && !disableOptions() && (
                        <Button
                            variant="success"
                            onClick={(e) => saveMessageHandler(e)}
                        >
                            Save
                        </Button>
                    )}
                </Col>
            </Form.Group>
        </>
    )
}

MessageURLControlRow.propTypes = {
    name: PropTypes.string.isRequired,
    message: PropTypes.object.isRequired,
    setMessage: PropTypes.func.isRequired,
    saveMessageHandler: PropTypes.func.isRequired,
    disableOptions: PropTypes.func.isRequired,
    isViewer: PropTypes.bool.isRequired,
    currentMessage: PropTypes.object,

}
function mapStateToProps() {
    return {};
}
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MessageURLControlRow);