import * as types from "./actionsTypes";
import * as deviceSettingApi from "../api/deviceSettingApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import { toast } from "react-toastify";

import { getDeviceById } from "../actions/deviceActions";

export const getSettingsByDevice = (auth, imei) => async (dispatch) => {
  dispatch(beginLoadingDevice());

  deviceSettingApi
    .getSettingsByDevice(auth, imei)
    .then((settings) => {
      dispatch({
        type: types.LOAD_DEVICE_SETTINGS_SUCCESS,
        payload: {
          settings: settings,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const saveDeviceSettings =
  (auth, Id, IMEI, settings) => async (dispatch) => {
    dispatch(beginApiCall());

    deviceSettingApi
      .saveSettingsForDevice(auth, IMEI, settings)
      .then((res) => {
        dispatch({
          type: types.SAVE_DEVICE_SETTINGS_SUCCESS,
          payload: {
            settings: res,
          },
        });
        dispatch(getDeviceById(auth, Id));
        toast.success("Settings saved for " + IMEI);
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };

export const updateDeviceSetting = (auth, id, setting) => async (dispatch) => {
  dispatch(beginApiCall());

  deviceSettingApi
    .updateSettingForDevice(auth, id, setting)
    .then((setting) => {
      dispatch({
        type: types.UPDATE_DEVICE_SETTING_SUCCESS,
        payload: {
          setting: setting,
        },
      });
      toast.success("The setting has been updated");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const removeDeviceSetting = (auth, id) => async (dispatch) => {
  dispatch(beginApiCall());

  deviceSettingApi
    .removeSettingForDevice(auth, id)
    .then(() => {
      dispatch({
        type: types.REMOVE_DEVICE_SETTING_SUCCESS,
        payload: {
          Id: id,
        },
      });
      toast.warning("The setting has been removed");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const removeAllUnprocessed = (auth, imei) => async (dispatch) => {
  dispatch(beginApiCall());

  deviceSettingApi
    .removeAllUnprocessedSettingsForDevice(auth, imei)
    .then(() => {
      dispatch({
        type: types.REMOVE_UNPROCESSED_DEVICE_SETTINGS_SUCCESS,
      });
      toast.warning("Unprocessed settings have been removed");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

const beginLoadingDevice = () => async (dispatch) => {
  dispatch(beginApiCall());
  
  dispatch({
    type: "LOADING_DEVICE",
    payload: {
      isLoading: true,
    },
  });
};
