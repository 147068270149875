import * as types from "./actionsTypes";
import * as rawMessagesApi from "../api/rawMessageApi";
import {beginApiCall, apiCallError} from "./apiStatusActions";

export const loadAllRawMessages = (auth) => async (dispatch) => {
    dispatch(beginApiCall());

    rawMessagesApi
        .getRawMessages(auth)
        .then((messages) => {
            dispatch({
                type: types.LOAD_FIRST_BATCH_RAW_MESSAGES_SUCCESS,
                payload: {
                    rawMessages: messages,
                },
            });
            
            dispatch({
                type: types.CHECK_END_OF_LIST,
                payload: {
                    messages: messages,
                }
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
};

export const loadAllRawMessagesFromId = (auth, id) => async (dispatch) => {
    dispatch(beginApiCall());

    rawMessagesApi
        .getRawMessagesFromId(auth, id)
        .then((messages) => {
            dispatch({
                type: types.LOAD_BATCH_RAW_MESSAGES_SUCCESS,
                payload: {
                    rawMessages: messages,
                },
            });
            dispatch({
                type: types.CHECK_END_OF_LIST,
                payload: {
                    messages: messages,
                }
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
};

export const loadSearchedRawMessages = (auth, searchTerm, filter) => async (dispatch) => {
    dispatch(beginApiCall());

    rawMessagesApi
        .getRawMessageFromSearch(auth, searchTerm, filter)
        .then((messages) => {
            dispatch({
                type: types.LOAD_SEARCH_RESULT_RAW_MESSAGES_SUCCESS,
                payload: {
                    rawMessages: messages,
                },
            });
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
};

