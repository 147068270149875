import * as types from "../actions/actionsTypes";
import initialState from "./initialState";


const deviceSettingReducer = (
  state = initialState.deviceDetails.settings,
  action
) => {
  switch (action.type) {
    case types.LOAD_DEVICE_SETTINGS_SUCCESS:
      return action.payload.settings;
      case types.SAVE_DEVICE_SETTINGS_SUCCESS:
      var newIds = action.payload.settings.map((setting) => {
        return setting.Id;
      });

      var filtered = [
        ...state.filter((item) => {
          return !newIds.includes(item.Id);
        }),
      ];

      action.payload.settings.forEach((setting) => {
        filtered.push(setting);
      });

      return filtered;
    case types.UPDATE_DEVICE_SETTING_SUCCESS:
      return [
        ...state.filter((setting) => setting.Id != action.payload.setting.Id),
        action.payload.setting,
      ];
    case types.REMOVE_DEVICE_SETTING_SUCCESS:
      return [...state.filter((setting) => setting.Id != action.payload.Id)];

    case types.REMOVE_UNPROCESSED_DEVICE_SETTINGS_SUCCESS:
      return [...state.filter((setting) => setting.Processed)];
    default:
      return state;
  }
};

export default deviceSettingReducer;
