import React from "react";
import PropTypes from "prop-types";
import {Form, Col, Row} from "react-bootstrap";

export const DeviceinfoHeader = ({device, state}) => {

    return (
        <>
            <Row>
                <Form.Group as={Col} controlId="IMEI">
                    <Form.Label>
                        <h6>IMEI: </h6>
                    </Form.Label>
                    <Form.Control plaintext readOnly defaultValue={device.IMEI}/>
                </Form.Group>
                <Form.Group as={Col} controlId="SN">
                    <Form.Label>
                        <h6>Serial Number: </h6>
                    </Form.Label>
                    <Form.Control plaintext readOnly defaultValue={device.SN}/>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} controlId="Hardware">
                    <Form.Label>
                        <h6>Hardware: </h6>
                    </Form.Label>
                    <Form.Control plaintext readOnly defaultValue={(device.DeviceName ?? "") + " " + device.Hardware}/>
                </Form.Group>
                <Form.Group as={Col} controlId="VIN">
                    <Form.Label>
                        <h6>VIN: </h6>
                    </Form.Label>
                    <Form.Control plaintext readOnly defaultValue={(state?.VIN ?? 0)}/>
                </Form.Group>
            </Row>
        </>
    )
}

DeviceinfoHeader.propTypes = {
    device: PropTypes.object.isRequired,
    state: PropTypes.object,
};

  