import {handleResponse, handleError} from "./apiUtils";

const baseUrl = process.env.API_URL + "/api/rawmessages/";

export function getRawMessagesCount(auth) {
    return fetch(baseUrl + "all/", {headers: {Authorization: auth.accessToken}})
        .then(handleResponse)
        .catch(handleError);
}

export function getRawMessages(auth) {
    return fetch(baseUrl, {headers: {Authorization: auth.accessToken}})
        .then(handleResponse)
        .catch(handleError);
}

export function getRawMessagesFromId(auth, id) {
    return fetch(baseUrl + id, {headers: {Authorization: auth.accessToken}})
        .then(handleResponse)
        .catch(handleError);
}

export function getRawMessageFromSearch(auth, searchTerm, filter) {
    return fetch(baseUrl + "search/" + searchTerm + "/" + filter, {headers: {Authorization: auth.accessToken}})
        .then(handleResponse)
        .catch(handleError);
}
