import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Modal, Form, Container, Table, Button } from "react-bootstrap";
import EndpointCheckboxTableRow from "./EndpointCheckboxTableRow";

const ChangeMessageModal = ({
    name,
    show,
    setShow,
    message,
    setMessage,
    saveMessageHandler,
}) => {
    var newMessage = message;
    const setChapterMask = (key, value) => {
        switch (key) {
            case "TIME":
                newMessage = { ...newMessage, TIME: value }
                break;
            case "SOT":
                newMessage = { ...newMessage, SOT: value }
                break;
            case "EOT":
                newMessage = { ...newMessage, EOT: value }
                break;
            case "CHG":
                newMessage = { ...newMessage, CHG: value }
                break;
            case "POR":
                newMessage = { ...newMessage, POR: value }
                break;
            case "WAKE":
                newMessage = { ...newMessage, WAKE: value }
                break;
            case "STB":
                newMessage = { ...newMessage, STB: value }
                break;
            case "MOV":
                newMessage = { ...newMessage, MOV: value }
                break;
            case "LOWBV":
                newMessage = { ...newMessage, LOWBV: value }
                break;
            case "PZ":
                newMessage = { ...newMessage, PZ: value }
                break;
            case "DWL":
                newMessage = { ...newMessage, DWL: value }
                break;
            case "LOG":
                newMessage = { ...newMessage, LOG: value }
                break;
            case "DS":
                newMessage = { ...newMessage, DS: value }
                break;
            case "DIAG":
                newMessage = { ...newMessage, DIAG: value }
                break;
            case "TL":
                newMessage = { ...newMessage, TL: value }
                break;
            case "REQ":
                newMessage = { ...newMessage, REQ: value }
                break;
        }
        setMessage(newMessage)
    }

    const handleSave = (e) => {
        e.preventDefault();
        setShow(false)
        saveMessageHandler()
    }
    const handleClose = () => setShow(false);
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                size="xl"
            >
                <Form>
                    <Modal.Header closeButton >
                        <Modal.Title id="contained-modal-title-vcenter">
                            {name}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Table striped hover responsive>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Live</th>
                                        <th>GNSS</th>
                                        <th>Tacho</th>
                                        <th>AltFuel</th>
                                        <th>Dashboard</th>
                                        <th>TripData</th>
                                        <th>BLE</th>
                                        <th>Events</th>
                                        <th>Settings</th>
                                        <th>Diagnostics</th>
                                        <th>Endpoints</th>
                                        <th>HighRes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <EndpointCheckboxTableRow
                                        name={'Timed'}
                                        id={"TIME"}
                                        chapterMask={newMessage.TIME}
                                        setChapterMask={setChapterMask}
                                    />
                                </tbody>
                                <tbody>
                                    <EndpointCheckboxTableRow
                                        name={'Start of trip'}
                                        id={"SOT"}
                                        chapterMask={newMessage.SOT}
                                        setChapterMask={setChapterMask}
                                    />
                                </tbody>
                                <tbody>
                                    <EndpointCheckboxTableRow
                                        name={'End of trip'}
                                        id={"EOT"}
                                        chapterMask={newMessage.EOT}
                                        setChapterMask={setChapterMask}
                                    />
                                </tbody>
                                <tbody>
                                    <EndpointCheckboxTableRow
                                        name={'Charging'}
                                        id={"CHG"}
                                        chapterMask={newMessage.CHG}
                                        setChapterMask={setChapterMask}
                                    />
                                </tbody>
                                <tbody>
                                    <EndpointCheckboxTableRow
                                        name={'Power cycle'}
                                        id={"POR"}
                                        chapterMask={newMessage.POR}
                                        setChapterMask={setChapterMask}
                                    />
                                </tbody>
                                <tbody>
                                    <EndpointCheckboxTableRow
                                        name={'Wake up'}
                                        id={"WAKE"}
                                        chapterMask={newMessage.WAKE}
                                        setChapterMask={setChapterMask}
                                    />
                                </tbody>
                                <tbody>
                                    <EndpointCheckboxTableRow
                                        name={'Standby'}
                                        id={"STB"}
                                        chapterMask={newMessage.STB}
                                        setChapterMask={setChapterMask}
                                    />
                                </tbody>
                                <tbody>
                                    <EndpointCheckboxTableRow
                                        name={'Movement'}
                                        id={"MOV"}
                                        chapterMask={newMessage.MOV}
                                        setChapterMask={setChapterMask}
                                    />
                                </tbody>
                                <tbody>
                                    <EndpointCheckboxTableRow
                                        name={'Low board voltage'}
                                        id={"LOWBV"}
                                        chapterMask={newMessage.LOWBV}
                                        setChapterMask={setChapterMask}
                                    />
                                </tbody>
                                <tbody>
                                    <EndpointCheckboxTableRow
                                        name={'IO Change'}
                                        id={"PZ"}
                                        chapterMask={newMessage.PZ}
                                        setChapterMask={setChapterMask}
                                    />
                                </tbody>
                                <tbody>
                                    <EndpointCheckboxTableRow
                                        name={'Unauth. driving'}
                                        id={"DWL"}
                                        chapterMask={newMessage.DWL}
                                        setChapterMask={setChapterMask}
                                    />
                                </tbody>
                                <tbody>
                                    <EndpointCheckboxTableRow
                                        name={'Login/logout'}
                                        id={"LOG"}
                                        chapterMask={newMessage.LOG}
                                        setChapterMask={setChapterMask}
                                    />
                                </tbody>
                                <tbody>
                                    <EndpointCheckboxTableRow
                                        name={'Driving style'}
                                        id={"DS"}
                                        chapterMask={newMessage.DS}
                                        setChapterMask={setChapterMask}
                                    />
                                </tbody>
                                <tbody>
                                    <EndpointCheckboxTableRow
                                        name={'Diagnostic'}
                                        id={"DIAG"}
                                        chapterMask={newMessage.DIAG}
                                        setChapterMask={setChapterMask}
                                    />
                                </tbody>
                                <tbody>
                                    <EndpointCheckboxTableRow
                                        name={'TachoLive'}
                                        id={"TL"}
                                        chapterMask={newMessage.TL}
                                        setChapterMask={setChapterMask}
                                    />
                                </tbody>
                                <tbody>
                                    <EndpointCheckboxTableRow
                                        name={'Request'}
                                        id={"REQ"}
                                        chapterMask={newMessage.REQ}
                                        setChapterMask={setChapterMask}
                                    />
                                </tbody>
                            </Table>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button
                            onClick={handleSave}
                            variant="success"
                            type="submit"
                        >
                            Save
                        </Button>

                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

ChangeMessageModal.propTypes = {
    name: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    message: PropTypes.object.isRequired,
    saveMessageHandler: PropTypes.func.isRequired,
    setMessage: PropTypes.func.isRequired,
}

function mapStateToProps() {
    return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeMessageModal);