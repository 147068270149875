import * as types from "./actionsTypes";
import * as authServerApi from "../api/authServerApi";
import {beginApiCall, apiCallError} from "./apiStatusActions";
import {toast} from "react-toastify";
import {LOAD_AUTHENTICATION_SERVERS_SUCCESS} from "./actionsTypes";

export const getAuthServerById = (auth, id) => async (dispatch) => {
    dispatch(beginApiCall());
    dispatch({
        type: "LOADING_AUTHENTICATION_SERVER_DETAILS",
        payload: {
            isLoading: true,
        },
    });

    authServerApi
        .getAuthServerById(auth, id)
        .then((authServer) => {
            dispatch({
                type: types.LOAD_AUTHENTICATION_SERVER_DETAILS_SUCCESS,
                payload: {
                    authServer: authServer,
                },
            });
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
}

export const fetchAuthServers = (auth) => async (dispatch) => {
    dispatch(beginApiCall());

    authServerApi
        .getAuthServers(auth)
        .then((authServers) => {
            dispatch({
                type: types.LOAD_AUTHENTICATION_SERVERS_SUCCESS,
                payload: {
                    authServers: authServers,
                },
            });
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
};

export const loadAuthServersByCustomer = (auth, customer) => (dispatch) => {
    dispatch(beginApiCall());
    
    authServerApi
        .getAuthServersByCustomer(auth, customer)
        .then((authServers) => {
            dispatch({
                type: LOAD_AUTHENTICATION_SERVERS_SUCCESS,
                payload: {
                    authServers: authServers
                }
            })
        })
        .catch((error) => {
            apiCallError(error);
        });
}

export const loadSearchedAuthServers = (auth, searchTerm, filter, customer = null) => async (dispatch) => {
    dispatch(beginApiCall());

    authServerApi
        .getAuthServersFromSearch(auth, searchTerm, filter, customer)
        .then((authServers) => {
            dispatch({
                type: types.LOAD_SEARCH_RESULT_AUTHENTICATION_SERVER_SUCCESS,
                payload: {
                    authServers: authServers,
                },
            });
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
}

export const saveAuthServer = (auth, authServer) => async (dispatch) => {
    dispatch(beginApiCall());

    authServerApi
        .saveAuthServer(auth, authServer)
        .then((res) => {
            dispatch({
                type: types.SAVE_AUTHENTICATION_SERVER_SUCCESS,
                payload: {
                    authServer: res,
                },
            });
            toast.success("Authentication Server " + res.Name + " succesfully created!");
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
}

export const removeAuthServer = (auth, id) => async (dispatch) => {
    dispatch(beginApiCall());

    authServerApi
        .removeAuthServer(auth, id)
        .then(() => {
            dispatch({
                type: types.REMOVE_AUTHENTICATION_SERVER_SUCCESS,
                payload: {
                    authServer: id,
                },
            });
            toast.warn("Authentication server succesfully removed!");
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
}

export const updateAuthServer = (auth, id, authServer) => async (dispatch) => {
    dispatch(beginApiCall());

    authServerApi
        .updateAuthServer(auth, id, authServer)
        .then((res) => {
            dispatch({
                type: types.UPDATE_AUTHENTICATION_SERVER_SUCCESS,
                payload: {
                    authServer: res,
                },
            });
            toast.success("Authentication Server " + res.Name + " succesfully updated!");
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
}