import * as Yup from "yup";
export const validationSchema = Yup.object({
    customerId: Yup.string()
        .required("Required")
        .test("is-customerId", "Customer is required", (value) => value !== "--"),
    // 6-11-24 Oscar Removed this as the new SignalR servers don't have all these parameters and for backward compatability and ease of developing I want to have all 
    // the tacho information in the same pages, but updating a signal R authserver with a new customer (which is the only thing that can be updated) means all this
    // stuff does not need to be checked. Now the user has to take care that stuff like the endpoint IP is set properly
    // name: Yup.string()
    //     .required("Required")
    //     .min(3, "Must be at least 3 characters"),
    // ip: Yup.string()
    //     .required("Required")
    //     .matches(
    //         /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
    //         "Invalid IP Address, must be in format: X.X.X.X"
    //     ),
    // devicePort: Yup.number()
    //     .required("Required")
    //     .min(1, "Must be at least 1")
    //     .max(65535, "Must be less than 65535"),
    // port: Yup.number()
    //     .required("Required")
    //     .min(1, "Must be at least 1")
    //     .max(65535, "Must be less than 65535"),
    // username: Yup.string()
    //     .required("Required")
    //     .min(3, "Must be at least 3 characters"),
    // password: Yup.string()
    //     .required("Required")
    //     .min(3, "Must be at least 3 characters"),
    // status: Yup.number()
    //     .required("Required")
    //     .nullable()
});

// import * as Yup from 'yup';

// const schema = Yup.object().shape({
//   isCompany: Yup.boolean(),
//   companyName: Yup.string().when('isCompany', {
//     is: true,
//     then: Yup.string().required('Company name is required'),
//     otherwise: Yup.string(),
//   }),
// });
