import React from "react";
import PropTypes from "prop-types";
// import { Chart } from "react-google-charts";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Rectangle } from "recharts";
import { Spinner } from "react-bootstrap";

const RenderLineChart = (data) => {
    const dates = data.data.map((x) => { return x.Date });
    const totals = data.data.map((x) => { return x.Total });

    const stats = [
        { date: dates[0], total: totals[0] },
        { date: dates[1], total: totals[1] },
        { date: dates[2], total: totals[2] },
        { date: dates[3], total: totals[3] },
        { date: dates[4], total: totals[4] },
        { date: dates[5], total: totals[5] },
        { date: dates[6], total: totals[6] },
        { date: dates[7], total: totals[7] },
    ]

    return (
        <>
            {stats.length > 0 ? (
                <BarChart width={500} height={300} data={stats}>
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="total" fill="#0771B8" activeBar={<Rectangle fill="#0771B8" stroke="#0771B8" />} />
                </BarChart>

            ) : (<div className="d-flex h-100">
                <Spinner className="m-auto" animation="border" variant="primary" />
            </div>)}

            {/* <Chart
                chartType="Bar"
                data={stats}
                // loader={
                //     <div className="d-flex h-100">
                //         <Spinner className="m-auto" animation="border" variant="primary" />
                //     </div>
                // }
                options={options}
                width="100%"
                height="100%"
            /> */}
        </>
    );
};

RenderLineChart.propTypes = {
    data: PropTypes.array.isRequired,
};

export default RenderLineChart;
