import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/api/roles/";

export function getRoles(auth) {
  return fetch(baseUrl, {
    headers: { Authorization: auth.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
}

export const getRoleById = (auth, id) => {
  return fetch(baseUrl + id, {
    headers: { Authorization: auth.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
};

export const updateRole = (auth, id, role) => {
  return fetch(baseUrl + id, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      Authorization: auth.accessToken,
    },
    body: JSON.stringify(role),
  })
    .then(handleResponse)
    .catch(handleError);
};

export const saveRole = (auth, role) => {
  return fetch(baseUrl, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: auth.accessToken,
    },
    body: JSON.stringify(role),
  })
    .then(handleResponse)
    .catch(handleError);
};

export const removeRole = (auth, id) => {
  return fetch(baseUrl + id, {
    method: "DELETE",
    headers: {
      Authorization: auth.accessToken,
    },
  })
    .then(handleResponse)
    .catch(handleError);
};
