import React from "react";
import { Chart } from "react-google-charts";

const DriverStateGraph = () => {

  const stats = [
    ['', 'Available', 'Soon Available', 'Not on Schedule'],
    ['Driver by Law', 28, 19, 29],
    ['Planned Driver RDL', 12, 32, 9]
  ];

  const options = {
    isStacked: 'percent',
    height: 225,
    legend: { position: 'top' },
    series: {
      0: { color: 'green' },
      1: { color: 'orange' },
      2: { color: 'red' }
    }
  };

  return (
    <Chart
      chartType="BarChart"
      data={stats}
      options={options}
      width="100%"
      height="100%"
    />
  );
};

export default DriverStateGraph;