import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/api/home/dashboard";

export function getDashboard(auth) {
  return fetch(baseUrl, {
    headers: { Authorization: auth.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
}
