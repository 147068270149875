import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const tachoObjectReducer = (state = initialState.tachoObjects, action) => {
  switch (action.type) {
    case types.LOAD_TACHO_OBJECT_SUCCESS:
      return action.payload.tachoObjects;
    default:
      return state;
  }
};

export default tachoObjectReducer;