import React from "react";
import moment from "moment";
import PropTypes from "prop-types";

// Styling
import "./Dashboard.css";
import Spinner from "../../common/Spinner";
import {
    Row,
    Container,
    Card,
    ListGroup,
    ListGroupItem,
} from "react-bootstrap";

// Components
import RenderLineChart from "./RenderLineChart";
import RenderPieChart from "./RenderPieChart";

const Dashboard = ({ auth, dashboard, apiCallsInProgress }) => {
    return (
        <>
            {apiCallsInProgress > 0 ? (
                <Spinner />
            ) : (
                <Container>
                    <div className="dashboard">
                        <Row>
                            <Card className="CardDeckDashoard">
                                <Card className="CardDashboard">
                                    <Card.Header>
                                        <Card.Title>Stats</Card.Title>
                                    </Card.Header>
                                    <Card.Body className="CardDashboardbody">
                                        <ListGroup className="list-group-flush">
                                            <ListGroupItem>
                                                Devices : <b>{dashboard.deviceCount}</b>
                                            </ListGroupItem>
                                            <ListGroupItem>
                                                Endpoints : <b>{dashboard.endpoints}</b>
                                            </ListGroupItem>
                                            <ListGroupItem>
                                                Messages : <b> {dashboard.rawMessages}</b>
                                            </ListGroupItem>
                                            {dashboard.logs > 0 && (
                                                <ListGroupItem>
                                                    Logs : <b>{dashboard.logs}</b>
                                                </ListGroupItem>
                                            )}
                                            <ListGroupItem>
                                                Last message :{" "}
                                                <b>
                                                    {dashboard.lastMsg == null
                                                        ? "None"
                                                        : moment(dashboard.lastMsg).format(
                                                            "DD-MM-YYYY HH:mm:ss"
                                                        )}
                                                </b>
                                            </ListGroupItem>
                                            <ListGroupItem>
                                                Age last message :{" "}
                                                <b>
                                                    {dashboard.lastMsg == null
                                                        ? "None"
                                                        : moment(dashboard.lastMsg).fromNow()}{" "}
                                                </b>{" "}
                                            </ListGroupItem>
                                        </ListGroup>
                                    </Card.Body>
                                </Card>

                                <Card className="CardDashboard">
                                    <Card.Header>
                                        <Card.Title>Message Count</Card.Title>
                                    </Card.Header>
                                    <Card.Body className="CardDashboardbody">
                                        <>
                                            {dashboard.weekly == 0 && (
                                                <p>Your device message count will be shown here.</p>
                                            )}
                                            {dashboard.weekly != 0 && (
                                                <RenderLineChart data={dashboard.weekly} />
                                            )}
                                        </>
                                    </Card.Body>
                                </Card>
                            </Card>
                        </Row>
                        <Row>
                            <br></br>
                        </Row>
                        {auth.user.Role == 'Admin' ? (
                            <Row>
                                <Card className="CardDeckDashoard">
                                    <Card className="CardDashboard">
                                        <Card.Header>
                                            <Card.Title>RawMessage Quality(Last Week)</Card.Title>
                                        </Card.Header>
                                        <Card.Body className="CardDashboardbody">
                                            <div>
                                                <RenderPieChart
                                                    data={dashboard.invalidmsg}
                                                />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                    <Card className="CardDashboard">
                                        <Card.Header>
                                            <Card.Title>Device Config Status</Card.Title>
                                        </Card.Header>
                                        <Card.Body className="CardDashboardbody">
                                            <RenderPieChart data={dashboard.insync}></RenderPieChart>
                                        </Card.Body>
                                    </Card>
                                </Card>
                            </Row>
                        ) : (
                            <div>

                            </div>
                        )}
                        <br />
                    </div>
                </Container>
            )}
        </>
    );
};

Dashboard.propTypes = {
    auth: PropTypes.object.isRequired,
    dashboard: PropTypes.object.isRequired,
    apiCallsInProgress: PropTypes.number.isRequired,
};

export default Dashboard;
