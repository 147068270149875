import * as types from "./actionsTypes";
import * as tachoDriverApi from "../api/tachoDriverApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export const fetchTachoDrivers = (auth, names) => async (dispatch) => {
  dispatch(beginApiCall());
  tachoDriverApi
    .getTachoByName(auth, names)
    .then((tachoDrivers) => {
      dispatch({
        type: types.LOAD_TACHO_DRIVER_SUCCESS,
        payload: {
          tachoDrivers: tachoDrivers,
        }
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};