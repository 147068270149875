import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const deviceTestsReducer = (state = initialState.deviceTests, action) => {
switch (action.type) {
    case "LOADING_DEVICETEST":
        return {...state,
        isLoading: true,
        };
    case types.LOAD_DEVICESTESTS_SUCCESS:
        return action.payload.deviceTests;
            
        
    default:
        return state;
    }
};

export default deviceTestsReducer;