import * as types from "./actionsTypes";
import * as roleApi from "../api/roleApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import { toast } from "react-toastify";

export const fetchRoles = (auth) => async (dispatch) => {
  dispatch(beginApiCall());

  roleApi
    .getRoles(auth)
    .then((roles) => {
      dispatch({
        type: types.LOAD_ROLES_SUCCESS,
        payload: {
          roles: roles,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const getRoleById = (auth, id) => async (dispatch) => {
  dispatch(beginApiCall());

  roleApi
    .getRoleById(auth, id)
    .then((role) => {
      dispatch({
        type: types.LOAD_ROLE_SUCCESS,
        payload: {
          role: role,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const saveRole = (auth, role) => async (dispatch) => {
  dispatch(beginApiCall());

  roleApi
    .saveRole(auth, role)
    .then((res) => {
      dispatch({
        type: types.SAVE_ROLE_SUCCESS,
        payload: {
          role: res,
        },
      });
      toast.success("Role " + res.Name + " succesfully created");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const updateRole = (auth, id, role) => async (dispatch) => {
  dispatch(beginApiCall());

  roleApi
    .updateRole(auth, id, role)
    .then((res) => {
      dispatch({
        type: types.UPDATE_ROLE_SUCCESS,
        payload: {
          role: res,
        },
      });
      toast.success("Role " + res.Name + " succesfully updated!");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const removeRole = (auth, id) => async (dispatch) => {
  dispatch(beginApiCall());

  roleApi
    .removeRole(auth, id)
    .then(() => {
      dispatch({
        type: types.REMOVE_ROLE_SUCCESS,
        payload: {
          role: id,
        },
      });
      toast.warning("Role succesfully removed!");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export default fetchRoles;
