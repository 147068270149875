import * as types from "./actionsTypes";
import * as deviceConfigurationGroupApi from "../api/deviceConfigurationGroupApi";
import {beginApiCall, apiCallError } from "./apiStatusActions";
import { toast } from "react-toastify";

export const saveConfigurationToSingleDevice =
  (auth, configId, device) => async (dispatch) => {
    dispatch(beginApiCall());

    deviceConfigurationGroupApi
      .saveConfigurationToDevice(auth, configId, device)
      .then((deviceArray) => {
        dispatch({
          type: types.SAVE_CONFIGURATION_GROUP_FOR_DEVICE_SUCCESS,
          payload: {
            device: deviceArray[0],
          },
        });
        toast.success(
          deviceArray[0].ConfigurationGroup + " successfully saved to device"
        );
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };

export const removeConfigurationToSingleDevice =
  (auth, device) => async (dispatch) => {
    dispatch(beginApiCall());

    deviceConfigurationGroupApi
      .removeConfigurationFromDevice(auth, device)
      .then((deviceArray) => {
        dispatch({
          type: types.SAVE_CONFIGURATION_GROUP_FOR_DEVICE_SUCCESS,
          payload: {
            device: deviceArray[0],
          },
        });
        toast.warn(
          "Configuration group successfully removed from " + deviceArray[0].IMEI
        );
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };

export const saveConfigurationToDevices =
  (auth, id, devices) => async (dispatch) => {
    dispatch(beginApiCall());

    deviceConfigurationGroupApi
      .saveConfigurationToDevice(auth, id, devices)
      .then((deviceArray) => {
        dispatch({
          type: types.SAVE_DEVICES_CONFIGURATION_GROUP_SUCCESS,
          payload: {
            devices: deviceArray,
          },
        });
        toast.success(
          "Configuration group for " +
            deviceArray.length +
            " device(s) successfully updated."
        );
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };

export const removeConfigurationFromDevices =
  (auth, devices) => async (dispatch) => {
    dispatch(beginApiCall());

    deviceConfigurationGroupApi
      .removeConfigurationFromDevice(auth, devices)
      .then((deviceArray) => {
        dispatch({
          type: types.SAVE_DEVICES_CONFIGURATION_GROUP_SUCCESS,
          payload: {
            devices: deviceArray,
          },
        });
        toast.warn(
          "Configuration group for " +
            deviceArray.length +
            " device(s) successfully updated."
        );
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
