const baseUrl = process.env.API_URL + "/api/devicetests";
import { handleResponse, handleError } from "./apiUtils";

export function getDevicetests(auth) {
  return fetch(baseUrl, {
    headers: {
      Authorization: auth.accessToken,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getDevicetestsById(auth, id) {
  return fetch(baseUrl + id, {
    headers: {
      Authorization: auth.accessToken,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveDevicetest(auth, devicetest) {
  return fetch(baseUrl, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: auth.accessToken,
    },
    body: JSON.stringify(devicetest),
  })
    .then(handleResponse)
    .catch(handleError);
}
