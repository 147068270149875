import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";

// Redux
import {/*useDispatch,*/ connect} from "react-redux";
// import {fetchConfigurationGroupsByCustomer} from "../../../actions/configurationGroupActions";

// Styling
import {Form, Button, Modal, Row, Col} from "react-bootstrap";

const ChangeCustomerModal = ({
                                 auth,
                                 show,
                                 setShow,
                                 customers,
                                 configurationGroups,
                                 selected,
                                 setCustomer,
                                 customer,
                                 setConfigurationGroup,
                                 configurationGroup,
                                 saveDevices,
                                 removeSingleSelect,
                             }) => {
    // const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(true);
    const [configurationGroupList, setConfigurationGroupList] = useState([]);
    
    useEffect(() => {
        if (auth.user.Role != "Admin") {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, []);

    const inputHandler = (e) => {
        if (e.target.name == "customer") {
            if (e.target.value == "null") {
                setConfigurationGroup("null");
                setConfigurationGroupList([]);
                if (auth.user.Role != "Admin") {
                    setDisabled(true);
                }
            } else {
                // dispatch(fetchConfigurationGroupsByCustomer(auth, e.target.value))
                setDisabled(false);
            }
            setCustomer(e.target.value);
        } else {
            setConfigurationGroup(e.target.value);
        }
    };

    useEffect(() => {
        if (configurationGroups && customer != "null") {
            setConfigurationGroupList(configurationGroups);
        }
    }, [configurationGroups]);

    const handleClose = () => {
        setShow(false);
        setCustomer("");
        removeSingleSelect();
        setConfigurationGroup("");
        setConfigurationGroupList([]);
    };

    return (
        <>
            <div className="set-customer-modal">
                <Modal
                    show={show}
                    onHide={handleClose}
                    centered
                    className="text-center"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Change Customer</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>You are about to change {selected.length} device(s).</p>
                        <Form className="text-left">
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Customer
                                </Form.Label>
                                <Col sm="9">
                                    <Form.Control
                                        as="select"
                                        name="customer"
                                        value={customer}
                                        onChange={inputHandler}
                                    >
                                        <option key={"null"} value={"null"}>
                                            --
                                        </option>
                                        {customers.map((customer) => {
                                            return (
                                                <option key={customer.Id} value={customer.Id}>
                                                    {customer.Name}
                                                </option>
                                            );
                                        })}
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Configuration
                                </Form.Label>
                                <Col sm="9">
                                    <Form.Control
                                        as="select"
                                        name="configurationGroup"
                                        value={configurationGroup}
                                        onChange={inputHandler}
                                    >
                                        <option key={"null"} value={"null"}>
                                            --
                                        </option>
                                        {configurationGroupList.map((configurationGroup) => {
                                            return (
                                                <option
                                                    key={configurationGroup.Id}
                                                    value={configurationGroup.Id}
                                                >
                                                    {configurationGroup.Name}
                                                </option>
                                            );
                                        })}
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button disabled={disabled} onClick={saveDevices}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
};

ChangeCustomerModal.propTypes = {
    auth: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    customers: PropTypes.array.isRequired,
    selected: PropTypes.array.isRequired,
    setCustomer: PropTypes.func.isRequired,
    customer: PropTypes.string.isRequired,
    saveDevices: PropTypes.func.isRequired,
    configurationGroups: PropTypes.array.isRequired,
    setConfigurationGroup: PropTypes.func.isRequired,
    configurationGroup: PropTypes.string.isRequired,
    removeSingleSelect: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        customers: state.customers,
        configurationGroups: state.deviceDetails.configurationGroups,
    };
}

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangeCustomerModal);
