import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Redux
import { useDispatch, connect } from "react-redux";
import {fetchCustomers} from "../../actions/customerActions";
import {fetchRTDIntegrators} from "../../actions/rtdIntegrationActions";

// Components
import CustomerList from "./components/CustomerList";
import CreateCustomer from "./components/CreateCustomer";
import SearchBar from "../common/SearchBar";
import Spinner from "../common/Spinner";

// Styling
import { Card } from "react-bootstrap";
import "./CustomerPage.css";
import {fetchFeatures} from "../../actions/featureActions";

const CustomersPage = ({ auth, customers, rtdIntegrations, apiCallsInProgress }) => {
  const dispatch = useDispatch();
  const customerList = [...customers];
  const [searchResult, setSearchResult] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [show, setShow] = useState(false);
  const filters = ["Name", "Country", "Reseller"];

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    dispatch(fetchFeatures(auth));
    dispatch(fetchCustomers(auth));
    dispatch(fetchRTDIntegrators(auth));
  };

  return (
    <div className="customers">
      <h2 className="title">Customers</h2>
      <SearchBar
        filters={filters}
        setSearchResult={setSearchResult}
        searchResult={searchResult}
        all={customerList}
        refresh={refresh}
        setSearchActive={setSearchActive}
      />
      <div className="create-object-div">
        <CreateCustomer show={show} setShow={setShow} rtdIntegrations={rtdIntegrations} />
      </div>
      {apiCallsInProgress > 0 && customers.length == 0 ? (
        <Spinner />
      ) : (
        <Card>
          {searchResult.length === 0 && searchActive == false ? (
            <CustomerList customers={customerList} rtdIntegrations={rtdIntegrations} />
          ) : (
            <CustomerList customers={searchResult} rtdIntegrations={rtdIntegrations} />
          )}
        </Card>
      )}
    </div>
  );
};

CustomersPage.propTypes = {
  auth: PropTypes.object.isRequired,
  customers: PropTypes.array.isRequired,
  rtdIntegrations: PropTypes.array.isRequired,
  apiCallsInProgress: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    customers: state.customers,
    rtdIntegrations: state.rtdIntegrations,
    apiCallsInProgress: state.apiCallsInProgress,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomersPage);
