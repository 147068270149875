import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/api/devices/configurationgroups/";

export const saveConfigurationToDevice = (auth, configId, devices) => {
  return fetch(baseUrl + configId, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: auth.accessToken,
    },
    body: JSON.stringify(devices),
  })
    .then(handleResponse)
    .catch(handleError);
};

export const removeConfigurationFromDevice = (auth, devices) => {
  return fetch(baseUrl, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      Authorization: auth.accessToken,
    },
    body: JSON.stringify(devices),
  })
    .then(handleResponse)
    .catch(handleError);
};
