import {apiCallError, beginApiCall} from "./apiStatusActions";
import * as deviceTestAPI from "../api/deviceTestSettingsApi";
import * as types from "./actionsTypes";
import {toast} from "react-toastify";

export const GetDeviceTestSettingsByImei = (auth, imei) => async (dispatch) => {
    dispatch(beginApiCall());

    deviceTestAPI
        .getDeviceTestSettings(auth, imei)
        .then((test) => {
            dispatch({
                type: types.GET_DEVICESETTINGS_SUCCESS,
                payload: {
                    deviceTestSettings: test,
                }
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        })
}

export const GetAllDeviceTestSettings = (auth) => async (dispatch) => {
    dispatch(beginApiCall())

    deviceTestAPI
        .GetAllDeviceTestSettings(auth)
        .then((tests) => {
            dispatch({
                type: types.GET_ALL_DEVICETESTSETTINGS_SUCCESS,
                payload: {
                    deviceTestSettings: tests,
                }
            })
        })
}

export const SetCurrentDeviceTestActive = (auth, imei) => async (dispatch) => {
    dispatch(beginApiCall())

    deviceTestAPI
        .setDeviceTestActive(auth, imei)
        .then(() => {
            dispatch({
                type: types.SET_DEVICETEST_ACTIVE,
                imei: imei
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        })
}

export const SetCurrentDeviceTestInActive = (auth, imei) => async (dispatch) => {
    dispatch(beginApiCall())

    deviceTestAPI
        .setDeviceTestInActive(auth, imei)
        .then(() => {
            dispatch({
                type: types.SET_DEVICETEST_INACTIVE,
                imei: imei
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        })
}

export const saveDeviceTestSettingsAction = (auth, deviceSettings) => async (dispatch) => {
    dispatch(beginApiCall());

    deviceTestAPI
        .saveDeviceTest(auth, deviceSettings)
        .then((test) => {
            dispatch({
                type: types.SAVE_DEVICETESTSETTINGS_SUCCESS,
                payload: {
                    deviceTestSettings: test,
                },
            })
            toast.success("Device test settings successfully saved!");
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        })
}

export const updateDeviceTestSettingsAction = (auth, id, deviceSettings) => async (dispatch) => {
    dispatch(beginApiCall());

    deviceTestAPI
        .updateDeviceTestSettings(auth, id, deviceSettings)
        .then((test) => {
            dispatch({
                type: types.UPDATE_DEVICETESTSETTINGS_SUCCESS,
                payload: {
                    deviceTestSettings: test,
                },
            })
            toast.success("Device test settings successfully updated!");
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        })
}