import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { connect, useDispatch } from "react-redux"
import SettingsProfileSettingsModal from "./SettingsProfileSettingsModal";
import { toast } from "react-toastify";
import { updateSettingsProfile } from "../../../actions/settingsProfileActions";
import SettingsProfileMessagesModal from "./SettingsProfileMessagesModal";

const UpdateSettingsProfile = ({
    auth,
    show,
    setShow,
    selectedSettingsProfile,
    setSelectedSettingsProfile,
    firmwares
}) => {
    const dispatch = useDispatch()
    const [page, setPage] = useState(0)

    const handleClose = () => {
        setShow(false)
        setPage(0)
    }

    const inputHandler = (e) => {
        const name = e.target.name
        const value = e.target.value
        const type = e.target.type
        console.log(name)
        console.log(value)
        if (type == "checkbox") {
            const value = e.target.checked;
            setSelectedSettingsProfile({
                ...selectedSettingsProfile,
                [name]: value ? "True" : "False",
            })
        } else {
            setSelectedSettingsProfile({
                ...selectedSettingsProfile,
                [name]: value,
            })
        }
    }

    const previousPage = () => {
        if (page > 0)
            setPage(page - 1)
    }
    const nextPage = () => {
        if (page < 2)
            setPage(page + 1)
    }

    const saveSettingsProfileHandler = () => {
        if (selectedSettingsProfile.Name == "" || selectedSettingsProfile.Name.length < 3) {
            toast.error("Name needs to be at least 3 characters")
        }
        else {
            dispatch(updateSettingsProfile(auth, selectedSettingsProfile))
            handleClose()
        }
    }

    return (
        <div>
            <Modal
                size={page == 1 ? "xl" : "lg"}
                show={show}
                onHide={handleClose}
                centered
                className="text-center"
                scrollable={true}
            >
                <Modal.Header closeButton>
                    {selectedSettingsProfile.Name}
                </Modal.Header>
                {page == 0 &&
                    <SettingsProfileSettingsModal
                        settingsProfile={selectedSettingsProfile}
                        inputHandler={inputHandler}
                        nextPage={nextPage}
                        handleClose={handleClose}
                        firmwares={firmwares}
                    />
                }
                {page == 1 &&
                    <SettingsProfileMessagesModal
                        settingsProfile={selectedSettingsProfile}
                        setSettingsProfile={setSelectedSettingsProfile}
                        nextPage={nextPage}
                        previousPage={previousPage}
                    />
                }
                {page == 2 &&
                    <>
                        <Modal.Body>
                            <Form.Label column sm="3">
                                <h6>Name:</h6>
                            </Form.Label>
                            <Col sm="8">
                                <Form.Control
                                    name="Name"
                                    type="string"
                                    defaultValue={selectedSettingsProfile.Name}
                                    onChange={(e) => inputHandler(e)}
                                />
                            </Col>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={previousPage}>Back</Button>
                            <Button onClick={saveSettingsProfileHandler}>Save</Button>
                        </Modal.Footer>
                    </>
                }
            </Modal>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
    }
}
UpdateSettingsProfile.propTypes = {
    auth: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    selectedSettingsProfile: PropTypes.object.isRequired,
    setSelectedSettingsProfile: PropTypes.func.isRequired,
    firmwares: PropTypes.array.isRequired,
}
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSettingsProfile)