import { connect } from "react-redux"
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Modal, Form, Row, Button, Col } from "react-bootstrap";

const MassUpdateFirmware = ({
    show,
    setShow,
    customers,
    firmwares,
    onSubmitMassBooterUpdate
}) => {
    const [customer, setCustomer] = useState()
    const [firmware, setFirmware] = useState()

    const handleClose = () => {
        setShow(false)
    }
    const handleShow = () => {
        setShow(true)
    }

    const inputHandler = (e) => {
        switch (e.target.name) {
            case "booter":
                setFirmware(e.target.value)
                break;
            case "customer":
                setCustomer(e.target.value)
                break;
        }
    }

    const onSubmit = () => {
        if (customer != undefined && firmware != undefined)
            onSubmitMassBooterUpdate(customer, firmware)
        handleClose()
    }

    const booters = [...firmwares].filter((e) => e.FirmwareFlag == 1)

    return (
        <div>
            <Button className="create-object-button" onClick={handleShow}>
                Mass Update Booter
            </Button>
            <Modal show={show} onHide={handleClose} centered className="text-center">
                <Modal.Header closeButton>
                    <Modal.Title>Mass update booter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group as={Row}>
                        <Col>
                            <Form.Label>
                                Customer
                            </Form.Label>

                        </Col>
                        <Col>

                            <Form.Label>
                                Booter
                            </Form.Label>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col>
                            <Form.Control
                                as="select"
                                name="customer"
                                value={customer}
                                onChange={inputHandler}
                            >
                                <option key="null" value="0">
                                    --
                                </option>
                                {customers && customers.map((customer) => {
                                    return (
                                        <option key={customer.Id} value={customer.Id}>
                                            {customer.Name}
                                        </option>
                                    )
                                })}

                            </Form.Control>
                        </Col>
                        <Col>
                            <Form.Control
                                as="select"
                                name="booter"
                                value={firmware}
                                onChange={inputHandler}
                            >
                                <option key="null" value="0">
                                    --
                                </option>
                                {booters && booters.map((firmware) => {
                                    return (
                                        <option key={firmware.Id} value={firmware.Id}>
                                            {firmware.Version}
                                        </option>
                                    )
                                })}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={onSubmit}>Submit</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

MassUpdateFirmware.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    firmwares: PropTypes.array.isRequired,
    customers: PropTypes.array.isRequired,
    onSubmitMassBooterUpdate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        customers: state.customers,
        firmwares: state.firmwares,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MassUpdateFirmware)