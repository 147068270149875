import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Components
import Spinner from "./Spinner";

const LazyLoader = ({ refresh, collection, apiCallsInProgress, endOfList }) => {
  var refreshHeight = document.body.offsetHeight - 1500;
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    refreshHeight = document.body.offsetHeight - 1500;
  }, [refreshHeight]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (
      scrollPosition >= refreshHeight &&
      scrollPosition !== 0 &&
      apiCallsInProgress === 0 &&
      !endOfList
    ) {
      nextResults();
    }
  }, [scrollPosition]);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const nextResults = () => {
    refresh(collection.at(-1)?.Id);
  };

  return (
    <div>
      {apiCallsInProgress > 0 ? <Spinner /> : <div>End of Data</div>}
      <div />
    </div>
  );
};

LazyLoader.propTypes = {
  refresh: PropTypes.func.isRequired,
  collection: PropTypes.array.isRequired,
  apiCallsInProgress: PropTypes.number.isRequired,
  endOfList: PropTypes.bool.isRequired,
};

export default LazyLoader;
