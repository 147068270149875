import React from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

const CustomerDevicesTab = ({ devices, features, customerDetails }) => {

  function dateValidator(lastDownloaded, interval, customerfeatures) {
    if (customerfeatures.Features.filter((e) => e === "Tacho Download").length == 1) {
      if (lastDownloaded >= "01-01-1900") {

        if (moment().add(interval, 'days') <= moment(lastDownloaded)) {
          return <div className="font-weight-bolder text-danger">{moment(lastDownloaded).format("DD-MM-YYYY HH:mm")}</div>
        } else {
          return <div className="font-weight-bolder text-success">{moment(lastDownloaded).format("DD-MM-YYYY HH:mm")}</div>
        }

      } else {
        return <div className="font-weight-bolder text-danger">NoDownload</div>
      }
    } else {
      return <div className="font-weight-bolder text-danger">Tacho Feature Disabled</div>
    }

  }

  return (
    <div>
      <p style={{ float: "right" }}>
        <strong>Results: </strong> {devices.length}
      </p>
      <br />
      <br />
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>IMEI</th>
            <th>SN</th>
            <th>Hardware</th>
            <th>ConfigurationGroup</th>
            <th>ICCID</th>
            <th>InSync</th>
            {features.filter((e) => e === "Tacho Download").length == 1 ? (
              <th>Last Download</th>
            ) : (<></>)}

          </tr>
        </thead>
        <tbody>
          {devices.length === 0 ? (
            <tr>
              <td colSpan="14" style={{ textAlign: "center" }}>
                No devices found
              </td>
            </tr>
          ) : (
            devices.map((device) => {
              return (
                <tr key={device.Id} className="table-row">
                  <td>
                    <Link to={"/device/" + device.Id}>{device.IMEI}</Link>
                  </td>
                  <td>{device.SN}</td>
                  <td>{device.Hardware}</td>
                  <td>{device.ConfigurationGroup}</td>
                  <td>{device.ICCID}</td>
                  <td>{device.InSync ? "Yes" : "No"}</td>
                  {features.filter((e) => e === "Tacho Download").length == 1 && (
                    <td>
                      {dateValidator(device.LastDownload, device.RTDInterval, customerDetails)}
                    </td>
                  )}


                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

CustomerDevicesTab.propTypes = {
  auth: PropTypes.object.isRequired,
  devices: PropTypes.array.isRequired,
  features: PropTypes.array.isRequired,
  customerDetails: PropTypes.object.isRequired,

};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    devices: state.customerDetails.devices,
    customerDetails: state.customerDetails.customer,
    features: state.auth.user.Features,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDevicesTab);
