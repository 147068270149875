import initialState from "./initialState";
import * as types from "../actions/actionsTypes";

export default function firmwareFlagsReducer(
    state = initialState.firmwareFlags,
    action
) {
    switch (action.type) {
        case types.LOAD_FIRMWAREFLAGS_SUCCESS:
            return action.payload.firmwareFlags;
            case types.LOGOUT:
            return initialState.firmwareFlags;
        default:
            return state;
    }
}