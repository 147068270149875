import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";

const CreateIntegration = ({ auth, show, setShow, customers, }) => {
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [validForm, setValidForm] = useState(false);
  const [showIntegrationFields, setShowIntegrationFields] = useState(false);
  const [integration, setIntegration] = useState({
    customerId: "",
    name: "",
    ip: "",
    port: 1,
    status: 0,
    username: "",
    password: "",
    API_KEY: "",
    Auth: "",
    MessageTypes: [],
    Protocols: [],
  });
  //TODO: Errorhandler toevoegen in useEffect met validation
  //TODO: Correct conversion from Azure_iot string to protocolId

  useEffect(() => { }, [integration]);

//   const initialState = {
//     user: {
//       role: '',
//     },
//   }
//   customers: [],
// };


const saveIntegrationHandler = () => {
  setShow(false);
  // var integration = {
  //     customerId: "",
  //     name: "",
  //     ip: "",
  //     port: 1,
  //     status: 0,
  //     username: "",
  //     password: "",
  //     API_KEY: "",
  //     Auth: "",
  //     MessageTypes: [],
  //     Protocols: [],
  // }
  // saveIntegration(integration)
  setValidForm();
  setIntegration({
    customerId: "",
    name: "",
    ip: "",
    port: 1,
    status: 0,
    username: "",
    password: "",
    API_KEY: "",
    Auth: "",
    MessageTypes: [],
    Protocols: [],
  });
};

const inputHandler = (e) => {
  let value = "";

  if (e.target.name === "port") {
    value = e.target.value.replace(/[^0-9]/gi, "");
  } else {
    value = e.target.value;
  }

  setIntegration({
    ...integration,
    [e.target.name]: value,
  });
};

const inputIntegrationHandler = (e) => {
  //let value = "";

  switch (e.target.value) {
    case "Azure_IoT":
      setShowIntegrationFields(true);
      break;
    default:
      break;
  }

  setIntegration({
    ...integration,
    [e.target.name]: e.target.value,
  });
};

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    {"Make sure the name consists of more than 3 characters."}
  </Tooltip>
);


const AzureIntegration = () => {
  return (
    <>
      <Form.Label>HostName</Form.Label>
      <Form.Control
        type="text"
        name="IP"
        onChange={inputHandler}
        placeholder="HostName"
        required
      />
      <small className="text-black-50">
        Hostname should contain the .azure-devices.net
      </small>
      <br />
      <Form.Label>Shared Access Key Name</Form.Label>
      <Form.Control
        type="text"
        name="Auth"
        onChange={inputHandler}
        placeholder="Shared Access Key Name"
        required
      />
      <Form.Label>Shared Access Key</Form.Label>
      <Form.Control
        type="text"
        name="API_KEY"
        onChange={inputHandler}
        placeholder="Shared Access Key"
        required
      />
    </>
  );
};

const isViewer = auth.user.Role ==="Viewer";

return (
  <div>
    {!isViewer && (
    <Button className="create-object-button mr-1" onClick={handleShow}>
      New Integration
    </Button>
    )}
    <Modal show={show} onHide={handleClose} centered className="text-center">
      <Modal.Header closeButton>
        <Modal.Title>Add Integration</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="text-left">
          <Form.Label>Customer</Form.Label>
          <Form.Control
            as="select"
            name="customerId"
            value={integration.customerId}
            onChange={inputHandler}
            required
          >
            <option key="null" value="null">
              --
            </option>
            {customers.map((customer) => {
              return (
                <option key={customer.Id} value={customer.Id}>
                  {customer.Name}
                </option>
              );
            })}
          </Form.Control>
          <Form.Label>Integration Type</Form.Label>
          <Form.Control
            as="select"
            name="Protocols"
            onChange={inputIntegrationHandler}
            required
          >
            <option key="null" value="null">
              --
            </option>
            <option key="AzureIotHub" value="2">
              Azure IoT Hub integration
            </option>
          </Form.Control>
          {showIntegrationFields ? <AzureIntegration /> : null}
          <Form.Label>Message Type</Form.Label>
          <Form.Control
            as="select"
            name="MessageTypes"
            onChange={inputHandler}
            required
          ></Form.Control>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {!validForm ? (
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <span className="d-inline-block">
              <Button
                variant="primary"
                style={!validForm ? { pointerEvents: "none" } : null}
                disabled={!validForm}
              >
                Save Integration
              </Button>
            </span>
          </OverlayTrigger>
        ) : (
          <Button
            variant="primary"
            disabled={!validForm}
            onClick={saveIntegrationHandler}
          >
            Save Endpoint
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  </div>
);
};

CreateIntegration.propTypes = {
  auth: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  customers: PropTypes.array.isRequired,
  // rol: PropTypes.show.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    customers: state.customers,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CreateIntegration);
