import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Redux
import { useDispatch } from "react-redux";
import {
    removeAccountUser,
    updateAccountUser,
} from "../../../actions/accountActions";
import { updateUser, removeUser } from "../../../actions/userActions";

// Components
import Confirmation from "../../common/Confirmation";
import UpdateUserModal from "./UpdateUserModal";
import { Button, Table } from "react-bootstrap";
import EditIcon from "../../common/icons/pen-to-square-solid.svg";
import DeleteIcon from "../../common/icons/trash-can-solid.svg";

const UserTable = ({ account, auth, users, roles }) => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [updatedUser, setUpdatedUser] = useState({
        email: "",
        roleId: "",
        password: "",
        passwordConf: "",
    });

    const selectUser = (e) => {
        setSelectedUser(
            users.find((user) => {
                return user.Id == e;
            })
        );
    };

    useEffect(() => {
        if (selectedUser != null) {
            setUpdatedUser({
                email: selectedUser.Email,
                roleId: selectedUser.Role,
                password: "",
                passwordConf: "",
            });
        }
    }, [selectedUser]);

    const updateUserHandler = () => {
        if (updatedUser.password == "") {
            delete updatedUser.password;
            delete updatedUser.passwordConf;
        }

        let role = roles.find((element) => element.Name == updatedUser.roleId);
        updatedUser.roleId = role.Id;

        if (account) {
            dispatch(updateAccountUser(auth, selectedUser.Id, updatedUser));
        } else {
            dispatch(updateUser(auth, selectedUser.Id, updatedUser));
        }

        setUpdatedUser({
            email: "",
            roleId: "",
            password: "",
            passwordConf: "",
        });
        setShowModal(false);
    };

    const removeUserHandler = (user) => {
        if (!user.Id) {
            alert("Oops, something went wrong.");
            return;
        }
        if (auth.user.Id == user.Id) {
            alert("You can't delete yourself!");
            return;
        }
        if (account) {
            dispatch(removeAccountUser(auth, user.Id));
        } else {
            dispatch(removeUser(auth, user.Id));
        }
    };

    const showModalHandler = (e) => {
        setShowModal(true);
        selectUser(e);
    }

    const isViewer = auth.user.Role === "Viewer";

    return (
        <>
            <Table striped>
                <thead className="thead-inverse">
                    <tr>
                        <th>Id</th>
                        <th>Email</th>
                        <th>Role</th>
                    </tr>
                </thead>
                <tbody>
                    {users.length === 0 ? (
                        <tr>
                            <td colSpan="4" style={{ textAlign: "center" }}>
                                No users found
                            </td>
                        </tr>
                    ) : (
                        users.map((user) => {
                            if (user.Id === auth.user.Id) {
                                return (
                                    <tr key={user.Id}>
                                        <td>{user.Id}</td>
                                        <td>{user.Email}</td>
                                        <td>{user.Role}</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                );
                            }
                            return (
                                <tr
                                    className="table-row"
                                    key={user.Id}
                                >
                                    <td>{user.Id}</td>
                                    <td>{user.Email}</td>
                                    <td>{user.Role}</td>
                                    <td>
                                        {!isViewer && (
                                            <Button className="shadow-sm border-secondary" variant="light" onClick={() => {
                                                showModalHandler(user.Id)
                                            }}>
                                                <img
                                                    src={EditIcon}
                                                    alt="Edit"
                                                />
                                            </Button>
                                        )}
                                    </td>
                                    <td>
                                        {!isViewer && (
                                            <Button className="shadow-sm border-secondary" variant="light">
                                                <Confirmation
                                                    buttonText={
                                                        <img
                                                            src={DeleteIcon}
                                                            alt="Delete"
                                                        />
                                                    }
                                                    description={`Are you sure you want to remove this user?`}
                                                    onConfirm={() => removeUserHandler(user)}
                                                />
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </Table>
            {selectedUser && (
                <UpdateUserModal
                    show={showModal}
                    setShow={setShowModal}
                    current={selectedUser}
                    updatedUser={updatedUser}
                    setUpdatedUser={setUpdatedUser}
                    updateUserHandler={updateUserHandler}
                />
            )}
        </>
    );
}
    ;

UserTable.propTypes = {
    auth: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    roles: PropTypes.array.isRequired,
    account: PropTypes.bool.isRequired,
};

export default UserTable;
