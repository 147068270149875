import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Time
import moment from "moment";

// Redux
import { useDispatch, connect } from "react-redux";
import { getCustomerHistoryByDevice } from "../../../actions/deviceCustomerConnActions";

const DeviceCustomerHistoryPane = ({
  auth,
  device,
  customerHistory,
  isLoading,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCustomerHistoryByDevice(auth, device.Id));
  }, []);

  return (
    <>
      {!isLoading ? (
        <table className="table table-striped table-hover">
          <thead className="thead-inverse">
            <tr>
              <th>Customer Name</th>
              <th>Start</th>
              <th>End</th>
            </tr>
          </thead>
          <tbody>
            {customerHistory
              .sort(function (a, b) {
                return new Date(b.Start) - new Date(a.Start);
              })
              .map((customer) => {
                return (
                  <tr className="table-row" key={customer.Start}>
                    <td>{customer.Customer}</td>
                    <td>
                      {moment(customer.Start).format("DD-MM-YYYY HH:mm:ss")}
                    </td>
                    <td>
                      {customer.End === null
                        ? ""
                        : moment(customer.End).format("DD-MM-YYYY HH:mm:ss")}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      ) : (
        ""
      )}
    </>
  );
};

DeviceCustomerHistoryPane.propTypes = {
  auth: PropTypes.object.isRequired,
  device: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  customerHistory: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    device: state.deviceDetails.device,
    customerHistory: state.deviceDetails.customerHistory,
    isLoading: state.deviceDetails.isLoading,
  };
}
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceCustomerHistoryPane);
