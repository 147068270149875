import {
    Button,
    Row,
    Form,
    Modal,
} from "react-bootstrap";
import React from "react";
import {ErrorMessage, Field, Formik, useFormik} from "formik";
import {validationSchema} from "../Validation/ValidationSchema";

//Redux
import {connect} from "react-redux";

import PropTypes from "prop-types";
// import { compileString } from "sass";

const CreateAuthServer = ({
                              show,
                              setShow,
                              onSubmit,
                              customers,
                          }) => {
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const initialValues = {
        customerId: "",
        name: "",
        ip: "",
        devicePort: 1,
        port: 1,
        username: "",
        password: "",
        status: 0
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    return (
        <div className="create-authServer">
            <Button className="create-object-button" onClick={handleShow}>
                New Authentication Server
            </Button>
            <Modal show={show} onHide={handleClose} centered className="text-center">
                <Modal.Header closeButton>
                    <Modal.Title>Add Authentication Server</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({errors, touched, handleSubmit}) => (
                        <Form onSubmit={handleSubmit} className="text-left">
                            <Modal.Body>
                                <Row>
                                    <Form.Label>Customer</Form.Label>
                                    <Field
                                        as="select"
                                        name="customerId"
                                        className={errors.customerId && touched.customerId ? "form-control is-invalid" : "form-control"}
                                    >
                                        <option>
                                            --
                                        </option>
                                        {
                                            customers.map((customers) => {
                                                return (
                                                    <option key={customers.Id} value={customers.Id}>
                                                        {customers.Name}
                                                    </option>
                                                );
                                            })
                                        };
                                    </Field>
                                    <ErrorMessage name="customerId" component="div" className="invalid-feedback"/>
                                </Row>
                                <Row>
                                    <Form.Label>Name</Form.Label>
                                    <Field
                                        type="text"
                                        name="name"
                                        placeholder="Server Name"
                                        className={errors.name && touched.name ? "form-control is-invalid" : "form-control"}
                                    />
                                    <ErrorMessage name="name" component="div" className="invalid-feedback"/>
                                </Row>
                                <Row>
                                    <Form.Label>Server IP Address</Form.Label>
                                    <Field
                                        type="text"
                                        name="ip"
                                        placeholder="Ip Address"
                                        className={errors.ip && touched.ip ? "form-control is-invalid" : "form-control"}
                                    />
                                    <ErrorMessage name="ip" component="div" className="invalid-feedback"/>

                                    <Form.Label>Device Port</Form.Label>
                                    <Field
                                        type="number"
                                        name="devicePort"
                                        minLength={1}
                                        maxLength={5}
                                        placeholder="Server Device Port"
                                        className={errors.devicePort && touched.devicePort ? "form-control is-invalid" : "form-control"}
                                    />
                                    <ErrorMessage name="devicePort" component="div" className="invalid-feedback"/>

                                    <Form.Label>Port</Form.Label>
                                    <Field
                                        type="number"
                                        name="port"
                                        minLength={1}
                                        maxLength={5}
                                        placeholder="Server Port"
                                        className={errors.port && touched.port ? "form-control is-invalid" : "form-control"}
                                    />
                                    <ErrorMessage name="port" component="div" className="invalid-feedback"/>

                                    <Form.Label>Username</Form.Label>
                                    <Field
                                        type="text"
                                        name="username"
                                        placeholder="Server Username"
                                        className={errors.username && touched.username ? "form-control is-invalid" : "form-control"}
                                    />
                                    <ErrorMessage name="username" component="div" className="invalid-feedback"/>

                                    <Form.Label>Password</Form.Label>
                                    <Field
                                        type="password"
                                        name="password"
                                        placeholder="Server Password"
                                        className={errors.password && touched.password ? "form-control is-invalid" : "form-control"}
                                    />
                                    <ErrorMessage name="password" component="div" className="invalid-feedback"/>

                                    <Form.Label>Status</Form.Label>
                                    <Field
                                        as="select"
                                        name="status"
                                        className={errors.status && touched.status ? "form-control is-invalid" : "form-control"}
                                    >
                                        <option key="0" value="0">
                                            Server Down
                                        </option>
                                        <option key="1" value="1">
                                            Server Up and Running
                                        </option>
                                    </Field>
                                    <ErrorMessage name="status" component="div" className="invalid-feedback"/>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" type="submit" disabled={!formik.isValid}>
                                    Save Authentication Server
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    );
};

CreateAuthServer.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    customers: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
    return {
        customers: state.customers,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAuthServer);
