import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const customerReducer = (state = initialState.customers, action) => {
  switch (action.type) {
    case types.LOAD_CUSTOMERS_SUCCESS:
      return action.payload.customers;
    case types.SAVE_CUSTOMER_SUCCESS:
      return [...state, action.payload.customer];
    case types.UPDATE_CUSTOMER_SUCCESS:
      return [
        ...state.filter(
          (customer) => customer.Id != action.payload.customer.Id
        ),
        action.payload.customer,
      ];
    case types.REMOVE_CUSTOMER_SUCCESS:
      return state.filter((customer) => customer.Id != action.payload.customer);
    default:
      return state;
  }
};

export default customerReducer;
