import React, {useEffect} from "react";
import PropTypes from "prop-types";

// Redux
import {useDispatch, connect} from "react-redux";
import {getRawmessagesChunkByDevice, getRawmessagesChunkByDeviceById} from "../../../actions/deviceActions";

// Components
import Spinner from "../../common/Spinner";
import LazyLoader from "../../common/LazyLoader";
import DeviceRawMessagesList from "./DeviceRawMessagesList";

const DeviceRawMessagesPane = ({auth, device, rawMessages, isLoading, apiCallsInProgress, endOfList}) => {
    const dispatch = useDispatch();
    const rawMessagesList = [...rawMessages];

    useEffect(() => {
        refresh();
    }, []);

    const refresh = (id = null) => {
        if (apiCallsInProgress === 0) {
            if (id === null) {
                dispatch(getRawmessagesChunkByDevice(auth, device.IMEI));
            } else {
                dispatch(getRawmessagesChunkByDeviceById(auth, device.IMEI, id))
            }
        }
    };

    return (
        <>
            {!isLoading ? (
                <>
                    <DeviceRawMessagesList
                        rawMessages={rawMessagesList}
                        refresh={refresh}
                    />
                    <LazyLoader
                        refresh={refresh}
                        collection={rawMessagesList}
                        apiCallsInProgress={apiCallsInProgress}
                        endOfList={endOfList}
                    />
                </>
            ) : (
                <Spinner/>
            )}
        </>
    );
};

DeviceRawMessagesPane.propTypes = {
    auth: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired,
    rawMessages: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    apiCallsInProgress: PropTypes.number.isRequired,
    endOfList: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        device: state.deviceDetails.device,
        rawMessages: state.deviceDetails.rawMessages,
        isLoading: state.deviceDetails.isLoading,
        apiCallsInProgress: state.apiCallsInProgress,
        endOfList: state.endOfList,
    };
}

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceRawMessagesPane);
