import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const configurationGroupReducer = (
  state = initialState.configurationGroups,
  action
) => {
  switch (action.type) {
    case types.LOAD_CONFIGURATION_GROUPS_SUCCESS:
      return action.payload.configurationGroups;
    case types.SAVE_CONFIGURATION_GROUP_SUCCESS:
      return [...state, action.payload.configurationGroup];
    case types.UPDATE_CONFIGURATION_GROUP_SUCCESS:
      return [
        ...state.filter(
          (config) => config.Id != action.payload.configurationGroup.Id
        ),
        action.payload.configurationGroup,
      ];
    case types.LOAD_SEARCH_RESULT_CONFIGURATION_GROUP_SUCCESS:
      return action.payload.configurationGroups;
    case types.LOAD_CONFIGURATION_GROUPS_CUSTOMER_SUCCESS:
      return action.payload.configurationGroups;
    case types.REMOVE_CONFIGURATION_GROUP_SUCCESS:
      return state.filter(
        (config) => config.Id != action.payload.configurationGroup
      );
    default:
      return state;
  }
};

export default configurationGroupReducer;
