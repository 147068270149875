import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

// Redux
import { connect, useDispatch } from "react-redux";
import { getDeviceById } from "../../actions/deviceActions";
import { loadActiveCanConfigs } from "../../actions/canConfigActions";
import { loadActiveFirmwares } from "../../actions/firmwareActions";

// Components
import Spinner from "../common/Spinner";
import DeviceNavigation from "./DeviceNavigation";

import "./DeviceDetails.css";

const ManageDeviceDetailsPage = ({ auth, device, devices, canConfigs, firmwares, ...props }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  // On mount; use param to fetch device
  useEffect(() => {
    dispatch(getDeviceById(auth, id));
    dispatch(loadActiveCanConfigs(auth));
    dispatch(loadActiveFirmwares(auth));
  }, [dispatch, auth, id]);

  // Ensure the arrays are not undefined and filter out undefined items
  const validDevices = devices.filter(device => device && device.name);
  const validCanConfigs = canConfigs.filter(config => config && config.name);
  const validFirmwares = firmwares.filter(firmware => firmware && firmware.version);

  const sortedDevices = [...validDevices].sort((a, b) => a.name.localeCompare(b.name));
  const sortedCanConfigs = [...validCanConfigs].sort((a, b) => a.name.localeCompare(b.name));
  const sortedFirmwares = [...validFirmwares].sort((a, b) => a.version.localeCompare(b.version));

  return (
    <>
      {device == undefined ? (
        <Spinner />
      ) : (
        <>
          <DeviceNavigation 
            auth={auth}
            device={device}
            sortedDevices={sortedDevices}
            sortedCanConfigs={sortedCanConfigs}
            sortedFirmwares={sortedFirmwares}
            {...props} 
          />
        </>
      )}
    </>
  );
};

ManageDeviceDetailsPage.propTypes = {
  auth: PropTypes.object.isRequired,
  device: PropTypes.object,
  devices: PropTypes.array.isRequired,
  canConfigs: PropTypes.array.isRequired,
  firmwares: PropTypes.array.isRequired,
  match: PropTypes.shape({
    params: PropTypes.array.isRequired, 
    id: PropTypes.string.isRequired,
  }),
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    devices: state.devices,
    device: state.deviceDetails.device,
    canConfigs: state.canConfigs,
    firmwares: state.firmwares,
  };
}

export default connect(
  mapStateToProps,
  null
)(ManageDeviceDetailsPage);
