import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const featureReducer = (state = initialState.features, action) => {
  switch (action.type) {
    case types.LOAD_FEATURES_SUCCESS:
      return action.payload.features;
    case types.SAVE_FEATURE_SUCCESS:
      return [...state, action.payload.feature];
    case types.UPDATE_FEATURE_SUCCESS:
      return [
        ...state.filter((feature) => feature.Id != action.payload.feature.Id),
        action.payload.feature,
      ];
    case types.REMOVE_FEATURE_SUCCESS:
      return state.filter((feature) => feature.Id != action.payload.feature);
    default:
      return state;
  }
};

export default featureReducer;
