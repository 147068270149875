import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";


// Redux
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateAccountUser } from "../../actions/accountActions";

// Styling
import { Row, Button, Col, Form } from "react-bootstrap";

const ProfileTab = ({ auth }) => {
  const dispatch = useDispatch();
  let history = useNavigate();
  const [validForm, setValidForm] = useState(false);
  const [user, setUser] = useState({
    email: auth.user.Email,
    password: "",
    passwordConf: "",
  });

  const inputHandler = (e) => {
    let value = "";
    if (e.target.name === "name") {
      value = e.target.value.replace(/[^\w\s]/gi, "");
    } else {
      value = e.target.value;
    }
    setUser({
      ...user,
      [e.target.name]: value,
    });
  };

  const updateProfile = () => {
    if (user.password == "" && user.email == auth.user.Email) return;
    if (user.password == "") {
      delete user.password;
      delete user.passwordConf;
    }
    dispatch(updateAccountUser(auth, auth.user.Id, user));
    alert("Please login with your new credentials.");
    history.push("/logout");
  };

  useEffect(() => {
    let validEmail;
    let validPassword;
    let passEqual;

    validEmail = user.email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    passEqual = user.password === user.passwordConf;
    validPassword = user.password.length > 6;

    console.log(validEmail);
    console.log(passEqual);
    console.log(validPassword);

    if (validPassword && passEqual && validEmail) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [user]);

  const isViewer = auth.user.Role === "Viewer";

  return (
    <div>
      <br />
      <Form className="account-form">
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              name="email"
              value={user.email}
              placeholder="Enter email"
              onChange={inputHandler}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={user.password}
              placeholder="Leave blank to keep the same"
              onChange={inputHandler}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Role</Form.Label>
            <Form.Control
              type="text"
              name="email"
              value={auth.user.Role}
              disabled
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Password Confirmation</Form.Label>
            <Form.Control
              type="password"
              name="passwordConf"
              value={user.passwordConf}
              placeholder="Leave blank to keep the same"
              onChange={inputHandler}
            />
            {user.password !== user.passwordConf && (
              <p className="text-invalid">
                <small> Passwords dont match. &nbsp;</small>
              </p>
            )}
            {user.password !== "" && user.password.length < 6 && (
              <p className="text-invalid">
                <small> Password needs to be 6 digits long.</small>
              </p>
            )}
          </Form.Group>
        </Row>

        <Row className="mb-6">
          <Form.Group as={Col}>
            <Form.Label>Reseller</Form.Label>
            <Form.Control
              type="text"
              name="email"
              value={auth.user.IsReseller ? "Yes" : "No"}
              disabled
            />
          </Form.Group>
          <Form.Group as={Col}></Form.Group>
        </Row>
        <hr></hr>
        <Form.Group>
          <Row>
            <Col sm="11"></Col>
            <Col sm="1">
            {!isViewer && (
              <Button
                variant="success"
                disabled={!validForm}
                onClick={(e) => updateProfile(e)}
              >
                Save
              </Button>
            )}
            </Col>
          </Row>
        </Form.Group>
      </Form>
    </div>
  );
};

ProfileTab.propTypes = {
  auth: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileTab);
