import React from "react";
import {connect, useDispatch} from "react-redux";

import {saveFirmware} from "../../../actions/firmwareActions";

import {Button, Form, Modal} from "react-bootstrap";
import PropTypes from "prop-types";
import {Field, Formik, useFormik} from "formik";
import * as Yup from 'yup';

const CreateFirmware = ({
                            auth,
                            show,
                            setShow,
                            firmwareFlags,
                            deviceTypes
                        }) => {

    const dispatch = useDispatch();

    const handleClose = () => {
        setShow(false);
    };

    const handleShow = () => {
        setShow(true);
    };

    const SUPPORTED_FORMATS = [
        "bin",
        "tlqs",
        "fota"
    ];

    const FirmwareSchema = Yup.object().shape({
        Version: Yup.string()
            .min(6, 'Version should be at least 6 character!')
            .max(8, 'Version should be at most 8 characters!')
            .required('Version is Required'),
        Release: Yup.string()
            .required('Release is Required'),
        DeviceType: Yup.string()
            .required('Device Type is Required'),
        FirmwareFlag: Yup.string()
            .required('Firmware Type is Required'),
        Status: Yup.string()
            .required('Active is Required'),
        Firmware: Yup.mixed()
            .required('Firmware file is Required')
            .test("fileSize",
                "File too large",
                value => {
                    return value && value.size <= 393216
                })
            .test('fileFormat',
                'Unsupported Format, Formats supported: .bin, .tlqs, .fota',
                value => {
                    let regex = /(?:\.([^.]+))?$/;
                    let ext = regex.exec(value?.name)[1];

                    return value && SUPPORTED_FORMATS.includes(ext)
                }),
    });

    const initialValues = {
        Version: "",
        Release: 'false',
        DeviceType: "0",
        FirmwareFlag: 0,
        Status: 'true',
        Firmware: ""
    };

    const onSubmit = (values) => {
        var firmware = new FormData();
        firmware.append("Version", values.Version);
        firmware.append("Release", values.Release);
        firmware.append("FirmwareFlag", values.FirmwareFlag);
        firmware.append("DeviceType", values.DeviceType);
        firmware.append("Active", values.Status);
        firmware.append("Firmware", values.Firmware, values.Firmware.name);
        firmware.append("Filename", values.Firmware.name);

        dispatch(saveFirmware(auth, firmware));
        handleClose();
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: FirmwareSchema,
        onSubmit: onSubmit,
    });

    return (
        <div>
            <Button className="create-object-button" onClick={handleShow}>
                New Firmware
            </Button>
            <Modal show={show} onHide={handleClose} centered className="text-center">
                <Modal.Header closeButton>
                    <Modal.Title>Create Firmware</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={initialValues}
                    validationSchema={FirmwareSchema}
                    onSubmit={onSubmit}
                >
                    {({
                          handleSubmit,
                          errors,
                          touched,
                          setFieldValue
                      }) => (
                        <Form onSubmit={handleSubmit}>
                            <Modal.Body className="text-left">
                                <label className="form-label">Version</label>
                                <Field
                                    name="Version"
                                    type="text"
                                    className={"form-control" + (errors.Version && touched.Version ? " is-invalid" : "")}
                                    placeholder="Version"/>
                                {errors.Version && touched.Version ? (
                                    <div className="text-danger">{errors.Version}</div>
                                ) : null}

                                <label className="form-label">Type (Release)</label>
                                <Field
                                    name="Release"
                                    as="select"
                                    className={"form-control" + (errors.Release && touched.Release ? " is-invalid" : "")}
                                    placeholder="Release"
                                >
                                    <option key={false} value={false}>
                                        Test version
                                    </option>
                                    <option key={true} value={true}>
                                        Release
                                    </option>
                                </Field>
                                {errors.Release && touched.Release ? (
                                    <div className="text-danger">{errors.Release}</div>
                                ) : null}

                                <label className="form-label">Device Type</label>
                                <Field
                                    name="DeviceType"
                                    as="select"
                                    className={"form-control" + (errors.DeviceType && touched.DeviceType ? " is-invalid" : "")}
                                    placeholder="Device Type"
                                >
                                    {deviceTypes.map((devicetype) => (
                                        <option key={devicetype.Id} value={devicetype.Id}>
                                            {devicetype.Name}
                                        </option>
                                    ))
                                    }
                                </Field>
                                {errors.DeviceType && touched.DeviceType ? (
                                    <div className="text-danger">{errors.DeviceType}</div>
                                ) : null}

                                <label className="form-label">Type Firmware</label>
                                <Field
                                    name="FirmwareFlag"
                                    as="select"
                                    className={"form-control" + (errors.FirmwareFlag && touched.FirmwareFlag ? " is-invalid" : "")}
                                    placeholder="Firmware type"
                                >
                                    {firmwareFlags.map((flag) => (
                                        <option key={flag.Id} value={flag.Id.toString()}>
                                            {flag.Flag}
                                        </option>
                                    ))}
                                </Field>
                                {errors.FirmwareFlag && touched.FirmwareFlag ? (
                                    <div className="text-danger">{errors.FirmwareFlag}</div>
                                ) : null}

                                <label className="form-label">Status</label>
                                <Field
                                    name="Status"
                                    as="select"
                                    className={"form-control" + (errors.Active && touched.Active ? " is-invalid" : "")}
                                    placeholder="Active"
                                >
                                    <option key={true} value="true">
                                        Active
                                    </option>
                                    <option key={false} value="false">
                                        InActive
                                    </option>
                                </Field>
                                {errors.Active && touched.Active ? (
                                    <div className="text-danger">{errors.Active}</div>
                                ) : null}

                                <label className="form-label">Firmware File</label>
                                <input
                                    id="Firmware"
                                    name="Firmware"
                                    type="file"
                                    className={"form-control" + (errors.Firmware && touched.Firmware ? " is-invalid" : "")}
                                    placeholder="Firmware"
                                    onChange={(event) => {
                                        setFieldValue("Firmware", event.currentTarget.files[0]);
                                    }}
                                />
                                {errors.Firmware && touched.Firmware ? (
                                    <div className="text-danger">{errors.Firmware}</div>
                                ) : null}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" type="submit" disabled={!formik.isValid}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    )
        ;
};

CreateFirmware.propTypes = {
    auth: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    canConfig: PropTypes.array,
    firmwareFlags: PropTypes.array.isRequired,
    deviceTypes: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        firmwareFlags: state.firmwareFlags,
        deviceTypes: state.deviceTypes,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CreateFirmware);
