import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./pages/common/PrivateRoute";
import AdminRoute from "./pages/common/AdminRoute";

import Header from "./pages/common/Header";
import PageNotFound from "./pages/PageNoteFound";
import LoginPage from "./pages/login/LoginPage";
import Logout from "./pages/login/LogoutPage";
import HomePage from "./pages/home/HomePage";
import ManageDeviceDetailsPage from "./pages/deviceDetails/ManageDeviceDetailsPage";
import EndpointPage from "./pages/endpoints/EndpointPage";
import LogPage from "./pages/Log/LogPage";
import RawMessagePage from "./pages/rawmessages/RawMessagePage";
import DevicesPage from "./pages/devices/DevicesPage";

import CustomersPage from "./pages/customers/CustomersPage";

import CustomerInfoPage from "./pages/customerDetails/CustomerInfoPage";
import AuthServerPage from "./pages/tacho/AuthServerPage";
import FeaturePage from "./pages/features/FeaturePage";
import AccountPage from "./pages/account/AccountPage";
import RolePage from "./pages/roles/RolePage";
// import ConfigurationGroupPage from "./pages/configurations/ConfigurationGroupPage";
import OverviewGraphPage from "./pages/overviewGraph/OverviewGraphPage";
import TachoObjectPage from "./pages/tachoObject/TachoObjectPage";
import TachoDriverPage from "./pages/tachoDriver/TachoDriverPage";
import TachoMassDownloadPage from "./pages/tachoMassDownload/TachoMassDownloadPage";
import TachoDriverDownloadPage from "./pages/tachoDriverDownload/TachoDriverDownloadPage";
import DeviceInstallPage from "./pages/deviceTest/NewDeviceTestPage/DeviceTestPage";
import DeviceTestOverviewPage from "./pages/deviceTest/deviceTestOverview/DeviceTestOverviewPage";
import CanConfigPage from "./pages/canconfigs/CanConfigPage";
import FirmwarePage from "./pages/firmware/FirmwarePage";
import CustomTestDataPage from "./pages/customTestData/CustomTestDataPage";
import SettingsProfilesPage from "./pages/settingsProfiles/SettingsProfilesPage";

//Rol access
// import PermissionsWrapper from './PermissionsWrapper';
// import NoPermissionPage from './NoPermissionPage';
// import { AuthProvider } from './AuthContext';

export default function App() {
  return (
    // <AuthProvider>
    //   <PermissionsWrapper>
        <div>
          <Header />
          <div className="container-fluid">
            <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/logout" element={<Logout />} />
                <Route element={<PrivateRoute />}>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/customer/:id" element={<CustomerInfoPage />} />
                  <Route path="/tacho" element={<AuthServerPage />} />
                  <Route path="/overviewgraph" element={<OverviewGraphPage />} />
                  <Route path="/tachoobject" element={<TachoObjectPage />} />
                  <Route path="/tachodriver" element={<TachoDriverPage />} />
                  <Route path="/tachomassdownload" element={<TachoMassDownloadPage />} />
                  <Route path="/tachodriverdownload" element={<TachoDriverDownloadPage />} />
                  <Route path="/canprotocol" element={<CanConfigPage />} />
                  <Route path="/firmware" element={<FirmwarePage />} />
                  <Route path="/devicetest/:id" element={<DeviceInstallPage />} />
                  <Route path="/devicetest" element={<DeviceInstallPage />} />
                  <Route path="/devicetests" element={<DeviceTestOverviewPage />} />
                  <Route path="customers" element={<CustomersPage />} />
                  <Route path="/CustomTestDataPage" element={<CustomTestDataPage />} />

                  {/* <PrivateRoute path="/customtestdata" element={CustomTestDataPage} /> */}
                  {/* <Route path= "/CustomTestDataPage" element= {CustomTestDataPage} />  */}

                  <Route path="/devices" element={<DevicesPage />} />
                  <Route path="/device/:id" element={<ManageDeviceDetailsPage />} />
                  <Route path="/endpoints" element={<EndpointPage />} />
                  <Route path="/rawmessages" element={<RawMessagePage />} />
                  <Route path="/log" element={<LogPage />} />
                  <Route path="/account" element={<AccountPage />} />
                  <Route path="*" element={<PageNotFound />} />
                </Route>
                <Route element={<AdminRoute />}>
                  {/* <Route path="/configurations" element={<ConfigurationGroupPage />} /> */}
                  <Route path="/settingsProfiles" element={<SettingsProfilesPage />} />
                  <Route path="/features" element={<FeaturePage />} />
                  <Route path="/roles" element={<RolePage />} />
                </Route>
                {/* <Route path='/no-permission' element={<NoPermissionPage />} /> */}
            </Routes>
            <ToastContainer position="top-right" autoClose={3500} closeOnClick />
          </div>
        </div>
    //   </PermissionsWrapper>
    // </AuthProvider>
  );
}


