import React from "react";
import PropTypes from "prop-types";
import { Form, Col, Row } from "react-bootstrap";


export const DeviceConfigurationGroup = ({
    configurationGroups,
    configurationGroup,
    setConfigurationGroup,
    disableOptions,

}) => {


    const inputHandler = (e) => {
        console.log(e.target.name);
        console.log(e.target.value);
        setConfigurationGroup(e.target.value);
      };

    

    return (
        <Form.Group as={Row}>
        <Form.Label column sm="3">
          <h6>Configuration:</h6>
        </Form.Label>
        <Col sm="9">
          <Form.Control
            name="configurationGroup"
            as="select"
            value={configurationGroup}
            disabled={disableOptions()}
            onChange={(e) => inputHandler(e)}
          >
            <option>--</option>
            {configurationGroups.map((config) => {
              return (
                <option key={config.Id} value={config.Id}>
                  {config.Name}
                </option>
              );
            })}
          </Form.Control>
        </Col>
      </Form.Group>
    )
}

DeviceConfigurationGroup.propTypes = {
    configurationGroups: PropTypes.array.isRequired,
    disableOptions: PropTypes.func.isRequired,
    configurationGroup:PropTypes.string,
    setConfigurationGroup:PropTypes.func.isRequired,
   
  };


