import * as types from "./actionsTypes";
import * as customTestDataApi from "../api/customTestDataApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import { toast } from "react-toastify";
// import { DOWNLOAD_FILE_CUSTOM_TEST_DATA_SUCCESS } from "./actionsTypes";

export const getCustomTestDataById = (auth, id) => async (dispatch) => {
    dispatch(beginApiCall());
    dispatch({
        type: "LOADING_CUSTOM_TEST_DATA_DETAILS",
        payload: {
            isLoading: true,
        },
    });


customTestDataApi
    .getCustomTestDataById(auth, id)
    .then((customTestData) => {
        dispatch({
            type: types.LOAD_CUSTOM_TEST_DATA_DETAILS_SUCCESS,
            payload: {
                customTestData: customTestData,
            },
        });
    })
    .catch((error) => {
        dispatch(apiCallError(error));
    });
}


export const fetchCustomTestData = (auth) => async (dispatch) => {
    dispatch(beginApiCall());

    customTestDataApi
        .getCustomTestData(auth)
        .then((customTestData) => {
            dispatch({
                type: types.LOAD_CUSTOM_TEST_DATA_SUCCESS,
                payload: {
                    customTestData: customTestData,
                },
            });
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
};

export const saveCustomTestData = (auth, customTestData) => async (dispatch) => {
    dispatch(beginApiCall());

    customTestDataApi
        .saveCustomTestData(auth, customTestData)
        .then((res) => {
            dispatch({
                type: types.SAVE_CUSTOM_TEST_DATA_SUCCESS,
                payload: {
                    customTestData: res,
                },
            });
            toast.success("Custom Test Data " + res.Name + " succesfully created!");
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
}

export const removeCustomTestData = (auth, id) => async (dispatch) => {
    dispatch(beginApiCall());

    customTestDataApi
        .removeCustomTestData(auth, id)
        .then(() => {
            dispatch({
                type: types.REMOVE_CUSTOMER_USER_SUCCESS,
                payload: {
                    customTestData: id,
                },
            });
            toast.warn("Custom Test Data succesfully removed!");
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
}

export const updateCustomTestData = (auth, id, customTestData) => async (dispatch) => {
    dispatch(beginApiCall());

    customTestDataApi
        .updateCustomTestData(auth, id, customTestData)
        .then((res) => {
            dispatch({
                type: types.UPDATE_CUSTOM_TEST_DATA_SUCCESS,
                payload: {
                    customTestData: res,
                },
            });
            toast.success("Custom Test Data " + res.Name + " succesfully updated!");
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
}