import * as types from "./actionsTypes";
import * as tachoDownloadApi from "../api/tachoDownloadApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";


export const fetchTachoDownloads = (auth, dwnltype) => async (dispatch) => {
    dispatch(beginApiCall());
    tachoDownloadApi
        .GetTachoDownloads(auth, dwnltype)
        .then((tachoDownloads) => {
            dispatch({
                type: types.LOAD_TACHO_DOWNLOAD_SUCCESS,
                payload: {
                    tachoDownloads: tachoDownloads,
                },
            });
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
};

export const fetchSearchResults = (auth, searchTerm, filter, type, customerId = null) => async (dispatch) => {
    dispatch(beginApiCall());

    tachoDownloadApi
        .GetSearchResults(auth, searchTerm, filter, type, customerId)
        .then((tachoResults) => {
            dispatch({
                type: types.LOAD_SEARCHED_TACHO_DOWNLOADS_SUCCESS,
                payload: {
                    tachoResults: tachoResults,
                },
            });
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
};

export const fetchResultsByCustomer = (auth, customerId, type) => async (dispatch) => {
    dispatch(beginApiCall());

    tachoDownloadApi
        .GetTachoByCustomer(auth, customerId, type)
        .then((tachoResults) => {
            dispatch({
                type: types.LOAD_TACHO_DOWNLOADS_BY_CUSTOMER_SUCCESS,
                payload: {
                    tachoResults: tachoResults,
                },
            });
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
}

export const requestMassMemoryDownload = (auth, imei) => async (dispatch) => {
    dispatch(beginApiCall());

    tachoDownloadApi
        .requestMassMemoryDownload(auth, imei)
        .then(() => {
            dispatch({
                type: types.REQUEST_TACHO_DOWNLOAD_SUCCESS,
            });

        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
}

export const requestCardDownload = (auth, cardnumber) => async (dispatch) => {
    dispatch(beginApiCall())

    tachoDownloadApi
        .requestCardDownload(auth, cardnumber)
        .then(() => {
            dispatch({
                type: types.REQUEST_TACHO_DOWNLOAD_SUCCESS,
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error))
        })
}
