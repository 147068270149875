import * as types from "./actionsTypes";
import * as firmwareApi from "../api/firmwareApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import { toast } from "react-toastify";

export const loadFirmwares = (auth) => async (dispatch) => {
    dispatch(beginApiCall());

    firmwareApi
        .getFirmwares(auth)
        .then((firmwares) => {
            dispatch({
                type: types.LOAD_FIRMWARES_SUCCESS,
                payload: {
                    firmwares: firmwares,
                },
            });
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
};

export const loadFirmwareFlags = (auth) => async (dispatch) => {
    dispatch(beginApiCall());

    firmwareApi
        .getFirmwareFlags(auth)
        .then((firmwareFlags) => {
            dispatch({
                type: types.LOAD_FIRMWAREFLAGS_SUCCESS,
                payload: {
                    firmwareFlags: firmwareFlags,
                }
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
}

export const loadActiveFirmwares = (auth) => async (dispatch) => {
    dispatch(beginApiCall());

    firmwareApi
        .getactiveFirmwares(auth)
        .then((firmwares) => {
            dispatch({
                type: types.LOAD_ACTIVEFIRMWARES_SUCCESS,
                payload: {
                    firmwares: firmwares,
                },
            });
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
};

export const loadFirmware = (auth, id) => async (dispatch) => {
    dispatch(beginApiCall());

    firmwareApi
        .getFirmwareById(auth, id)
        .then((firmware) => {
            dispatch({
                type: types.LOAD_FIRMWARE_SUCCESS,
                payload: {
                    firmwares: firmware,
                },
            });
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
};

export const updateFirmware = (auth, id, firmware) => async (dispatch) => {
    dispatch(beginApiCall());

    firmwareApi
        .putFirmwareById(auth, id, firmware)
        .then((firmware) => {
            dispatch({
                type: types.UPDATE_FIRMWARE_SUCCESS,
                payload: {
                    firmwares: firmware,
                },
            });
            toast.success("Firmware " + firmware.Version + " saved!");
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
};

export const saveFirmware = (auth, firmware) => async (dispatch) => {
    dispatch(beginApiCall());

    firmwareApi
        .saveFirmware(auth, firmware)
        .then((firmware) => {
            dispatch({
                type: types.SAVE_FIRMWARE_SUCCESS,
                payload: {
                    firmwares: firmware,
                },
            });
            toast.success("Firmware " + firmware.Version + " saved!");
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
};

export const deleteFirmware = (auth, id) => async (dispatch) => {
    dispatch(beginApiCall());

    firmwareApi
        .deleteFirmware(auth, id)
        .then((firmware) => {
            dispatch({
                type: types.DELETE_FIRMWARE_SUCCESS,
                payload: {
                    firmwares: firmware,
                },
            });
            toast.success("Firmware " + firmware.Version + " deleted!");
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
}

export const massUpdateFirmware = (auth, request) => async (dispatch) => {
    dispatch(beginApiCall())

    firmwareApi
        .massUpdateFirmware(auth, request)
        .then((response) => {
            dispatch({
                type: types.MASS_UPDATE_SUCCESS,
                payload: request,
            })
            toast.success(response.Message)
        })
        .catch((error) => {
            dispatch(apiCallError(error))
        })
}

export const massUpdateBooter = (auth, request) => async (dispatch) => {
    dispatch(beginApiCall())

    firmwareApi
        .massUpdateBooter(auth, request)
        .then((response) => {
            dispatch({
                type: types.MASS_UPDATE_SUCCESS,
                payload: request,
            })
            toast.success(response.Message)
        })
        .catch((error) => {
            dispatch(apiCallError(error))
        })
}
