import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/api/devices/modemrequests/";

export function getModemrequestsByDevice(auth, imei) {
  return fetch(baseUrl + imei, {
    headers: { Authorization: auth.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveModemrequestForDevice(auth, imei, request) {
  return fetch(baseUrl + imei, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: auth.accessToken,
    },
    body: JSON.stringify(request),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function updateModemrequestForDevice(auth, imei, request) {
  return fetch(baseUrl + imei, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      Authorization: auth.accessToken,
    },
    body: JSON.stringify(request),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function removeModemrequestForDevice(auth, id) {
  return fetch(baseUrl + id, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: auth.accessToken,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}
