import React from "react";
import PropTypes from "prop-types";
import { Form, Col, Row } from "react-bootstrap";


export const DeviceCustomer = ({
    disableOptions,
    customers,
    customer,
    setCustomer,
    
    
}) => {
    

    const inputHandler = (e) => {
        setCustomer(e.target.value);
      };

    return (
        <Form.Group as={Row}>
        <Form.Label column sm="3">
        <h6>Customer:</h6>
        </Form.Label>
        <Col sm="9">
        <Form.Control
            name="customer"
            disabled={disableOptions()}
            as="select"
            value={customer}
            onChange={(e) => inputHandler(e)}
        >
            <option key="null" value="null">
            --
            </option>
            {customers.map((customer) => {
            return (
                <option key={customer.Id} value={customer.Id}>
                {customer.Name}
                </option>
            );
            })}
        </Form.Control>
        </Col>
        </Form.Group>
    )
}


DeviceCustomer.propTypes = {
    disableOptions: PropTypes.func.isRequired,
    setCustomer: PropTypes.func.isRequired,
    customer: PropTypes.string,
    customers: PropTypes.array.isRequired,
  };


