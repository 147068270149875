import * as types from "./actionsTypes";
import * as logApi from "../api/logApi";
import {beginApiCall, apiCallError} from "./apiStatusActions";

export const loadLogs = (auth) => async (dispatch) => {
    dispatch(beginApiCall());

    logApi
        .getLogs(auth)
        .then((logs) => {
            dispatch({
                type: types.LOAD_FIRST_BATCH_LOGS_SUCCESS,
                payload: {
                    logs: logs,
                },
            });
            dispatch({
                type: types.CHECK_END_OF_LIST,
                payload: {
                    messages: logs,
                }
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
};

export const loadAllLogsFromId = (auth, id) => async (dispatch) => {
    dispatch(beginApiCall());
    
    logApi
        .getLogsFromId(auth, id)
        .then((logs) => {
            dispatch({
                type: types.LOAD_BATCH_LOGS_SUCCESS,
                payload: {
                    logs: logs
                },
            });
            dispatch({
                type: types.CHECK_END_OF_LIST,
                payload: {
                    messages: logs,
                }
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
};

export const loadSearchedLogs = (auth, searchTerm, filter) => async (dispatch) => {
    dispatch(beginApiCall());

    logApi
        .getLogsFromSearch(auth, searchTerm, filter)
        .then((logs) => {
            dispatch({
                type: types.LOAD_SEARCHED_LOGS_SUCCESS,
                payload: {
                    logs: logs,
                },
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
}
