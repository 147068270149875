import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const deviceTestsReducer = (state = initialState.deviceTest, action) => {
switch (action.type) {
    case "LOADING_DEVICETEST":
        return {...state,
        isLoading: true,
        };
    case types.LOAD_DEVICESTEST_SUCCESS:
        return{
            ...state,
            test : action.payload.deviceTest,
            isLoading: false,
        };
    case types.SAVE_DEVICETEST_SUCCES:
        return state;
    default:
        return state;
    }
};

export default deviceTestsReducer;