import React, { useState } from "react"
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

const EndpointCheckboxTableRow = ({
    name,
    id,
    chapterMask,
    setChapterMask,
}) => {
    const LIVE = { value: 1, name: "LIVE" }
    const GNSS = { value: 2, name: "GNSS" }
    const TACHO = { value: 4, name: "TACHO" }
    const ALTFUEL = { value: 8, name: "ALTFUEL" }
    const DASHBOARD = { value: 16, name: "DASHBOARD" }
    const TRIPDATA = { value: 32, name: "TRIPDATA" }
    const BLE = { value: 64, name: "BLE" }
    const EVENTS = { value: 128, name: "EVENTS" }
    const SETTINGS = { value: 256, name: "SETTINGS" }
    const DIAGNOSTICS = { value: 512, name: "DIAGNOSTICS" }
    const ENDPOINTS = { value: 1024, name: "ENDPOINTS" }
    const HIGHRESDATA = { value: 2048, name: "HIGHRESDATA" }

    const [newChapterMask, setNewChapterMask] = useState(chapterMask);

    const handleCheckbox = (e) => {
        var tempvalue = newChapterMask;
        if (e.target.checked)
            tempvalue = tempvalue | e.target.value;
        else
            tempvalue = tempvalue & ~(e.target.value);
        if (newChapterMask != tempvalue) {
            setNewChapterMask(tempvalue);
            setChapterMask(id, tempvalue)
        }
    }

    return (
        <>
            <tr key={name} className="table-row">
                <th scope="row">{name}</th>
                <td>
                    <Form.Check
                        name={LIVE.name}
                        value={LIVE.value}
                        checked={(newChapterMask & LIVE.value)}
                        onChange={handleCheckbox} />
                </td>
                <td>
                    <Form.Check
                        name={GNSS.name}
                        value={GNSS.value}
                        checked={newChapterMask & (GNSS.value)}
                        onChange={handleCheckbox} />
                </td>
                <td>
                    <Form.Check
                        name={TACHO.name}
                        value={TACHO.value}
                        checked={newChapterMask & (TACHO.value)}
                        onChange={handleCheckbox} />
                </td>
                <td>
                    <Form.Check
                        name={ALTFUEL.name}
                        value={ALTFUEL.value}
                        checked={newChapterMask & (ALTFUEL.value)}
                        onChange={handleCheckbox} />
                </td>
                <td>
                    <Form.Check
                        name={DASHBOARD.name}
                        value={DASHBOARD.value}
                        checked={newChapterMask & (DASHBOARD.value)}
                        onChange={handleCheckbox} />
                </td>
                <td>
                    <Form.Check
                        name={TRIPDATA.name}
                        value={TRIPDATA.value}
                        checked={newChapterMask & (TRIPDATA.value)}
                        onChange={handleCheckbox} />
                </td>
                <td>
                    <Form.Check
                        name={BLE.name}
                        value={BLE.value}
                        checked={newChapterMask & (BLE.value)}
                        onChange={handleCheckbox} />
                </td>
                <td>
                    <Form.Check
                        name={EVENTS.name}
                        value={EVENTS.value}
                        checked={newChapterMask & (EVENTS.value)}
                        onChange={handleCheckbox} />
                </td>
                <td>
                    <Form.Check
                        name={SETTINGS.name}
                        value={SETTINGS.value}
                        checked={newChapterMask & (SETTINGS.value)}
                        onChange={handleCheckbox} />
                </td>
                <td>
                    <Form.Check
                        name={DIAGNOSTICS.name}
                        value={DIAGNOSTICS.value}
                        checked={newChapterMask & (DIAGNOSTICS.value)}
                        onChange={handleCheckbox} />
                </td>
                <td>
                    <Form.Check
                        name={ENDPOINTS.name}
                        value={ENDPOINTS.value}
                        checked={newChapterMask & (ENDPOINTS.value)}
                        onChange={handleCheckbox} />
                </td>
                <td>
                    <Form.Check
                        name={HIGHRESDATA.name}
                        value={HIGHRESDATA.value}
                        checked={newChapterMask & (HIGHRESDATA.value)}
                        onChange={handleCheckbox} />
                </td>
            </tr>
        </>
    )
}

EndpointCheckboxTableRow.propTypes = {
    name: PropTypes.string.isRequired,
    chapterMask: PropTypes.number.isRequired,
    setChapterMask: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EndpointCheckboxTableRow);