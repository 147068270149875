import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { Button, Table, Form, Row, Col } from "react-bootstrap";
import EditIcon from "../../common/icons/pen-to-square-solid.svg";
import ChangeMessageModal from "./components/ChangeMessageModal";
import { saveDeviceSettings } from "../../../actions/deviceSettingActions";
import { getDeviceById } from "../../../actions/deviceActions";
import { getSettingsProfiles } from "../../../actions/settingsProfileActions";
import Spinner from "../../common/Spinner";
import MessageURLControlRow from "./components/MessageURLControlRow"

const DeviceMessagesPane = ({
    auth,
    device,
    isLoading,
    message0,
    message1,
    message2,
    settingsProfileId,
    settingsProfiles,
    apiCallsInProgress,
    deviceDetails,
}) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState({});

    const initialValues = { message0: message0, message1: message1, message2: message2 };

    const disableOptions = () => {
        return (!auth.user.IsReseller || settingsProfile);
    };

    const showModalHandler = (message) => {
        setMessage(message)
        setShow(true);
    }

    useEffect(() => {
        if (apiCallsInProgress == 0) {
            dispatch(getSettingsProfiles(auth));
            dispatch(getDeviceById(auth, device.Id));
        }
    }, [])

    const saveMessageHandler = () => {
        checkAndDispatch();
    }
    const checkAndDispatch = () => {
        var changeList = []
        for (const msg in initialValues) {
            if (message.index == initialValues[msg].index) {
                var ind = message.index
                delete message.index
                for (const property in message) {
                    if (initialValues.message0[property] == message[property])
                        continue;
                    var obj = {
                        SettingName: "endpoint" + ind + "JSON",
                        Value: JSON.stringify(message)
                    }
                    changeList.push(obj);
                    break;
                }
                dispatch(saveDeviceSettings(auth, device.Id, device.IMEI, {
                    SettingsList: changeList,
                }))
            }
        }
    }

    const isViewer = auth.user.Role === "Viewer";
    const devMessages = [message0, message1, message2]
    const settingsProfile = settingsProfiles.find((e) => { return e.Id == settingsProfileId })
    const oldMessage0 = JSON.parse(deviceDetails.currentConfig.endpoint0JSON)
    const oldMessage1 = JSON.parse(deviceDetails.currentConfig.endpoint1JSON)
    const oldMessage2 = JSON.parse(deviceDetails.currentConfig.endpoint2JSON)
    return (
        <>
            {!isLoading ? (
                <>
                    <Form.Group as={Row}>
                        <Form.Label column sm="3">
                            <h6>Settings profile: </h6>
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control
                                disabled={true}
                                as="select"
                            >
                                <option key="null" value="null">
                                    {settingsProfile ? settingsProfile.Name : "--"}
                                </option>
                            </Form.Control>
                        </Col>

                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm="3">
                            <h6>InSync: </h6>
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control plaintext readOnly defaultValue={device.InSync ? "Yes" : "No"} />
                        </Col>
                    </Form.Group>
                    <hr></hr>
                    <MessageURLControlRow
                        name="Message 0 URL"
                        message={initialValues.message0}
                        setMessage={setMessage}
                        isViewer={isViewer}
                        disableOptions={disableOptions}
                        saveMessageHandler={saveMessageHandler} // todo: make italic if out of sync
                        currentMessage={oldMessage0}
                    />
                    <br />
                    <MessageURLControlRow
                        name="Message 1 URL"
                        message={initialValues.message1}
                        setMessage={setMessage}
                        isViewer={isViewer}
                        disableOptions={disableOptions}
                        saveMessageHandler={saveMessageHandler}
                        currentMessage={oldMessage1}
                    />
                    <br />
                    <MessageURLControlRow
                        name="Message 2 URL"
                        message={initialValues.message2}
                        setMessage={setMessage}
                        isViewer={isViewer}
                        disableOptions={disableOptions}
                        saveMessageHandler={saveMessageHandler}
                        currentMessage={oldMessage2}
                    />
                    <hr></hr>
                    <Table striped hover responsive>
                        <thead>
                            <tr>
                                <th>Message</th>
                                <th>Time</th>
                                <th>TripStart</th>
                                <th>TripEnd</th>
                                <th>Charge</th>
                                <th>Power</th>
                                <th>Wake</th>
                                <th>Standby</th>
                                <th>Move</th>
                                <th>LowBV</th>
                                <th>IO</th>
                                <th>Unauth</th>
                                <th>Login</th>
                                <th>DriveStyle</th>
                                <th>Diag</th>
                                <th>TachoLive</th>
                                <th>Request</th>
                            </tr>
                        </thead>
                        <tbody>
                            {devMessages.map((message, index) => {
                                const isViewer = auth.user.Role === "Viewer";
                                let oldMessage = oldMessage0
                                if (index == 1)
                                    oldMessage = oldMessage1
                                if (index == 2)
                                    oldMessage = oldMessage2
                                return (
                                    <tr key={index} className="table-row">
                                        <td>Message {message.index} </td>
                                        <td>{message.TIME == oldMessage.TIME ? (message.TIME) : (oldMessage.TIME) + "*"}</td>
                                        <td>{message.SOT == oldMessage.SOT ? (message.SOT) : (oldMessage.SOT) + "*"}</td>
                                        <td>{message.EOT == oldMessage.EOT ? (message.EOT) : (oldMessage.EOT) + "*"}</td>
                                        <td>{message.CHG == oldMessage.CHG ? (message.CHG) : (oldMessage.CHG) + "*"}</td>
                                        <td>{message.POR == oldMessage.POR ? (message.POR) : (oldMessage.POR) + "*"}</td>
                                        <td>{message.WAKE == oldMessage.WAKE ? (message.WAKE) : (oldMessage.WAKE) + "*"}</td>
                                        <td>{message.STB == oldMessage.STB ? (message.STB) : (oldMessage.STB) + "*"}</td>
                                        <td>{message.MOV == oldMessage.MOV ? (message.MOV) : (oldMessage.MOV) + "*"}</td>
                                        <td>{message.LOWBV == oldMessage.LOWBV ? (message.LOWBV) : (oldMessage.LOWBV) + "*"}</td>
                                        <td>{message.PZ == oldMessage.PZ ? (message.PZ) : (oldMessage.PZ) + "*"}</td>
                                        <td>{message.DWL == oldMessage.DWL ? (message.DWL) : (oldMessage.DWL) + "*"}</td>
                                        <td>{message.LOG == oldMessage.LOG ? (message.LOG) : (oldMessage.LOG) + "*"}</td>
                                        <td>{message.DS == oldMessage.DS ? (message.DS) : (oldMessage.DS) + "*"}</td>
                                        <td>{message.DIAG == oldMessage.DIAG ? (message.DIAG) : (oldMessage.DIAG) + "*"}</td>
                                        <td>{message.TL == oldMessage.TL ? (message.TL) : (oldMessage.TL) + "*"}</td>
                                        <td>{message.REQ == oldMessage.REQ ? (message.REQ) : (oldMessage.REQ) + "*"}</td>
                                        <td>
                                            {!isViewer && !disableOptions() && (
                                                <Button className="shadow-sm border-secondary" variant="light"
                                                    onClick={() => {
                                                        showModalHandler(message)
                                                    }}>
                                                    <img src={EditIcon} alt="Edit" />
                                                </Button>
                                            )}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    {message && (
                        <ChangeMessageModal
                            message={message}
                            saveMessageHandler={saveMessageHandler}
                            setMessage={setMessage}
                            name={device.IMEI}
                            show={show}
                            setShow={setShow}
                        />
                    )}
                    <hr></hr>
                    <Form.Group>
                        <Row>
                            <Col sm="11">
                                <Button
                                    variant="outline-warning"
                                    onClick={() => history.push("/devices")}
                                >
                                    Back
                                </Button>
                            </Col>

                        </Row>
                    </Form.Group>
                </>
            ) : (
                <Spinner />
            )}
        </>
    );
};

DeviceMessagesPane.propTypes = {
    auth: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired,
    deviceDetails: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    configurationGroups: PropTypes.array.isRequired,
    message0: PropTypes.object.isRequired,
    message1: PropTypes.object.isRequired,
    message2: PropTypes.object.isRequired,
    settingsProfileId: PropTypes.string,
    settingsProfiles: PropTypes.array.isRequired,
    apiCallsInProgress: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        device: state.deviceDetails.device,
        isLoading: state.deviceDetails.isLoading,
        message0: state.deviceDetails.newConfig.message0,
        message1: state.deviceDetails.newConfig.message1,
        message2: state.deviceDetails.newConfig.message2,
        settingsProfiles: state.settingsProfiles,
        settingsProfileId: state.deviceDetails.newConfig.settingsProfileID,
        configurationGroups: state.configurationGroups,
        apiCallsInProgress: state.apiCallsInProgress,
        deviceDetails: state.deviceDetails,
    }
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceMessagesPane);
