import * as types from "./actionsTypes"
import * as settingsProfileApi from "../api/settingsProfileApi"
import { beginApiCall, apiCallError } from "./apiStatusActions"

export const getSettingsProfiles = (auth) => async (dispatch) => {
    dispatch(beginApiCall())

    settingsProfileApi
        .getSettingsProfiles(auth)
        .then((settingsProfiles) => {
            dispatch({
                type: types.LOAD_SETTINGS_PROFILES_SUCCESS,
                payload: {
                    settingsProfiles: settingsProfiles,
                },
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error))
        })
};

export const saveSettingsProfile = (auth, profile) => async (dispatch) => {
    dispatch(beginApiCall())
    settingsProfileApi
        .saveSettingsProfile(auth, profile)
        .then((settingsProfile) => {
            dispatch({
                type: types.SAVE_SETTINGS_PROFILE_SUCCESS,
                payload: {
                    settingsProfile: settingsProfile
                }
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error))
        })
}

export const removeSettingsProfile = (auth, profileId) => async (dispatch) => {
    dispatch(beginApiCall())
    settingsProfileApi
        .removeSettingsProfile(auth, profileId)
        .then(() => {
            dispatch({
                type: types.DELETE_SETTINGS_PROFILE_SUCCESS,
                payload: {
                    Id: profileId,
                }
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error))
        })
}

export const updateSettingsProfile = (auth, profile) => async (dispatch) => {
    dispatch(beginApiCall())
    settingsProfileApi
        .updateSettingsProfile(auth, profile)
        .then((settingsProfile) => {
            dispatch({
                type: types.UPDATE_SETTINGS_PROFILE_SUCCESS,
                payload: {
                    settingsProfile: settingsProfile,
                }
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error))
        })
}

export const fetchSettingsProfilesByCustomer = (auth, customer) => async (dispatch) => {
    dispatch(beginApiCall())
    settingsProfileApi
        .fetchSettingsProfilesByCustomer(auth, customer)
        .then((settingsProfiles) => {
            dispatch({
                type: types.LOAD_SETTINGS_PROFILES_SUCCESS,
                payload: {
                    settingsProfiles: settingsProfiles,
                }
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error))
        })
}


export const fetchSettingsProfilesByName = (auth, name, customer = null) => async (dispatch) => {
    dispatch(beginApiCall())
    settingsProfileApi
        .fetchSettingsProfilesByName(auth, name, customer)
        .then((settingsProfiles)=>{
            dispatch({
                type: types.LOAD_SETTINGS_PROFILES_SUCCESS,
                payload: {
                    settingsProfiles: settingsProfiles,
                }
            })
        })
        .catch((error)=>{
            dispatch(apiCallError(error))
        })
}