import React from "react";
import PropTypes from "prop-types";

// Styling
import { Modal, Button, Form, Row, Col } from "react-bootstrap";

const RequestModal = ({
  device,
  show,
  setShow,
  request,
  saveRequestHandler,
  setRequest,
  isLoading,
}) => {
  const handleClose = () => setShow(false);
  return (
    <>
      {!isLoading ? (
        <>
          <Modal
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={handleClose}
          >
            <Form>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  IMEI: {device.IMEI}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    <h6>Command: </h6>
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      defaultValue={request.Command}
                      onChange={(e) =>
                        setRequest({
                          Id: request.Id,
                          IMEI: request.IMEI,
                          Command: e.target.value,
                          CommandType: 20,
                        })
                      }
                    />
                  </Col>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={saveRequestHandler}
                  disabled={
                    request.Command.length < 3 || request.Executed != null
                      ? true
                      : false
                  }
                  variant="success"
                  type="submit"
                >
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </>
      ) : (
        ""
      )}
    </>
  );
};

RequestModal.propTypes = {
  device: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired,
  saveRequestHandler: PropTypes.func.isRequired,
  setRequest: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default RequestModal;
