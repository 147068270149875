import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Redux
import { connect, useDispatch } from "react-redux";
import { updateAccountCustomer } from "../../actions/accountActions";

// Styling
import { Row, Button, Col, Form } from "react-bootstrap";

// Util
import Countries from "../../util/Countries";

const CustomerDetailsTab = ({ auth, customer }) => {
  const dispatch = useDispatch();
  const [validForm, setValidForm] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({
    name: "",
    country: "",
    isReseller: "",
    reseller: "",
    start: "",
  });

  useEffect(() => {
    if (customer != null) {
      setCustomerDetails({
        name: customer.Name,
        country: customer.Country,
        isReseller: customer.IsReseller,
        reseller: customer.Reseller,
        start: customer.Start,
      });
    }
  }, [customer]);

  const inputHandler = (e) => {
    let value = "";
    if (e.target.name === "name") {
      value = e.target.value.replace(/[^\w\s]/gi, "");
    } else {
      value = e.target.value;
    }
    setCustomerDetails({
      ...customerDetails,
      [e.target.name]: value,
    });
  };

  useEffect(() => {
    let validName;

    validName = customerDetails.name.length >= 3;

    if (validName) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [customerDetails]);

  const updateCustomerDetails = () => {
    if (
      customerDetails.name == customer.Name &&
      customerDetails.country == customer.Country
    )
      return;
    const obj = {
      name: customerDetails.name,
      country: customerDetails.country,
    };
    dispatch(updateAccountCustomer(auth, customer.Id, obj));
  };

  const isViewer = auth.user.Role === "Viewer";

  return (
    <div>
      <br />
      <Form className="account-form">
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={customerDetails.name}
              placeholder="Enter name"
              onChange={inputHandler}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Country</Form.Label>
            <Form.Control
              as="select"
              name="country"
              value={customerDetails.country}
              onChange={inputHandler}
            >
              {Countries.map((country) => {
                return (
                  <option key={country.code} value={country.name}>
                    {country.name}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>IsReseller</Form.Label>
            <Form.Control
              value={customerDetails.isReseller ? "Yes" : "No"}
              disabled
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Reseller</Form.Label>
            <Form.Control
              value={
                customerDetails.reseller !== null
                  ? customerDetails.reseller
                  : "None"
              }
              disabled
            />
          </Form.Group>
        </Row>
        <hr></hr>
        <Form.Group>
          <Row>
            <Col sm="11"></Col>
            <Col sm="1">
            {!isViewer && (
              <Button
                variant="success"
                disabled={!validForm}
                onClick={(e) => updateCustomerDetails(e)}
              >
                Save
              </Button>
            )}
            </Col>
          </Row>
        </Form.Group>
      </Form>
    </div>
  );
};

CustomerDetailsTab.propTypes = {
  auth: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    customer: state.account.customer,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetailsTab);
