// actions/featureActions.js
import * as types from "./actionsTypes";
import * as featureApi from "../api/featureApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import { toast } from "react-toastify";

export const fetchFeatures = (auth) => async (dispatch) => {
  dispatch(beginApiCall());

  featureApi
    .getFeatures(auth)
    .then((features) => {
      dispatch({
        type: types.LOAD_FEATURES_SUCCESS,
        payload: {
          features: features,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const getFeatureById = (auth, id) => async (dispatch) => {
  dispatch(beginApiCall());

  featureApi
    .getFeatureById(auth, id)
    .then((feature) => {
      dispatch({
        type: types.LOAD_FEATURE_SUCCESS,
        payload: {
          feature: feature,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const saveFeature = (auth, feature) => async (dispatch) => {
  dispatch(beginApiCall());

  featureApi
    .saveFeature(auth, feature)
    .then((res) => {
      dispatch({
        type: types.SAVE_FEATURE_SUCCESS,
        payload: {
          feature: res,
        },
      });
      toast.success("Feature " + res.Name + " successfully created");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const updateFeature = (auth, id, feature) => async (dispatch) => {
  dispatch(beginApiCall());

  featureApi
    .updateFeature(auth, id, feature)
    .then((res) => {
      dispatch({
        type: types.UPDATE_FEATURE_SUCCESS,
        payload: {
          feature: res,
        },
      });
      toast.success("Feature " + res.Name + " successfully updated!");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const removeFeature = (auth, id) => async (dispatch) => {
  dispatch(beginApiCall());

  featureApi
    .removeFeature(auth, id)
    .then(() => {
      dispatch({
        type: types.REMOVE_FEATURE_SUCCESS,
        payload: {
          feature: id,
        },
      });
      toast.warn("Feature successfully removed!");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};
