import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/api/customers/";

export const getCustomerById = (auth, id) => {
  return fetch(baseUrl + id, {
    headers: { Authorization: auth.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
};

export function getCustomers(auth) {
  return fetch(baseUrl, {
    headers: { Authorization: auth.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
}

export const updateCustomer = (auth, id, customer) => {
  return fetch(baseUrl + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.accessToken,
    },
    body: JSON.stringify(customer),
  })
    .then(handleResponse)
    .catch(handleError);
};

export const saveCustomer = (auth, customer) => {
  return fetch(baseUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.accessToken,
    },
    body: JSON.stringify(customer),
  })
    .then(handleResponse)
    .catch(handleError);
};

export const removeCustomer = (auth, id) => {
  return fetch(baseUrl + id, {
    method: "DELETE",
    headers: {
      Authorization: auth.accessToken,
    },
  })
    .then(handleResponse)
    .catch(handleError);
};
