import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function (state = initialState.auth, action) {
  const { type, payload } = action;
  switch (type) {
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.auth.Auth,
        accessToken: payload.auth.AccessToken,
      };
    case types.LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        accessToken: "",
      };
    case types.LOGOUT:
      return {
        isLoggedIn: false,
        user: null,
        accessToken: "",
      };
    default:
      return state;
  }
}
