import React, {useState} from "react";
import PropTypes from "prop-types";
import {connect, useDispatch} from "react-redux";

import {
    updateCanConfig,
} from "../../actions/canConfigActions";

import {SortedTable} from "../common/SortedTable";
import SortButton from "../common/SortButton";

import UpdateCanConfig from "./components/UpdateCanConfig";
import {Button, Table} from "react-bootstrap";

import EditIcon from "../common/icons/pen-to-square-solid.svg";

const CanConfigList = ({auth, canConfigs}) => {
        const dispatch = useDispatch();
        const [showModal, setShowModal] = useState(false);
        const {list, requestSort, sortConfig} = SortedTable(canConfigs);
        const [selectedCANConfig, setselectedCANConfig] = useState({});

        const selectCANConfig = (e) => {
            setselectedCANConfig(
                canConfigs.find((canConfig) => {
                    return canConfig.Id === e;
                })
            );
        };

        const updateCanConfigHandler = (id, updatedCanConfig) => {
            var canconfig = new FormData();
            canconfig.append("Id", id);
            canconfig.append("Protocol", updatedCanConfig.Protocol);
            canconfig.append("Version", updatedCanConfig.Version);
            canconfig.append("Brand", updatedCanConfig.Brand);
            canconfig.append("Name", updatedCanConfig.Name);
            canconfig.append("Active", updatedCanConfig.Active);
            canconfig.append("DeviceType", updatedCanConfig.DeviceType);
            if (updatedCanConfig.Config != null) {
                canconfig.append(
                    "Config",
                    updatedCanConfig.Config,
                    updatedCanConfig.Config.name
                );
                canconfig.append("Filename", updatedCanConfig.Config.name);
            } else {
                canconfig.append("Config", "");
                canconfig.append("Filename", "");
            }

            dispatch(updateCanConfig(auth, updatedCanConfig.Id, canconfig));
        };

    const showModalHandler = (e) => {
        setShowModal(true);
        selectCANConfig(e);
    }

        return (
            <>
                <Table hover responsive striped>
                    <thead className="thead-inverse">
                    <tr>
                        <th className="device-table-customer">
                            Protocol
                            <SortButton
                                name="Protocol"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Brand
                            <SortButton
                                name="Brand"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Name
                            <SortButton
                                name="Name"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            DeviceType
                            <SortButton
                                name="DeviceType"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Active
                            <SortButton
                                name="Active"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {list.length === 0 ? (
                        <tr>
                            <td colSpan="9" style={{textAlign: "center"}}>
                                Nothing found
                            </td>
                        </tr>
                    ) : (
                        list.map((canconfig) => {
                            return (
                                <tr
                                    className="table-row"
                                    key={canconfig.Id}
                                >
                                    <td className="timeStamp-table-cell">{canconfig.Protocol}</td>
                                    <td>{canconfig.Brand}</td>
                                    <td>{canconfig.Name}</td>
                                    <td>
                                        {
                                            canconfig.DeviceType == 4 ? "QAN-ALL 4G" :
                                                canconfig.DeviceType == 2 ? "QAN-ALL 2G" :
                                                    canconfig.DeviceType == 1 ? "QAN-HUB" : "Unknown Device"
                                        }
                                    </td>
                                    <td>{canconfig.Active ? "Yes" : "No"}</td>
                                    <td>
                                        <Button className="shadow-sm border-secondary" variant="light" onClick={() => {
                                            showModalHandler(canconfig.Id)
                                        }}>
                                            <img
                                                src={EditIcon}
                                                alt="Edit"
                                            />
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })
                    )}
                    </tbody>
                </Table>
                {showModal && (
                    <UpdateCanConfig
                        show={showModal}
                        setShow={setShowModal}
                        canConfig={selectedCANConfig}
                        updateCanConfigHandler={updateCanConfigHandler}
                    />
                )}
            </>
        );
    }
;

CanConfigList.propTypes = {
    auth: PropTypes.object.isRequired,
    canConfigs: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CanConfigList);
