import { connect } from "react-redux"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Modal, Form, Row, Button, Col } from "react-bootstrap"

const MassUpdateCANConfig = ({
    show,
    setShow,
    canConfigs,
    onSubmitMassCANConfigUpdate
}) => {
    const [protocol, setProtocol] = useState()
    const [newProtocol, setNewProtocol] = useState()

    const handleClose = () => {
        setShow(false)
    }
    const handleShow = () => {
        setShow(true)
    }

    const inputHandler = (e) => {
        switch (e.target.name) {
            case "protocol":
                setProtocol(e.target.value)
                break;
            case "newProtocol":
                setNewProtocol(e.target.value)
                break;
        }
    }

    const onSubmit = () => {
        console.log(newProtocol)

        if (protocol != undefined && newProtocol != undefined)
            onSubmitMassCANConfigUpdate(protocol, newProtocol)
        handleClose()
    }

    return (
        <div>
            <Button className="create-object-button" onClick={handleShow}>
                Mass Update QTL
            </Button>
            <Modal show={show} onHide={handleClose} centered className="text-start">
                <Modal.Header closeButton>
                    <Modal.Title>Mass Update QTL</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group as={Row}>
                        <Col>
                            <Form.Label>
                                QTL:
                            </Form.Label>
                        </Col>
                        <Col>
                            <Form.Label>
                                New QTL:
                            </Form.Label>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col>
                            <Form.Control
                                as="select"
                                name="protocol"
                                value={protocol}
                                onChange={inputHandler}
                            >
                                <option key="null" value="0">
                                    --
                                </option>
                                {canConfigs && canConfigs.map((canconfig) => {
                                    return (
                                        <option key={canconfig.Id} value={canconfig.Protocol}>
                                            {canconfig.Protocol}
                                        </option>
                                    )
                                })}
                            </Form.Control>
                        </Col>
                        <Col>
                            <Form.Control
                                as="select"
                                name="newProtocol"
                                value={newProtocol}
                                onChange={inputHandler}
                            >
                                <option key="null" value="0">
                                    --
                                </option>
                                {canConfigs && canConfigs.map((canconfig) => {
                                    return (
                                        <option key={canconfig.Id} value={canconfig.Protocol}>
                                            {canconfig.Protocol}
                                        </option>
                                    )
                                })}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={onSubmit}>Submit</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

MassUpdateCANConfig.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    canConfigs: PropTypes.array.isRequired,
    onSubmitMassCANConfigUpdate: PropTypes.func.isRequired,
}
function mapStateToProps(state){
    return{
        canConfigs: state.canConfigs
    }
}
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(MassUpdateCANConfig)