import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  fetchResultsByCustomer,
  fetchSearchResults,
  fetchTachoDownloads,
  requestCardDownload,
} from "../../actions/tachoDownloadActions";
import { Card, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { fetchTachoDrivers } from "../../actions/tachoDriverActions";
import TachoDriverList from "./components/TachoDriverList";
import TachoSelectedDriverList from "./components/TachoSelectedDriverList";
import { fetchCustomers } from "../../actions/customerActions";
import * as tachoDownloadApi from "../../api/tachoDownloadApi";
import { apiCallError } from "../../actions/apiStatusActions";
import LazySearchBar from "../common/LazySearchBar";
import { handleError } from "../../api/apiUtils";

const TachoDriverPage = ({
  auth,
  customers,
  tachoDownloads,
  tachoDrivers,
  apiCallsInProgress
}) => {
  const dwnltype = 2;
  const dispatch = useDispatch();
  const tachoDownloadList = [...tachoDownloads];
  const tachoDriversList = [...tachoDrivers];
  let filters = 
    auth.user.IsReseller
      ? ["Name", "CardNumber"]
      : ["Name", "CardNumber"];
  const [resultCount, setResultCount] = useState(0);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    if (apiCallsInProgress === 0) {
      getCustomers(auth);

      tachoDownloadApi
        .GetTotalRowsCount(auth, dwnltype)
        .then((resultCount) => {
          setResultCount(resultCount);
        })
        .catch((error) => {
          apiCallError(error);
        });
      dispatch(fetchTachoDownloads(auth, dwnltype));
    }
  };

  const [names, setNames] = useState([]);

  const handleCheckAllBoxes = (value, uniqueDrivers) => {
    if (value) {
      let newNames = [];
      uniqueDrivers.forEach(driver => {
        newNames = [...newNames, driver.Name]
      });
      setNames(newNames)
    }
    else {
      setNames([])
    }
  }

  const handleCheckboxChange = (checkboxValue) => {
    if (names.includes(checkboxValue)) {
      setNames(
        names.filter((value) => value !== checkboxValue)
      );
    } else {
      setNames([...names, checkboxValue]);
    }
  };

  useEffect(() => {
    dispatch(fetchTachoDrivers(auth, names));
  }, [names]);

  const getCustomers = (auth) => {
    dispatch(fetchCustomers(auth));
  };

  const getSearchResults = (searchTerm, filter, customerId = null) => {
    dispatch(
      fetchSearchResults(auth, searchTerm, filter, dwnltype, customerId)
    );
  };

  const setSearchActive = () => {};

  const getTachoDownloadsByCustomer = (customer) => {
    dispatch(fetchResultsByCustomer(auth, customer, dwnltype));
  };

  const handleMassMemoryDownloadRequest = (cardnumber) => {
    dispatch(requestCardDownload(auth, cardnumber))
  }

  const downloadFile = (e) => {
    let fileName = e.target.getAttribute("filename");
    const baseUrl = process.env.API_URL + "/api/rtddata/";
    let uri = baseUrl + e.target.id;

    fetch(uri, {
      headers: { Authorization: auth.accessToken },
    })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const href = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => handleError(error));
  };

  return (
    <div className="driverOverview">
      <h2 className="title">Driver Overview</h2>
      <LazySearchBar
        filters={filters}
        refresh={refresh}
        customers={customers}
        resultCount={resultCount}
        getSearchResult={getSearchResults}
        setSearchActive={setSearchActive}
        apiCallsInProgress={apiCallsInProgress}
        getObjectByCustomer={getTachoDownloadsByCustomer}
      />
      <br />
      <Row>
        <Col >
          <Card >
            <TachoDriverList 
              tachoDownloads={tachoDownloadList}
              onCheckboxChange={handleCheckboxChange} 
              names={names}
              checkAllBoxes={handleCheckAllBoxes}
              handleDownloadRequest={handleMassMemoryDownloadRequest}
            />
          </Card>
        </Col>
        <Col md={4}>
          <Card >
          <TachoSelectedDriverList
              tachoDrivers={tachoDriversList}
              downloadFile={downloadFile}
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
};

TachoDriverPage.propTypes = {
  auth: PropTypes.object.isRequired,
  customers: PropTypes.array.isRequired,
  tachoDownloads: PropTypes.array.isRequired,
  tachoDrivers: PropTypes.array.isRequired,
  apiCallsInProgress: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    customers: state.customers,
    tachoDownloads: state.tachoDownloads,
    tachoDrivers: state.tachoDrivers,
    apiCallsInProgress: state.apiCallsInProgress,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TachoDriverPage);