import React, { useState } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import CreateAuthServer from "../tacho/components/CreateAuthServer";

const CustomerAuthServersTab = ({ auth, authServers }) => {
  const [show, setShow] = useState(false);

  return (
    <div>
      <br />
      <div className="create-object-div">
        {show && (
          <CreateAuthServer
            show={show}
            setShow={setShow}
            defaultCustomer={auth.user.CustomerId}
          />
        )}
      </div>
      <br />
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>Customer</th>
            <th>Name</th>
            <th>IP</th>
            <th>Device Port</th>
            <th>Port</th>
            <th>Username</th>
            <th>Password</th>
            <th>Status</th>
            <th>Endpoint</th>
          </tr>
        </thead>
        <tbody>
          {authServers.length === 0 ? (
            <tr>
              <td colSpan="14" style={{ textAlign: "center" }}>
                No Authentication Servers found
              </td>
            </tr>
          ) : (
            authServers.map((authServers) => {
              return (
                <tr key={authServers.Id} className="table-row">
                  {/*<td>*/}
                  {/*    <Link to={"/device/" + companyCards.Id}>{companyCards.IMEI}</Link>*/}
                  {/*</td>*/}
                  <td>{authServers.Customer}</td>
                  <td>{authServers.Name}</td>
                  <td>{authServers.IP}</td>
                  <td>{authServers.DevicePort}</td>
                  <td>{authServers.Port}</td>
                  <td>{authServers.Username}</td>
                  <td>{authServers.Password}</td>
                  <td>{authServers.Status}</td>
                  <td>{authServers.Endpoint}</td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

CustomerAuthServersTab.propTypes = {
  auth: propTypes.object.isRequired,
  authServers: propTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    authServers: state.customerDetails.authServers,
  };
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerAuthServersTab);
