import React, { useState } from "react";
import PropTypes from "prop-types";

//Compoments
import { SortedTable } from "../../../common/SortedTable";
import SortButton from "../../../common/SortButton";
import { TabContainer, Row, Col, Alert, Button } from "react-bootstrap";

import moment from "moment";

const DeviceTestList = ({ deviceTests }) => {
  const { list, requestSort, sortConfig } = SortedTable(deviceTests);
  const [show, setshow] = useState("");

  const Triggerextendedmenu = (id) => {
    if (id == show) {
      setshow("");
    } else {
      setshow(id);
    }
  };

  return (
    <>
      <table className="table table-hover devices-table">
        <thead className="thead-inverse">
          <tr>
            <th className="device-table-customer">
              Customer
              <SortButton
                name="Customer"
                requestSort={requestSort}
                sortConfig={sortConfig}
              />
            </th>
            <th>
              IMEI
              <SortButton
                name="IMEI"
                requestSort={requestSort}
                sortConfig={sortConfig}
              />
            </th>
            <th>
              User
              <SortButton
                name="User"
                requestSort={requestSort}
                sortConfig={sortConfig}
              />
            </th>
            <th>
              TimeStamp
              <SortButton
                name="TimeStamp"
                requestSort={requestSort}
                sortConfig={sortConfig}
              />
            </th>
            <th>
              Connection
              <SortButton
                name="ValidConnection"
                requestSort={requestSort}
                sortConfig={sortConfig}
              />
            </th>
            <th>
              DeviceTime
              <SortButton
                name="ValidTimeStamp"
                requestSort={requestSort}
                sortConfig={sortConfig}
              />
            </th>
            <th>
              Ignition
              <SortButton
                name="ValidIGNState"
                requestSort={requestSort}
                sortConfig={sortConfig}
              />
            </th>
            <th>
              Position
              <SortButton
                name="ValidPosition"
                requestSort={requestSort}
                sortConfig={sortConfig}
              />
            </th>
            <th>
              Odo
              <SortButton
                name="ValidOdo"
                requestSort={requestSort}
                sortConfig={sortConfig}
              />
            </th>
            <th>
              VIN
              <SortButton
                name="ValidVIN"
                requestSort={requestSort}
                sortConfig={sortConfig}
              />
            </th>
            <th>
              IButton
              <SortButton
                name="ValidIbutton"
                requestSort={requestSort}
                sortConfig={sortConfig}
              />
            </th>
            <th>
              PB Switch
              <SortButton
                name="ValidIO1State"
                requestSort={requestSort}
                sortConfig={sortConfig}
              />
            </th>
            <th>
              Test Result
              <SortButton
                name="TestResult"
                requestSort={requestSort}
                sortConfig={sortConfig}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {list.length === 0 ? (
            <tr>
              <td colSpan="9" style={{ textAlign: "center" }}>
                No Tests found
              </td>
            </tr>
          ) : (
            list.map((devicetest) => {
              return (
                <TabContainer key={devicetest.Id}>
                  <tr onClick={() => Triggerextendedmenu(devicetest.Id)}>
                    <td>{devicetest.CustomerName}</td>
                    <td>{devicetest.IMEI}</td>
                    <td>{devicetest.UserEmail}</td>
                    <td>
                      {moment(devicetest.TimeStamp).format(
                        "DD-MM-YYYY HH:mm:ss"
                      )}
                    </td>
                    <td>{devicetest.ValidConnection ? "Ok" : "Nok"}</td>
                    <td>{devicetest.ValidTimeStamp ? "Ok" : "Nok"}</td>
                    <td>{devicetest.ValidIGNState ? "Ok" : "Nok"}</td>
                    <td>{devicetest.ValidPosition ? "Ok" : "Nok"}</td>
                    <td>{devicetest.ValidOdo ? "Ok" : "Nok"}</td>
                    <td>{devicetest.ValidVIN ? "Ok" : "Nok"}</td>
                    <td>{devicetest.ValidIbutton ? "Ok" : "Nok"}</td>
                    <td>{devicetest.ValidIO1State ? "Ok" : "Nok"}</td>
                    <td>
                      {devicetest.TestResult ? (
                        <Button variant="success" size="sm">
                          TEST OK
                        </Button>
                      ) : (
                        <Button variant="danger" size="sm">
                          FAILED
                        </Button>
                      )}
                    </td>
                  </tr>
                  <tr key={devicetest.Id} hidden={show != devicetest.Id}>
                    <td></td>
                    <td colSpan="12">
                      <Row>
                        <Col>
                          <div>
                            <b>Position Data</b>
                          </div>
                          <div>LAT : {devicetest.LAT}</div>
                          <div>LON : {devicetest.LON}</div>
                          <div>SatCount : {devicetest.SatCount}</div>
                        </Col>
                        <Col>
                          <div>
                            <b>Can Data</b>
                          </div>
                          <div>Odo Value : {devicetest.Odo}</div>
                          <div>VIN : {devicetest.VIN}</div>
                        </Col>
                        <Col>
                          <div>
                            <b>Ibutton Data</b>
                          </div>
                          <div>
                            Ibutton Enabled :{" "}
                            {devicetest.IbuttonActive ? "Yes" : "No"}
                          </div>
                          <div>Ibutton : {devicetest.Ibutton}</div>
                          <div>
                            Valid : {devicetest.ValidIbutton ? "Yes" : "No"}
                          </div>
                        </Col>
                        <Col>
                          <div>
                            <b>Private Switch Data</b>
                          </div>
                          <div>
                            PrivateSwitch Enabled :{" "}
                            {devicetest.PrivateSwitchActive ? "Yes" : "No"}
                          </div>
                          <div>
                            Valid : {devicetest.ValidIO1State ? "Yes" : "No"}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <br />
                          {devicetest.TestResult ? (
                            <Alert variant="success">
                              <b>TEST OK</b>
                            </Alert>
                          ) : (
                            <Alert variant="danger">
                              <b>TEST FAILED</b>
                            </Alert>
                          )}
                          <div></div>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                </TabContainer>
              );
            })
          )}
        </tbody>
      </table>
    </>
  );
};

DeviceTestList.propTypes = {
  deviceTests: PropTypes.array.isRequired,
};

export default DeviceTestList;
