import { toast } from "react-toastify";
import { handleResponse, handleError } from "./apiUtils";

const baseUrl = process.env.API_URL + "/api/rtddata/";

export const getTachoDownloadById = (auth, id) => {
    return fetch(baseUrl + id, {
        headers: { Authorization: auth.accessToken },
    })
        .then(handleResponse)
        .catch(handleError);
};

export const GetTachoDownloads = (auth, dwnltype) => {
    return fetch(baseUrl + "?type=" + dwnltype, {
        headers: { Authorization: auth.accessToken },
    })
        .then(handleResponse)
        .catch(handleError);
}

export const GetSearchResults = (auth, searchTerm, filter, type, customerId = null) => {
    return customerId === "null"
        ? fetch(`${baseUrl}search/${searchTerm}/${filter}/?type=${type}`,
            {
                headers: { Authorization: auth.accessToken },
            })
            .then(handleResponse)
            .catch(handleError)
        : fetch(`${baseUrl}search/${searchTerm}/${filter}/${customerId}/?type=${type}`,
            {
                headers: { Authorization: auth.accessToken },
            })
            .then(handleResponse)
            .catch(handleError);
}

export const GetTachoByCustomer = (auth, customerId, type) => {
    return fetch(`${baseUrl}customer/${customerId}/?type=${type}`, {
        headers: { Authorization: auth.accessToken },
    })
        .then(handleResponse)
        .catch(handleError);
}

export const GetTotalRowsCount = (auth, type) => {
    return fetch(`${baseUrl}all/?type=${type}`, {
        headers: { Authorization: auth.accessToken },
    })
        .then(handleResponse)
        .catch(handleError);
}

export const requestMassMemoryDownload = (auth, imei) => {
    return fetch(`${baseUrl}requestMassDownload/${imei}`, {
        method: "POST",
        headers: { Authorization: auth.accessToken },
    })
        .then((response) => response.text())
        .then((text) => toast.success(text))
        .catch(handleError)
}

export const requestCardDownload = (auth, cardnumber) => {
    return fetch(`${baseUrl}requestCardDownload/${cardnumber}`, {
        method: "POST",
        headers: { Authorization: auth.accessToken },
    })
        .then((response) => response.text())
        .then((text) => toast.success(text))
        .catch(handleError)
}