import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Redux
import { connect, useDispatch } from "react-redux";
import { fetchRoles } from "../../actions/roleActions";
import { getAccount } from "../../actions/accountActions";

// Components
import ProfileTab from "./ProfileTab";
import CustomerDetailsTab from "./CustomerDetailsTab";
import UsersTab from "./UsersTab";
import Spinner from "../common/Spinner";

// Styling
import { Card, Tabs, Tab } from "react-bootstrap";

const AccountPage = ({ auth, customer }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAccount(auth));
    dispatch(fetchRoles(auth));
  }, []);

  return (
    <div>
      <Card className="card-style device">
        <Card.Header>
          <h1 className="title">Account</h1>
        </Card.Header>
        <Card.Body>
          {customer === null ? (
            <Spinner />
          ) : (
            <Tabs>
              <Tab eventKey="user" title="Profile">
                <ProfileTab />
              </Tab>
              <Tab eventKey="customer" title="Customer">
                <CustomerDetailsTab />
              </Tab>
              <Tab eventKey="users" title="Users">
                <UsersTab />
              </Tab>
            </Tabs>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

AccountPage.propTypes = {
  auth: PropTypes.object.isRequired,
  customer: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    customer: state.account.customer,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);