import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useDispatch, connect } from "react-redux";
import { fetchCustomTestData } from "../../actions/customTestDataActions";

import SearchBar from "../common/SearchBar";
import { Card } from "react-bootstrap";
import Spinner from "../common/Spinner";
import CustomTestDataList from "./components/CustomTestDataList";

const CustomTestDataPage = ({ auth, customTestData, apiCallsInProgress }) => {
  const dispatch = useDispatch();
  const customTestDataList = [...customTestData];
  const filters = ["Customer", "FileName"];
  const [searchResult, setSearchResult] = useState([]);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    dispatch(fetchCustomTestData(auth));
  };

  return (
    <div className="customTestData">
      <h2 className="title">Custom Test Data</h2>
      <SearchBar
        filters={filters}
        setSearchResult={setSearchResult}
        searchResult={searchResult}
        all={customTestDataList}
        refresh={refresh}
      />
      {apiCallsInProgress > 0 && customTestDataList.length ? (
        <Spinner />
      ) : (
        <Card>
          {searchResult.length === customTestDataList.length ? (
            <CustomTestDataList customTestData={customTestDataList} />
          ) : (
            <CustomTestDataList customTestData={customTestData} />
          )}
        </Card>
      )}
    </div>
  );
};

CustomTestDataPage.propTypes = {
  auth: PropTypes.object.isRequired,
  customTestData: PropTypes.array.isRequired,
  apiCallsInProgress: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    customTestData: state.customTestData,
    apiCallsInProgress: state.apiCallsInProgress,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomTestDataPage);
