import { toast } from "react-toastify";

export async function handleResponse(response) {
  if (response.ok) return response.json();
  if (response.status === 400) {
    try {
      const errorMessage = await response.json();

      if (errorMessage.errors) {
        for (const property in errorMessage.errors) {
          toast.error("API call failed. " + errorMessage.errors[property]);
        }
      }

      if (errorMessage.message != null) {
        throw new Error(errorMessage.message);
      } else {
        const error = await response.text();
        throw new Error(error);
      }
    } catch (e) {
      throw new Error("Failed to parse JSON: " + e.message);
    }
  }
  

  throw new Error("Network response was not ok.");
}

export function handleError(error) {
  if (error.message == "No Results Found!") {
    console.log("API call failed. " + error);
    return [];
  }

  if (error instanceof SyntaxError) {
    toast.error("API call failed. Invalid JSON: " + error.message);
    console.log("API call failed. Invalid JSON: " + error.message);
  } else {
    toast.error("API call failed. " + error.message);
    console.log("API call failed. " + error.message);
  }

  throw error;
}

export function authHeader() {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.accessToken) {
    return { Authorization: user.accessToken };
  } else {
    return {};
  }
}
