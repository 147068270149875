import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/api/customtestdata/";

export const getCustomTestDataById = (auth, id) => {
  return fetch(baseUrl + id, {
    headers: { Authorization: auth.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
};

export function getCustomTestData(auth) {
  return fetch(baseUrl, {
    headers: { Authorization: auth.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
}

export const updateCustomTestData = (auth, id, customTestData) => {
  return fetch(baseUrl + id, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      Authorization: auth.accessToken,
    },
    body: JSON.stringify(customTestData),
  })
    .then(handleResponse)
    .catch(handleError);
};

export const saveCustomTestData = (auth, customTestData) => {
  return fetch(baseUrl, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: auth.accessToken,
    },
    body: JSON.stringify(customTestData),
  })
    .then(handleResponse)
    .catch(handleError);
};

export const removeCustomTestData = (auth, id) => {
  return fetch(baseUrl + id, {
    method: "DELETE",
    headers: {
      Authorization: auth.accessToken,
    },
  })
    .then(handleResponse)
    .catch(handleError);
};

// export const getCustomTestDataFile = (auth, customTestData) => {
//     let uri = baseUrl + customTestData.target.Id;
//     return fetch(uri,
//         {headers: {
//             Authorization: auth.accessToken,
//             },
//         })
//         .then(res => {
//             return res.blob();
//         })
//         .then(blob => {
//             const href = window.URL.createObjectURL(blob);
//             const link = document.createElement('a');
//             link.href = href;
//             link.setAttribute('download', customTestData.target.FileName);
//             document.body.appendChild(link);
//             link.click();
//             document.body.removeChild(link)
//         })
//         .then(handleResponse)
//         .then(handleError);
// };
