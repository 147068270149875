import React from "react";
import PropTypes from "prop-types";

import {Modal, Form, Button, Alert} from "react-bootstrap"
import {ErrorMessage, Field, Formik, useFormik} from "formik";
import * as Yup from 'yup';

const ChangeCustomerOnCompanyCard = ({
                                         customers,
                                         selectedCompanyCard,
                                         setShow,
                                         show,
                                         onSubmit,
                                     }) => {
    const handleClose = () => setShow(false);

    const initialValues = {
        CardNumber: selectedCompanyCard.CardNumber,
        CompanyName: selectedCompanyCard.CompanyName,
        ValidUntil: selectedCompanyCard.ValidUntil,
        Customer: selectedCompanyCard.Customer,
        CustomerId: selectedCompanyCard.CustomerId,
        AuthServerId: selectedCompanyCard.AuthServerId,
        Devices: selectedCompanyCard.Devices
    }

    const validationSchema = Yup.object().shape({
        CardNumber: Yup.string()
            .required("CardNumber is required"),
        CompanyName: Yup.string()
            .required("CompanyName is required"),
        ValidUntil: Yup.string()
            .required("ValidUntil is required"),
        CustomerId: Yup.string()
            .required("Customer is required"),
        AuthServerId: Yup.string()
            .required("AuthServerId is required"),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit
    });

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>Change CompanyCard</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({values, errors, touched, handleSubmit, handleChange, handleBlur}) => (
                    <Form onSubmit={handleSubmit}>
                        <Modal.Body>
                            <Form.Label>Company Card</Form.Label>
                            <Field className="form-control" name="CardNumber" disabled
                                   value={values.CardNumber.toString()}/>

                            <Form.Label>Company Name</Form.Label>
                            <Field className="form-control" name="CompanyName" disabled value={values.CompanyName}/>

                            <Form.Label>Current Customer</Form.Label>
                            <Field className="form-control" name="Customer" disabled value={values.Customer}/>
                            {selectedCompanyCard.Devices.length == 0 ? (
                                <>
                                    <Form.Label>New Customer</Form.Label>
                                    <Field
                                        as="select"
                                        name="CustomerId"
                                        values={values.CustomerId}
                                        className={`form-control ${touched.CustomerId && errors.CustomerId ? "is-invalid" : ""}`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                        <option value="" label="Select a Customer">
                                            Select a Customer{" "}
                                        </option>
                                        {customers.map((customer) => {
                                            return (
                                                <option key={customer.Id} value={customer.Id}>
                                                    {customer.Name}
                                                </option>
                                            );
                                        })}
                                    </Field>
                                    <ErrorMessage name="CustomerId" component="div" className="invalid-feedback"/>
                                </>
                            ) : (
                                <>
                                    <br></br>
                                    <Alert variant="danger">
                                        <Alert.Heading>CompanyCard is in use</Alert.Heading>
                                        <p>
                                            You can not change the customer on this CompanyCard
                                        </p>
                                    </Alert>
                                </>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" type="submit" disabled={!formik.isValid}>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}


ChangeCustomerOnCompanyCard.propTypes = {
    customers: PropTypes.array.isRequired,
    selectedCompanyCard: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default ChangeCustomerOnCompanyCard;