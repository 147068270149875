import React, {  useState } from "react";
import PropTypes from "prop-types";

import { connect, useDispatch } from "react-redux";
import EditIcon from "../../common/icons/pen-to-square-solid.svg";

import UpdateEndpoint from "./UpdateEndpoint";
import {
    fetchEndpoints,
    // fetchEndpointById,
    deleteEndpoint,
    updateEndpoint,
} from "../../../actions/endpointActions";
import { SortedTable } from "../../common/SortedTable";
import SortButton from "../../common/SortButton";
import { Table, Button } from "react-bootstrap";
import Confirmation from "../../common/Confirmation";
import DeleteIcon from "../../common/icons/trash-can-solid.svg";

// import { fetchEndpointById } from "../../../actions/customerActions";

const EndpointList = ({ auth, endpoints, customers, protocols, messageTypes, }) => {


    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const { list, requestSort, sortConfig } = SortedTable(endpoints);
    const [selectedEndpoint, setSelectedEndpoint] = useState(null);

    // useEffect(() => {
    //     console.log("Endpoints: ", endpoints);
    //     console.log("Customers: ", customers);
    //     console.log("Protocols: ", protocols);
    //     console.log("Message Types: ", messageTypes);
    // }, [endpoints, customers, protocols, messageTypes]);


    // useEffect(() => {
    //     console.table(endpoints);
    // }, [endpoints]);

    const handleclose = () => {
        setShow(false);
        dispatch(fetchEndpoints(auth));
    };

    const selectEndpoint = (e) => {
        setSelectedEndpoint(
            endpoints.find((endpoint) => {
                return endpoint.Id === e;
            })
        );
    };

    const updateEndpointHandler = (endpointupdate) => {
        dispatch(updateEndpoint(auth, endpointupdate.Id, endpointupdate));
        handleclose();
    };

    const removeEndpointHandler = (e) => {
        const endpoint = endpoints.find((item) => item.Id === e);
        dispatch(deleteEndpoint(auth, e, endpoint.MessageType));
        setShow(false);
    };

    const showModalHandler = (e) => {
        setShow(true);
        selectEndpoint(e);
    };

    return (
        <>
            <Table striped>
                <thead className="thead-inverse">
                    <tr>
                        <th>
                            Customer
                            <SortButton
                                name="Customer"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Name
                            <SortButton
                                name="Name"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            IP
                            <SortButton
                                name="IP"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Port
                            <SortButton
                                name="Port"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Protocol
                            <SortButton
                                name="Protocol"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Username
                            <SortButton
                                name="Username"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Password
                            <SortButton
                                name="Password"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            API_Key
                            <SortButton
                                name="API_KEY"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Authorization
                            <SortButton
                                name="Auth"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Status
                            <SortButton
                                name="Status"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Devices
                            <SortButton
                                name="amountdevices"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {list.length === 0 ? (
                        <tr>
                            <td colSpan="15" style={{ textAlign: "center" }}>
                                No endpoints found
                            </td>
                        </tr>
                    ) : (
                        list.map((endpoint) => {
                            // console.log('Endpoint ${index}:', endpoint);
                            const isViewer = auth.user.Role === "Viewer"

                            return (
                                <tr className="table-row" key={endpoint.Id}>
                                    <td>{endpoint.Customer}</td>
                                    <td>{endpoint.Name}</td>
                                    <td>{endpoint.IP}</td>
                                    <td>{endpoint.Port}</td>
                                    <td>{endpoint.Protocols[0].Protocol}</td>
                                    <td>{endpoint.Username}</td>
                                    <td>{endpoint.Password}</td>
                                    <td>{endpoint.API_KEY}</td>
                                    <td>{endpoint.Auth}</td>
                                    <td>{endpoint.Status}</td>
                                    <td>{endpoint.Amountdevices}</td>
                                    <td>
                                        {!isViewer && (
                                            <Button className="shadow-sm border-secondary" variant="light" onClick={() => {
                                                showModalHandler(endpoint.Id)
                                            }}>
                                                <img
                                                    src={EditIcon}
                                                    alt="Edit"
                                                />
                                            </Button>
                                        )}
                                    </td>
                                    <td>
                                        {!isViewer && (
                                            <Button className="shadow-sm border-secondary" variant="light">
                                                <Confirmation
                                                    buttonText={
                                                        <img
                                                            src={DeleteIcon}
                                                            alt="Delete"
                                                        />
                                                    }
                                                    description={`Are you sure you want to remove this endpoint?`}
                                                    onConfirm={() => removeEndpointHandler(endpoint.Id)}
                                                />
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </Table>
            {selectedEndpoint && (
                <UpdateEndpoint
                    show={show}
                    setShow={setShow}
                    endpoint={selectedEndpoint}
                    customers={customers}
                    protocols={protocols}
                    messageTypes={messageTypes}
                    updateEndpointHandler={updateEndpointHandler}
                />
            )}
        </>
    );
};

EndpointList.propTypes = {
    auth: PropTypes.object.isRequired,
    endpoints: PropTypes.array.isRequired,
    customers: PropTypes.array.isRequired,
    protocols: PropTypes.array.isRequired,
    messageTypes: PropTypes.array.isRequired
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        endpoints: state.endpoints,
        customers: state.customers,
        protocols: state.protocols,
        messageTypes: state.messageTypes,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EndpointList);
