const baseUrl = process.env.API_URL + "/api/devicetestsettings/";
import {handleError, handleResponse} from "./apiUtils";

export function getDeviceTestSettings(auth, imei) {
    return fetch(baseUrl + imei, {
        headers: { Authorization: auth.accessToken },
    })
        .then(handleResponse)
        .catch(handleError);
}

export function GetAllDeviceTestSettings(auth) {
    return fetch(baseUrl, {
        headers: { Authorization: auth.accessToken },
    })
        .then(handleResponse)
        .catch(handleError);
}

export function saveDeviceTest(auth, deviceTestSettings) {
    return fetch(baseUrl, {
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: auth.accessToken,
        },
        body: JSON.stringify(deviceTestSettings),
    })
        .then(handleResponse)
        .catch(handleError);
}

export function updateDeviceTestSettings(auth, id, deviceTestSettings) {
    return fetch(baseUrl + id, {
        method: "PUT",
        headers: {
            "content-type": "application/json",
            Authorization: auth.accessToken,
        },
        body: JSON.stringify(deviceTestSettings),
    })
        .then(handleResponse)
        .catch(handleError);
}

export function setDeviceTestActive(auth, imei) {
    return fetch(baseUrl + "setactive/" + imei, {
        headers: { Authorization: auth.accessToken },
    })
        .then(handleResponse)
        .catch(handleError);
}

export function setDeviceTestInActive(auth, imei) {
    return fetch(baseUrl + "setinactive/" + imei, {
        headers: { Authorization: auth.accessToken },
    })
        .then(handleResponse)
        .catch(handleError);
}