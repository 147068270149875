import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import SortButton from "../../common/SortButton";
import { SortedTable } from "../../common/SortedTable";
import moment from "moment";
import { useContextMenu } from "react-contexify";

const TachoDriverList = ({
  tachoDownloads,
  onCheckboxChange,
  names,
  checkAllBoxes,
  handleDownloadRequest
}) => {
  const { show } = useContextMenu({ id: "TachoDriverList" });
  const { list, requestSort, sortConfig } = SortedTable(tachoDownloads);

  const uniqueDrivers = Object.values(
    list.reduce((result, item) => {
      if (!(item.Name in result) || new Date(item.TimeStampTo) > new Date(result[item.Name].TimeStampTo)) {
        result[item.Name] = item;
      }
      return result;
    }, {})
  );

  const handleCheckAllBoxes = (checkboxvalue) => {
    checkAllBoxes(checkboxvalue, uniqueDrivers)
  }

  const handleCheckboxToggle = (checkboxValue) => {
    onCheckboxChange(checkboxValue);
  };

  const requestDownload = (cardnumber) => {
    console.log("finna request download")
    handleDownloadRequest(cardnumber)
  }
  return (
    <div>
      <div className="tachoDriverList rounded border border-light-subtle">
        <table className="table table-striped table-hover" style={{ fontSize: '14px' }}>
          <thead>
            <tr>
              <th>
                <input
                  style={{ width: '16px', height: '16px' }}
                  type="checkbox"
                  className="checkbox-table-style m-0"
                  checked={uniqueDrivers.every(driver => names.includes(driver.Name))}
                  onChange={(e) => handleCheckAllBoxes(e.target.checked)}
                />
              </th>
              <th>
                Name
                <SortButton
                  name="DriverName"
                  requestSort={requestSort}
                  sortConfig={sortConfig}
                />
              </th>
              <th>
                Card Number
                <SortButton
                  name="CardNumber"
                  requestSort={requestSort}
                  sortConfig={sortConfig}
                />
              </th>
              <th>
                Last Event
                <SortButton
                  name="LastEvent"
                  requestSort={requestSort}
                  sortConfig={sortConfig}
                />
              </th>
              <th>
                Data Until
                <SortButton
                  name="LastData"
                  requestSort={requestSort}
                  sortConfig={sortConfig}
                />
              </th>
              <th>
                Next RDL
                <SortButton
                  name="NextRDL"
                  requestSort={requestSort}
                  sortConfig={sortConfig}
                />
              </th>
              <th>
                Days Left (Law)
                <SortButton
                  name="Law"
                  requestSort={requestSort}
                  sortConfig={sortConfig}
                />
              </th>
              <th>

              </th>
            </tr>
          </thead>
          <tbody>
            {list === undefined ? (
              <tr>
                <td colSpan="15" className="text-center">
                  No tacho downloads found
                </td>
              </tr>
            ) : (
              uniqueDrivers.map((tachoDownload) => {
                const CurrentDate = moment();
                const NextDate = moment(tachoDownload.TimeStampTo).add(30, 'd');
                const DaysLeft = NextDate.diff(CurrentDate, 'days');
                {/* const DaysLeft = CalculateDate / (60 * 60 * 24 * 1000); */ }

                return (
                  <tr
                    onContextMenu={(e) => {
                      show({ event: e, props: { tachoDownload: tachoDownload } });
                    }}
                    className="table-row"
                    key={tachoDownload.Name}
                    id={tachoDownload.id}
                  >
                    <td>
                      <input
                        type="checkbox"
                        style={{ width: '17px', height: '17px' }}
                        className="checkbox-table-style m-0"
                        name="check"
                        checked={names.includes(tachoDownload.Name)}
                        value={tachoDownload.Name}
                        onChange={() => handleCheckboxToggle(tachoDownload.Name)}
                      />
                    </td>
                    <td>{tachoDownload.Name}</td>
                    <td>{tachoDownload.CardNumber}</td>
                    <td>{moment().format("DD-MM-YYYY HH:mm")}</td>
                    <td>
                      {tachoDownload.TimeStampTo !== "1990-01-01T00:00:00Z" ? (
                        <>
                          {moment(tachoDownload.TimeStampTo).format("DD-MM-YYYY HH:mm")}
                        </>
                      ) : (
                        <>Unknown Period</>
                      )}
                    </td>
                    <td>
                      {tachoDownload.TimeStampTo !== "1990-01-01T00:00:00Z" ? (
                        <>
                          {moment(tachoDownload.TimeStampTo).add(14, 'd').format("DD-MM-YYYY")}
                        </>
                      ) : (
                        <>Unknown Period</>
                      )}
                    </td>
                    <td>
                      {tachoDownload.TimeStampTo !== "1990-01-01T00:00:00Z" ? (
                        <>
                          {DaysLeft.toFixed(0) < 0 ?
                            <p style={{ color: 'red' }}>
                              {DaysLeft.toFixed(0)}
                            </p> : <p>
                              {DaysLeft.toFixed(0)}
                            </p>}
                        </>
                      ) : (
                        <>Unknown</>
                      )}
                    </td>
                    <td>
                      <Button
                        className="shadow-sm border-secondary"
                        variant="light"
                        size="sm"
                        onClick={() => requestDownload(tachoDownload.CardNumber)}>
                        <span>RDL</span>
                      </Button>
                    </td>

                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

TachoDriverList.propTypes = {
  tachoDownloads: PropTypes.array.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  checkAllBoxes: PropTypes.func.isRequired,
  names: PropTypes.array.isRequired,
  handleDownloadRequest: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    tachoDownloads: state.tachoDownloads,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TachoDriverList)
