import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";

// Redux
import {connect} from "react-redux";

// Styling
import {Form, Button, Modal, Row} from "react-bootstrap";

const UpdateUserModal = ({
                             show,
                             setShow,
                             updatedUser,
                             setUpdatedUser,
                             roles,
                             updateUserHandler,
                         }) => {
    const handleClose = () => setShow(false);
    const [validForm, setValidForm] = useState(false);

    const inputHandler = (e) => {
        let value = e.target.value;
        setUpdatedUser({
            ...updatedUser,
            [e.target.name]: value,
        });
    };

    useEffect(() => {
        let validEmail;
        let validPassword;
        let validRole;

        validEmail = updatedUser.email.match(
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        );
        validPassword = updatedUser.password.length > 6;
        validPassword = updatedUser.password === updatedUser.passwordConf;
        validRole = updatedUser.roleId !== "null";

        if (validEmail && validPassword && validRole) {
            setValidForm(true);
        } else {
            setValidForm(false);
        }
    }, [updatedUser]);

    return (
        <div className="update-user-modal">
            <Modal show={show} onHide={handleClose} centered className="text-center">
                <Modal.Header>
                    <Modal.Title>Update User</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Row>
                        <Row>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="text"
                                name="email"
                                value={updatedUser.email}
                                placeholder="Enter email"
                                onChange={inputHandler}
                            />
                        </Row>
                        <Row>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="password"
                                value={updatedUser.password}
                                placeholder="Leave blank to keep the same"
                                onChange={inputHandler}
                            />
                        </Row>
                        <Row>
                            <Form.Label>Password Confirmation</Form.Label>
                            <Form.Control
                                type="password"
                                name="passwordConf"
                                value={updatedUser.passwordConf}
                                placeholder="Leave blank to keep the same"
                                onChange={inputHandler}
                            />
                            {updatedUser.password !== updatedUser.passwordConf && (
                                <p className="text-invalid">
                                    <small> Passwords dont match.</small>
                                </p>
                            )}
                        </Row>
                        <Row>
                            <Form.Label>Role</Form.Label>
                            <Form.Control
                                as="select"
                                name="roleId"
                                value={updatedUser.roleId}
                                onChange={inputHandler}
                            >
                                {roles.map((role) => {
                                    return <option key={role.Id}>{role.Name}</option>;
                                })}
                            </Form.Control>
                        </Row>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                    <Button
                        variant="primary"
                        disabled={!validForm}
                        onClick={updateUserHandler}
                    >
                        Save User
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

UpdateUserModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    updatedUser: PropTypes.object.isRequired,
    setUpdatedUser: PropTypes.func.isRequired,
    roles: PropTypes.array.isRequired,
    updateUserHandler: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        users: state.users,
        roles: state.roles,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUserModal);
