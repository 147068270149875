import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

// Redux
import {useDispatch, connect} from "react-redux";
import {fetchCustomers, getCustomerById} from "../../actions/customerActions";

// Components
import Spinner from "../common/Spinner";
import CustomerDevicesTab from "./CustomerDevicesTab";
import CustomerUsersTab from "./CustomerUsersTab";
import CustomerCompanyCardsTab from "./CustomerCompanyCardsTab";

// Styling
import {Card, Tabs, Tab} from "react-bootstrap";
import "./CustomerInfo.css";
import CustomerAuthServersTab from "./CustomerAuthServersTab";

const CustomerInfoPage = ({auth, customer, customers, isLoading, features}) => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const [key, setKey] = useState("devices");

    const refresh = () => {
        if (!customers.length) {
            dispatch(fetchCustomers(auth,id));
        }

        dispatch(getCustomerById(auth,id));
    }

    useEffect(() => {
        refresh();
    }, []);

    return (
        <>
            {customer === null || isLoading ? (
                <Spinner/>
            ) : (
                <Card className="card-style device">
                    <Card.Header>
                        <h1 className="title">{customer.Name}</h1>
                    </Card.Header>

                    <Card.Body>
                        <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
                            <Tab eventKey="devices" title="Devices">
                                {key == "devices" && <CustomerDevicesTab/>}
                            </Tab>
                            <Tab eventKey="users" title="Users">
                                {key == "users" && <CustomerUsersTab/>}
                            </Tab>
                            {features.filter((e) => e === "Tacho Download").length == 1 && (
                                <Tab eventKey="companyCards" title="Company Cards"
                                     disabled={customer.Features.filter((e) => e === "Tacho Download").length == 0}
                                >
                                    {key == "companyCards" && <CustomerCompanyCardsTab/>}
                                </Tab>
                            )}
                            {features.filter((e) => e === "Tacho Download").length == 1 && (
                                <Tab eventKey="authServers" title="Authentication Servers"
                                     disabled={customer.Features.filter((e) => e === "Tacho Download").length == 0}
                                >
                                    {key == "authServers" && <CustomerAuthServersTab/>}
                                </Tab>
                            )}
                        </Tabs>
                    </Card.Body>
                </Card>
            )
            }
        </>
    );
};

CustomerInfoPage.propTypes = {
    auth: PropTypes.object.isRequired,
    customers: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    customer: PropTypes.object,
    features: PropTypes.array.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.bool.isRequired,
        }),
    }),
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        customers: state.customers,
        customer: state.customerDetails.customer,
        isLoading: state.customerDetails.isLoading,
        features: state.auth.user.Features,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerInfoPage);
