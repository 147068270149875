import * as types from "./actionsTypes";
import * as userApi from "../api/usersApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import { toast } from "react-toastify";

export const saveUser = (auth, user) => async (dispatch) => {
  dispatch(beginApiCall());
  userApi
    .saveUser(auth, user)
    .then((res) => {
      dispatch({
        type: types.SAVE_CUSTOMER_USER_SUCCESS,
        payload: {
          user: res,
        },
      });
      toast.success("User " + res.Email + " succesfully created");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const updateUser = (auth, id, user) => async (dispatch) => {
  dispatch(beginApiCall());

  userApi
    .updateUser(auth, id, user)
    .then((res) => {
      dispatch({
        type: types.UPDATE_CUSTOMER_USER_SUCCESS,
        payload: {
          user: res,
        },
      });
      toast.success("User " + res.Email + " succesfully updated!");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const removeUser = (auth, id) => async (dispatch) => {
  dispatch(beginApiCall());

  userApi
    .removeUser(auth, id)
    .then(() => {
      dispatch({
        type: types.REMOVE_CUSTOMER_USER_SUCCESS,
        payload: {
          user: id,
        },
      });
      toast.warning("User succesfully removed!");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};
