import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage, Field, Formik, useFormik } from "formik";
import * as Yup from 'yup';

// Redux
import { connect } from "react-redux";

import Countries from "../../../util/Countries";

import { Modal, Form, Button } from "react-bootstrap";

const UpdateCustomerModal = ({
    auth,
    show,
    setShow,
    customer,
    customerDetails,
    features,
    onSubmit,
    rtdIntegrations,
}) => {
    const handleClose = () => setShow(false);

    const initialValues = customerDetails != null ? {
        name: customerDetails.Name,
        country: customerDetails.Country,
        isReseller: customerDetails.IsReseller,
        resellerId: customerDetails.Reseller == null ? "" : customerDetails.Reseller,
        features: [...features].filter((x) => customerDetails.Features.includes(x.Name)).map((item) => item.Id),
        RTDInterval: customerDetails.RTDInterval,
        RTDIntegration: customerDetails.RTDIntegration ?? false,
        RTDIntegrationId: customerDetails.RTDIntegrationId === 0 ? "" : customerDetails.RTDIntegrationId,
        RTDIntCustId: customerDetails.RTDIntCustId == null ? "" : customerDetails.RTDIntCustId,
    } : {
        name: customer.Name,
        country: customer.Country,
        isReseller: customer.IsReseller,
        resellerId: customer.Reseller == null ? "" : customer.Reseller,
        features: [],
        RTDInterval: customer.RTDInterval,
        RTDIntegration: customer.RTDIntegration ?? false,
        RTDIntegrationId: customer.RTDIntegrationId === 0 ? "" : customer.RTDIntegrationId,
        RTDIntCustId: customer.RTDIntCustId == null ? "" : customer.RTDIntCustId,
    }

    const validationSchema = Yup.object({
        name: Yup.string()
            .min(3, 'Must be at least 3 characters')
            .max(50, 'Must be 50 characters or less')
            .required('Required'),
        country: Yup.string()
            .oneOf(
                Countries.map((country) => country.name),
                "Invalid Country"
            )
            .required("Required"),
        isReseller: Yup.boolean()
            .required('Required'),
        features: Yup.array()
            .required('Required'),
        RTDInterval: Yup.number()
            .min(2, 'Must be at least 2 days')
            .max(59, 'Must be 59 days or less')
            .required('Required'),
        RTDIntegration: Yup.boolean(),
        RTDIntegrationId: Yup.string()
            .when("RTDIntegration", {
                is: (value) => value == true,
                then: (schema) => (schema).required("Required")
            }),
        RTDIntCustId: Yup.string()
            .when("RTDIntegration", {
                is: (value) => value == true,
                then: () => Yup.string().required("Required")
            })
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: onSubmit,
        enableReinitialize: true,
    });

    return (
        <div>
            <Modal show={show} onHide={handleClose} centered className="text-center">
                <Modal.Header closeButton>
                    <Modal.Title>Update Customer</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    enableReinitialize={true}
                >
                    {({ errors, touched, values, handleSubmit, setFieldValue }) => (
                        <Form onSubmit={handleSubmit}>
                            <Modal.Body className='text-left'>
                                <Form.Label>Name</Form.Label>
                                <Field
                                    name="name"
                                    type="text"
                                    value={values.name}
                                    className={`form-control ${touched.name && errors.name ? "is-invalid" : ""}`}
                                />
                                <ErrorMessage name="name" component="div" className="invalid-feedback" />

                                <Form.Label>Country</Form.Label>
                                <Field
                                    as="select"
                                    name="country"
                                    value={values.country}
                                    className={`form-control ${touched.country && errors.country ? "is-invalid" : ""}`}
                                >
                                    {Countries.map((country) => (
                                        <option key={country.code} value={country.name}>
                                            {country.name}
                                        </option>
                                    ))}
                                </Field>
                                <ErrorMessage name="country" component="div" className="invalid-feedback" />
                                {auth.user.Role === "Admin" && (
                                    <>
                                        <Form.Label>Reseller</Form.Label>
                                        <Field
                                            checked={values.isReseller}
                                            type="checkbox"
                                            name="isReseller"
                                            disabled={!auth.user.roles == "Admin"}
                                            className={errors.isReseller && touched.isReseller ? "form-check-input is-invalid" : "form-check-input"}
                                        />
                                        <ErrorMessage name="isReseller" component="div" className="invalid-feedback" />
                                        <br></br>
                                    </>
                                )}
                                <Form.Label>Features</Form.Label>
                                <Field
                                    as="select"
                                    multiple
                                    name="features"
                                    value={values.features}
                                    className={`form-control ${touched.features && errors.features ? "is-invalid" : ""}`}
                                >
                                    {features.map((feature) => {
                                        return (
                                            <option key={feature.Id} value={feature.Id}>
                                                {feature.Name}
                                            </option>
                                        );
                                    })}
                                </Field>
                                <ErrorMessage name="features" component="div" className="invalid-feedback" />

                                <Form.Label>RTD Interval</Form.Label>
                                <Field
                                    name="RTDInterval"
                                    type="number"
                                    value={values.RTDInterval}
                                    className={`form-control ${touched.RTDInterval && errors.RTDInterval ? "is-invalid" : ""}`}
                                />
                                <ErrorMessage name="RTDInterval" component="div" className="invalid-feedback" />


                                <Form.Label>RTD Integration</Form.Label>
                                <Field
                                    type="checkbox"
                                    name="RTDIntegration"
                                    checked={!!values.RTDIntegration}
                                    onChange={(e) => {
                                        console.log(e.target.checked);
                                        setFieldValue("RTDIntegration", e.target.checked);
                                    }}
                                    className={`form-check-input ${touched.RTDIntegration && errors.RTDIntegration ? "is-invalid" : ""}`}
                                />
                                <ErrorMessage name="RTDIntegration" component="div" className="invalid-feedback" />
                                <br></br>
                                {values.RTDIntegration && (
                                    <>
                                        <Form.Label>RTD Integration Types</Form.Label>
                                        <Field
                                            as="select"
                                            name="RTDIntegrationId"
                                            value={values.RTDIntegrationId}
                                            className={errors.RTDIntegrationId && touched.RTDIntegrationId ? "form-control is-invalid" : "form-control"}
                                        >
                                            <option>
                                                --
                                            </option>
                                            {rtdIntegrations.map((integration) => {
                                                return (
                                                    <option key={integration.Id} value={integration.Id}>
                                                        {integration.Name}
                                                    </option>
                                                );
                                            })}
                                        </Field>
                                        <ErrorMessage name="RTDIntegrationId" component="div"
                                            className="invalid-feedback" />

                                        <Form.Label>RTD Customer Integration</Form.Label>
                                        <Field
                                            name="RTDIntCustId"
                                            type="text"
                                            value={values.RTDIntCustId}
                                            className={`form-control ${touched.RTDIntCustId && errors.RTDIntCustId ? "is-invalid" : ""}`}
                                        />
                                        <ErrorMessage name="RTDIntCustId" component="div" className="invalid-feedback" />
                                    </>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" type="submit" disabled={!formik.isValid}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    );
};

UpdateCustomerModal.propTypes = {
    auth: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    customer: PropTypes.object.isRequired,
    customerDetails: PropTypes.object,
    features: PropTypes.array.isRequired,
    rtdIntegrations: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        customers: state.customers,
        features: state.features,
        customerDetails: state.customerDetails.customer
        // customer: state.customerDetails.customer,
    };
}

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateCustomerModal);
