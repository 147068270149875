const baseUrl = process.env.API_URL + "/api/auth/";
import { handleResponse, handleError } from "./apiUtils";

export const login = async function (credentials) {
  return fetch(baseUrl + "login", {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then(handleResponse)
    .then((response) => {
      if (response.AccessToken) {
        localStorage.setItem("telematiqs-auth", JSON.stringify(response.Auth));
        localStorage.setItem(
          "telematiqs-token",
          JSON.stringify(response.AccessToken)
        );
      }
      return response;
    })
    .catch(handleError);
};

export const getAccount = (auth, id) => {
  return fetch(baseUrl + "account/" + id, {
    headers: { Authorization: auth.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
};

export default {
  login,
  getAccount,
};
