import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const tachoDriverReducer = (state = initialState.tachoDrivers, action) => {
  switch (action.type) {
    case types.LOAD_TACHO_DRIVER_SUCCESS:
      return action.payload.tachoDrivers;
    default:
      return state;
  }
};

export default tachoDriverReducer;