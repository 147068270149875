import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const logsReducer = (state = initialState.logs, action) => {
  switch (action.type) {
    case types.LOAD_FIRST_BATCH_LOGS_SUCCESS:
      return action.payload.logs;
    case types.LOAD_BATCH_LOGS_SUCCESS: {
      let result = [...state];
      
      action.payload.logs.map((item) => {
        result = [...result, item];
      })
      
      return result;
    }
    case types.LOAD_SEARCHED_LOGS_SUCCESS:
      return action.payload.logs;
    default:
      return state;
  }
};

export default logsReducer;
