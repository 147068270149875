import * as types from "./actionsTypes";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import { toast } from "react-toastify";
import * as authApi from "../api/authApi";
import * as customerApi from "../api/customerApi";
import * as userApi from "../api/usersApi";

export const getAccount = (auth) => async (dispatch) => {
  dispatch(beginApiCall());

  authApi
    .getAccount(auth, auth.user.CustomerId)
    .then((account) => {
      dispatch({
        type: types.LOAD_ACCOUNT_SUCCESS,
        payload: {
          account: account,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const updateAccountCustomer =
  (auth, id, customer) => async (dispatch) => {
    customerApi
      .updateCustomer(auth, id, customer)
      .then((res) => {
        dispatch({
          type: types.UPDATE_ACCOUNT_CUSTOMER_SUCCESS,
          payload: {
            customer: res,
          },
        });
        toast.success("Customer " + res.Name + " succesfully updated!");
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };

export const saveAccountUser = (auth, user) => async (dispatch) => {
  userApi
    .saveUser(auth, user)
    .then((res) => {
      dispatch({
        type: types.SAVE_ACCOUNT_USER_SUCCESS,
        payload: {
          user: res,
        },
      });
      toast.success("User " + res.Email + " succesfully created");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const updateAccountUser = (auth, id, user) => async (dispatch) => {
  userApi
    .updateUser(auth, id, user)
    .then((res) => {
      dispatch({
        type: types.UPDATE_ACCOUNT_USER_SUCCESS,
        payload: {
          user: res,
        },
      });
      toast.success("User " + res.Email + " succesfully updated!");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const removeAccountUser = (auth, id) => async (dispatch) => {
  userApi
    .removeUser(auth, id)
    .then(() => {
      dispatch({
        type: types.REMOVE_ACCOUNT_USER_SUCCESS,
        payload: {
          user: id,
        },
      });
      toast.warning("User succesfully removed!");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};
