import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const tachoDownloadReducer = (state = initialState.tachoDownloads, action) => {
    switch (action.type) {
        case types.LOAD_TACHO_DOWNLOAD_SUCCESS:
            return action.payload.tachoDownloads;
        case types.LOAD_SEARCHED_TACHO_DOWNLOADS_SUCCESS:
            return action.payload.tachoResults;
        case types.LOAD_TACHO_DOWNLOADS_BY_CUSTOMER_SUCCESS:
            return action.payload.tachoResults;
        case types.LOAD_COUNT_TACHO_DOWNLOADS_SUCCESS:
            return action.payload.tachoResultCount;
        case types.REQUEST_TACHO_DOWNLOAD_SUCCESS:
            return state;
        default:
            return state;
    }
};

export default tachoDownloadReducer;
