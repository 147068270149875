import { handleResponse, handleError } from "./apiUtils";

const baseUrl = process.env.API_URL + "/api/AddonBoardFW/";

export function getFirmwares(auth) {
    return fetch(baseUrl, {
        headers: { Authorization: auth.accessToken },
    })
        .then(handleResponse)
        .catch(handleError);
}

export function getFirmwareFlags(auth) {
    return fetch(baseUrl + "flags/", {
        headers: { Authorization: auth.accessToken },
    })
        .then(handleResponse)
        .catch(handleError)
}

export function getactiveFirmwares(auth) {
    return fetch(baseUrl + "active/", {
        headers: { Authorization: auth.accessToken },
    })
        .then(handleResponse)
        .catch(handleError);
}

export function getFirmwareById(auth, id) {
    return fetch(baseUrl + id, {
        headers: { Authorization: auth.accessToken },
    })
        .then(handleResponse)
        .catch(handleError);
}

export function putFirmwareById(auth, id, data) {
    return fetch(baseUrl + id, {
        method: "PUT",
        headers: {
            Authorization: auth.accessToken,
        },
        body: data,
        redirect: "follow",
    })
        .then(handleResponse)
        .catch(handleError);
}

export const saveFirmware = (auth, firmware) => {
    return fetch(baseUrl, {
        method: "POST",
        headers: {
            Authorization: auth.accessToken,
        },
        body: firmware,
        redirect: "follow",
    })
        .then(handleResponse)
        .catch(handleError);
};

export function deleteFirmware(auth, id) {
    return fetch(baseUrl + id, {
        method: "DELETE",
        headers: {
            Authorization: auth.accessToken,
        },
    })
        .then(handleResponse)
        .catch(handleError);
}

export function massUpdateFirmware(auth, request) {
    return fetch(baseUrl + "MassFirmwareUpdate/" + request.customer + "/" + request.firmware, {
        method: "POST",
        headers: {
            Authorization: auth.accessToken,
        },
    })
        .then(handleResponse)
        .catch(handleError)
}

export function massUpdateBooter(auth, request) {
    return fetch(baseUrl + "MassBooterUpdate/" + request.customer + "/" + request.firmware, {
        method: "POST",
        headers: {
            Authorization: auth.accessToken,
        },
    })
        .then(handleResponse)
        .catch(handleError)
}