import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Components
import Spinner from "../../common/Spinner";

// Styling
import { Form, Row, Col, Button } from "react-bootstrap";

const DeviceCanConfigPane = ({ auth, currentConfig, newConfig, isLoading }) => {
  const canConfigSettings = [
    "CanStatus",
    "CAN1baudrate",
    "CAN1mode",
    "CAN2baudrate",
    "CAN2mode",
    "Speedcalibrationvalue",
    "Fuelcalibrationvalue",
    "PTOstatus",
  ];

  const isViewer = auth.user.Role === "Viewer";

  return (
    <>
      {!isLoading ? (
        <Form>
          {canConfigSettings.map((setting) => {
            return (
              <Form.Group
                as={Row}
                key={setting}
                controlId="formCanConfigSetting"
              >
                <Form.Label column sm="3">
                  <h6>
                    {setting}
                    <i>
                      {currentConfig[setting] == newConfig[setting]
                        ? ""
                        : " (" + currentConfig[setting] + ")"}
                    </i>
                  </h6>
                </Form.Label>
                <Col sm="9">
                  <Form.Control defaultValue={newConfig[setting]} />
                </Col>
              </Form.Group>
            );
          })}
          <hr />
          <Form.Group>
            <Row>
              <Col sm="11">
                <Button variant="outline-warning" type="submit">
                  Back
                </Button>
              </Col>
              {!isViewer && (
              <Col sm="1">
                <Button disabled={true} variant="success" type="submit">
                  Save
                </Button>
              </Col>
              )}
            </Row>
          </Form.Group>
        </Form>
      ) : (
        <Spinner />
      )}
    </>
  );
};

DeviceCanConfigPane.propTypes = {
  auth: PropTypes.object.isRequired,
  currentConfig: PropTypes.object.isRequired,
  newConfig: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    currentConfig: state.deviceDetails.currentConfig,
    newConfig: state.deviceDetails.newConfig,
    isLoading: state.deviceDetails.isLoading,
  };
}
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceCanConfigPane);
