import {apiCallError, beginApiCall} from "./apiStatusActions";
import * as types from "./actionsTypes";
import * as rtdIntegrationsApi from "../api/rtdIntegrationsApi";

export const fetchRTDIntegrators = (auth) => async (dispatch) => {
    dispatch(beginApiCall());

    rtdIntegrationsApi
        .getRTDIntegrators(auth)
        .then((rtdIntegrations) => {
            dispatch({
                type: types.LOAD_RTDINTEGRATIONS_SUCCESS,
                payload: {
                    rtdIntegrations: rtdIntegrations,
                },
            });
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
};