import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { SortedTable } from "../../common/SortedTable";
import SortButton from "../../common/SortButton";

const CustomTestDataList = ({ auth, customTestData }) => {
  const { list, requestSort, sortConfig } = SortedTable(customTestData);

  const downloadFile = (e) => {
    let id = e.target.id;
    let fileName = e.target.getAttribute("filename");
    const baseUrl = process.env.API_URL + "/api/customtestdata/";
    let uri = baseUrl + id;

    fetch(uri, { headers: { Authorization: auth.accessToken } })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className="authServer">
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>
              Customer
              <SortButton
                name="Customer"
                requestSort={requestSort}
                sortConfig={sortConfig}
              />
            </th>
            <th>
              FileName
              <SortButton
                name="FileName"
                requestSort={requestSort}
                sortConfig={sortConfig}
              />
            </th>
            <th>Download File</th>
          </tr>
        </thead>
        <tbody>
          {list.length === 0 ? (
            <tr>
              <td colSpan="15" style={{ textAlign: "center" }}>
                No Custom Test Data
              </td>
            </tr>
          ) : (
            list.map((customTestData) => {
              return (
                <tr
                  className="table-row"
                  key={customTestData.Id}
                >
                  <td>{customTestData.Customer}</td>
                  <td>{customTestData.FileName}</td>
                  <td
                    onClick={downloadFile}
                    id={customTestData.Id}
                    // fileName={customTestData.FileName}
                  >
                    Download File Icon
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

CustomTestDataList.propTypes = {
  auth: PropTypes.object.isRequired,
  customTestData: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomTestDataList);
