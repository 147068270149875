import * as deviceTypeApi from "../api/deviceTypeApi";
import * as types from "./actionsTypes";
import {apiCallError, beginApiCall} from "./apiStatusActions";

export const fetchDeviceTypes = (auth) => async (dispatch) => {
    dispatch(beginApiCall());

    deviceTypeApi
        .getDeviceTypes(auth)
        .then((deviceTypes) => {
            dispatch({
                type: types.LOAD_DEVICE_TYPES_SUCCESS,
                payload: {
                    deviceTypes: deviceTypes,
                }
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error))
        });
}