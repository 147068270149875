import * as types from "./actionsTypes";
import * as endpointApi from "../api/endpointApi";
import {beginApiCall, apiCallError} from "./apiStatusActions";
import {toast} from "react-toastify";

export const fetchEndpoints = (auth) => async (dispatch) => {
    dispatch(beginApiCall());

    endpointApi
        .getEndpoints(auth)
        .then((endpoints) => {
            
            dispatch({
                type: types.LOAD_ENDPOINTS_SUCCESS,
                payload: {endpoints: endpoints},
            });
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
};

export const fetchMessageTypes = (auth) => async (dispatch) => {
    dispatch(beginApiCall());

    endpointApi
        .getMessageTypes(auth)
        .then((messageTypes) => {
            dispatch({
                type: types.LOAD_MESSAGE_TYPES_SUCCESS,
                payload: {
                    messageTypes: messageTypes,
                }
            });
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
}

export const fetchProtocols = (auth) => async (dispatch) => {
    dispatch(beginApiCall());

    endpointApi
        .getProtocols(auth)
        .then((protocols) => {
            dispatch({
                type: types.LOAD_PROTOCOLS_SUCCESS,
                payload: {
                    protocols: protocols,
                }
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error))
        });
}

export const fetchEndpointById = (auth, id) => async (dispatch) => {
    dispatch(beginApiCall());

    endpointApi
        .getEndpointById(auth, id)
        .then((res) => {
            dispatch({
                type: types.LOAD_ENDPOINT_SUCCESS,
                payload: {
                    endpoint: res
                },
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
}

export const fetchEndpointsByCustomer = (auth, id) => async (dispatch) => {
    dispatch(beginApiCall());

    endpointApi
        .getEndpointsByCustomer(auth, id)
        .then((endpoints) => {
            dispatch({
                type: types.LOAD_ENDPOINTS_SUCCESS,
                payload: {
                    endpoints: endpoints,
                },
            });
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
};

export const deleteEndpoint =
  (auth, id, messageType) => async (dispatch) => {
    dispatch(beginApiCall());

    endpointApi
      .removeEndpoint(auth, id)
      .then(() => {
        const obj = { MessageType: messageType, Protocol: "non" };
        dispatch({
          type: types.REMOVE_DEVICE_ENDPOINT_SUCCESS,
          payload: {
            Id: id,
            emptyEndpoint: obj,
          },
        });
        toast.success("Endpoint removed");
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };

export const saveEndpoint = (auth, endpoint) => async (dispatch) => {
    dispatch(beginApiCall());

    endpointApi
        .saveEndpoint(auth, endpoint)
        .then((res) => {
            dispatch({
                type: types.SAVE_ENDPOINT_SUCCESS,
                payload: {
                    endpoint: res
                },
            });
            toast.success("Endpoint " + res.Name + " successfully created!");
        })
        .catch((error) => {
            dispatch(apiCallError(error))
        });
}

export const saveIntegration = (auth, integration) => async (dispatch) => {
    dispatch(beginApiCall());

    endpointApi
        .saveIntegration(auth, integration)
        .then((res) => {
            dispatch({
                type: types.SAVE_ENDPOINT_SUCCESS,
                payload: {
                    endpoint: res
                },
            });
            toast.success("Endpoint " + res.Name + " successfully created!");
        })
        .catch((error) => {
            dispatch(apiCallError(error))
        });
}

export const updateEndpoint = (auth, id, endpoint) => async (dispatch) => {
    dispatch(beginApiCall());

    endpointApi
        .updateEndpoint(auth, id, endpoint)
        .then((res) => {
            dispatch({
                type: types.UPDATE_ENDPOINT_SUCCESS,
                payload: {
                    endpoint: res,
                },
            });
            toast.success("Endpoint " + res.Name + " succesfully updated!");
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
}

export const removeEndpoint = (auth, id) => async (dispatch) => {
    dispatch(beginApiCall());

    endpointApi
        .removeEndpoint(auth, id)
        .then(() => {
            dispatch({
                type: types.REMOVE_ENDPOINT_SUCCESS,
                payload: {
                    endpoint: id,
                },
            });
            toast.warn("Endpoint succesfully removed!");
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
}

export const loadSearchedEndpoints = (auth, searchTerm, filter, customer = null) => async (dispatch) => {
    dispatch(beginApiCall());
    
    endpointApi
        .getEndpointsFromSearch(auth, searchTerm, filter, customer)
        .then((endpoints) => {
            dispatch({
                type: types.LOAD_SEARCH_RESULT_ENDPOINT_SUCCESS,
                payload: {
                    endpoints: endpoints,
                }
            })
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        })
}