import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const rawMessagesReducer = (state = initialState.rawMessages, action) => {
    switch (action.type) {
        case types.LOAD_FIRST_BATCH_RAW_MESSAGES_SUCCESS:
            return action.payload.rawMessages;
        case types.LOAD_BATCH_RAW_MESSAGES_SUCCESS: {
            let result = [...state];
            
            action.payload.rawMessages.map((item) => {
                result = [...result, item]
            });
            
            return result;
        }
        case types.LOAD_SEARCH_RESULT_RAW_MESSAGES_SUCCESS: 
            return action.payload.rawMessages;
        default:
            return state;
    }
};

export default rawMessagesReducer;
