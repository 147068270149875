import React, {useState} from "react";
import PropTypes from "prop-types";

// Redux
import {connect, useDispatch} from "react-redux";
import {removeRole, getRoleById} from "../../actions/roleActions";

// Components
import Confirmation from "../common/Confirmation";
import {SortedTable} from "../common/SortedTable";
import SortButton from "../common/SortButton";
import {Item, Menu, useContextMenu} from "react-contexify";

const RoleList = ({auth, roles}) => {
    const dispatch = useDispatch();
    const {show} = useContextMenu({id: 'role'});

    const {list, requestSort, sortConfig} = SortedTable(roles);
    const [selectedRole, setSelectedRole] = useState(null);

    const selectRole = (e) => {
        setSelectedRole(
            roles.find((role) => {
                return role.Id == e;
            })
        );
        dispatch(getRoleById(auth, e));
    };

    const removeRoleHandler = (role) => {
        dispatch(removeRole(auth, role.Id));
    };

    return (
        <div className="role">
            <table className="table table-striped table-hover">
                <thead>
                <tr>
                    <th>
                        Name
                        <SortButton
                            name="Name"
                            requestSort={requestSort}
                            sortConfig={sortConfig}
                        />
                    </th>
                    <th>
                        Id
                        <SortButton
                            name="Id"
                            requestSort={requestSort}
                            sortConfig={sortConfig}
                        />
                    </th>
                    <th>
                        UsedBy
                        <SortButton
                            name="UsedBy"
                            requestSort={requestSort}
                            sortConfig={sortConfig}
                        />
                    </th>
                </tr>
                </thead>
                <tbody>
                {list.length === 0 ? (
                    <tr>
                        <td colSpan="15" style={{textAlign: "center"}}>
                            No roles found
                        </td>
                    </tr>
                ) : (
                    list.map((role) => {
                        return (
                            <tr
                                onContextMenu={(e) => {
                                    show({event: e, props: {role: role}});
                                }}
                                className="table-row"
                                key={role.Id}
                                id={role.Id}
                            >
                                <td>{role.Name}</td>
                                <td>{role.Id}</td>
                                <td>{role.UsedBy}</td>
                            </tr>
                        );
                    })
                )}
                </tbody>
            </table>
            <Menu id="role">
                <Item closeOnClick={false} onClick={(e) => selectRole(e.props.role.Id)}>
                    <Confirmation
                        buttonText={{ ok: "Remove role" }} 
                        description={`Are you sure you want to remove this role?`}
                        onConfirm={() => removeRoleHandler(selectedRole)}
                    />
                </Item>
                <Item>Cancel</Item>
            </Menu>
        </div>
    );
};

RoleList.propTypes = {
    auth: PropTypes.object.isRequired,
    roles: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RoleList);
