import React from "react";
import {connect, useDispatch} from "react-redux";

import {saveCanConfig} from "../../../actions/canConfigActions";

import {Button, Form, Modal} from "react-bootstrap";
import PropTypes from "prop-types";
import {Field, Formik, useFormik} from "formik";
import * as Yup from 'yup';

const CreateCanConfig = ({auth, show, setShow, deviceTypes}) => {
    const dispatch = useDispatch();

    const handleClose = () => {
        setShow(false);
    };

    const handleShow = () => {
        setShow(true);
    };

    const onSubmit = (values) => {
        let canConfig = new FormData();

        canConfig.append("Protocol", values.Protocol);
        canConfig.append("Version", values.Version);
        canConfig.append("Brand", values.Brand);
        canConfig.append("Name", values.Name);
        canConfig.append("Active", values.Active);
        canConfig.append("DeviceType", values.DeviceType);
        canConfig.append("Config", values.Config, values.Config.name);
        canConfig.append("Filename", values.Config.name);

        dispatch(saveCanConfig(auth, canConfig));
        handleClose();
    };

    const initialValues = {
        Protocol: "",
        Version: "",
        Brand: "",
        Name: "",
        Active: "true",
        DeviceType: "4",
        Config: ""
    };

    const SUPPORTED_FORMATS = [
        "s2s",
        "tlqs"
    ];
    
    const validationSchema = Yup.object().shape({
        Protocol: Yup.string()
        .min(6, "Protocol need to be greater than 6 characters.")
        .max(10, "Protocol need to be less than 7 characters.")
        .required("Protocol is required"),
        Version: Yup.string()
            .min(1, "Version need to be greater than 6 characters.")
            .max(10, "Version need to be less than 7 characters.")
            .required("Version is required"),
        Brand: Yup.string().required("Brand is required"),
        Name: Yup.string()
            .min(3, "Name need to be greater than 3 characters.")
            .max(50, "Name need to be less than 50 characters.")
            .required("Name is required"),
        Active: Yup.string().required("Active is required"),
        DeviceType: Yup.string().nullable().required("Device Type is required"),
        Config: Yup.mixed()
            .required("Config is required")
            .test("fileSize",
                "File too large",
                value => value && value.size <= 36864)
            .test('fileFormat',
                'Unsupported Format, Formats supported: .bin, .tlqs, .fota',
                value => {
                    let regex = /(?:\.([^.]+))?$/;
                    let ext = regex.exec(value?.name)[1];

                    return value && SUPPORTED_FORMATS.includes(ext)
                }),
    })

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: onSubmit,
        validationSchema: validationSchema
    });

    return (
        <div>
            <Button className="create-object-button" onClick={handleShow}>
                New QTLibrary
            </Button>
            <Modal show={show} onHide={handleClose} centered className="text-center">
                <Modal.Header closeButton>
                    <Modal.Title>Create QTLibrary</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({errors, touched, handleSubmit, setFieldValue}) => (
                        <Form onSubmit={handleSubmit}>
                            <Modal.Body className="text-left">
                                <label htmlFor="Protocol" className="form-label">Protocol Code</label>
                                <Field
                                    type="text"
                                    name="Protocol"
                                    className={`form-control ${
                                        touched.Protocol && errors.Protocol ? "is-invalid" : ""
                                    }`}
                                    placeholder="Protocol"
                                />
                                {errors.Protocol && touched.Protocol ? (
                                    <div className="text-danger">{errors.Protocol}</div>
                                ) : null}

                                <label htmlFor="Version" className="form-label">Version</label>
                                <Field
                                    type="text"
                                    name="Version"
                                    className={`form-control ${
                                        touched.Version && errors.Version ? "is-invalid" : ""
                                    }`}
                                    placeholder="Version"
                                />
                                {errors.Version && touched.Version ? (
                                    <div className="text-danger">{errors.Version}</div>
                                ) : null}

                                <label htmlFor="Brand" className="form-label">Brand</label>
                                <Field
                                    type="text"
                                    name="Brand"
                                    className={`form-control ${
                                        touched.Brand && errors.Brand ? "is-invalid" : ""
                                    }`}
                                    placeholder="Brand"
                                />
                                {errors.Brand && touched.Brand ? (
                                    <div className="text-danger">{errors.Brand}</div>
                                ) : null}

                                <label htmlFor="Name" className="form-label">Name</label>
                                <Field
                                    type="text"
                                    name="Name"
                                    className={`form-control ${
                                        touched.Name && errors.Name ? "is-invalid" : ""
                                    }`}
                                    placeholder="Name"
                                />
                                {errors.Name && touched.Name ? (
                                    <div className="text-danger">{errors.Name}</div>
                                ) : null}

                                <label htmlFor="Active" className="form-label">Status</label>
                                <Field
                                    name="Active"
                                    as="select"
                                    className={`form-control ${
                                        touched.Active && errors.Active ? "is-invalid" : ""
                                    }`}
                                    placeholder="Active"
                                >
                                    <option key={false} value={false}>
                                        InActive
                                    </option>
                                    <option key={true} value={true}>
                                        Active
                                    </option>
                                </Field>
                                {errors.Active && touched.Active ? (
                                    <div className="text-danger">{errors.Active}</div>
                                ) : null}

                                <label htmlFor="DeviceType" className="form-label">Device Type</label>
                                <Field
                                    as="select"
                                    name="DeviceType"
                                    className={`form-control ${
                                        touched.DeviceType && errors.DeviceType ? "is-invalid" : ""
                                    }`}
                                    placeholder="DeviceType"
                                >
                                    {deviceTypes.map((devicetype) => (
                                        <option key={devicetype.Id} value={devicetype.Id}>
                                            {devicetype.Name}
                                        </option>
                                    ))
                                    }
                                </Field>

                                <label htmlFor="Config" className="form-label">Config</label>
                                <input
                                    type="file"
                                    name="Config"
                                    className={`form-control ${
                                        touched.Config && errors.Config ? "is-invalid" : ""
                                    }`}
                                    placeholder="Config"
                                    onChange={(event) => {
                                        setFieldValue("Config", event.currentTarget.files[0]);
                                    }}
                                />
                                {errors.Config && touched.Config ? (
                                    <div className="text-danger">{errors.Config}</div>
                                ) : null}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" type="submit" disabled={!formik.isValid}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    );
};

CreateCanConfig.propTypes = {
    auth: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    canConfig: PropTypes.array,
    deviceTypes: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        deviceTypes: state.deviceTypes,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCanConfig);
