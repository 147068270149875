import React, { useState } from "react";
import PropTypes from "prop-types";

// Redux
import { connect, useDispatch } from "react-redux";
import UserTable from "./components/UserTable";
import CreateUserModal from "./components/CreateUserModal";
import { saveAccountUser } from "../../actions/accountActions";

const UsersTab = ({ auth, users, roles }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [newUser, setNewUser] = useState({
    customerId: "",
    email: "",
    password: "",
    passwordConf: "",
    roleId: "null",
  });

  const saveNewUser = () => {
    newUser.customerId = auth.user.CustomerId;
    delete newUser.passwordConf;

    dispatch(saveAccountUser(auth, newUser));
    setShow(false);
    setNewUser({
      customerId: auth.user.CustomerId,
      email: "",
      password: "",
      passwordConf: "",
      roleId: "null",
    });
  };

  return (
    <div>
      <div className="create-object-div">
        <CreateUserModal
          auth={auth}
          show={show}
          setShow={setShow}
          newUser={newUser}
          setNewUser={setNewUser}
          roles={roles}
          saveNewUser={saveNewUser}
        />
      </div>
      <br />
      <UserTable account={true} auth={auth} users={users} roles={roles} />
    </div>
  );
};

UsersTab.propTypes = {
  auth: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    users: state.account.users,
    roles: state.roles,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UsersTab);
