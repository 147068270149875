import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const deviceEndpointReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_DEVICE_ENDPOINTS_SUCCESS:
      return action.payload.endpoints;
    case types.SAVE_DEVICE_ENDPOINT_SUCCESS:
      return [
        ...state.filter(endpoint => endpoint.MessageType !== action.payload.endpoints.MessageType),
        action.payload.endpoints,
      ];
    case types.UPDATE_DEVICE_ENDPOINT_SUCCESS:
      return [
        ...state.filter(endpoint => endpoint.Id !== action.payload.endpoints.Id),
        action.payload.endpoints,
      ];

    case types.REMOVE_DEVICE_ENDPOINT_SUCCESS:
      return [
        ...state.filter(endpoint => endpoint.Id !== action.payload.Id),
        action.payload.emptyEndpoint];
    default:
      return state;
  }
};

export default deviceEndpointReducer;
