import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";

// Styling
import {Card, Button, Form} from "react-bootstrap";
import "./SearchBar.css";

const SearchBar = ({
                       filters,
                       customers,
                       setSearchResult,
                       searchResult,
                       all,
                       refresh,
                       setSearchActive,
                   }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [filter, setFilter] = useState(filters[0]);
    const [customer, setCustomer] = useState(typeof customers !== "undefined" && customers.length > 0 ? customers[0] : 0);

    const handleFilter = (e) => {
        setFilter(e.target.value);
    };
    
    const handleCustomer = (e) => {
        setCustomer(e.target.value);
    }
    
    const handleSearchTerm = (e) => {
        setSearchTerm(e.target.value);
    };
    const handleRefresh = () => {
        setSearchTerm("");
        refresh();
    };

    useEffect(() => {
        if (searchTerm === "") {
            setSearchActive(false);
            setSearchResult([]);
        } else {
            const result = all.filter(
                (items) =>
                    items[filter] &&
                    items[filter]
                        .toString()
                        .toLowerCase()
                        .includes(searchTerm.toLocaleLowerCase())
            );
            setSearchResult(result);
            setSearchActive(true);
        }

    }, [searchTerm, filter]);

    return (
        <>
            <Card>
                <div className="search">
                    <Form.Control
                        className="search-select border-start"
                        as="select"
                        name="filter"
                        value={filter}
                        onChange={handleFilter}
                    >
                        {filters.map((filter) => {
                            return (
                                <option key={filter} value={filter}>
                                    {filter}
                                </option>
                            );
                        })}
                    </Form.Control>
                    {customers != null ? (
                        <Form.Control
                            as="select"
                            name="customer"
                            value={customer}
                            onChange={handleCustomer}
                            className="search-select rounded-0"
                        >
                            <option>
                                --
                            </option>
                            {customers.map((customer) => {
                                return (
                                    <option key={customer.Id} value={customer.Id}>
                                        {customer.Name}
                                    </option>
                                )
                            })}

                        </Form.Control>
                    ) : (
                        <></>
                    )}
                    <input
                        className="search-input"
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearchTerm}
                    />
                    <div className="result">
                        <b>Results : </b>
                        {searchResult.length > 0
                            ? " " + searchResult.length
                            : " " + all.length}
                    </div>
                    <Button
                        className="refresh-border border-end"
                        variant="light"
                        onClick={handleRefresh}
                    >
                        ⟳
                    </Button>
                </div>
            </Card>
        </>
    );
};

SearchBar.propTypes = {
    filters: PropTypes.array.isRequired,
    customers: PropTypes.array,
    setSearchResult: PropTypes.func.isRequired,
    searchResult: PropTypes.array.isRequired,
    all: PropTypes.array.isRequired,
    refresh: PropTypes.func.isRequired,
    setSearchActive: PropTypes.func.isRequired,
};

export default SearchBar;
