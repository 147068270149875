import initialState from "./initialState";
import * as types from "../actions/actionsTypes";

export default function messageTypesReducer(
    state = initialState.messageTypes,
    action
) {
    switch (action.type) {
        case types.LOAD_MESSAGE_TYPES_SUCCESS:
            return action.payload.messageTypes;
        case types.LOGOUT:
            return initialState.messageTypes;
        default:
            return state;
    }
}