import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Redux
import { useDispatch, connect } from "react-redux";
import { fetchFeatures } from "../../actions/featureActions";

// Components
import FeatureList from "./FeatureList";
import CreateFeatureModal from "./CreateFeatureModal";
import Spinner from "../common/Spinner";
import SearchBar from "../common/SearchBar";

// Styling
import { Card } from "react-bootstrap";

const FeaturePage = ({ auth, apiCallsInProgress, features }) => {
  const dispatch = useDispatch();
  const featureList = [...features];
  const [searchResult, setSearchResult] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [show, setShow] = useState(false);
  const filters = ["Name"];

  useEffect(() => {
    dispatch(fetchFeatures(auth));
  }, []);

  const refresh = () => {
    dispatch(fetchFeatures(auth));
  };

  return (
    <div className="features">
      <h2 className="title">Features</h2>
      <SearchBar
        filters={filters}
        setSearchResult={setSearchResult}
        searchResult={searchResult}
        all={featureList}
        refresh={refresh}
        setSearchActive={setSearchActive}
      />
      <div className="create-object-div">
        <CreateFeatureModal show={show} setShow={setShow} />
      </div>
      {features.length === 0 && apiCallsInProgress > 0 ? (
        <Spinner />
      ) : (
        <Card>
          {searchResult.length === 0 && searchActive == false ? (
            <FeatureList features={featureList} />
          ) : (
            <FeatureList features={searchResult} />
          )}
        </Card>
      )}
    </div>
  );
};

FeaturePage.propTypes = {
  auth: PropTypes.object.isRequired,
  features: PropTypes.array.isRequired,
  apiCallsInProgress: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    features: state.features,
    apiCallsInProgress: state.apiCallsInProgress,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FeaturePage);
