import * as types from "./actionsTypes";
import * as deviceEndpointApi from "../api/deviceEndpointApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import { toast } from "react-toastify";

export const getEndpointsByDevice = (auth, id) => async (dispatch) => {
  dispatch(beginLoadingDevice());

  deviceEndpointApi
    .getEndpointsByDevice(auth, id)
    .then((deviceEndpoints) => {
      const defaultEndpoints = [
        { MessageType: "TD", Protocol: "non" },
        { MessageType: "RT", Protocol: "non" },
        { MessageType: "TC", Protocol: "non" },
        { MessageType: "TL", Protocol: "non" },
      ];

      defaultEndpoints.forEach((defaultEndpoint, index, array) => {
        var endpoint = deviceEndpoints.find((deviceEndpoint) => {
          return defaultEndpoint.MessageType == deviceEndpoint.MessageType;
        });
        if (endpoint != undefined) {
          array[index] = endpoint;
        }
      });
      dispatch({
        type: types.LOAD_DEVICE_ENDPOINTS_SUCCESS,
        payload: {
          endpoints: defaultEndpoints,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const saveEndpointDevice = (auth, request) => async (dispatch) => {
  dispatch(beginApiCall());

  deviceEndpointApi
    .saveEndpointDevice(auth, request)
    .then((endpoint) => {
      dispatch({
        type: types.SAVE_DEVICE_ENDPOINT_SUCCESS,
        payload: {
          endpoints: endpoint,
        },
      });
      toast.success("Endpoint saved");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const updateEndpointDevice = (auth, request) => async (dispatch) => {
  dispatch(beginApiCall());

  deviceEndpointApi
    .updateEndpointDevice(auth, request)
    .then((endpoint) => {
      dispatch({
        type: types.UPDATE_DEVICE_ENDPOINT_SUCCESS,
        payload: {
          endpoints: endpoint,
        },
      });
      toast.success("Endpoint updated");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const removeEndpointDevice =
  (auth, id, messageType) => async (dispatch) => {
    dispatch(beginApiCall());

    deviceEndpointApi
      .removeEndpointDevice(auth, id)
      .then(() => {
        const obj = { MessageType: messageType, Protocol: "non" };
        dispatch({
          type: types.REMOVE_DEVICE_ENDPOINT_SUCCESS,
          payload: {
            Id: id,
            emptyEndpoint: obj,
          },
        });
        toast.success("Endpoint removed");
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };

const beginLoadingDevice = () => async (dispatch) => {
  dispatch(beginApiCall());
  
  dispatch({
    type: "LOADING_DEVICE",
    payload: {
      isLoading: true,
    },
  });
};
