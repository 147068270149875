import * as types from "./actionsTypes";
import * as canConfigApi from "../api/canConfigApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import { toast } from "react-toastify";

export const loadCanConfigs = (auth) => async (dispatch) => {
  dispatch(beginApiCall());

  canConfigApi
    .getCANConfigs(auth)
    .then((canConfigs) => {
      dispatch({
        type: types.LOAD_CANCONFIGS_SUCCESS,
        payload: {
          canConfigs: canConfigs,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const loadActiveCanConfigs = (auth) => async (dispatch) => {
  dispatch(beginApiCall());

  canConfigApi
    .getactiveCANConfigs(auth)
    .then((canConfigs) => {
      dispatch({
        type: types.LOAD_ACTIVECANCONFIGS_SUCCESS,
        payload: {
          canConfigs: canConfigs,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const loadCanConfig = (auth, id) => async (dispatch) => {
  dispatch(beginApiCall());

  canConfigApi
    .getCANConfigById(auth, id)
    .then((canConfig) => {
      dispatch({
        type: types.LOAD_CANCONFIG_SUCCESS,
        payload: {
          canConfigs: canConfig,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const updateCanConfig = (auth, id, canconfig) => async (dispatch) => {
  dispatch(beginApiCall());

  canConfigApi
    .putCANConfigById(auth, id, canconfig)
    .then((canConfig) => {
      dispatch({
        type: types.UPDATE_CANCONFIG_SUCCESS,
        payload: {
          canConfigs: canConfig,
        },
      });
      toast.success("CanProtocol " + canConfig.Name + " saved!");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const saveCanConfig = (auth, canconfig) => async (dispatch) => {
  dispatch(beginApiCall());

  canConfigApi
    .saveCANConfig(auth, canconfig)
    .then((canConfig) => {
      dispatch({
        type: types.SAVE_CANCONFIG_SUCCESS,
        payload: {
          canConfigs: canConfig,
        },
      });
      toast.success("CanProtocol " + canConfig.Name + " saved!");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const massUpdateCANConfig = (auth, request) => async(dispatch) => {
  dispatch(beginApiCall())
  canConfigApi.massUpdateCANConfig(auth, request)
  .then((resultCount)=>{
    dispatch({
      type: types.MASS_UPDATE_CANCONFIG_SUCCESS,
      payload:
      {
        resultCount: resultCount,
      }
    })
    toast.success("Succesfully updated " + resultCount + " devices to QTL " + request.protocolCodeToUpdateTo)
  })
  .catch((error)=>
  {
    dispatch(apiCallError(error))
  })
}