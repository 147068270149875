import React, { useRef, useEffect, useState } from "react";
import { Navigate  } from "react-router";
import PropTypes from "prop-types";
// import * as jwt_decode from "jwt-decode";
import { jwtDecode } from "jwt-decode";


//import jwt from "jsonwebtoken";

// Time
import moment from "moment";

// Redux
import { connect, useDispatch } from "react-redux";
import { login, logout } from "../../actions/authActions";

// Styling
import "./Login.css";
import { Button, Form, Card } from "react-bootstrap";

const Login = ({ auth, apiCallsInProgress }) => {
  const dispatch = useDispatch();
  const [logingin, setLogingin] = useState(false);
  const [validtoken, setvalidtoken] = useState(false);
  const emailRef = useRef();
  const passwordRef = useRef();

  const validatetoken = (auth) => {
    if (auth.isLoggedIn === true) {
      //var decodedToken = jwt.decode(auth.accessToken, { complete: true });
      // var decodedToken = jwt_decode(auth.accessToken);
      var decodedToken = jwtDecode(auth.accessToken);
      var current = moment(Math.floor(Date.now() / 1000));
      if (decodedToken.exp - 10 > current) {
        setvalidtoken(true);
        setLogingin(true);
      } else {
        setvalidtoken(false);
        setLogingin(false);

        //dispatch(renew(auth.RefreshToken));
        onLogout();
      }
    }
  };

  useEffect(() => {
    validatetoken(auth);
  }, [auth]);

  const onLogin = (event) => {
    event.preventDefault();
    dispatch(login(emailRef.current.value, passwordRef.current.value));
  };

  const onLogout = () => {
    dispatch(logout());
    return <Navigate to="/Login" />;
  };

  return logingin === true && validtoken === true ? (
    <Navigate to="/" />
  ) : (
    <div className="login">
      <Card className="login-card">
        <Card.Body>
          <h3 className="text-center mb-4">Log In</h3>
          <Form onSubmit={onLogin}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                autoFocus={true}
                type="email"
                ref={emailRef}
                placeholder="Email"
                required = {true}
              />
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                ref={passwordRef}
                placeholder="Password"
                required = {true}
              />
            </Form.Group>
            <Button
              className="w-100 text-center mt-3"
              variant="primary"
              type="Submit"
              disabled={apiCallsInProgress > 0}
            >
              Log In
            </Button>
          </Form>
          {/* <div className="w-100 text-center mt-3">
            <Link to="/forgot-password">Forgotten Password?</Link>
          </div> */}
        </Card.Body>
      </Card>
    </div>
  );
};

Login.propTypes = {
  auth: PropTypes.object.isRequired,
  apiCallsInProgress: PropTypes.number.isRequired,
};


function mapStateToProps(state) {
  return {
    auth: state.auth,
    apiCallsInProgress: state.apiCallsInProgress,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
