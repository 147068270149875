import {connect} from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {Field, Formik, useFormik} from "formik";
import * as Yup from 'yup';

const UpdateFirmware = ({
                            show,
                            setShow,
                            firmware,
                            firmwareFlags,
                            deviceTypes,
                            onSubmitFirmwareUpdate
                        }) => {
    const handleClose = () => {
        setShow(false);
    };

    const SUPPORTED_FORMATS = [
        "bin",
        "tlqs",
        "fota"
    ];

    const validationSchema = Yup.object().shape({
        Version: Yup.string()
            .min(6, 'Version should be at least 6 character!')
            .max(8, 'Version should be at most 8 characters!')
            .required('Version is Required'),
        Release: Yup.string()
            .required('Release is Required'),
        FirmwareFlag: Yup.string()
            .required('Firmware Type is Required'),
        DeviceType: Yup.string()
            .required('Device Type is Required'),
        Active: Yup.string()
            .required('Status is Required'),
        //If firmware is empty, no validation needed because it is optional
        Firmware: Yup.mixed()
            .nullable()
            .test(
                'fileSize',
                'File too large',
                value => value == null ? true : value && value.size <= 393216 // 384 Kb
            )
            .test('fileFormat',
                'Unsupported Format, Formats supported: .bin, .tlqs, .fota',
                value => {
                    if (value == null) return true; // if no file selected, skip validation

                    let regex = /(?:\.([^.]+))?$/;
                    let ext = regex.exec(value?.name)[1];

                    return value && SUPPORTED_FORMATS.includes(ext)
                }),
    })

    const initialValues = {
        Version: firmware.Version,
        Release: firmware.Release,
        FirmwareFlag: firmware.FirmwareFlag,
        DeviceType: firmware.DeviceType,
        Active: firmware.Active,
        Firmware: null,
    };

    const onSubmit = (values) => {
        onSubmitFirmwareUpdate(firmware.Id, values)
        handleClose();
    }


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: onSubmit,
    });

    return (
        <div>
            <Modal show={show} onHide={handleClose} centered className="text-center">
                <Modal.Header closeButton>
                    <Modal.Title>Update Firmware</Modal.Title>
                </Modal.Header>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                >
                    {({
                          handleSubmit,
                          errors,
                          touched,
                          setFieldValue,
                      }) => (
                        <Form onSubmit={handleSubmit} className="text-left">
                            <Modal.Body>
                                <label className="form-label">Version</label>
                                <Field
                                    type="text"
                                    name="Version"
                                    placeholder="Version"
                                    className="form-control"
                                />
                                {errors.Version && touched.Version ? (
                                    <div className="text-danger">{errors.Version}</div>
                                ) : null}

                                <label className="form-label">Type (Release)</label>
                                <Field
                                    as="select"
                                    name="Release"
                                    className="form-control"
                                    placeholder="Release"
                                >
                                    <option key={false} value={false}>
                                        Test version
                                    </option>
                                    <option key={true} value={true}>
                                        Release version
                                    </option>
                                </Field>
                                {errors.Release && touched.Release ? (
                                    <div className="text-danger">{errors.Release}</div>
                                ) : null}

                                <label className="form-label">Type Firmware</label>
                                <Field
                                    as="select"
                                    name="FirmwareFlag"
                                    className="form-control"
                                    placeholder="FirmwareFlag"
                                >
                                    {/*TODO: Dit gaat fout omdat value niet null mag zijn en dit veld eigenlijk verplicht is*/}
                                    {/*Dit staat er alleen nog in omdat niet alle firmwares een Flag hebben*/}
                                    <option key=""> - -</option>
                                    {firmwareFlags.map((flag) => (
                                        <option key={flag.Id} value={flag.Id}>
                                            {flag.Flag}
                                        </option>
                                    ))}
                                </Field>
                                {errors.FirmwareFlag && touched.FirmwareFlag ? (
                                    <div className="text-danger">{errors.FirmwareFlag}</div>
                                ) : null}

                                <label className="form-label">Device Type</label>
                                <Field
                                    as="select"
                                    name="DeviceType"
                                    className="form-control"
                                    placeholder="DeviceType"
                                >
                                    {deviceTypes.map((devicetype) => (
                                        <option key={devicetype.Id} value={devicetype.Id}>
                                            {devicetype.Name}
                                        </option>
                                    ))
                                    }
                                </Field>
                                {errors.DeviceType && touched.DeviceType ? (
                                    <div className="text-danger">{errors.DeviceType}</div>
                                ) : null}

                                <label className="form-label">Status</label>
                                <Field
                                    as="select"
                                    name="Active"
                                    className="form-control"
                                    placeholder="Active"
                                >
                                    <option key={true} value={true}>
                                        Active
                                    </option>
                                    <option key={false} value={false}>
                                        InActive
                                    </option>
                                </Field>
                                {errors.Active && touched.Active ? (
                                    <div className="text-danger">{errors.Active}</div>
                                ) : null}

                                <label className="form-label">Firmware File</label>
                                <input
                                    type="file"
                                    id="Firmware"
                                    name="Firmware"
                                    className="form-control"
                                    placeholder="Firmware"
                                    onChange={(event) => {
                                        setFieldValue("Firmware", event.currentTarget.files[0]);
                                        firmware.Filename = event.currentTarget.files[0]?.name;
                                    }}
                                />
                                <small>Current File: {firmware.Filename}</small>
                                {errors.Firmware && touched.Firmware ? (
                                    <div className="text-danger">{errors.Firmware}</div>
                                ) : null}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" type="submit" disabled={!formik.isValid}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    );
};

UpdateFirmware.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    firmware: PropTypes.object.isRequired,
    firmwareFlags: PropTypes.array.isRequired,
    deviceTypes: PropTypes.array.isRequired,
    onSubmitFirmwareUpdate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        firmwareFlags: state.firmwareFlags,
        deviceTypes: state.deviceTypes,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateFirmware);
