import * as types from "./actionsTypes";
import * as configurationGroupApi from "../api/configurationGroupApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import { toast } from "react-toastify";

export const fetchConfigurationGroups = (auth) => async (dispatch) => {
  dispatch(beginApiCall());

  configurationGroupApi
    .loadConfigurationGroups(auth)
    .then((configurationGroups) => {
      dispatch({
        type: types.LOAD_CONFIGURATION_GROUPS_SUCCESS,
        payload: {
          configurationGroups: configurationGroups,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const fetchConfigurationGroupsByCustomer =
  (auth, customerId) => async (dispatch) => {
    dispatch(beginApiCall());

    configurationGroupApi
      .loadConfigurationGroupsByCustomer(auth, customerId)
      .then((configurationGroups) => {
        dispatch({
          type: types.LOAD_CONFIGURATION_GROUPS_CUSTOMER_SUCCESS,
          payload: {
            configurationGroups: configurationGroups,
          },
        });
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };

export const fetchConfigGroupsBySearch = (auth, searchTerm, filter, customer = null) => async (dispatch) => {
    dispatch(beginApiCall());
    
    configurationGroupApi
        .loadConfigGroupsBySearch(auth, searchTerm, filter, customer)
        .then((configGroups) => {
            dispatch({
                type: types.LOAD_SEARCH_RESULT_CONFIGURATION_GROUP_SUCCESS,
                payload: {
                    configurationGroups: configGroups,
                },
            });
        })
        .catch((error) => {
            dispatch(apiCallError(error));
        });
}

export const saveConfigurationGroup = (auth, config) => async (dispatch) => {
  dispatch(beginApiCall());

  configurationGroupApi
    .saveConfigurationGroup(auth, config)
    .then((configurationGroup) => {
      dispatch({
        type: types.SAVE_CONFIGURATION_GROUP_SUCCESS,
        payload: {
          configurationGroup: configurationGroup,
        },
      });
      toast.success(
        "Configuration group " + configurationGroup.Name + " saved!"
      );
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const updateConfigurationGroup =
  (auth, id, config) => async (dispatch) => {
    dispatch(beginApiCall());

    configurationGroupApi
      .updateConfigurationGroup(auth, id, config)
      .then((configurationGroup) => {
        dispatch({
          type: types.UPDATE_CONFIGURATION_GROUP_SUCCESS,
          payload: {
            configurationGroup: configurationGroup,
          },
        });
        toast.success(
          "Configuration group " + configurationGroup.Name + " updated!"
        );
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };

export const removeConfigurationGroup = (auth, id) => async (dispatch) => {
  dispatch(beginApiCall());

  configurationGroupApi
    .removeConfigurationGroup(auth, id)
    .then(() => {
      dispatch({
        type: types.REMOVE_CONFIGURATION_GROUP_SUCCESS,
        payload: {
          configurationGroup: id,
        },
      });
      toast.error("Configuration Group removed");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};
