import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Navigate } from "react-router";
import { logout } from "../../actions/authActions";
import PropTypes from "prop-types";
import Spinner from "../common/Spinner";

const LogOutPage = ({ auth }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logout());
  }, []);

  return <>{auth.isLoggedIn == true ? <Spinner /> : <Navigate to="/" />}</>;
};

LogOutPage.propTypes = {
  auth: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LogOutPage);

