import * as types from "./actionsTypes";
import * as tachoObjectApi from "../api/tachoObjectApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export const fetchTachoObjects = (auth, licensePlates) => async (dispatch) => {
  dispatch(beginApiCall());
  tachoObjectApi
    .getTachoByLicense(auth, licensePlates)
    .then((tachoObjects) => {
      dispatch({
        type: types.LOAD_TACHO_OBJECT_SUCCESS,
        payload: {
          tachoObjects: tachoObjects,
        }
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};