import React from "react";
import PropTypes from "prop-types";
import { Modal, Form, Col, Button, Row } from "react-bootstrap";

const SettingsProfileSettingsModal = ({
    settingsProfile,
    inputHandler,
    nextPage,
    handleClose,
    firmwares,
}) => {

    const fws = [...firmwares].sort((a, b) => (parseInt(a.Version) > parseInt(b.MessageType) ? 1 : -1))
    return (
        <>
            <Modal.Body>
                <Modal.Title>Settings</Modal.Title>
                <Form.Group as={Row}>
                    <Form.Label column lg={3}>
                        Firmware
                    </Form.Label>
                    <Col>
                        <Form.Control
                            as="select"
                            name="applicationVersion"
                            value={settingsProfile.applicationVersion}
                            onChange={inputHandler}
                        >
                            <option key="null" value="0">
                                No firmware sync
                            </option>
                            {fws && fws.map((firmware) => {
                                return (
                                    <option key={firmware.Id} value={firmware.Version}>
                                        {firmware.Version}
                                    </option>
                                )
                            })}
                        </Form.Control>
                    </Col>
                </Form.Group>
                <hr />
                <Form.Group as={Row}>
                    <Form.Label column lg={3}>
                        iButton
                    </Form.Label>
                    <Col>
                        <Form.Check
                            name="iButton"
                            value={settingsProfile.iButton}
                            defaultChecked={settingsProfile.iButton == "True" ? true : false}
                            onChange={inputHandler}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column lg={3}>
                        PrivateSwitchEnabled
                    </Form.Label>
                    <Col>
                        <Form.Check
                            name="PrivateSwitchEnabled"
                            value={settingsProfile.PrivateSwitchEnabled}
                            defaultChecked={
                                settingsProfile.PrivateSwitchEnabled == "True" ? true : false
                            }
                            onChange={inputHandler}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column lg={3}>
                        TachoLiveEnabled
                    </Form.Label>
                    <Col>
                        <Form.Check
                            name="TachoLiveEnabled"
                            value={settingsProfile.TachoLiveEnabled}
                            defaultChecked={
                                settingsProfile.TachoLiveEnabled == "True" ? true : false
                            }
                            onChange={inputHandler}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column lg={3}>
                        Tacho RDL
                    </Form.Label>
                    <Col>
                        <Form.Check
                            name="TachoRDLEnabled"
                            value={settingsProfile.TachoRDLEnabled}
                            defaultChecked={
                                settingsProfile.TachoRDLEnabled == "True" ? true : false
                            }
                            onChange={inputHandler}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column lg={3}>
                        External5VEnabled
                    </Form.Label>
                    <Col>
                        <Form.Check
                            name="external5VEnabled"
                            value={settingsProfile.External5VEnabled}
                            defaultChecked={
                                settingsProfile.External5VEnabled == "True" ? true : false
                            }
                            onChange={inputHandler}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column lg={3}>
                        DrivingStyleEventsEnabled
                    </Form.Label>
                    <Col>
                        <Form.Check
                            name="drivingStyleEventsEnabled"
                            value={settingsProfile.drivingStyleEventsEnabled}
                            defaultChecked={
                                settingsProfile.drivingStyleEventsEnabled == "True" ? true : false
                            }
                            onChange={inputHandler}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column lg={3}>
                        OutputTogglingEnabled
                    </Form.Label>
                    <Col>
                        <Form.Check
                            name="outputTogglingEnabled"
                            value={settingsProfile.outputTogglingEnabled}
                            defaultChecked={
                                settingsProfile.outputTogglingEnabled == "True" ? true : false
                            }
                            onChange={inputHandler}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column lg={3}>
                        TimedIntervalRelativeToSpeed
                    </Form.Label>
                    <Col>
                        <Form.Check
                            name="timedIntervalRelativeToSpeed"
                            value={settingsProfile.timedIntervalRelativeToSpeed}
                            defaultChecked={
                                settingsProfile.timedIntervalRelativeToSpeed == "True" ? true : false
                            }
                            onChange={inputHandler}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column lg={3}>
                        HighResolutionRecordingEnabled
                    </Form.Label>
                    <Col>
                        <Form.Check
                            name="highResolutionRecordingEnabled"
                            value={settingsProfile.highResolutionRecordingEnabled}
                            defaultChecked={
                                settingsProfile.highResolutionRecordingEnabled == "True" ? true : false
                            }
                            onChange={inputHandler}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column lg={3}>
                        CANWakeUpEnabled
                    </Form.Label>
                    <Col>
                        <Form.Check
                            name="canWakeupEnabled"
                            value={settingsProfile.CANWakeUpEnabled}
                            defaultChecked={
                                settingsProfile.CANWakeUpEnabled == "True" ? true : false
                            }
                            onChange={inputHandler}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column lg={3}>
                        MinuteRuleEnabled
                    </Form.Label>
                    <Col>
                        <Form.Check
                            name="minuteRuleEnabled"
                            value={settingsProfile.minuteRuleEnabled}
                            defaultChecked={
                                settingsProfile.minuteRuleEnabled == "True" ? true : false
                            }
                            onChange={inputHandler}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column lg={3}>
                        StandbyMessageInterval (s)
                    </Form.Label>
                    <Col>
                        <Form.Control
                            type="number"
                            name="standbyMessageInterval"
                            defaultValue={settingsProfile.standbyMessageInterval}
                            onChange={inputHandler}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column lg={3}>
                        TimedMessageInterval (s)
                    </Form.Label>
                    <Col>
                        <Form.Control
                            type="number"
                            name="timedMessageInterval"
                            defaultValue={settingsProfile.timedMessageInterval}
                            onChange={inputHandler}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column lg={3}>
                        iButtonClearInterval (s)
                    </Form.Label>
                    <Col>
                        <Form.Control
                            type="number"
                            name="iButtonClearInterval"
                            defaultValue={settingsProfile.iButtonClearInterval}
                            onChange={inputHandler}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column lg={3}>
                        ChargingMessageInterval (s)
                    </Form.Label>
                    <Col>
                        <Form.Control
                            type="number"
                            name="chargingMessageInterval"
                            defaultValue={settingsProfile.chargingMessageInterval}
                            onChange={inputHandler}
                        />
                    </Col>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={nextPage}>Next</Button>
            </Modal.Footer>
        </>
    )
}

SettingsProfileSettingsModal.propTypes = {
    settingsProfile: PropTypes.object.isRequired,
    inputHandler: PropTypes.func.isRequired,
    nextPage: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    firmwares: PropTypes.array.isRequired,
}

export default (SettingsProfileSettingsModal)