import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/api/configurationgroups/";

export function loadConfigurationGroups(auth) {
  return fetch(baseUrl, {
    headers: { Authorization: auth.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function loadConfigurationGroupsByCustomer(auth, customerId) {
  return fetch(`${baseUrl}customer/${customerId}`, {
    headers: { Authorization: auth.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function loadConfigGroupsBySearch(
  auth,
  searchTerm,
  filter,
  customer = null
) {
  return customer === null
    ? fetch(`${baseUrl}search/${searchTerm}/${filter}`, {
        headers: { Authorization: auth.accessToken },
      })
        .then(handleResponse)
        .catch(handleError)
    : fetch(`${baseUrl}search/${searchTerm}/${filter}/${customer}`, {
        headers: { Authorization: auth.accessToken },
      })
        .then(handleResponse)
        .catch(handleError);
}

export function getConfigurationGroupsCount(auth) {
  return fetch(baseUrl + "all/", {
    headers: { Authorization: auth.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveConfigurationGroup(auth, config) {
  return fetch(baseUrl, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: auth.accessToken,
    },
    body: JSON.stringify(config),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function updateConfigurationGroup(auth, id, config) {
  return fetch(baseUrl + id, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      Authorization: auth.accessToken,
    },
    body: JSON.stringify(config),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function removeConfigurationGroup(auth, id) {
  return fetch(baseUrl + id, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: auth.accessToken,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}
