import React, { useState} from "react";
import PropTypes from "prop-types";

// Router
import { Link } from "react-router-dom";

// Components
import UpdateCustomerModal from "./UpdateCustomerModal";
import { SortedTable } from "../../common/SortedTable";
import SortButton from "../../common/SortButton";

import {
  removeCustomer,
  getCustomerById,
  updateCustomer,
} from "../../../actions/customerActions";

// Redux
import { connect, useDispatch } from "react-redux";
import { Button, Table } from "react-bootstrap";
import EditIcon from "../../common/icons/pen-to-square-solid.svg";
import DeleteIcon from "../../common/icons/trash-can-solid.svg";
import Confirmation from "../../common/Confirmation";

const CustomerList = ({ auth, customers, rtdIntegrations }) => {
  const dispatch = useDispatch();
  const { list, requestSort, sortConfig } = SortedTable(customers);

  // const [filteredCustomers, setFilteredCustomers] = useState([]);

  const [show, setShow] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  // useEffect(() => {
  //   if (isViewer) {
  //     setFilteredCustomers(customers.filter(customer => customer.IsReseller));
  //   } else {
  //     setFilteredCustomers(customers);
  //   }
  // }, [auth.user.Role, customers]);


  const removeCustomerHandler = (customer) => {
    if (auth.user.CustomerId === customer.Id) {
      alert("You can't delete yourself!");
      return;
    }
    dispatch(removeCustomer(auth, customer.Id));
  };

  const showModalHandler = (id) => {
    dispatch(getCustomerById(auth, id));
    setSelectedCustomer(
      customers.find((customer) => customer.Id === id)
    );

    setShow(true);
  };

  const onSubmit = (values) => {
    values.RTDIntegrationId = values.RTDIntegrationId === "" ? 0 : values.RTDIntegrationId;
    dispatch(updateCustomer(auth, selectedCustomer.Id, values));
    setShow(false);
  };


  return (
    <div className="customer">
      <Table responsive hover striped>
        <thead>
          <tr>
            <th>
              Name
              <SortButton
                name="Name"
                requestSort={requestSort}
                sortConfig={sortConfig}
              />
            </th>
            <th>
              Country
              <SortButton
                name="Country"
                requestSort={requestSort}
                sortConfig={sortConfig}
              />
            </th>
            <th>
              Main Reseller
              <SortButton
                name="Reseller"
                requestSort={requestSort}
                sortConfig={sortConfig}
              />
            </th>
            <th>
              IsReseller
              <SortButton
                name="IsReseller"
                requestSort={requestSort}
                sortConfig={sortConfig}
              />
            </th>
            <th>
              Integration
              <SortButton
                name="RTDIntegrations"
                requestSort={requestSort}
                sortConfig={sortConfig}
              />
            </th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {list.length === 0 ? (
            <tr>
              <td colSpan="15" style={{ textAlign: "center" }}>
                No customers found
              </td>
            </tr>
          ) : (
            list.map((customer) => {
              const isViewer = auth.user.Role === "Viewer"
              return (
                <tr className="table-row" key={customer.Id}>
                  <td>
                    <Link to={"/customer/" + customer.Id}>{customer.Name}</Link>
                  </td>
                  <td>{customer.Country}</td>
                  <td>{customer.Reseller}</td>
                  <td>{customer.IsReseller ? "Yes" : "No"}</td>
                  <td>{rtdIntegrations.map((integration) => (customer.RTDIntegration && customer.RTDIntegrationId === integration.Id) ? integration.Name : "")}</td>
                  {/* function allowed to have a preview as a Viewer Role */}
                  {!isViewer && (
                    <>
                      <td>
                        <Button
                          className="shadow-sm border-secondary"
                          variant="light"
                          onClick={() => showModalHandler(customer.Id)}
                        >
                          <img src={EditIcon} alt="Edit" />
                        </Button>
                      </td>
                      <td>
                        <Button className="shadow-sm border-secondary" variant="light">
                          <Confirmation
                            buttonText={<img src={DeleteIcon} alt="Delete" />}
                            description={`Are you sure you want to remove this customer?`}
                            onConfirm={() => removeCustomerHandler(customer)}
                          />
                        </Button>
                      </td>
                    </>
                  )}
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
      {selectedCustomer && (
        <UpdateCustomerModal
          show={show}
          setShow={setShow}
          onSubmit={onSubmit}
          customer={selectedCustomer}
          rtdIntegrations={rtdIntegrations}
        />
      )}
    </div>
  );
};

CustomerList.propTypes = {
  auth: PropTypes.object.isRequired,
  customers: PropTypes.array.isRequired,
  rtdIntegrations: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);
