import React from "react";
import { Modal, Form, Button, Row} from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { ErrorMessage, Field, Formik } from "formik";
import { validationSchema } from "../Validation/ValidationSchema";

const UpdateAuthServer = ({
    show,
    setShow,
    onSubmit,
    authServer,
    customers,
}) => {
    const handleClose = () => setShow(false);

    const initialValues = {
        customerId: authServer.CustomerId,
        name: authServer.Name,
        ip: authServer.IP,
        devicePort: authServer.DevicePort,
        port: authServer.Port,
        username: authServer.Username,
        password: authServer.Password,
        status: authServer.Status,
    };

    return (
        <div>
            <Modal show={show} onHide={handleClose} centered className="text-center">
                <Modal.Header closeButton>
                    <Modal.Title>Update Authentication Server</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ errors, touched, values, handleSubmit }) => (
                        <Form onSubmit={handleSubmit} className="text-left">
                            <Modal.Body>
                                <Row>
                                    <Form.Label>Customer</Form.Label>
                                    <Field
                                        as="select"
                                        name="customerId"
                                        value={values.customerId}
                                        className={
                                            errors.customerId && touched.customerId
                                                ? "form-control is-invalid"
                                                : "form-control"
                                        }
                                    >
                                        <option>--</option>
                                        {customers.map((customer) => (
                                            <option key={customer.Id} value={customer.Id}>
                                                {customer.Name}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage
                                        name="customerId"
                                        component="div"
                                        className="invalid-feedback"
                                    />

                                    {/* Rest of your form fields */}
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" type="submit">
                                    Save Authentication Server
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    );
};

UpdateAuthServer.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    authServer: PropTypes.object,
    customers: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        customers: state.customers,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateAuthServer);
