import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Styling
import { Form, Button, Modal, OverlayTrigger, Tooltip, Row } from "react-bootstrap";

const CreateUserModal = ({
  auth,
  show,
  setShow,
  saveNewUser,
  roles,
  newUser,
  setNewUser,
}) => {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [validForm, setValidForm] = useState(false);

  const inputHandler = (e) => {
    let value = "";
    if (e.target.name === "username") {
      value = e.target.value.replace(/[^\w\s]/gi, "");
    } else {
      value = e.target.value;
    }
    setNewUser({
      ...newUser,
      [e.target.name]: value,
    });
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {
        "Make sure your username consists of more than 3 characters and you've entered a valid email. A strong password is also required."
      }
    </Tooltip>
  );

  useEffect(() => {
    let validEmail;
    let validPassword;
    let validRole;

    validEmail = newUser.email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    validPassword = newUser.password.length > 6;
    validPassword = newUser.password === newUser.passwordConf;
    validRole = newUser.roleId !== "null";

    if (validEmail && validPassword && validRole) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [newUser]);

  const isViewer = auth.user.Role === "Viewer";
  return (
    <div className="create-object">
      {!isViewer && (
        <Button
          className="create-object-button"
          variant="primary"
          onClick={handleShow}
        >
          Add users
        </Button>
      )}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body className="show-grid">
            <Row>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                name="email"
                value={newUser.email}
                placeholder="Enter email"
                onChange={inputHandler}
              />
            </Row>
            <Row>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={newUser.password}
                placeholder="Enter password"
                onChange={inputHandler}
              />
            </Row>
            <Row>
              <Form.Label>Password Confirmation</Form.Label>
              <Form.Control
                type="password"
                name="passwordConf"
                value={newUser.passwordConf}
                placeholder="Leave blank to keep the same"
                onChange={inputHandler}
              />
              {newUser.password !== newUser.passwordConf && (
                <p className="text-invalid">
                  <small> Passwords dont match.</small>
                </p>
              )}
            </Row>
            <Row>
              <Form.Label>Role</Form.Label>
              <Form.Control
                as="select"
                name="roleId"
                value={newUser.roleId}
                onChange={inputHandler}
              >
                <option value="null" key="null">
                  --
                </option>
                {roles.map((role) => {
                  return (
                    <option value={role.Id} key={role.Id}>
                      {role.Name}
                    </option>
                  );
                })}
              </Form.Control>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>

            {!validForm ? (
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <span className="d-inline-block">
                  <Button
                    variant="primary"
                    style={!validForm ? { pointerEvents: "none" } : null}
                    disabled={!validForm}
                    onClick={saveNewUser}
                  >
                    Save User
                  </Button>
                </span>
              </OverlayTrigger>
            ) : (
              <Button
                variant="primary"
                disabled={!validForm}
                onClick={saveNewUser}
              >
                Save User
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default CreateUserModal;

CreateUserModal.propTypes = {
  auth: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  saveNewUser: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  newUser: PropTypes.object.isRequired,
  setNewUser: PropTypes.func.isRequired,
};
