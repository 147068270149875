import React from "react";
import PropTypes from "prop-types";
import {Button} from "react-bootstrap";
import ChangeCustomerModal from "./ChangeCustomerModal";

const DevicesFooter = ({
                           show,
                           setShow,
                           selected,
                           customer,
                           setCustomer,
                           configurationGroup,
                           setConfigurationGroup,
                           saveDevices,
                           removeSingleSelect,
                           showModalHandlerMulti,
                           disableOptions
                       }) => {
    return (
        <>
            <div className="footer d-flex flex-row">
                <div className="ml-auto mx-2">
                    <p className="footer-devices-text my-auto mx-0">
                        {selected.length} {selected.length > 1 ? "devices" : "device"} selected
                        <Button
                            size="sm"
                            variant="primary"
                            className="footer-button ml-2 shadow-sm"
                            onClick={() => {
                                showModalHandlerMulti();
                            }}
                        >
                            Change Customer
                        </Button>
                    </p>
                </div>
            </div>
            {!disableOptions && (
                <>
                    {selected.length > 1 && (
                        <ChangeCustomerModal
                            show={show}
                            setShow={setShow}
                            selected={selected}
                            customer={customer}
                            setCustomer={setCustomer}
                            configurationGroup={configurationGroup}
                            setConfigurationGroup={setConfigurationGroup}
                            saveDevices={saveDevices}
                            removeSingleSelect={removeSingleSelect}
                        />
                    )}
                </>
            )}
        </>
    );
};

DevicesFooter.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    selected: PropTypes.array.isRequired,
    customer: PropTypes.string.isRequired,
    setCustomer: PropTypes.func.isRequired,
    configurationGroup: PropTypes.string.isRequired,
    setConfigurationGroup: PropTypes.func.isRequired,
    saveDevices: PropTypes.func.isRequired,
    disableOptions: PropTypes.bool.isRequired,
    showModalHandlerMulti: PropTypes.func.isRequired,
    removeSingleSelect: PropTypes.func.isRequired,
};

export default DevicesFooter;
