import { combineReducers } from "redux";
import apiCallsInProgress from "./apiStatusReducer";
import devices from "./deviceReducer";
import auth from "./authReducer";
import endpoints from "./endpointReducer";
import customers from "./customerReducer";
import rtdIntegrations from "./rtdIntegrationsReducer";
import dashboard from "./dashboardReducer";
import deviceDetails from "./deviceDetailsReducer";
import rawMessages from "./rawMessageReducer";
import logs from "./logReducer";
import roles from "./roleReducer";
import users from "./userReducer";
import features from "./featureReducer";
import customerDetails from "./customerDetailsReducer";
import account from "./accountReducer";
import configurationGroups from "./configurationGroupReducer";
import authServers from "./authServerReducer";
import tachoDownloads from "./tachoDownloadReducer";
import customTestData from "./customTestDataReducer";
import deviceTest from "./deviceTestReducer";
import deviceTests from "./deviceTestsReducer";
import deviceTestSettings from "./deviceTestSettingsReducer";
import messageTypes from "./messageTypesReducer";
import protocols from "./protocolsReducer";
import canConfigs from "./canConfigReducer";
import firmwares from "./firmwareReducer";
import firmwareFlags from "./firmwareFlagsReducer";
import endOfList from "./listStatusReducer"
import deviceTypes from "./deviceTypesReducer";
import tachoObjects from "./tachoObjectReducer";
import tachoDrivers from "./tachoDriverReducer";
import settingsProfiles from "./settingsProfileReducer"

const appReducer = combineReducers({
  auth,
  account,
  roles,
  customers,
  rtdIntegrations,
  customerDetails,
  configurationGroups,
  dashboard,
  devices,
  deviceDetails,
  authServers,
  tachoDownloads,
  customTestData,
  endpoints,
  features,
  apiCallsInProgress,
  rawMessages,
  deviceTypes,
  logs,
  users,
  deviceTest,
  deviceTests,
  deviceTestSettings,
  messageTypes,
  protocols,
  canConfigs,
  firmwares,
  firmwareFlags,
  tachoObjects,
  tachoDrivers,
  endOfList,
  settingsProfiles,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
