import * as companyCardApi from "../api/companyCardApi";
import * as types from "./actionsTypes";
import { toast } from "react-toastify";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export const updateCompanyCard =
  (auth, id, companyCard) => async (dispatch) => {
    dispatch(beginApiCall());

    companyCardApi
      .updateCompanyCard(auth, id, companyCard)
      .then((companyCard) => {
        dispatch({
          type: types.UPDATE_COMPANY_CARD_SUCCESS,
          payload: {
            companyCard: companyCard,
          },
        });
        toast.success("Company Card succesfully updated");
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
