import React, { useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { Button, Table } from "react-bootstrap"
import { SortedTable } from "../../common/SortedTable"
import SortButton from "../../common/SortButton"
import EditIcon from "../../common/icons/pen-to-square-solid.svg"
import DeleteIcon from "../../common/icons/trash-can-solid.svg"
import Confirmation from "../../common/Confirmation"
import { removeSettingsProfile } from "../../../actions/settingsProfileActions"
import UpdateSettingsProfile from "./UpdateSettingsProfile";
import Spinner from "../../common/Spinner";

const SettingsProfilesList = ({
    auth,
    settingsProfiles,
    customers,
    firmwares,
}) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const { list, requestSort, sortConfig } = SortedTable(settingsProfiles)
    const [selectedSettingsProfile, setSelectedSettingsProfile] = useState()

    const showModalHandler = (e) => {
        setShow(true); 
        selectProfile(e);
    }

    const selectProfile = (id) => {
        setSelectedSettingsProfile(
            settingsProfiles.find((profile) => {
                return profile.Id == id
            })
        )
    }

    const removeSettingsProfileHandler = (id) => {
        dispatch(removeSettingsProfile(auth, id))
    }

    return (
        <div className="customer">
            <Table striped hover responsive>
                <thead >
                    <tr>
                        <th>
                            Name
                            <SortButton
                                key="Name"
                                name="Name"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Customer
                            <SortButton
                                key="Customer"
                                name="Customer"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Firmware
                            <SortButton
                                key="Firmware"
                                name="Firmware"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Devices
                            <SortButton
                                key="Devices"
                                name="Devices"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list && customers ? (
                            list.map((profile) => {
                                const isViewer = auth.user.Role === "Viewer";
                                const cust = customers.find((customer) => customer.Id == profile.CustomerId)
                                return (
                                    <tr
                                        className="table-row"
                                        key={profile.Id}
                                    >
                                        <td>{profile.Name}</td>
                                        <td>{cust && cust.Name ? cust.Name : ""}</td>
                                        <td>{profile.applicationVersion != "0" ? profile.applicationVersion : "No firmware sync"}</td>
                                        <td>{profile.devices !== undefined ? profile.devices : "-"}</td>
                                        <td>
                                            {!isViewer &&
                                                <Button className="shadow-sm border-secondary" variant="light"
                                                    onClick={() => {
                                                        showModalHandler(profile.Id)
                                                    }}>
                                                    <img
                                                        src={EditIcon}
                                                        alt="Edit"
                                                    />
                                                </Button>}
                                        </td>
                                        <td>
                                            {!isViewer &&
                                                <Button className="shadow-sm border-secondary" variant="light">
                                                    <Confirmation
                                                        buttonText={
                                                            <img
                                                                src={DeleteIcon}
                                                                alt="Delete"
                                                            />
                                                        }
                                                        description={`Are you sure you want to remove this settings profile?`}
                                                        onConfirm={() => removeSettingsProfileHandler(profile.Id)}
                                                    />
                                                </Button>}
                                        </td>
                                    </tr>
                                )
                            })
                        ) : (
                            <Spinner />
                        )
                    }
                </tbody>
            </Table>
            {
                selectedSettingsProfile && (
                    <UpdateSettingsProfile
                        show={show}
                        setShow={setShow}
                        selectedSettingsProfile={selectedSettingsProfile}
                        setSelectedSettingsProfile={setSelectedSettingsProfile}
                        firmwares={firmwares}
                    />
                )
            }
        </div>
    )
}

SettingsProfilesList.propTypes = {
    auth: PropTypes.object.isRequired,
    customers: PropTypes.array.isRequired,
    settingsProfiles: PropTypes.array.isRequired,
    firmwares: PropTypes.array.isRequired,
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        settingsProfiles: state.settingsProfiles,
        customers: state.customers,
        firmwares: state.firmwares,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsProfilesList)