import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Styling
import { Form, Button, Modal, Row } from "react-bootstrap";

const CreateUserForCustomerModal = ({
  auth,
  show,
  setShow,
  saveCustomerUser,
  newUser,
  setNewUser,
  roles,
}) => {
  const [validForm, setValidForm] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const inputHandler = (e) => {
    const value = e.target.value;
    setNewUser({
      ...newUser,
      [e.target.name]: value,
    });
  };

  useEffect(() => {
    let validEmail;
    let validPassword;

    validEmail = newUser.email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    validPassword = newUser.password.length >= 6;
    validPassword = newUser.password === newUser.passwordConf;

    if (validEmail && validPassword) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [newUser]);

  const isViewer = auth.user.Role === "Viewer";

  return (


    <div className="create-user">
      {!isViewer && (
        <Button onClick={handleShow} style={{ float: "right" }}>
          Add user
        </Button>
      )}


      <Modal show={show} onHide={handleClose} centered className="text-center">
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="text-left">
            <Row>
              <Form.Label>Email</Form.Label>
              <Form.Control
                placeholder="Enter email"
                type="text"
                name="email"
                value={newUser.email}
                onChange={inputHandler}
              />
            </Row>
            <Row>
              <Form.Label>Password</Form.Label>
              <Form.Control
                placeholder="Enter password"
                type="password"
                name="password"
                value={newUser.password}
                onChange={inputHandler}
              />
            </Row>
            <Row>
              <Form.Label>Password Confirmation</Form.Label>
              <Form.Control
                placeholder="Enter password confirmation"
                type="password"
                name="passwordConf"
                value={newUser.passwordConf}
                onChange={inputHandler}
              />
              {newUser.password !== newUser.passwordConf && (
                <p className="text-invalid">
                  <small> Passwords dont match.</small>
                </p>
              )}
            </Row>
            <Row>
              <Form.Label>Role</Form.Label>
              <Form.Control
                as="select"
                name="roleId"
                value={newUser.roleId}
                onChange={inputHandler}
              >
                <option value="null" key="null">
                  --
                </option>
                {roles.map((role) => {
                  return (
                    <option value={role.Id} key={role.Id}>
                      {role.Name}
                    </option>
                  );
                })}
              </Form.Control>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={!validForm}
            onClick={saveCustomerUser}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateUserForCustomerModal;

CreateUserForCustomerModal.propTypes = {
  auth: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  saveCustomerUser: PropTypes.func.isRequired,
  newUser: PropTypes.object.isRequired,
  setNewUser: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
};
