import React from "react";
import { Chart } from "react-google-charts";

const VehicleStateGraph = () => {

  const stats = [
    ['', 'Available', 'Soon Available', 'Not on Schedule'],
    ['Vehicle by Law', 10, 24, 20],
    ['Planned Vehicle RDL', 16, 22, 23]
  ];

  const options = {
    isStacked: 'percent',
    height: 225,
    legend: { position: 'top' },
    series: {
      0: { color: 'green' },
      1: { color: 'orange' },
      2: { color: 'red' }
    }
  };

  return (
    <Chart
      chartType="BarChart"
      data={stats}
      options={options}
      width="100%"
      height="100%"
    />
  );
};

export default VehicleStateGraph;