import React, {useState} from "react";
import PropTypes from "prop-types";
import {connect, useDispatch} from "react-redux";

import {deleteFirmware, updateFirmware} from "../../actions/firmwareActions";

import {SortedTable} from "../common/SortedTable";
import SortButton from "../common/SortButton";

import UpdateFirmware from "./components/UpdateFirmware";

import EditIcon from "../common/icons/pen-to-square-solid.svg";

// Time
import moment from "moment";
import {Button} from "react-bootstrap";
import Confirmation from "../common/Confirmation";
import DeleteIcon from "../common/icons/trash-can-solid.svg";

const FirmwareList = ({auth, firmwares, firmwareFlags}) => {
        const dispatch = useDispatch();
        const [showModal, setShowModal] = useState(false);
        const {list, requestSort, sortConfig} = SortedTable(firmwares);
        const [selectedFirmware, setSelectedFirmware] = useState({});

        const selectFirmware = (e) => {
            setSelectedFirmware(
                firmwares.find((firmware) => {
                    return firmware.Id === e;
                })
            );
        };

        const onSubmitFirmwareUpdate = (id, firmware) => {
            var formData = new FormData();

            formData.append("Id", id);
            formData.append("Version", firmware.Version);
            formData.append("Release", firmware.Release);
            formData.append("FirmwareFlag", firmware.FirmwareFlag);
            formData.append("DeviceType", firmware.DeviceType);
            formData.append("Active", firmware.Active);
            if (firmware.Firmware != null) {
                formData.append(
                    "Firmware",
                    firmware.Firmware,
                    firmware.Firmware.name
                );
                formData.append("Filename", firmware.Firmware.name);
            } else {
                formData.append("Firmware", "");
                formData.append("Filename", "");
            }

            dispatch(updateFirmware(auth, id, formData));
        }

        const showModalHandler = (e) => {
            setShowModal(true);
            selectFirmware(e);
        }

        const removeUserHandler = (id) => {
            setShowModal(false);
            dispatch(deleteFirmware(auth, id));
        }

        return (
            <>
                <table className="table table-striped table-hover">
                    <thead className="thead-inverse">
                    <tr>
                        <th className="device-table-customer">
                            Version
                            <SortButton
                                name="Version"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Filename
                            <SortButton
                                name="Filename"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Release
                            <SortButton
                                name="Release"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Flag
                            <SortButton
                                name="Type Firmware"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Created
                            <SortButton
                                name="Created"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            DeviceType
                            <SortButton
                                name="DeviceType"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Active
                            <SortButton
                                name="Active"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {list.length === 0 ? (
                        <tr>
                            <td colSpan="9" style={{textAlign: "center"}}>
                                Nothing found
                            </td>
                        </tr>
                    ) : (
                        list.map((firmware) => {
                            return (
                                <tr className="table-row" key={firmware.Id}>
                                    <td className="timeStamp-table-cell">{firmware.Version}</td>
                                    <td>{firmware.Filename}</td>
                                    <td>{firmware.Release ? "Release" : "Test Version"}</td>
                                    <td>{firmwareFlags.find(x => x.Id === firmware.FirmwareFlag)?.Flag ?? "No Flag"}</td>
                                    <td>
                                        {moment(firmware.Created).format("DD-MM-YYYY HH:mm:ss")}
                                    </td>
                                    <td>
                                        {
                                            firmware.DeviceType == 4 ? "QAN-ALL 4G" :
                                                firmware.DeviceType == 2 ? "QAN-ALL 2G" :
                                                    firmware.DeviceType == 1 ? "QAN-HUB" : "Unknown Device"
                                        }
                                    </td>
                                    <td>
                                        {firmware.Active ? "Active" : "Inactive"}
                                    </td>
                                    <td>
                                        <Button className="shadow-sm border-secondary d-flex" variant="light"
                                                onClick={() => {
                                                    showModalHandler(firmware.Id)
                                                }}>
                                            <img
                                                className="m-auto"
                                                src={EditIcon}
                                                alt="Edit"
                                            />
                                        </Button>
                                    </td>
                                    {firmware.Active ? (
                                        <td></td>
                                    ) : (
                                        <td>
                                            <Button className="shadow-sm border-secondary d-flex" variant="light">
                                                <Confirmation
                                                    buttonText={
                                                        <img
                                                            src={DeleteIcon}
                                                            alt="Delete"
                                                        />
                                                    }
                                                    description={`Are you sure you want to remove this user?`}
                                                    onConfirm={() => removeUserHandler(firmware.Id)}
                                                />
                                            </Button>
                                        </td>
                                    )}
                                </tr>
                            );
                        })
                    )}
                    </tbody>
                </table>

                {showModal && (
                    <UpdateFirmware
                        show={showModal}
                        setShow={setShowModal}
                        firmware={selectedFirmware}
                        onSubmitFirmwareUpdate={onSubmitFirmwareUpdate}
                    />
                )}
            </>
        );
    }
;

FirmwareList.propTypes = {
    auth: PropTypes.object.isRequired,
    firmwares: PropTypes.array.isRequired,
    firmwareFlags: PropTypes.array.isRequired
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        firmwareFlags: state.firmwareFlags,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FirmwareList);
