import * as types from "./actionsTypes";
import * as deviceModemRequestApi from "../api/deviceModemRequestApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import { toast } from "react-toastify";
import * as _ from "lodash"

export const getModemrequestsByDevice = (auth, imei) => async (dispatch) => {
  dispatch(beginLoadingDevice());

  deviceModemRequestApi
    .getModemrequestsByDevice(auth, imei)
    .then((requests) => {
      dispatch({
        type: types.LOAD_DEVICE_MODEM_REQUESTS_SUCCESS,
        payload: {
          modemRequests: requests,
        },
      });
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const saveModemRequest = (auth, imei, request) => async (dispatch) => {
  _.debounce(() => dispatch(beginApiCall()));

  deviceModemRequestApi
    .saveModemrequestForDevice(auth, imei, request)
    .then((req) => {
      dispatch({
        type: types.SAVE_DEVICE_MODEM_REQUEST_SUCCESS,
        payload: {
          modemRequest: req,
        },
      });
      toast.success("The request has been sent");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const updateModemRequest = (auth, imei, request) => async (dispatch) => {
  _.debounce(() => dispatch(beginApiCall()));

  deviceModemRequestApi
    .updateModemrequestForDevice(auth, imei, request)
    .then((req) => {
      dispatch({
        type: types.UPDATE_DEVICE_MODEM_REQUEST_SUCCESS,
        payload: {
          modemRequest: req,
        },
      });
      toast.success("The request has been updated");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

export const deleteModemRequest = (auth, id) => async (dispatch) => {
  dispatch(beginApiCall());

  deviceModemRequestApi
    .removeModemrequestForDevice(auth, id)
    .then(() => {
      dispatch({
        type: types.REMOVE_DEVICE_MODEM_REQUEST_SUCCESS,
        payload: {
          Id: id,
        },
      });
      toast.warning("The request has been removed");
    })
    .catch((error) => {
      dispatch(apiCallError(error));
    });
};

const beginLoadingDevice = () => async (dispatch) => {
  dispatch(beginApiCall());
  
  dispatch({
    type: "LOADING_DEVICE",
    payload: {
      isLoading: true,
    },
  });
};
