import React, {useEffect} from "react";
import PropTypes from "prop-types";

import {Table, Button, Modal} from "react-bootstrap";
import {SortedTable} from "../../common/SortedTable";
import SortButton from "../../common/SortButton";
import {connect, useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {updateCompanyCard} from "../../../actions/companyCardActions";

const AddDevicesToCompanyCard = ({
                                     auth,
                                     show,
                                     setShow,
                                     selected,
                                     setSelected,
                                     companyCard,
                                     devices,
                                     disableOptions,
                                     clearAllSelected,
                                 }) => {
    const dispatch = useDispatch();
    const handleClose = () => {
        clearAllSelected();
        setShow(false)
    };
    const {requestSort, sortConfig} = SortedTable(devices);

    useEffect(() => {
        if (companyCard) {
            setSelected(companyCard.Devices);
        }
    }, [companyCard]);

    const tableRowSelected = (e) => {
        if (e.target.checked === true) {
            let selectedList = [...selected, e.target.value];
            setSelected(selectedList);
        } else {
            let unSelectedList = [...selected];
            let index = unSelectedList.indexOf(e.target.value);
            if (index !== -1) {
                unSelectedList.splice(index, 1);
                setSelected(unSelectedList);
            }
        }
    };

    const setCheckboxes = (e) => {
        if (e.target.checked === true) {
            let deviceList = [];
            devices.forEach((device) => {
                deviceList.push(device.IMEI);
            });
            setSelected(deviceList);
        } else {
            setSelected([]);
        }
    };

    const saveDevicesToCompanyCard = () => {
        let newcompanycard = {...companyCard, Devices: selected};
        dispatch(updateCompanyCard(auth, companyCard.Id, newcompanycard));
        handleClose();
    };

    return (
        <Modal size="xl" show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>
                    {companyCard.CardNumber} - {companyCard.CompanyName}{" "}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table hover responsive striped>
                    <thead>
                    <tr>
                        <th className="checkbox-table-cell">
                            <input
                                disabled={disableOptions}
                                type="checkbox"
                                className="checkbox-table-style"
                                checked={devices.length === selected.length}
                                onChange={(e) => setCheckboxes(e)}
                            />
                        </th>
                        <th>
                            IMEI
                            <SortButton
                                name="IMEI"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            SN
                            <SortButton
                                name="SN"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Hardware
                            <SortButton
                                name="Hardware"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            ConfigurationGroup
                            <SortButton
                                name="ConfigurationGroup"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            ICCID
                            <SortButton
                                name="ICCID"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            InSync
                            <SortButton
                                name="InSync"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {devices.length === 0 ? (
                        <tr>
                            <td colSpan="14" style={{textAlign: "center"}}>
                                No devices found
                            </td>
                        </tr>
                    ) : (
                        devices.map((device) => {
                            return (
                                <tr
                                    className={"table-row " + (selected.includes(device.IMEI) ? "row-selected" : "")}
                                    key={device.IMEI}
                                >
                                    <td className="options-table-cell">
                                        <input
                                            disabled={disableOptions}
                                            type="checkbox"
                                            className="options-table-cell"
                                            name="device"
                                            value={device.IMEI}
                                            onChange={tableRowSelected}
                                            checked={selected.includes(device.IMEI)}
                                        />
                                    </td>
                                    <td>
                                        <Link to={"/device/" + device.Id}>{device.IMEI}</Link>
                                    </td>
                                    <td>{device.SN}</td>
                                    <td>{device.Hardware}</td>
                                    <td>{device.ConfigurationGroup}</td>
                                    <td>{device.ICCID}</td>
                                    <td>{device.InSync ? "Yes" : "No"}</td>
                                </tr>
                            );
                        })
                    )}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={saveDevicesToCompanyCard}>
                    Save Devices
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

AddDevicesToCompanyCard.propTypes = {
    auth: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    selected: PropTypes.array.isRequired,
    setSelected: PropTypes.func.isRequired,
    companyCard: PropTypes.object.isRequired,
    devices: PropTypes.array.isRequired,
    disableOptions: PropTypes.bool.isRequired,
    clearAllSelected: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        devices: state.customerDetails.devices,
    };
}

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddDevicesToCompanyCard);
