import React from "react";
import PropTypes from "prop-types";
// import {Chart} from "react-google-charts";
import { PieChart, Pie, LabelList } from "recharts";
import { Spinner } from "react-bootstrap";

const RenderPieChart = (data) => {
    const names = data.data.map((x) => {
        let keys = Object.keys(x);
        return keys[0];
    });
    const values = data.data.map(x => x.Value);

    const stats = [
        // ['', ''],
        { name: names[0], value: values[0] },
        { name: setNameFormat(names[1]), value: values[1] }
    ];

    // const options = {
    //     chartArea: {
    //         left: '20%',
    //         right: 0,
    //         bottom: 20,
    //         top: 20,
    //     },
    //     pieSliceText: 'label',
    //     slices: {
    //         1: { offset: 0.1 },
    //     },
    // };

    function setNameFormat(name) {
        switch (name) {
            case 'Insync':
                return 'Out of Sync';
            case 'ValidJson':
                return 'Invalid Json';
            default:
                return 'Other';
        }
    }

    return (
        <>
            {stats.length > 0 ? (
                <div className="d-flex h-100">
                    <PieChart width={500} height={300}>
                        <Pie
                            dataKey="value"
                            data={stats}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            legendType="line"
                            outerRadius={150}
                            fill="#0771B8"
                            label={false}
                        >
                        <LabelList dataKey="name" position="inside"/>
                        </Pie>
                    </PieChart>
                </div>
            ) : (
                <div className="d-flex h-100">
                    <Spinner className="m-auto" animation="border" variant="primary" />
                </div>
            )}
            {/* <Chart
                width="100%"
                height="300px"
                chartType="PieChart"
                loader={
                    <div className="d-flex h-100">
                        <Spinner className="m-auto" animation="border" variant="primary" />
                    </div>
                }
                data={stats}
                options={options}
                className="pieChart"
            >
            
            </Chart> */}
        </>
    );
};

RenderPieChart.propTypes = {
    data: PropTypes.array.isRequired,
};

export default RenderPieChart;
