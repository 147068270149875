import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const rtdIntegrationsReducer = (state = initialState.rtdIntegrations, action) => {
  switch (action.type) {
    case types.LOAD_RTDINTEGRATIONS_SUCCESS:
      return action.payload.rtdIntegrations;
  //   case types.SAVE_CUSTOMER_SUCCESS:
  //     return [...state, action.payload.rtdIntegrations];
  //   case types.UPDATE_CUSTOMER_SUCCESS:
  //     return [
  //       ...state.filter(
  //         (customer) => customer.Id != action.payload.customer.Id
  //       ),
  //       action.payload.customer,
  //     ];
  //   case types.REMOVE_CUSTOMER_SUCCESS:
  //     return state.filter((customer) => customer.Id != action.payload.customer);
    default:
      return state;
  }
};

export default rtdIntegrationsReducer;
