import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const deviceModemRequestReducer = (
  state = initialState.deviceDetails.modemRequests,
  action
) => {
  switch (action.type) {
    case types.LOAD_DEVICE_MODEM_REQUESTS_SUCCESS:
      return action.payload.modemRequests;

    case types.SAVE_DEVICE_MODEM_REQUEST_SUCCESS:
      return [
        ...state,
        action.payload.modemRequest
      ];

    case types.UPDATE_DEVICE_MODEM_REQUEST_SUCCESS:
      return state.map(modemRequest =>
        modemRequest.Id === action.payload.modemRequest.Id
          ? action.payload.modemRequest
          : modemRequest
      );

    case types.REMOVE_DEVICE_MODEM_REQUEST_SUCCESS:
      console.log(action.payload)
      return state.filter(modemRequest => modemRequest.Id !== action.payload.Id);

    default:
      return state;
  }
};

export default deviceModemRequestReducer;
