import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const userReducer = (state = initialState.users, action) => {
  switch (action.type) {
    case types.LOAD_USERS_SUCCESS:
      return action.payload.users;
    case types.SAVE_USER_SUCCESS:
      return [...state, action.payload.user];
    case types.UPDATE_USER_SUCCESS:
      return [
        ...state.filter((user) => user.Id != action.payload.user.Id),
        action.payload.user,
      ];
    case types.REMOVE_USER_SUCCESS:
      return state.filter((user) => user.Id != action.payload.user);
    default:
      return state;
  }
};

export default userReducer;
