import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Redux
import { useDispatch, connect } from "react-redux";
import {
    loadDeviceChunk,
    loadDeviceChunkFromId,
    loadDevicesByCustomer,
    loadSearchedDevices
} from "../../actions/deviceActions";
import {
    saveDevicesToCustomer,
    removeDevicesFromCustomer,
} from "../../actions/deviceCustomerConnActions";
import { fetchCustomers } from "../../actions/customerActions";


// Components
import DeviceList from "./components/DeviceList";
import Spinner from "../common/Spinner";
import DevicesFooter from "./components/DevicesFooter";

// Styling
import { Card } from "react-bootstrap";
import "./DevicesPage.css";
import "./DevicesPage.scss";
import {
    removeConfigurationFromDevices,
    saveConfigurationToDevices,
} from "../../actions/deviceConfigurationGroupActions";
import LazySearchBar from "../common/LazySearchBar";
import LazyLoader from "../common/LazyLoader";
import * as DevicesApi from "../../api/deviceApi";
import { apiCallError } from "../../actions/apiStatusActions";

const DevicesPage = ({ auth, devices, customers, apiCallsInProgress, endOfList }) => {
    const dispatch = useDispatch();
    const [deviceList, setDeviceList] = useState([]);
    //const [searchActive, setSearchActive] = useState(false);
    const [selected, setSelected] = useState([]);
    const [singleSelect, setSingleSelect] = useState("");
    const [show, setShow] = useState(false);
    const [resultCount, setResultCount] = useState(0);
    const [customer, setCustomer] = useState("null");
    const [configurationGroup, setConfigurationGroup] = useState("null");
    const filters = [
        "IMEI",
        "SN",
        "Customer",
        "ConfigurationGroup",
        "ICCID",
        "Firmware",
        "DeviceName",
        "Protocol",
        "ProtocolName",
    ];

    useEffect(() => {
        refresh();
        disabledOptions();
    }, []);

    useEffect(() => {
        if (Array.isArray(devices)) {
            setDeviceList([...devices]);
        } else {
            console.error("Expected devices to be an array, but got:", devices);
        }
    }, [devices]);

    const refresh = (id = null) => {
        if (apiCallsInProgress === 0) {
            getCustomers(auth);
            DevicesApi
                .getDevicesCount(auth)
                .then((resultCount) => {
                    setResultCount(resultCount);
                })
                .catch((error) => {
                    dispatch(apiCallError(error));
                })
            if (id === null) {
                dispatch(loadDeviceChunk(auth));
            } else {
                dispatch(loadDeviceChunkFromId(auth, id))
            }
        }
    };

    const getCustomers = (auth) => {
        dispatch(fetchCustomers(auth));
    }

    const setSearchActive = () => {
    };

    const getSearchResult = (searchTerm, filter, customer = null) => {
        dispatch(loadSearchedDevices(auth, searchTerm, filter, customer));
    };

    const getDevicesByCustomer = (customer) => {
        dispatch(loadDevicesByCustomer(auth, customer));
    };

    const saveDevices = () => {
        const selectedDeviceList = devices.filter((device) => {
            return selected.includes(device.IMEI);
        });

        saveCustomer(customer, selectedDeviceList);
        saveConfiguration(configurationGroup, selectedDeviceList);

        handleClose();
    };

    const saveCustomer = (customer, devices) => {
        if (customer != "null") {
            // check if customer has changed and return only id's in an object;
            const filtered = {
                devices: devices
                    .filter((device) => {
                        return device.CustomerId != customer;
                    })
                    .map((device) => {
                        return device.IMEI;
                    }),
            };
            if (filtered.devices.length === 0) return;
            dispatch(saveDevicesToCustomer(auth, customer, filtered));
        } else {
            // check if customer isnt already null and return only id's in an object;
            const filtered = {
                devices: devices
                    .filter((device) => {
                        return device.Customer != null;
                    })
                    .map((device) => {
                        return device.IMEI;
                    }),
            };
            if (filtered.devices.length === 0) return;
            dispatch(removeDevicesFromCustomer(auth, filtered));
        }
    };

    const saveConfiguration = (configurationGroup, devices) => {
        if (configurationGroup != "null") {
            // check if configuration has changed and return only id's in an object;
            const filtered = {
                devices: devices
                    .filter((device) => {
                        return device.ConfigurationGroupId != configurationGroup;
                    })
                    .map((device) => {
                        return device.IMEI;
                    }),
            };
            if (filtered.devices.length === 0) return;
            dispatch(saveConfigurationToDevices(auth, configurationGroup, filtered));
        } else {
            // check if configurationGroup isnt already null and return only id's in an object;
            const filtered = {
                devices: devices
                    .filter((device) => {
                        return device.ConfigurationGroup != null;
                    })
                    .map((device) => {
                        return device.IMEI;
                    }),
            };
            if (filtered.devices.length === 0) return;
            dispatch(removeConfigurationFromDevices(auth, filtered));
        }
    };

    const handleClose = () => {
        setShow(false);
        setCustomer("null");
        setConfigurationGroup("null");
        setSelected([]);
        setSingleSelect("");
    };

    const removeSelectOnOutsideclick = (e) => {
        if (e.target.id !== "") {
            setSelected([]);
            setSingleSelect("");
        }
    };

    const [disableOptions, setDisableOptions] = useState(true);
    const disabledOptions = () => {

        if (auth.user.IsReseller) {
            setDisableOptions(false);
        } else {
            setDisableOptions(true);
        }
    };

    const addSingleSelect = (device) => {
        setSingleSelect(device);
        if (selected.includes(device)) {
            return;
        }
        let selectedList = [...selected, device];
        setSelected(selectedList);
    };

    const showModalHandler = (e) => {
        setShow(true);
        addSingleSelect(e);
    }

    const showModalHandlerMulti = () => {
        setShow(true);
    }

    const removeSingleSelect = () => {
        let unSelectedList = [...selected];
        if (unSelectedList.indexOf(singleSelect) !== -1) {
            let newSelectedList = unSelectedList.filter((device) => device !== singleSelect);
            setSelected(newSelectedList);
        }
        setSingleSelect("");
    };

    return (
        <div
            className="devices"
            id="devices-list-page"
            onClick={removeSelectOnOutsideclick}
        >
            <h2 className="title">Devices</h2>
            <LazySearchBar
                filters={filters}
                refresh={refresh}
                customers={customers}
                resultCount={resultCount}
                getSearchResult={getSearchResult}
                setSearchActive={setSearchActive}
                getObjectByCustomer={getDevicesByCustomer}
                apiCallsInProgress={apiCallsInProgress}
            />
            <br />
            <Card>
                <>
                    {apiCallsInProgress === 0 ? (
                        <>
                            <DeviceList
                                selected={selected}
                                setCustomer={setCustomer}
                                features={auth.user.Features}
                                setSelected={setSelected}
                                customer={customer}
                                saveDevices={saveDevices}
                                setShow={setShow}
                                configurationGroup={configurationGroup}
                                devices={deviceList}
                                show={show}
                                disableOptions={disableOptions}
                                setConfigurationGroup={setConfigurationGroup}
                                removeSingleSelect={removeSingleSelect}
                                showModalHandler={showModalHandler}
                                auth={auth}
                            />
                            <LazyLoader
                                refresh={refresh}
                                collection={deviceList}
                                apiCallsInProgress={apiCallsInProgress}
                                endOfList={endOfList} />
                        </>
                    ) : (
                        <Spinner />
                    )}
                </>
            </Card>
            {selected.length != 0
                ?
                <DevicesFooter
                    show={show}
                    setShow={setShow}
                    selected={selected}
                    customer={customer}
                    setCustomer={setCustomer}
                    configurationGroup={configurationGroup}
                    setConfigurationGroup={setConfigurationGroup}
                    saveDevices={saveDevices}
                    disableOptions={disableOptions}
                    showModalHandlerMulti={showModalHandlerMulti}
                    removeSingleSelect={removeSingleSelect}
                />
                : null}
        </div>
    );
};

DevicesPage.propTypes = {
    auth: PropTypes.object.isRequired,
    devices: PropTypes.array.isRequired,
    customers: PropTypes.array.isRequired,
    apiCallsInProgress: PropTypes.number.isRequired,
    endOfList: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        devices: state.devices,
        customers: state.customers,
        apiCallsInProgress: state.apiCallsInProgress,
        endOfList: state.endOfList,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DevicesPage);
