import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/api/canconfig/";

export function getCANConfigs(auth) {
  return fetch(baseUrl, {
    headers: { Authorization: auth.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getactiveCANConfigs(auth) {
  return fetch(baseUrl + "active/", {
    headers: { Authorization: auth.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getCANConfigById(auth, id) {
  return fetch(baseUrl + id, {
    headers: { Authorization: auth.accessToken },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function putCANConfigById(auth, id, data) {
  return fetch(baseUrl + id, {
    method: "PUT",
    headers: {
      Authorization: auth.accessToken,
    },
    body: data,
    redirect: "follow",
  })
    .then(handleResponse)
    .catch(handleError);
}

export const saveCANConfig = (auth, canConfig) => {
  return fetch(baseUrl, {
    method: "POST",
    headers: {
      Authorization: auth.accessToken,
    },
    body: canConfig,
    redirect: "follow",
  })
    .then(handleResponse)
    .catch(handleError);
};

export function massUpdateCANConfig(auth, request) {
  return fetch(baseUrl + "massUpdate/" + request.protocolCode + "/" + request.protocolCodeToUpdateTo, {
      method: "POST",
      headers: {
          Authorization: auth.accessToken,
      },
  })
      .then(handleResponse)
      .catch(handleError)
}