import PropTypes from "prop-types";
import {Button} from "react-bootstrap";
import moment from "moment";
import React from "react";


const DeviceRawMessagesList = ({rawMessages, refresh}) => {
    return (
        <>
            <table className="table table-striped table-hover">
                <thead className="thead-inverse">
                <tr>
                    <th>Timestamp</th>
                    <th>ValidJson</th>
                    <th>Message</th>
                    <th>
                        <Button
                            style={{float: "right"}}
                            variant="light"
                            onClick={() => refresh(null)}
                        >
                            ⟳
                        </Button>
                    </th>
                </tr>
                </thead>
                <tbody className="raw-messages-table-body">
                {rawMessages.map((rawmessage) => {
                    return (
                        <tr className="table-row" key={rawmessage.Id}>
                            <td>
                                {moment(rawmessage.TimeStampServer).format(
                                    "DD-MM-YYYY HH:mm:ss"
                                )}
                            </td>
                            <td>{rawmessage.ValidJson === true ? "Yes" : ""}</td>
                            <td colSpan="2">{rawmessage.Message}</td>
                        </tr>
                    );
                })}


                </tbody>
            </table>
        </>
    )
}

DeviceRawMessagesList.propTypes = {
    rawMessages: PropTypes.array.isRequired,
    refresh: PropTypes.func.isRequired,
};

export default DeviceRawMessagesList;