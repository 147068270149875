import {connect} from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {Field, Formik, useFormik} from "formik";
import * as Yup from 'yup';

const UpdateCanConfig = ({
                             show,
                             setShow,
                             canConfig,
                             deviceTypes,
                             updateCanConfigHandler,
                         }) => {
    const handleClose = () => setShow(false);

    const SUPPORTED_FORMATS = [
        "s2s",
        "tlqs"
    ];

    const validationSchema = Yup.object().shape({
        Protocol: Yup.string()
            .required("Protocol is Required")
            .min(6, "Protocol need to be greater than 6 characters.")
            .max(10, "Protocol need to be less than 7 characters."),
        Version: Yup.string()
            .min(1, "Version need to be greater than 6 characters.")
            .max(10, "Version need to be less than 7 characters.")
            .required("Version is Required"),
        Brand: Yup.string()
            .required("Brand is Required"),
        Name: Yup.string()
            .min(3, "Name need to be greater than 3 characters.")
            .max(50, "Name need to be less than 50 characters.")
            .required("Name is Required"),
        Active: Yup.string()
            .required("Status is Required"),
        DeviceType: Yup.string()
            .required("Device Type is Required"),
        Config: Yup.mixed()
            .nullable()
            .test(
                'fileSize',
                'File too large',
                value => value == null ? true : value && value.size <= 36864 // 36 Kb
            )
            .test('fileFormat',
                'Unsupported Format, Formats supported: .s2s',
                value => {
                    if (value == null) return true; // if no file selected, skip validation

                    let regex = /(?:\.([^.]+))?$/;
                    let ext = regex.exec(value?.name)[1];

                    return value && SUPPORTED_FORMATS.includes(ext.toLowerCase())
                })
    })

    const initialValues = {
        Protocol: canConfig.Protocol,
        Version: canConfig.Version ?? "",
        Brand: canConfig.Brand,
        Name: canConfig.Name,
        Active: canConfig.Active,
        DeviceType: canConfig.DeviceType,
        Config: null,
    };

    const onSubmit = (values) => updateCanConfigHandler(canConfig.Id, values)

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: onSubmit,
    });

    return (
        <div>
            <Modal show={show} onHide={handleClose} centered className="text-center">
                <Modal.Header closeButton>
                    <Modal.Title>Update QTLibrary</Modal.Title>
                </Modal.Header>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                >
                    {({
                          handleSubmit,
                          setFieldValue,
                          errors,
                          touched
                      }) => (
                        <Form onSubmit={handleSubmit} className="text-left">
                            <Modal.Body>
                                <Form.Label>Protocol Code</Form.Label>
                                <Field
                                    type="text"
                                    name="Protocol"
                                    className={`form-control ${touched.Protocol && errors.Protocol ? "is-invalid" : ""}`}
                                    placeholder="Protocol"
                                />
                                {touched.Protocol && errors.Protocol ? (
                                    <div className="invalid-feedback">{errors.Protocol}</div>
                                ) : null}

                                <Form.Label>Version</Form.Label>
                                <Field
                                    type="text"
                                    name="Version"
                                    className={`form-control ${touched.Version && errors.Version ? "is-invalid" : ""}`}
                                    placeholder="Version"
                                />
                                {touched.Version && errors.Version ? (
                                    <div className="invalid-feedback">{errors.Version}</div>
                                ) : null}

                                <Form.Label>Brand</Form.Label>
                                <Field
                                    type="text"
                                    name="Brand"
                                    className={`form-control ${touched.Brand && errors.Brand ? "is-invalid" : ""}`}
                                    placeholder="Brand"
                                />
                                {touched.Brand && errors.Brand ? (
                                    <div className="invalid-feedback">{errors.Brand}</div>
                                ) : null}

                                <Form.Label>Name</Form.Label>
                                <Field
                                    type="text"
                                    name="Name"
                                    className={`form-control ${touched.Name && errors.Name ? "is-invalid" : ""}`}
                                    placeholder="Name"
                                />
                                {touched.Name && errors.Name ? (
                                    <div className="invalid-feedback">{errors.Name}</div>
                                ) : null}

                                <Form.Label>Status</Form.Label>
                                <Field
                                    as="select"
                                    name="Active"
                                    className={`form-control ${touched.Active && errors.Active ? "is-invalid" : ""}`}
                                >
                                    <option key={false} value={false}>
                                        Inactive
                                    </option>
                                    <option key={true} value={true}>
                                        Active
                                    </option>
                                </Field>
                                {touched.Active && errors.Active ? (
                                    <div className="invalid-feedback">{errors.Active}</div>
                                ) : null}

                                <Form.Label>Device Type</Form.Label>
                                <Field
                                    as="select"
                                    name="DeviceType"
                                    className={`form-control ${touched.DeviceType && errors.DeviceType ? "is-invalid" : ""}`}
                                >
                                    {deviceTypes.map((devicetype) => (
                                        <option key={devicetype.Id} value={devicetype.Id}>
                                            {devicetype.Name}
                                        </option>
                                    ))
                                    }
                                </Field>
                                {touched.DeviceType && errors.DeviceType ? (
                                    <div className="invalid-feedback">{errors.DeviceType}</div>
                                ) : null}

                                <Form.Label>QTLibrary File</Form.Label>
                                <input
                                    type="file"
                                    name="Config"
                                    className={`form-control ${touched.Config && errors.Config ? "is-invalid" : ""}`}
                                    placeholder="Config"
                                    onChange={(event) => {
                                        canConfig.Filename = event.currentTarget.files[0].name;
                                        setFieldValue("Config", event.currentTarget.files[0]);
                                    }}
                                />
                                <small>{canConfig.Filename != null ? `Current File: ${canConfig.Filename}` : "No File Found!"}</small>
                                {touched.Config && errors.Config ? (
                                    <div className="invalid-feedback">{errors.Config}</div>
                                ) : null}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" type="submit" disabled={!formik.isValid}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    );
};

UpdateCanConfig.propTypes = {
    auth: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    deviceTypes: PropTypes.array.isRequired,
    updateCanConfigHandler: PropTypes.func.isRequired,
    canConfig: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        deviceTypes: state.deviceTypes,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCanConfig);
