import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Redux
import { useDispatch, connect } from "react-redux";

import { fetchCustomers } from "../../../actions/customerActions";
//import { fetchConfigurationGroupsByCustomer } from "../../../actions/configurationGroupActions";
import {
  saveSingleDeviceToCustomer,
  removeDevicesFromCustomer,
} from "../../../actions/deviceCustomerConnActions";
import {
  saveConfigurationToSingleDevice,
  removeConfigurationToSingleDevice,
} from "../../../actions/deviceConfigurationGroupActions";
import { getDeviceById, saveDeviceName, saveDeviceNote } from "../../../actions/deviceActions";

// Components
import Spinner from "../../common/Spinner";

import moment from "moment";

// Styling
import { Form, Row, Col, Button } from "react-bootstrap";
import { DeviceinfoHeader } from "./components/DeviceinfoHeader";
import { DeviceCustomer } from "./components/DeviceCustomer";
import { saveDeviceSettings } from "../../../actions/deviceSettingActions";
import { getSettingsProfiles } from "../../../actions/settingsProfileActions";

const DeviceInfoPaneV2 = ({
  auth,
  device,
  state,
  currentConfig,
  canConfigs,
  firmwares,
  newConfig,
  customers,
  isLoading,
  settingsProfiles,
}) => {
  const dispatch = useDispatch();
  const [configurationGroup, setConfigurationGroup] = useState();
  const [customer, setCustomer] = useState();
  const [note, setNote] = useState(device.Note);
  const [name, setName] = useState(device.Name);

  const [newConfigSettings, setNewConfigSettings] = useState({}); // NEWFORUX
  const [changedSettings, setChangedSettings] = useState({});

  const refresh = () => {
    setChangedSettings({});
    dispatch(fetchCustomers(auth));
    dispatch(getDeviceById(auth, device.Id));
    dispatch(getSettingsProfiles(auth))
    setConfigValues();
  };

  useEffect(() => {
    refresh();
  }, []);

  const setConfigValues = () => {
    setNewConfigSettings({
      BooterVersion: newConfig.BooterVersion,
      CAN1Version: newConfig.CAN1Version,
      CAN2Version: newConfig.CAN2Version,
      ConfigVersion: newConfig.ConfigVersion,
      ProtocolCode: newConfig.ProtocolCode,
      Firmware: newConfig.Firmware,
    });
  };

  useEffect(() => {
    if (device != null && currentConfig != null) {
      setCustomer(device.CustomerId);
      setConfigurationGroup(device.ConfigurationGroupId);
    }
  }, [device]);

  // useEffect(() => {
  //   if (customer != undefined && customer != "null") {
  //     // Disabled for Clear Error Handing, Need to adjusted
  //     // dispatch(fetchConfigurationGroupsByCustomer(auth, customer));
  //   }
  // }, [customer]);

  const inputHandler = (e) => {
    const value = e.target.value;
    setNewConfigSettings({
      ...newConfigSettings,
      [e.target.name]: value,
    });
    setChangedSettings({
      ...changedSettings,
      [e.target.name]: value,
    });
  };

  const noteHandler = (e) => {
    const value = e.target.value;
    setNote(value);
  }

  const nameHandler = (e) => {
    const value = e.target.value;
    setName(value);
  }

  const saveNewInformation = (e) => {
    e.preventDefault();

    var deviceList = {
      devices: [device.IMEI],
    };

    // If customer has changed
    if (device.CustomerId != customer) {
      if (customer === "null") {
        dispatch(removeDevicesFromCustomer(auth, deviceList));
        if (device.ConfigurationGroup !== null) {
          dispatch(removeConfigurationToSingleDevice(auth, deviceList));
        }
        return;
      } else {
        dispatch(saveSingleDeviceToCustomer(auth, customer, deviceList));
      }
    }

    if (device.Note != note) {
      dispatch(saveDeviceNote(auth, device.IMEI, note));
      console.log(note)
      console.log(device)
    }

    if (device.Name != name) {
      dispatch(saveDeviceName(auth, device.IMEI, name))
    }

    // If configuration has changed
    if (device.ConfigurationGroupId != configurationGroup) {
      if (configurationGroup === "--") {
        dispatch(removeConfigurationToSingleDevice(auth, deviceList));
      } else {
        dispatch(
          saveConfigurationToSingleDevice(auth, configurationGroup, deviceList)
        );
      }
    }

    var changeList = [];
    for (const property in changedSettings) {
      if (newConfig[property] == changedSettings[property]) continue;
      var obj = {
        SettingName: property,
        Value: changedSettings[property],
      };
      changeList.push(obj);
    }

    dispatch(
      saveDeviceSettings(auth, device.Id, device.IMEI, {
        SettingsList: changeList,
      })
    );
  };

  const disableOptions = () => {
    return !auth.user.IsReseller;
  };

  const isViewer = auth.user.Role === "Viewer";
  const profile = settingsProfiles.find((e) => e.Id == currentConfig.settingsProfileID)

  return (
    <>
      {!isLoading ? (
        <Form>
          <DeviceinfoHeader device={device} state={state} />
          {/* <DeviceControlRow device={device} auth={auth} /> */}
          <hr />
          <h5>Information</h5>
          <DeviceCustomer
            disableOptions={disableOptions}
            setCustomer={setCustomer}
            customer={customer}
            customers={customers}
          />

          {/* is now in settings tab, so removed */}
          {/* <DeviceConfigurationGroup
            configurationGroups={configurationGroups}
            disableOptions={disableOptions}
            configurationGroup={configurationGroup}
            setConfigurationGroup={setConfigurationGroup}
          /> */}
          <hr></hr>

          <Form.Group as={Row}>
            <Form.Label column sm="3">
              <h6>
                Booter Version:{" "}
                <i>
                  {currentConfig.BooterVersion == newConfig.BooterVersion
                    ? ""
                    : " (" + currentConfig.BooterVersion + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control as="select" disabled={true}>
                <option>{newConfigSettings.BooterVersion}</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              <h6>
                Protocol Code:{" "}
                <i>
                  {currentConfig.ProtocolCode == newConfigSettings.ProtocolCode
                    ? ""
                    : " (" + currentConfig.ProtocolCode + ")"}
                  {device.DeviceType == 2 && (currentConfig.CAN1Version < "2207000" || currentConfig.CAN2Version < "2207000")
                    ? "(OLD CAN ver)"
                    : ""}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                as="select"
                name="ProtocolCode"
                disabled={device.DeviceType == 2 && (currentConfig.CAN1Version < "2207000" || currentConfig.CAN2Version < "2207000") ? true : false}
                value={newConfigSettings.ProtocolCode}
                onChange={(e) => inputHandler(e)}
              >

                {canConfigs.filter(e => e.Protocol == currentConfig.ProtocolCode).length == 0 ?
                  <option key={currentConfig.ProtocolCode} value={currentConfig.ProtocolCode} >{currentConfig.ProtocolCode} - CurrentVersion</option> : <></>}

                {canConfigs.filter(e => e.DeviceType == device.DeviceType).map((canconfig) => {
                  return (
                    <option key={canconfig.Protocol} value={canconfig.Protocol}>
                      {canconfig.Protocol} - {canconfig.Brand} - {canconfig.Name}
                    </option>
                  );
                })}

              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              <h6>
                Firmware:{" "}
                <i>
                  {currentConfig.Firmware == newConfigSettings.Firmware
                    ? ""
                    : " (" + currentConfig.Firmware + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                as="select"
                name="Firmware"
                value={newConfigSettings.Firmware}
                disabled={profile && profile.applicationVersion != "0"}  // todo: also check if profile has sync enabled
                onChange={(e) => inputHandler(e)}
              >

                {firmwares.filter(e => e.Version == currentConfig.Firmware).length == 0 ?
                  <option key={currentConfig.Firmware} value={currentConfig.Firmware} >{currentConfig.Firmware} - CurrentVersion</option> : <></>}

                {firmwares.filter(e => e.DeviceType == device.DeviceType && (e.FirmwareFlag == 1 || e.FirmwareFlag == 2)).map((firmware) => {
                  return (
                    <option key={firmware.Version} value={firmware.Version}>
                      {firmware.Version} - {firmware.FirmwareFlag == 1 ? "Booter" : "Application"} - {" "}
                      {moment(firmware.Created).format("DD-MM-YYYY HH:MM")}
                    </option>
                  );
                })}

              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              <h6>ICCID:</h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control plaintext readOnly defaultValue={device.ICCID} />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              <h6>
                APN:
                <i>
                  {currentConfig.APN == newConfig.APN
                    ? ""
                    : " (" + currentConfig.APN + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                plaintext
                readOnly
                defaultValue={currentConfig.APN}
              />
            </Col>
          </Form.Group>
          {/* <Form.Group as={Row}>
            <Form.Label column sm="3">
              <h6>
                GPS / ModemVersion:
                <i>
                  {currentConfig.ModemVersion == newConfig.ModemVersion
                    ? ""
                    : " (" + currentConfig.ModemVersion + ")"}
                </i>
              </h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                plaintext
                readOnly
                defaultValue={currentConfig.ModemVersion}
              />
            </Col>
          </Form.Group> */}
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              <h6>Config in sync:</h6>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                plaintext
                readOnly
                defaultValue={device.InSync ? "Yes" : "No"}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column lg={3}>
              Note
              <i>
                { }
              </i>
            </Form.Label>
            <Col>
              <Form.Control
                type="string"
                name="Note"
                defaultValue={device.Note != note ? note : device.Note}
                onChange={noteHandler}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column lg={3}>
              Name
              <i>
                { }
              </i>
            </Form.Label>
            <Col>
              <Form.Control
                type="string"
                name="Name"
                defaultValue={device.Name != name ? name : device.Name}
                onChange={nameHandler}
              />
            </Col>
          </Form.Group>
          <hr></hr>
          <Form.Group>
            <Row>
              <Col sm="11">
                <Button variant="outline-warning" type="submit">
                  Back
                </Button>
              </Col>
              <Col sm="1">
                {!isViewer && (
                  <Button
                    variant="success"
                    onClick={(e) => saveNewInformation(e)}
                  >
                    Save
                  </Button>
                )}
              </Col>
            </Row>
          </Form.Group>
        </Form>
      ) : (
        <Spinner />
      )}
    </>
  );
};

DeviceInfoPaneV2.propTypes = {
  auth: PropTypes.object.isRequired,
  device: PropTypes.object.isRequired,
  state: PropTypes.object,
  configurationGroups: PropTypes.array.isRequired,
  currentConfig: PropTypes.object,
  newConfig: PropTypes.object.isRequired,
  customers: PropTypes.array.isRequired,
  canConfigs: PropTypes.array.isRequired,
  firmwares: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  settingsProfiles: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    device: state.deviceDetails.device,
    state: state.deviceDetails.state,
    configurationGroups: state.deviceDetails.configurationGroups,
    currentConfig: state.deviceDetails.currentConfig,
    newConfig: state.deviceDetails.newConfig,
    customers: state.customers,
    canConfigs: state.canConfigs,
    firmwares: state.firmwares,
    isLoading: state.deviceDetails.isLoading,
    settingsProfiles: state.settingsProfiles,
  };
}
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceInfoPaneV2);
