import initialState from "./initialState";
import * as types from "../actions/actionsTypes";

const deviceTestSettingsReducer = (state = initialState.deviceTestSettings, action) => {
    switch (action.type) {
        case types.GET_ALL_DEVICETESTSETTINGS_SUCCESS:
            return action.payload.deviceTestSettings;
        case types.SET_DEVICETEST_ACTIVE:
            return state.filter((devTestSetting) => {
                if (devTestSetting.imei === action.payload.deviceTestSettings.imei) {
                    devTestSetting.IsActive = true;
                    return [...state];
                } else {
                    return [...state];
                }
            })
        case types.SET_DEVICETEST_INACTIVE:
            return state.filter((devTestSetting) => {
                if (devTestSetting.imei === action.payload.deviceTestSettings.imei) {
                    devTestSetting.IsActive = false;
                    return [...state];
                } else {
                    return [...state];
                }
            })
        case types.GET_DEVICESETTINGS_SUCCESS:
            return [...state, action.payload.deviceTestSettings];
        case types.SAVE_DEVICETESTSETTINGS_SUCCESS:
            return [...state, action.payload.deviceTestSettings];
        case types.UPDATE_DEVICETESTSETTINGS_SUCCESS:
            return [
                ...state.filter(
                    (deviceTestSetting) => deviceTestSetting.Id !== action.payload.deviceTestSettings.Id),
                action.payload.deviceTestSettings
            ];
        default:
            return state;
    }
};

export default deviceTestSettingsReducer;