import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const accountReducer = (state = initialState.account, action) => {
  switch (action.type) {
    case types.LOAD_ACCOUNT_SUCCESS:
      return {
        users: action.payload.account.Users,
        customer: action.payload.account.Customer,
      };
    case types.UPDATE_ACCOUNT_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: action.payload.customer,
      };
    case types.SAVE_ACCOUNT_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload.user],
      };
    case types.UPDATE_ACCOUNT_USER_SUCCESS:
      return {
        ...state,
        users: [
          ...state.users.filter((user) => user.Id != action.payload.user.Id),
          action.payload.user,
        ],
      };
    case types.REMOVE_ACCOUNT_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter((user) => user.Id != action.payload.user),
      };
    default:
      return state;
  }
};

export default accountReducer;
