import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/api/companycards/";

export const updateCompanyCard = (auth, id, companyCard) => {
  return fetch(baseUrl + id, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      Authorization: auth.accessToken,
    },
    body: JSON.stringify(companyCard),
  })
    .then(handleResponse)
    .catch(handleError);
};
