import React from 'react';
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import {Modal, Button, Form} from "react-bootstrap";

const DeviceSettingsModal = ({
                                 customers,
                                 canConfigs,
                                 configurationGroups,
                                 deviceInputSettings,
                                 showSettingsModal,
                                 startDeviceTesting,
                                 setDeviceInputSettings,
                                 saveDeviceTestSettingsToDb,
                                 handleDeviceSettingsModalClose,
                             }) => {

    const inputHandler = (e) => {
        if (e.target.value === "null") return;

        setDeviceInputSettings({
            ...deviceInputSettings,
            [e.target.name]: e.target.value,
        })
    }

    return (
        <>
            <Modal
                size="md"
                show={showSettingsModal}
                onHide={handleDeviceSettingsModalClose}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Device Test Settings: {deviceInputSettings.Imei}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="text-left">
                        <Form.Label>Customer</Form.Label>
                        <Form.Control
                            as="select"
                            name="CustomerId"
                            value={deviceInputSettings.CustomerId}
                            onChange={inputHandler}
                            required
                        >
                            <option key="null" value="null">
                                --
                            </option>
                            {customers.map((customer) => {
                                return (
                                    <option key={customer.Id} value={customer.Id}>
                                        {customer.Name}
                                    </option>
                                );
                            })}
                        </Form.Control>
                        <Form.Label>Configuration</Form.Label>
                        <Form.Control
                            as="select"
                            name="ConfigurationGroupId"
                            value={deviceInputSettings.ConfigurationGroupId}
                            onChange={inputHandler}
                            required
                        >
                            <option key="null" value="null">
                                --
                            </option>
                            {configurationGroups.map((configurationGroup) => {
                                return (
                                    <option key={configurationGroup.Id} value={configurationGroup.Id}>
                                        {configurationGroup.Name}
                                    </option>
                                );
                            })}
                        </Form.Control>
                        <Form.Label>Can Protocol</Form.Label>
                        <Form.Control
                            as="select"
                            name="Protocol"
                            value={deviceInputSettings.Protocol}
                            onChange={inputHandler}
                            required
                        >
                            <option key="" value="">
                                --
                            </option>
                            {canConfigs.map((canConfig) => {
                                return (
                                    <option key={canConfig.Protocol} value={canConfig.Protocol}>
                                        {canConfig.Name}
                                    </option>
                                );
                            })}
                        </Form.Control>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-secondary" onClick={handleDeviceSettingsModalClose}>Close</Button>
                    <Button onClick={saveDeviceTestSettingsToDb}>Save Settings</Button>
                    <Button onClick={startDeviceTesting}>Start Test</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

DeviceSettingsModal.propTypes = {
    customers: PropTypes.array.isRequired,
    canConfigs: PropTypes.array.isRequired,
    configurationGroups: PropTypes.array.isRequired,
    deviceInputSettings: PropTypes.object.isRequired,
    showSettingsModal: PropTypes.bool.isRequired,
    startDeviceTesting: PropTypes.func.isRequired,
    setDeviceInputSettings: PropTypes.func.isRequired,
    saveDeviceTestSettingsToDb: PropTypes.func.isRequired,
    handleDeviceSettingsModalClose: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        customers: state.customers,
        canConfigs: state.canConfigs,
        configurationGroups: state.configurationGroups,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSettingsModal);