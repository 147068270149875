import * as types from "../actions/actionsTypes"
import initialState from "./initialState"

const settingsProfileReducer = (
    state = initialState.settingsProfiles,
    action
) => {
    switch (action.type) {
        case types.LOAD_SETTINGS_PROFILES_SUCCESS:
            return action.payload.settingsProfiles;
        case types.SAVE_SETTINGS_PROFILE_SUCCESS:
            return [
                ...state.filter((profile) => profile.Id != action.payload.settingsProfile.Id),
                action.payload.settingsProfile
            ]
        case types.DELETE_SETTINGS_PROFILE_SUCCESS:
            return state.filter(settingsProfile => settingsProfile.Id !== action.payload.Id);
        case types.UPDATE_SETTINGS_PROFILE_SUCCESS:
            return [
                ...state.filter((profile) => profile.Id != action.payload.settingsProfile.Id),
                action.payload.settingsProfile
            ]
        default:
            return state;
    }
};

export default settingsProfileReducer;