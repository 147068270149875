import * as Yup from "yup";

const validationSchema = Yup.object().shape({
    CustomerId: Yup.string()
        .required("Customer is Required")
        .test(
            "customerId",
            "No Customer selected",
            (value) => value && value !== "null" && value.length > 0
        ),
    Name: Yup.string()
        .min(3, "Name must be at least 3 characters")
        .required("Name is Required"),
    IP: Yup.string()
        .required("IP(Address) is Required"),
    Port: Yup.number()
        .min(1, "Port must be at least 1 character")
        .max(65535, "Port must be less than 65535")
        .required("Port is Required")
        .typeError("Port must be a number"),
    Username: Yup.string()
        .required("Username is Required")
        .nullable(),
    Password: Yup.string()
        .required("Password is Required")
        .nullable(),
    API_KEY: Yup.string()
        .required("API_KEY is Required")
        .nullable(),
    Auth: Yup.string()
        .required("Authorization is Required")
        .nullable(),
    Status: Yup.number()
        .required("Status is Required"),
    MessageTypes: Yup.string()
        .required("Message Type is Required")
        .test(
            "MessageTypes",
            "No MessageTypes selected",
            (value) => value && value.length > 0
        ),
    Protocols: Yup.string()
        .required("Protocols is Required")
        .test(
            "Protocols",
            "No Protocols selected",
            (value) => value && value.length > 0
        )
        .when("MessageTypes", {
            is: (value) => value && value.length > 0 && value === '0',
            then: (schema) => schema.test(
                "MessageTypesWithProtocols",
                "MessageTypes and Protocols are not compatible",
                (value) => value && value.length > 0 && (value === '0' || value === '1' || value === '100')
            )
        })
        .when("MessageTypes", {
            is: (value) => value && value.length > 0 && value === '2',
            then: (schema) => schema.test(
                "MessageTypesWithProtocols",
                "MessageTypes and Protocols are not compatible",
                (value) => value && value.length > 0 && value !== '101' && value !== '100'
            )
        })
        .when("MessageTypes", {
            is: (value) => value && value.length > 0 && value === '5',
            then: (schema) => schema.test(
                "MessageTypesWithProtocols",
                "MessageTypes and Protocols are not compatible",
                (value) => value && value.length > 0 && (value === '0' || value === '1' || value === '101')
            )
        })
        .when("MessageTypes", {
            is: (value) => value && value.length > 0 && value !== '2' && value !== '5' && value !== '0',
            then: (schema) => schema.test(
                "MessageTypesWithProtocols",
                "MessageTypes and Protocols are not compatible",
                (value) => value && value.length > 0 && (value === '0' || value === '1')
            )
        })
});


export default validationSchema;