import React from "react";
import PropTypes from "prop-types";

// Time
import moment from "moment";

const RawMessageList = ({rawMessages}) => {
    
    return (
        <>
            <table className="table table-striped table-hover">
                <thead className="thead-inverse">
                <tr>
                    <th className="timeStamp-table-cell">TimeStamp</th>
                    <th>Valid JSON</th>
                    <th>RawMessage</th>
                </tr>
                </thead>
                <tbody className="raw-messages-table-body">
                {rawMessages.length === 0 ? (
                    <tr>
                        <td colSpan="12" style={{textAlign: "center"}}>
                            No messages found
                        </td>
                    </tr>
                ) : (
                    rawMessages.map((rawMessage) => {
                        return (
                            <tr className="table-row" key={rawMessage.Id}>
                                <td className="timeStamp-table-cell">
                                    {moment(rawMessage.TimeStampServer).format(
                                        "DD-MM-YYYY HH:mm:ss"
                                    )}
                                </td>
                                <td className="timeStamp-table-cell">
                                    {rawMessage.ValidJson === true ? "Yes" : "No"}
                                </td>
                                <td>{rawMessage.Message}</td>
                            </tr>
                        );
                    })
                )}
                </tbody>
            </table>
        </>
    );
}
RawMessageList.propTypes = {
    rawMessages: PropTypes.array.isRequired,
};

export default RawMessageList;
