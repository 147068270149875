import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

const customTestDataReducer = (state = initialState.customTestData, action) => {
    switch (action.type) {
        case types.LOAD_CUSTOM_TEST_DATA_SUCCESS:
            return action.payload.customTestData;
        case types.SAVE_CUSTOM_TEST_DATA_SUCCESS:
            return [...state, action.payload.customTestData];
        case types.UPDATE_CUSTOM_TEST_DATA_SUCCESS:
            return [
                ...state.filter((server) => server.Id !== action.payload.customTestData.Id),
                action.payload.customTestData,
            ];
        case types.REMOVE_CUSTOM_TEST_DATA_SUCCESS:
            return state.filter((server) => server.Id !== action.payload.customTestData);
        default:
            return state;
    }
};

export default customTestDataReducer;
