import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import { Table, Button } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import AddDevicesToCompanyCard from "./components/AddDevicesToCompanyCard";
import ChangeCustomerOnCompanyCard from "./components/ChangeCustomerOnCompanyCard";

import { getCustomerById } from "../../actions/customerActions";
import EditIcon from "../common/icons/pen-to-square-solid.svg";
import { updateCompanyCard } from "../../actions/companyCardActions";

const CustomerCompanyCardsTab = ({
    auth,
    companyCards,
    customers,
    currentcustomer
}) => {
    const dispatch = useDispatch();
    const [selectedCompanyCard, setSelectedCompanyCard] = useState(null);
    const [showChangeDevices, setShowChangeDevices] = useState(false);
    const [showChangeCostumer, setShowChangeCostumer] = useState(false);
    const [selected, setSelected] = useState([]);
    const [disableOptions, setDisableOptions] = useState(true);
    const selector = (companyCardId) => {
        let companyCard = null;
        companyCards.filter((obj) => obj.Id === companyCardId).map(res => {
            companyCard = res
        });
        setSelected(companyCard.Devices)
        setSelectedCompanyCard(companyCard);
    }

    const handleShowChangeDevices = (companyCard) => {
        setSelectedCompanyCard(companyCard);
        setShowChangeDevices(true);
    };

    const clearAllSelected = () => {
        setSelected([]);
    };

    useEffect(() => {
        disabledOptions();
    }, []);

    const disabledOptions = () => {
        if (auth.user.IsReseller) {
            setDisableOptions(false);
        } else {
            setDisableOptions(true);
        }
    };

    const showModalDeviceHandler = (e) => {
        setShowChangeDevices(true);
        selector(e);
    }

    const showModalCustomerHandler = (e) => {
        setShowChangeCostumer(true);
        selector(e);
    }

    const onSubmit = (values) => {
        setShowChangeCostumer(false);
        dispatch(updateCompanyCard(auth, selectedCompanyCard.Id, values))
            .then(() => {
                dispatch(getCustomerById(auth, currentcustomer.Id));
            });
    };

    const isViewer = auth.user.Role === "Viewer";
    // const isUser = auth.user.Role === "User";

    return (
        <div>
            <p style={{ float: "right" }}>
                <strong>Results: </strong> {companyCards.length}
            </p>
            <br />
            <br />
            <Table hover striped responsive>
                <thead>
                    <tr>
                        <th>Customer ID</th>
                        <th>Authentication Server ID</th>
                        <th>Card Number</th>
                        <th>Company Name</th>
                        <th>Valid Until</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {companyCards.length === 0 ? (
                        <tr>
                            <td colSpan="14" style={{ textAlign: "center" }}>
                                No company cards found
                            </td>
                        </tr>
                    ) : (
                        companyCards.map((companyCard) => {
                            return (
                                <tr
                                    className="table-row"
                                    key={companyCard.Id}
                                >
                                    <td>{companyCard.Customer}</td>
                                    <td>{companyCard.AuthServer}</td>
                                    <td>{companyCard.CardNumber}</td>
                                    <td
                                        key={companyCard.Id} onClick={() => handleShowChangeDevices(companyCard)}
                                    >{companyCard.CompanyName}</td>
                                    <td>{companyCard.ValidUntil}</td>
                                    <td>
                                        {/* {!isViewer && ( */}
                                        {!isViewer && (
                                            <Button className="shadow-sm border-secondary" variant="light"
                                                onClick={() => {
                                                    showModalDeviceHandler(companyCard.Id)
                                                }}
                                            >
                                                <img
                                                    src={EditIcon}
                                                    alt="Edit"
                                                />
                                                <span className="ml-1">Device</span>
                                            </Button>
                                        )}
                                        {/* )} */}
                                    </td>
                                    <td>
                                        {!isViewer && (
                                            <Button className="shadow-sm border-secondary" variant="light"
                                                onClick={() => {
                                                    showModalCustomerHandler(companyCard.Id)
                                                }}
                                            >
                                                <img
                                                    src={EditIcon}
                                                    alt="Edit"
                                                />
                                                <span className="ml-1">Customer</span>
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </Table>
            {selectedCompanyCard && (
                <AddDevicesToCompanyCard
                    show={showChangeDevices}
                    setShow={setShowChangeDevices}
                    selected={selected}
                    setSelected={setSelected}
                    companyCard={selectedCompanyCard}
                    disableOptions={disableOptions}
                    clearAllSelected={clearAllSelected}
                />
            )}
            {selectedCompanyCard && (
                <ChangeCustomerOnCompanyCard
                    customers={customers}
                    selectedCompanyCard={selectedCompanyCard}
                    show={showChangeCostumer}
                    setShow={setShowChangeCostumer}
                    onSubmit={onSubmit}
                />
            )}
        </div>
    );
};

CustomerCompanyCardsTab.propTypes = {
    auth: propTypes.object.isRequired,
    companyCards: propTypes.array.isRequired,
    customers: propTypes.array.isRequired,
    currentcustomer: propTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        companyCards: state.customerDetails.companyCards,
        customers: state.customers,
        currentcustomer: state.customerDetails.customer
    };
}

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerCompanyCardsTab);
