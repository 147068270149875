import React, {useEffect} from 'react';
import {connect} from "react-redux";
import moment from "moment";
import * as PropTypes from "prop-types";
import checkSolid from "../../../common/icons/checkSolid.svg";
import xmarkSolid from "../../../common/icons/xmarkSolid.svg";
import {Card, CloseButton, Row, Col, Alert, Button, Spinner as Spinnerbt} from "react-bootstrap";

//DeviceTests aanvullen = deviceTestData
//checkPendingCondition erbij zetten voor oa ignition en odo stand en alles wat een bevestigingsknop nodig heeft.
const DeviceTest = ({
                        rawMessages,
                        deviceTestData,
                        deviceInputSettings,
                        checkPendingCondition,
                        handleDeviceTestPageClose
                    }) => {

    useEffect(() => {
    }, [rawMessages])

    const deviceTestRows = () => {
        const rows = [...Array(Math.ceil(deviceTestData.length / 4))];
        const deviceRows = rows.map((row, idx) => deviceTestData.slice(idx * 4, idx * 4 + 4));

        return (deviceRows.map((rowData, rowNum) => (
            <div key={rowNum}>
                <Row key={rowNum}>
                    {rowData.map(testData => (
                        <Col key={testData.title}>
                            <Card
                                className={testData.state === 1 ? "bg-success" : testData.state === 3 ? "bg-danger" : null}>
                                <Card.Title>{testData.title}</Card.Title>
                                <Card.Body>{testData.state === 2
                                    ? (
                                        (testData.title === "Valid Ignition State" || testData.title === "Validate Private Switch") &&
                                        testData.value !== null &&
                                        testData.state === 2
                                            ? (<>
                                                <Alert variant="danger">{testData.value}</Alert>
                                                <Spinnerbt animation="border" variant="primary"/>
                                            </>)
                                            : (<Spinnerbt animation="border" variant="primary"/>))
                                    : (testData.value
                                        ? (testData.value && testData.state === 4
                                            ? (<>
                                                {testData.value}
                                                <br/>
                                                <Button
                                                    variant="success"
                                                    onClick={() => checkPendingCondition('true')}>
                                                    <img
                                                        src={checkSolid}
                                                        alt="CorrectOdo"
                                                    ></img>
                                                </Button>{' '}
                                                <Button
                                                    variant="danger"
                                                    onClick={() => checkPendingCondition('false')}>
                                                    <img
                                                        src={xmarkSolid}
                                                        alt="IncorrectOdo"
                                                    ></img>
                                                </Button>{' '}
                                            </>)
                                            : (testData.value))
                                        : testData.desc)}</Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <br/>
            </div>
        )));
    }

    return (
        <>
            <br/>
            <Card>
                <Card.Header>
                    <Card.Title>
                        <h2 className="title">
                            <CloseButton onClick={handleDeviceTestPageClose}/>
                            Device : {deviceInputSettings.Imei}
                        </h2>
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    {deviceTestRows()}
                </Card.Body>
            </Card>
            <br/>
            <h3>Live rawmessage</h3>
            <table className="table table-striped table-hover">
                <thead className="thead-inverse">
                <tr>
                    <th>Timestamp</th>
                    <th>Message</th>
                </tr>
                </thead>
                <tbody className="raw-messages-table-body">
                {rawMessages.map((rawMessage) => {
                    return (
                        <tr className="table-row" key={rawMessage.Id}>
                            <td>
                                {moment(rawMessage.TimeStampServer).format(
                                    "DD-MM-YYYY HH:mm:ss"
                                )}
                            </td>
                            <td colSpan="2">{rawMessage.Message}</td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </>
    );
};

DeviceTest.propTypes = {
    deviceTestData: PropTypes.array.isRequired,
    deviceInputSettings: PropTypes.object.isRequired,
    checkPendingCondition: PropTypes.func.isRequired,
    handleDeviceTestPageClose: PropTypes.func.isRequired,
    rawMessages: PropTypes.array.isRequired,
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceTest);